import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = (n) => (_pushScopeId("data-v-e76584e0"), n = n(), _popScopeId(), n);
const _hoisted_1 = { ref: "dpComponent" };
const _hoisted_2 = ["value", "disabled"];
const _hoisted_3 = {
    key: 0,
    class: "relative w-full"
};
const _hoisted_4 = { class: "absolute top-2 flex w-full overflow-hidden rounded-lg border-2 border-solid border-neutral-900 bg-white" };
const _hoisted_5 = { class: "datepicker-col" };
const _hoisted_6 = ["onClick"];
const _hoisted_7 = { class: "datepicker-col" };
const _hoisted_8 = ["onClick"];
const _hoisted_9 = { class: "datepicker-col" };
const _hoisted_10 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("input", {
            value: _ctx.displayDate,
            placeholder: "DD/MM/AAAA",
            class: "box-border w-full rounded-md border-2 border-black bg-transparent p-2 text-sm placeholder-neutral-500",
            onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.visible = true)),
            disabled: _ctx.disabled
        }, null, 8, _hoisted_2),
        (_ctx.visible)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("ul", _hoisted_5, [
                        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(31, (d) => {
                            return _createElementVNode("li", {
                                key: d,
                                class: _normalizeClass({ active: _ctx.isCurrentDay(d) }),
                                onClick: ($event) => (_ctx.selectDay(d))
                            }, _toDisplayString(d), 11, _hoisted_6);
                        }), 64))
                    ]),
                    _createElementVNode("ul", _hoisted_7, [
                        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(12, (m) => {
                            return _createElementVNode("li", {
                                key: m,
                                class: _normalizeClass({ active: _ctx.isCurrentMonth(m) }),
                                onClick: ($event) => (_ctx.selectMonth(m))
                            }, _toDisplayString(m), 11, _hoisted_8);
                        }), 64))
                    ]),
                    _createElementVNode("ul", _hoisted_9, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.yearRange, (y) => {
                            return (_openBlock(), _createElementBlock("li", {
                                key: y,
                                class: _normalizeClass({ active: _ctx.isCurrentYear(y) }),
                                onClick: ($event) => (_ctx.selectYear(y))
                            }, _toDisplayString(y), 11, _hoisted_10));
                        }), 128))
                    ])
                ])
            ]))
            : _createCommentVNode("", true)
    ], 512));
}
