import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = (n) => (_pushScopeId("data-v-8a16f612"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["min", "max", "disabled", "value"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("input", {
        ref: "slider",
        type: "range",
        class: _normalizeClass(["pe-slider", _ctx.baseClasses]),
        min: _ctx.min,
        max: _ctx.max,
        disabled: _ctx.disabled,
        value: _ctx.modelValue,
        onInput: _cache[0] || (_cache[0] =
            //@ts-ignore
            (...args) => (_ctx.onUpdate && _ctx.onUpdate(...args)))
    }, null, 42, _hoisted_1));
}
