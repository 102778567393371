import { toast } from '@/common/util';
import baseApi from './base.api';
import { plantarApiV2 } from '@/config/appAPIs';
export const contratoService = {
    ...baseApi,
    resource: 'Contrato',
    getAll(opt, options) {
        const paramsDictionary = {};
        const orderDictionary = {};
        const extraData = {};
        Object.keys(opt).forEach((key) => {
            if (typeof paramsDictionary[key] !== 'undefined' && typeof opt[key] !== 'undefined' && opt[key] !== null)
                extraData[paramsDictionary[key]] = opt[key];
        });
        // Ordenação
        if (opt.orderBy?.length) {
            extraData['Ordenacao'] = opt.orderBy.map((item) => `${orderDictionary[item.prop]}${item.order === 'DESC' ? 'Desc' : 'Asc'}`);
        }
        return contratoService
            .get('', extraData, options)
            .then(({ success, data }) => {
            return {
                success,
                // normalize the received data, convert the attributes from portuguese to english, and properly format the data
                data: data.map((item) => ({
                    id: item.id,
                    personId: item.pessoaId,
                    agbAccountCode: item.codigoContaAGB,
                    expirationDate: item.dataDeValidade ? new Date(item.dataDeValidade) : null,
                    contractDate: item.dataDeContrato ? new Date(item.dataDeContrato) : null,
                    observation: item.observacao,
                    usersQuantity: item.quantidadeDeUsuariosContratados,
                    diskSpace: item.espacoEmDiscoContratado,
                    coursesRecordsLimit: item.limiteDeRegistrosDeCursos,
                    trailsRecordsLimit: item.limiteDeRegistroDeFormacoes,
                    file: item.arquivo,
                    responsibleName: item.nomeDoResponsavel,
                    responsibleEmail: item.emailDoResponsavel,
                    responsibleCellphone: item.celularDoResponsavel,
                    defaultAccessUrl: item.urlDeAcessoPadrao,
                    customAccessUrl: item.urlDeAcessoPersonalizado,
                    communicationEmail: item.emailDeComunicacaoUsadoPelaEmpresa,
                    managerPersonType: item.tipoPessoaGestor,
                    managerCpf: item.cpfGestor,
                    managerEmail: item.emailGestor,
                    managerName: item.nomeGestor,
                    managerPersonId: item.personIdGestor,
                    managerUserId: item.userIdGestor,
                    contractTenantId: item.tenantIdDoContrato,
                    owner: item.owner,
                    bunnyVideoCollectionId: item.bunnyVideoCollectionId,
                    active: item.active,
                    companyName: item.nomeEmpresa,
                    aliareIdentificationDocumentPerson: item.aliareIdentificationDocumentPeople,
                    aliareTypePeople: item.aliareTypePeople,
                    activeUsersQuantity: item.qtdeUsuarioAtivos,
                    sectionsIds: item.secoesIds,
                    aliareSubscriptionId: item.aliareSubscriptionId,
                    createdAt: new Date(item.createdAt),
                    clientClassification: item.classificacaoCliente,
                    projectStatus: item.statusProjeto,
                    companyId: item.empresaId,
                    aliareProduct: item.produtoAliare,
                    communicationsContracts: item.contratosComunicacoes
                }))
            };
        });
    },
    async isDistribuidor() {
        try {
            return await this.get(`IsDistribuidor`);
        }
        catch (error) {
            const { notifications } = error.response?.data || {};
            toast.error({ message: notifications[0] || 'Erro ao verificar informações do usuário!' });
        }
    },
    async getUsageInfo() {
        try {
            return await this.get(`UsageInfo`);
        }
        catch (error) {
            const { notifications } = error.response?.data || {};
            toast.error({ message: notifications[0] || 'Erro ao buscar informações do contrato!' });
        }
    },
    async create(newContract) {
        try {
            return await plantarApiV2.post(this.resource, { ...newContract });
        }
        catch (error) {
            const { notifications } = error.response?.data || {};
            toast.error({ message: notifications[0] || 'Erro ao criar contrato!' });
        }
    }
};
export default contratoService;
