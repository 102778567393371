import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "bg-neutral-100 dark:bg-neutral-800 pb-20 mb-20 rounded-lg" };
const _hoisted_2 = { class: "text-right" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_pe_icon = _resolveComponent("pe-icon");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("button", {
                class: "cursor-pointer border-0 bg-transparent p-4 text-xl hover:text-neutral-700",
                onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.$emit('onClose')))
            }, [
                _createVNode(_component_pe_icon, null, {
                    default: _withCtx(() => [
                        _createTextVNode("close")
                    ]),
                    _: 1
                })
            ])
        ]),
        _renderSlot(_ctx.$slots, "default")
    ]));
}
