<template>
	<svg version="1.1"
		id="svg2458" xmlns:svg="http://www.w3.org/2000/svg" xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape" xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
		xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 248.6 222.3"
		style="enable-background:new 0 0 248.6 222.3;" xml:space="preserve">
		<g id="c" transform="translate(-77.05,-74.04)">
			<g id="g2562">
				<path id="path2257" class="st0" d="M79.2,80.1h209.4v125.3H79.2V80.1z M250.1,256.4h47.2V302h-47.2L250.1,256.4L250.1,256.4z"/>
				<path id="path2259" class="st1" d="M255.1,292.1l13-23.5l10.7,15l6.5-8l9.6,16.4h-39.8L255.1,292.1L255.1,292.1z M283.3,264
					c0,1.9-1.5,3.4-3.4,3.4s-3.4-1.5-3.4-3.4l0,0c0-1.9,1.5-3.4,3.4-3.4C281.8,260.7,283.3,262.2,283.3,264z"/>
				<g id="g2263">
					<path id="path2261" class="st1" d="M294.9,292.2c0,0,0-0.1,0-0.2s0-0.3,0-0.5c0-0.5,0-1.2,0-2.1c0-1.8,0-4.4,0-7.8
						c0-6.7-0.1-16.1-0.1-27.5l0.2,0.2c-11.5,0-25.2,0-39.8,0c-0.1,0,0.4-0.4,0.2-0.2v10.2c0,3.4,0,6.6,0,9.8c0,6.4,0,12.4,0,18
						l-0.2-0.2c11.9,0,21.8,0.1,28.8,0.1c3.5,0,6.2,0,8.1,0.1c0.9,0,1.6,0,2.2,0c0.2,0,0.4,0,0.6,0
						C294.9,292.2,294.9,292.2,294.9,292.2s-0.1,0-0.2,0s-0.3,0-0.5,0c-0.5,0-1.2,0-2.1,0c-1.9,0-4.6,0-8.1,0c-7,0-17,0.1-28.9,0.1
						h-0.2v-0.2c0-5.6,0-11.6,0-18c0-3.2,0-6.5,0-9.8v-10.2c-0.2,0.2,0.3-0.3,0.2-0.2c14.6,0,28.3,0,39.8,0h0.2v0.2
						c0,11.4-0.1,21-0.1,27.7c0,3.3,0,5.9,0,7.7c0,0.9,0,1.5,0,2c0,0.2,0,0.4,0,0.5C295,292.2,294.9,292.2,294.9,292.2L294.9,292.2z"
						/>
				</g>
				<path id="path2265" class="st2" d="M87.9,89.5h211.7v120.4H87.9V89.5z"/>
				<g id="g2291">
					<path id="path2283" class="st1" d="M236,267.7l6.3,3.4l-6.1,4l-6.1,4l-0.2-7.4l-0.3-7.4L236,267.7z"/>
					<g id="g2289">
						<path id="path2285" class="st1" d="M268.1,291.4c0,0.1-14.7,0.1-32.9,0.2c-18.2,0-32.9,0-32.9-0.1s14.7-0.1,32.9-0.2
							C253.4,291.3,268.1,291.4,268.1,291.4z"/>
						<path id="path2287" class="st1" d="M203.4,291.5c0,0.5-0.4,0.8-0.8,0.8s-0.8-0.4-0.8-0.8c0-0.5,0.4-0.8,0.8-0.8
							S203.4,291,203.4,291.5z"/>
					</g>
				</g>
				<g id="g2303">
					<path id="path2293" class="cs" d="M273.3,296.8H194c-0.6,0-1.1-0.5-1.1-1.1v-48c0-0.6,0.5-1.1,1.1-1.1h79.3
						c0.6,0,1.1,0.5,1.1,1.1v48C274.4,296.3,273.9,296.8,273.3,296.8z"/>
					<path id="path2295" class="st1" d="M234.5,266.7l6.7,3.6l-6.4,4.2l-6.4,4.2l-0.3-7.8l-0.3-7.8L234.5,266.7z"/>
					<g id="g2301">
						<path id="path2297" class="st1" d="M268.5,291.8c0,0.1-15.6,0.1-34.9,0.2c-19.3,0-34.9,0-34.9-0.1s15.6-0.1,34.9-0.2
							C252.9,291.7,268.5,291.7,268.5,291.8L268.5,291.8z"/>
						<path id="path2299" class="st1" d="M199.9,291.8c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9
							S199.9,291.4,199.9,291.8z"/>
					</g>
				</g>
				<g id="g2315">
					<path id="rect2305" class="st0" d="M185.9,267.4h44.7c0.6,0,1.1,0.5,1.1,1.1v26.7c0,0.6-0.5,1.1-1.1,1.1h-44.7
						c-0.6,0-1.1-0.5-1.1-1.1v-26.7C184.8,267.9,185.3,267.4,185.9,267.4z"/>
					<path id="path2307" class="st1" d="M208.7,278.9l3.8,2.1l-3.7,2.4l-3.7,2.4l-0.2-4.5l-0.2-4.5L208.7,278.9z"/>
					<g id="g2313">
						<path id="path2309" class="st1" d="M228.2,293.3c0,0.1-9,0.1-20,0.1c-11.1,0-20-0.1-20-0.1s9-0.1,20-0.1
							C219.3,293.2,228.2,293.2,228.2,293.3z"/>
						<path id="path2311" class="st1" d="M188.9,293.3c0,0.3-0.2,0.5-0.5,0.5s-0.5-0.2-0.5-0.5s0.2-0.5,0.5-0.5
							S188.9,293.1,188.9,293.3z"/>
					</g>
				</g>
				<g id="g2373">
					<path id="path2317" class="st1" d="M97.4,100.2H307v133.6H97.4V100.2z"/>
					<path id="path2319" class="st4" d="M307.2,233.2c0-1,0-51.6-0.1-133.6l0.1,0.1c-56.2,0-129.6,0-209.6,0l0,0l0.2-0.2
						c0,47.3,0,92.4,0,133.6l-0.1-0.2C220.4,233.2,305.9,233.2,307.2,233.2c-1.3,0-86.8,0.1-209.6,0.2h-0.1v-0.2
						c0-41.1,0-86.3,0-133.6v-0.2h0.2c80,0,153.4,0,209.6,0h0.1v0.1C307.2,181.6,307.2,232.2,307.2,233.2L307.2,233.2z"/>
					<path id="path2321" class="st4" d="M307.3,107.7c0,0.1-47,0.2-104.9,0.2s-104.9-0.1-104.9-0.2s47-0.2,104.9-0.2
						S307.3,107.6,307.3,107.7z M104.3,103.7c0,0.8-0.6,1.4-1.4,1.4s-1.4-0.6-1.4-1.4s0.6-1.4,1.4-1.4S104.3,103,104.3,103.7z
						M108.5,103.7c0,0.8-0.6,1.4-1.4,1.4s-1.4-0.6-1.4-1.4s0.6-1.4,1.4-1.4S108.5,103,108.5,103.7z M112.6,103.7
						c0,0.8-0.6,1.4-1.4,1.4s-1.4-0.6-1.4-1.4s0.6-1.4,1.4-1.4S112.6,103,112.6,103.7z"/>
					<path id="path2323" class="st0" d="M111.4,179.6h43.2v41.8h-43.2C111.4,221.4,111.4,179.6,111.4,179.6z"/>
					<path id="path2325" class="st1" d="M114.8,217.9l12-21.5l9.8,13.8l5.9-7.3l8.8,15.1h-36.5V217.9z M140.7,192.2
						c0,1.7-1.4,3.1-3.1,3.1c-1.7,0-3.1-1.4-3.1-3.1s1.4-3.1,3.1-3.1S140.7,190.4,140.7,192.2z"/>
					<path id="path2327" class="st1" d="M151.3,218c0,0,0-0.1,0-0.2c0-0.1,0-0.3,0-0.5c0-0.4,0-1.1,0-1.9c0-1.7,0-4.1,0-7.1
						c0-6.1-0.1-14.8-0.1-25.3l0.2,0.2c-10.6,0-23.1,0-36.5,0c-0.1,0,0.4-0.4,0.2-0.2v9.4c0,3.1,0,6.1,0,9c0,5.9,0,11.4,0,16.5
						l-0.2-0.2c10.9,0,20,0.1,26.4,0.1c3.2,0,5.7,0,7.4,0c0.8,0,1.5,0,2,0c0.2,0,0.4,0,0.5,0C151.2,218,151.3,218,151.3,218
						s-0.1,0-0.2,0c-0.1,0-0.3,0-0.5,0c-0.5,0-1.1,0-1.9,0c-1.7,0-4.2,0-7.4,0c-6.4,0-15.6,0.1-26.5,0.1h-0.2V218c0-5.1,0-10.6,0-16.5
						c0-2.9,0-5.9,0-9v-9.4c-0.2,0.2,0.2-0.3,0.2-0.2c13.4,0,25.9,0,36.5,0h0.2v0.2c0,10.5-0.1,19.2-0.1,25.4c0,3,0,5.4,0,7.1
						c0,0.8,0,1.4,0,1.9c0,0.2,0,0.4,0,0.5C151.3,217.9,151.3,218,151.3,218L151.3,218z"/>
					<g id="g2333">
						<path id="path2329" class="st5" d="M160.7,187.6H190v1.5h-29.3V187.6z"/>
						<path id="path2331" class="st4" d="M200.1,193.9c0,0.1-8.8,0.1-19.6,0.1s-19.6-0.1-19.6-0.1c0-0.1,8.8-0.1,19.6-0.1
							S200.1,193.8,200.1,193.9z M200.1,198c0,0.1-8.8,0.1-19.6,0.1s-19.6-0.1-19.6-0.1c0-0.1,8.8-0.1,19.6-0.1S200.1,197.9,200.1,198
							z M200.1,202.1c0,0.1-8.8,0.1-19.6,0.1s-19.6-0.1-19.6-0.1c0-0.1,8.8-0.1,19.6-0.1S200.1,202,200.1,202.1z M200.1,206.2
							c0,0.1-8.8,0.1-19.6,0.1s-19.6-0.1-19.6-0.1c0-0.1,8.8-0.1,19.6-0.1S200.1,206.1,200.1,206.2z M200.1,210.3
							c0,0.1-8.8,0.1-19.6,0.1s-19.6-0.1-19.6-0.1c0-0.1,8.8-0.1,19.6-0.1S200.1,210.2,200.1,210.3z M181,214.1
							c0,0.1-4.5,0.1-10.1,0.1s-10.1-0.1-10.1-0.1c0-0.1,4.5-0.1,10.1-0.1S181,214,181,214.1z"/>
					</g>
					<g id="g2339">
						<path id="path2335" class="st5" d="M255.2,185.1h29.3v1.5h-29.3V185.1z"/>
						<path id="path2337" class="st4" d="M294.6,191.4c0,0.1-8.8,0.1-19.6,0.1c-10.8,0-19.6-0.1-19.6-0.1c0-0.1,8.8-0.1,19.6-0.1
							S294.6,191.3,294.6,191.4z M294.6,195.5c0,0.1-8.8,0.1-19.6,0.1c-10.8,0-19.6-0.1-19.6-0.1c0-0.1,8.8-0.1,19.6-0.1
							S294.6,195.4,294.6,195.5z M294.6,199.6c0,0.1-8.8,0.1-19.6,0.1c-10.8,0-19.6-0.1-19.6-0.1c0-0.1,8.8-0.1,19.6-0.1
							S294.6,199.5,294.6,199.6z M294.6,203.7c0,0.1-8.8,0.1-19.6,0.1c-10.8,0-19.6-0.1-19.6-0.1c0-0.1,8.8-0.1,19.6-0.1
							S294.6,203.7,294.6,203.7z M294.6,207.8c0,0.1-8.8,0.1-19.6,0.1c-10.8,0-19.6-0.1-19.6-0.1c0-0.1,8.8-0.1,19.6-0.1
							S294.6,207.8,294.6,207.8z M275.5,211.6c0,0.1-4.5,0.1-10.1,0.1s-10.1-0.1-10.1-0.1c0-0.1,4.5-0.1,10.1-0.1
							S275.5,211.5,275.5,211.6z"/>
					</g>
					<g id="g2343">
						<path id="path2341" class="st0" d="M249.1,222.9c0-0.1,0.6-0.1,1.4-0.1l-0.1,0.1v-0.3v-0.3c0-0.2,0-0.3,0-0.5
							c0-0.3,0.1-0.4,0.1-0.4s0.1,0.2,0.1,0.4c0,0.1,0,0.3,0,0.5v0.3v0.3c-0.1,0.1,0.1-0.1-0.1,0.1C249.8,223,249.1,222.9,249.1,222.9
							L249.1,222.9z M242.9,222.9c0-0.1,0.7-0.1,1.6-0.1s1.6,0.1,1.6,0.1c0,0.1-0.7,0.1-1.6,0.1S242.9,222.9,242.9,222.9z
							M236.7,222.9c0-0.1,0.7-0.1,1.6-0.1s1.6,0.1,1.6,0.1c0,0.1-0.7,0.1-1.6,0.1S236.7,222.9,236.7,222.9z M230.5,222.9
							c0-0.1,0.7-0.1,1.6-0.1s1.6,0.1,1.6,0.1c0,0.1-0.7,0.1-1.6,0.1S230.5,222.9,230.5,222.9z M224.3,222.9c0-0.1,0.7-0.1,1.6-0.1
							s1.6,0.1,1.6,0.1c0,0.1-0.7,0.1-1.6,0.1S224.3,222.9,224.3,222.9z M218.1,222.9c0-0.1,0.7-0.1,1.6-0.1s1.6,0.1,1.6,0.1
							c0,0.1-0.7,0.1-1.6,0.1S218.1,222.9,218.1,222.9z M211.9,222.9c0-0.1,0.7-0.1,1.6-0.1s1.6,0.1,1.6,0.1c0,0.1-0.7,0.1-1.6,0.1
							S211.9,222.9,211.9,222.9z M207.4,221.4c0.1,0,0.1,0.6,0.1,1.4l-0.1-0.1h0.3c0.1,0,0.2,0,0.3,0c0.2,0,0.3,0,0.5,0
							c0.3,0,0.4,0.1,0.4,0.1s-0.2,0.1-0.4,0.1c-0.1,0-0.3,0-0.5,0c-0.1,0-0.2,0-0.3,0h-0.3c-0.1-0.1,0.1,0.2-0.1-0.1
							C207.2,222.1,207.3,221.4,207.4,221.4L207.4,221.4z M207.4,215.4c0.1,0,0.1,0.7,0.1,1.5s-0.1,1.5-0.1,1.5
							c-0.1,0-0.1-0.7-0.1-1.5S207.3,215.4,207.4,215.4z M207.4,209.4c0.1,0,0.1,0.7,0.1,1.5s-0.1,1.5-0.1,1.5c-0.1,0-0.1-0.7-0.1-1.5
							S207.3,209.4,207.4,209.4z M207.4,203.4c0.1,0,0.1,0.7,0.1,1.5s-0.1,1.5-0.1,1.5c-0.1,0-0.1-0.7-0.1-1.5
							S207.3,203.4,207.4,203.4z M207.4,197.5c0.1,0,0.1,0.7,0.1,1.5s-0.1,1.5-0.1,1.5c-0.1,0-0.1-0.7-0.1-1.5
							S207.3,197.5,207.4,197.5z M207.4,191.5c0.1,0,0.1,0.7,0.1,1.5s-0.1,1.5-0.1,1.5c-0.1,0-0.1-0.7-0.1-1.5
							S207.3,191.5,207.4,191.5z M207.4,185.5c0.1,0,0.1,0.7,0.1,1.5s-0.1,1.5-0.1,1.5c-0.1,0-0.1-0.7-0.1-1.5
							S207.3,185.5,207.4,185.5z M208.8,181c0,0.1-0.6,0.1-1.4,0.1l0.1-0.1v0.3v0.3c0,0.2,0,0.3,0,0.5c0,0.3-0.1,0.4-0.1,0.4
							s-0.1-0.2-0.1-0.4c0-0.1,0-0.3,0-0.5c0-0.1,0-0.2,0-0.3V181c0.1-0.1-0.1,0.1,0.1-0.1C208.2,180.9,208.8,180.9,208.8,181
							L208.8,181z M215,181c0,0.1-0.7,0.1-1.6,0.1s-1.6-0.1-1.6-0.1c0-0.1,0.7-0.1,1.6-0.1S215,181,215,181z M221.2,181
							c0,0.1-0.7,0.1-1.6,0.1S218,181,218,181c0-0.1,0.7-0.1,1.6-0.1S221.2,181,221.2,181z M227.4,181c0,0.1-0.7,0.1-1.6,0.1
							s-1.6-0.1-1.6-0.1c0-0.1,0.7-0.1,1.6-0.1S227.4,181,227.4,181z M233.6,181c0,0.1-0.7,0.1-1.6,0.1s-1.6-0.1-1.6-0.1
							c0-0.1,0.7-0.1,1.6-0.1S233.6,181,233.6,181z M239.8,181c0,0.1-0.7,0.1-1.6,0.1s-1.6-0.1-1.6-0.1c0-0.1,0.7-0.1,1.6-0.1
							S239.8,181,239.8,181z M246.1,181c0,0.1-0.7,0.1-1.6,0.1s-1.6-0.1-1.6-0.1c0-0.1,0.7-0.1,1.6-0.1S246.1,181,246.1,181z
							M250.6,182.5c-0.1,0-0.1-0.6-0.1-1.4l0.1,0.1h-0.3c-0.1,0-0.2,0-0.3,0c-0.2,0-0.3,0-0.5,0c-0.3,0-0.4-0.1-0.4-0.1
							s0.2-0.1,0.4-0.1c0.1,0,0.3,0,0.5,0h0.3h0.3c0.1,0.1-0.1-0.2,0.1,0.1C250.8,181.8,250.7,182.5,250.6,182.5L250.6,182.5z
							M250.6,188.5c-0.1,0-0.1-0.7-0.1-1.5s0.1-1.5,0.1-1.5c0.1,0,0.1,0.7,0.1,1.5S250.7,188.5,250.6,188.5z M250.6,194.5
							c-0.1,0-0.1-0.7-0.1-1.5s0.1-1.5,0.1-1.5c0.1,0,0.1,0.7,0.1,1.5S250.7,194.5,250.6,194.5z M250.6,200.5c-0.1,0-0.1-0.7-0.1-1.5
							s0.1-1.5,0.1-1.5c0.1,0,0.1,0.7,0.1,1.5S250.7,200.5,250.6,200.5z M250.6,206.4c-0.1,0-0.1-0.7-0.1-1.5s0.1-1.5,0.1-1.5
							c0.1,0,0.1,0.7,0.1,1.5S250.7,206.4,250.6,206.4z M250.6,212.4c-0.1,0-0.1-0.7-0.1-1.5s0.1-1.5,0.1-1.5c0.1,0,0.1,0.7,0.1,1.5
							S250.7,212.4,250.6,212.4z M250.6,218.4c-0.1,0-0.1-0.7-0.1-1.5s0.1-1.5,0.1-1.5c0.1,0,0.1,0.7,0.1,1.5S250.7,218.4,250.6,218.4
							z"/>
					</g>
					<g id="g2355">
						<path id="path2345" class="st5" d="M237.3,141.2c0,0,0-0.1,0-0.3s0-0.5,0-0.9c0-0.8,0-1.9,0-3.4c0-2.9,0-7.1-0.1-12.2l0.1,0.1
							c-7.4,0-16.9,0-27.2,0c0,0,0.3-0.3,0.1-0.1v8.6c0,2.8,0,5.5,0,8.1l-0.1-0.1c7.9,0,14.7,0,19.5,0.1c2.4,0,4.3,0,5.6,0
							c0.6,0,1.1,0,1.5,0c0.2,0,0.3,0,0.4,0C237.3,141.2,237.3,141.2,237.3,141.2s0,0-0.1,0s-0.2,0-0.4,0c-0.4,0-0.8,0-1.5,0
							c-1.3,0-3.2,0-5.6,0c-4.8,0-11.6,0-19.6,0.1H210v-0.1c0-2.6,0-5.3,0-8.1v-8.6c-0.1,0.1,0.2-0.2,0.1-0.1c10.3,0,19.8,0,27.2,0
							h0.1v0.1c0,5.1,0,9.3-0.1,12.2c0,1.4,0,2.6,0,3.3c0,0.4,0,0.7,0,0.9C237.3,141.1,237.3,141.2,237.3,141.2L237.3,141.2z"/>
						<path id="path2347" class="st5" d="M223.9,131.5l2.2,1.2l-2.1,1.4l-2.1,1.4l-0.1-2.6l-0.1-2.6L223.9,131.5z M235.4,139.5
							c0,0.1-5.2,0.1-11.6,0.1s-11.6-0.1-11.6-0.1s5.2-0.1,11.6-0.1S235.4,139.5,235.4,139.5z"/>
						<circle id="circle2349" class="cp" cx="212.2" cy="139.5" r="0.3"/>
						<path id="path2351" class="st5" d="M243.4,126.4h29.3v1.5h-29.3L243.4,126.4L243.4,126.4z"/>
						<path id="path2353" class="st7" d="M282.9,132.8c0,0.1-8.8,0.1-19.6,0.1c-10.8,0-19.6-0.1-19.6-0.1s8.8-0.2,19.6-0.2
							C274.1,132.6,282.9,132.7,282.9,132.8z M282.9,136.9c0,0.1-8.8,0.2-19.6,0.2c-10.8,0-19.6-0.1-19.6-0.2s8.8-0.1,19.6-0.1
							C274.1,136.8,282.9,136.8,282.9,136.9z"/>
					</g>
					<g id="g2367">
						<path id="path2357" class="st5" d="M237.3,165.8c0,0,0-0.1,0-0.3s0-0.5,0-0.9c0-0.8,0-1.9,0-3.4c0-2.9,0-7.1-0.1-12.2l0.1,0.1
							c-7.4,0-16.9,0-27.2,0c0,0,0.3-0.3,0.1-0.1v8.6c0,2.8,0,5.5,0,8.1l-0.1-0.1c7.9,0,14.7,0,19.5,0.1c2.4,0,4.3,0,5.6,0
							c0.6,0,1.1,0,1.5,0c0.2,0,0.3,0,0.4,0C237.3,165.8,237.3,165.8,237.3,165.8s0,0-0.1,0s-0.2,0-0.4,0c-0.4,0-0.8,0-1.5,0
							c-1.3,0-3.2,0-5.6,0c-4.8,0-11.6,0-19.6,0.1H210v-0.1c0-2.6,0-5.3,0-8.1v-8.6c-0.1,0.1,0.2-0.2,0.1-0.1c10.3,0,19.8,0,27.2,0
							h0.1v0.1c0,5.1,0,9.3-0.1,12.2c0,1.4,0,2.6,0,3.3c0,0.4,0,0.7,0,0.9C237.3,165.7,237.3,165.8,237.3,165.8L237.3,165.8z"/>
						<path id="path2359" class="st5" d="M223.9,156l2.2,1.2l-2.1,1.4l-2.1,1.4l-0.1-2.6l-0.1-2.6L223.9,156z M235.4,164.1
							c0,0.1-5.2,0.1-11.6,0.1s-11.6-0.1-11.6-0.1s5.2-0.1,11.6-0.1S235.4,164,235.4,164.1z"/>
						<circle id="circle2361" class="cp" cx="212.2" cy="164.1" r="0.3"/>
						<path id="path2363" class="st5" d="M243.4,151h29.3v1.5h-29.3L243.4,151L243.4,151z"/>
						<path id="path2365" class="st7" d="M282.9,157.3c0,0.1-8.8,0.2-19.6,0.2c-10.8,0-19.6-0.1-19.6-0.2s8.8-0.1,19.6-0.1
							C274.1,157.2,282.9,157.2,282.9,157.3z M282.9,161.4c0,0.1-8.8,0.1-19.6,0.1c-10.8,0-19.6-0.1-19.6-0.1s8.8-0.2,19.6-0.2
							C274.1,161.2,282.9,161.4,282.9,161.4z"/>
					</g>
					<g id="g2371">
						<path id="path2369" class="st2" d="M195.9,171.7c0-0.1,0.6-0.1,1.3-0.1l-0.1,0.1v-0.3v-0.2c0-0.2,0-0.3,0-0.4
							c0-0.2,0.1-0.4,0.1-0.4s0.1,0.1,0.1,0.4c0,0.1,0,0.3,0,0.4v0.2v0.3c-0.1,0.1,0.1-0.1-0.1,0.1
							C196.5,171.8,195.9,171.7,195.9,171.7z M190.6,171.7c0-0.1,0.6-0.1,1.3-0.1s1.3,0.1,1.3,0.1s-0.6,0.1-1.3,0.1
							S190.6,171.7,190.6,171.7z M185.2,171.7c0-0.1,0.6-0.1,1.3-0.1s1.3,0.1,1.3,0.1s-0.6,0.1-1.3,0.1S185.2,171.7,185.2,171.7z
							M179.9,171.7c0-0.1,0.6-0.1,1.3-0.1s1.3,0.1,1.3,0.1s-0.6,0.1-1.3,0.1S179.9,171.7,179.9,171.7z M174.6,171.7
							c0-0.1,0.6-0.1,1.3-0.1s1.3,0.1,1.3,0.1s-0.6,0.1-1.3,0.1S174.6,171.7,174.6,171.7z M169.3,171.7c0-0.1,0.6-0.1,1.3-0.1
							s1.3,0.1,1.3,0.1s-0.6,0.1-1.3,0.1S169.3,171.7,169.3,171.7z M164,171.7c0-0.1,0.6-0.1,1.3-0.1s1.3,0.1,1.3,0.1
							s-0.6,0.1-1.3,0.1S164,171.7,164,171.7z M158.7,171.7c0-0.1,0.6-0.1,1.3-0.1s1.3,0.1,1.3,0.1s-0.6,0.1-1.3,0.1
							S158.7,171.7,158.7,171.7z M153.3,171.7c0-0.1,0.6-0.1,1.3-0.1s1.3,0.1,1.3,0.1s-0.6,0.1-1.3,0.1S153.3,171.7,153.3,171.7z
							M148,171.7c0-0.1,0.6-0.1,1.3-0.1s1.3,0.1,1.3,0.1s-0.6,0.1-1.3,0.1S148,171.7,148,171.7z M142.7,171.7c0-0.1,0.6-0.1,1.3-0.1
							s1.3,0.1,1.3,0.1s-0.6,0.1-1.3,0.1S142.7,171.7,142.7,171.7z M137.4,171.7c0-0.1,0.6-0.1,1.3-0.1s1.3,0.1,1.3,0.1
							s-0.6,0.1-1.3,0.1S137.4,171.7,137.4,171.7z M132.1,171.7c0-0.1,0.6-0.1,1.3-0.1s1.3,0.1,1.3,0.1s-0.6,0.1-1.3,0.1
							S132.1,171.7,132.1,171.7z M126.7,171.7c0-0.1,0.6-0.1,1.3-0.1c0.7,0,1.3,0.1,1.3,0.1s-0.6,0.1-1.3,0.1S126.7,171.7,126.7,171.7
							z M121.4,171.7c0-0.1,0.6-0.1,1.3-0.1s1.3,0.1,1.3,0.1s-0.6,0.1-1.3,0.1S121.4,171.7,121.4,171.7z M116.1,171.7
							c0-0.1,0.6-0.1,1.3-0.1s1.3,0.1,1.3,0.1s-0.6,0.1-1.3,0.1S116.1,171.7,116.1,171.7z M112.1,170.3c0.1,0,0.1,0.6,0.1,1.3
							l-0.1-0.1h0.3h0.2c0.2,0,0.3,0,0.4,0c0.2,0,0.4,0.1,0.4,0.1s-0.2,0.1-0.4,0.1c-0.1,0-0.3,0-0.4,0h-0.2h-0.3
							c-0.1-0.1,0.1,0.1-0.1-0.1C112,170.9,112,170.3,112.1,170.3L112.1,170.3z M112.1,165.1c0.1,0,0.1,0.6,0.1,1.3s-0.1,1.3-0.1,1.3
							s-0.1-0.6-0.1-1.3C112,165.7,112,165.1,112.1,165.1z M112.1,159.9c0.1,0,0.1,0.6,0.1,1.3s-0.1,1.3-0.1,1.3s-0.1-0.6-0.1-1.3
							C112,160.4,112,159.9,112.1,159.9z M112.1,154.6c0.1,0,0.1,0.6,0.1,1.3s-0.1,1.3-0.1,1.3s-0.1-0.6-0.1-1.3
							C112,155.2,112,154.6,112.1,154.6z M112.1,149.4c0.1,0,0.1,0.6,0.1,1.3s-0.1,1.3-0.1,1.3s-0.1-0.6-0.1-1.3
							S112,149.4,112.1,149.4z M112.1,144.2c0.1,0,0.1,0.6,0.1,1.3s-0.1,1.3-0.1,1.3s-0.1-0.6-0.1-1.3
							C112,144.7,112,144.2,112.1,144.2z M112.1,138.9c0.1,0,0.1,0.6,0.1,1.3s-0.1,1.3-0.1,1.3s-0.1-0.6-0.1-1.3
							S112,138.9,112.1,138.9z M112.1,133.7c0.1,0,0.1,0.6,0.1,1.3s-0.1,1.3-0.1,1.3s-0.1-0.6-0.1-1.3S112,133.7,112.1,133.7z
							M112.1,128.5c0.1,0,0.1,0.6,0.1,1.3s-0.1,1.3-0.1,1.3s-0.1-0.6-0.1-1.3C112,129,112,128.5,112.1,128.5z M112.1,123.2
							c0.1,0,0.1,0.6,0.1,1.3s-0.1,1.3-0.1,1.3s-0.1-0.6-0.1-1.3S112,123.2,112.1,123.2z M113.5,119.3c0,0.1-0.6,0.1-1.3,0.1l0.1-0.1
							v0.3c0,0.1,0,0.2,0,0.2c0,0.2,0,0.3,0,0.4c0,0.2-0.1,0.4-0.1,0.4s-0.1-0.2-0.1-0.4c0-0.1,0-0.3,0-0.4v-0.2v-0.3
							c0.1-0.1-0.1,0.1,0.1-0.1C112.9,119.1,113.5,119.2,113.5,119.3z M118.8,119.3c0,0.1-0.6,0.1-1.3,0.1s-1.3-0.1-1.3-0.1
							s0.6-0.1,1.3-0.1S118.8,119.2,118.8,119.3z M124.1,119.3c0,0.1-0.6,0.1-1.3,0.1s-1.3-0.1-1.3-0.1s0.6-0.1,1.3-0.1
							S124.1,119.2,124.1,119.3z M129.4,119.3c0,0.1-0.6,0.1-1.3,0.1s-1.3-0.1-1.3-0.1s0.6-0.1,1.3-0.1
							C128.8,119.1,129.4,119.2,129.4,119.3z M134.7,119.3c0,0.1-0.6,0.1-1.3,0.1s-1.3-0.1-1.3-0.1s0.6-0.1,1.3-0.1
							S134.7,119.2,134.7,119.3z M140,119.3c0,0.1-0.6,0.1-1.3,0.1s-1.3-0.1-1.3-0.1s0.6-0.1,1.3-0.1S140,119.2,140,119.3z
							M145.4,119.3c0,0.1-0.6,0.1-1.3,0.1s-1.3-0.1-1.3-0.1s0.6-0.1,1.3-0.1S145.4,119.2,145.4,119.3z M150.7,119.3
							c0,0.1-0.6,0.1-1.3,0.1s-1.3-0.1-1.3-0.1s0.6-0.1,1.3-0.1S150.7,119.2,150.7,119.3z M156,119.3c0,0.1-0.6,0.1-1.3,0.1
							s-1.3-0.1-1.3-0.1s0.6-0.1,1.3-0.1S156,119.2,156,119.3z M161.3,119.3c0,0.1-0.6,0.1-1.3,0.1s-1.3-0.1-1.3-0.1s0.6-0.1,1.3-0.1
							S161.3,119.2,161.3,119.3z M166.6,119.3c0,0.1-0.6,0.1-1.3,0.1s-1.3-0.1-1.3-0.1s0.6-0.1,1.3-0.1S166.6,119.2,166.6,119.3z
							M172,119.3c0,0.1-0.6,0.1-1.3,0.1s-1.3-0.1-1.3-0.1s0.6-0.1,1.3-0.1S172,119.2,172,119.3z M177.3,119.3c0,0.1-0.6,0.1-1.3,0.1
							s-1.3-0.1-1.3-0.1s0.6-0.1,1.3-0.1S177.3,119.2,177.3,119.3z M182.6,119.3c0,0.1-0.6,0.1-1.3,0.1s-1.3-0.1-1.3-0.1
							s0.6-0.1,1.3-0.1S182.6,119.2,182.6,119.3z M187.9,119.3c0,0.1-0.6,0.1-1.3,0.1s-1.3-0.1-1.3-0.1s0.6-0.1,1.3-0.1
							S187.9,119.2,187.9,119.3z M193.2,119.3c0,0.1-0.6,0.1-1.3,0.1s-1.3-0.1-1.3-0.1s0.6-0.1,1.3-0.1S193.2,119.2,193.2,119.3z
							M197.2,120.6c-0.1,0-0.1-0.6-0.1-1.3l0.1,0.1h-0.3h-0.2c-0.1,0-0.3,0-0.4,0c-0.2,0-0.4-0.1-0.4-0.1s0.1-0.1,0.4-0.1
							c0.1,0,0.3,0,0.4,0h0.2h0.3c0.1,0.1-0.1-0.1,0.1,0.1C197.4,120,197.3,120.6,197.2,120.6L197.2,120.6z M197.2,125.8
							c-0.1,0-0.1-0.6-0.1-1.3s0.1-1.3,0.1-1.3s0.1,0.6,0.1,1.3S197.3,125.8,197.2,125.8z M197.2,131.1c-0.1,0-0.1-0.6-0.1-1.3
							s0.1-1.3,0.1-1.3s0.1,0.6,0.1,1.3C197.4,130.5,197.3,131.1,197.2,131.1z M197.2,136.3c-0.1,0-0.1-0.6-0.1-1.3s0.1-1.3,0.1-1.3
							s0.1,0.6,0.1,1.3C197.4,135.7,197.3,136.3,197.2,136.3z M197.2,141.5c-0.1,0-0.1-0.6-0.1-1.3s0.1-1.3,0.1-1.3s0.1,0.6,0.1,1.3
							C197.4,141,197.3,141.5,197.2,141.5z M197.2,146.8c-0.1,0-0.1-0.6-0.1-1.3s0.1-1.3,0.1-1.3s0.1,0.6,0.1,1.3
							C197.4,146.2,197.3,146.8,197.2,146.8z M197.2,152c-0.1,0-0.1-0.6-0.1-1.3s0.1-1.3,0.1-1.3s0.1,0.6,0.1,1.3
							C197.4,151.4,197.3,152,197.2,152z M197.2,157.2c-0.1,0-0.1-0.6-0.1-1.3s0.1-1.3,0.1-1.3s0.1,0.6,0.1,1.3
							C197.4,156.7,197.3,157.2,197.2,157.2z M197.2,162.5c-0.1,0-0.1-0.6-0.1-1.3s0.1-1.3,0.1-1.3s0.1,0.6,0.1,1.3
							C197.4,161.9,197.3,162.5,197.2,162.5z M197.2,167.7c-0.1,0-0.1-0.6-0.1-1.3s0.1-1.3,0.1-1.3s0.1,0.6,0.1,1.3
							C197.4,167.1,197.3,167.7,197.2,167.7z"/>
					</g>
				</g>
				<g id="g2453">
					<path id="path2375" class="cs" d="M191.1,171.3l-82.7,4.5c-0.6,0-1.2-0.4-1.2-1.1l-2.7-50c0-0.6,0.4-1.2,1.1-1.2l82.7-4.5
						c0.6,0,1.2,0.4,1.2,1.1l2.7,50C192.2,170.7,191.7,171.2,191.1,171.3L191.1,171.3z"/>
					<path id="path2377" class="st1" d="M148.9,142.1l7.2,3.4l-6.5,4.8l-6.5,4.8l-0.7-8.1l-0.7-8.2L148.9,142.1z"/>
					<g id="g2383">
						<path id="path2379" class="st1" d="M185.8,166.3c0,0.1-16.3,1-36.3,2.1c-20.1,1.1-36.4,1.9-36.4,1.9s16.3-1,36.3-2.1
							C169.5,167.1,185.8,166.2,185.8,166.3z"/>
						<path id="path2381" class="st1" d="M114.4,170.2c0,0.5-0.4,0.9-0.9,1c-0.5,0-0.9-0.4-1-0.9c0-0.5,0.4-0.9,0.9-1
							C113.9,169.3,114.3,169.7,114.4,170.2z"/>
					</g>
					<g id="g2387" class="st8">
						<path id="path2385" d="M183.8,119.3c-1.3,2.5-2.7,5.4-1.8,8.1c0.4,1.2,1.3,2.3,1.9,3.4c2,3.5,1.9,7.8,1.8,11.8
							c0,1.1-0.1,2.4-1,3.2c-0.7,0.6-1.7,0.7-2.6,0.7c-3.8,0.1-7.5,0.3-11.2,0.4v19.8l20.9-4.6l-1-29.6l-0.5-5l-2.1-8.5L183.8,119.3
							L183.8,119.3z"/>
					</g>
					<g id="g2451">
						<path id="path2389" class="cp" d="M165.9,279.4l0.7,16.7l9-0.2l-0.7-16.7L165.9,279.4z"/>
						<path id="path2391" class="st4" d="M171,295.4c0.1,0-0.1-3.7-0.3-8.2s-0.5-8.2-0.6-8.2s0.1,3.7,0.3,8.2S171,295.4,171,295.4z"/>
						<path id="path2393" class="st4" d="M166.6,295l9-0.2v1.2l-9,0.2V295z"/>
						<path id="path2395" class="st4" d="M175.4,290.6c0,0-0.1-0.1-0.2-0.4c-0.2-0.2-0.5-0.5-0.8-0.8c-0.4-0.3-0.9-0.6-1.5-0.9
							s-1.3-0.4-2.1-0.4c-1.6,0.1-2.8,0.9-3.4,1.7c-0.3,0.4-0.5,0.8-0.6,1c-0.1,0.3-0.1,0.4-0.1,0.4c0.1,0,0.2-0.6,0.9-1.3
							c0.6-0.7,1.8-1.5,3.3-1.5c1.5-0.1,2.7,0.6,3.5,1.2C175,290.1,175.4,290.6,175.4,290.6L175.4,290.6z"/>
						<path id="path2397" class="st9" d="M175.2,283.9l0.1,1.8l-9,0.2l-0.1-1.4L175.2,283.9z"/>
						<path id="path2399" class="cp" d="M144.1,279.9l0.5,10.5c0,0-10.5,4-10.6,5.9l20.3-0.1v-16.3H144.1L144.1,279.9z"/>
						<path id="path2401" class="st4" d="M150.7,289.4c0.4,0.1,0.7,0.5,0.6,0.9s-0.5,0.7-0.9,0.6s-0.7-0.6-0.6-1
							C149.8,289.6,150.4,289.2,150.7,289.4 M154.3,296.1v-1.6l-19.6,0.7c0,0-0.9,0.4-0.8,1L154.3,296.1z"/>
						<path id="path2403" class="st4" d="M144.3,290.4c0,0.1,0.5,0.1,1,0.5c0.5,0.3,0.8,0.7,0.9,0.7c0.1,0-0.1-0.6-0.7-1
							C144.9,290.2,144.3,290.3,144.3,290.4L144.3,290.4z M142.2,291.3c0,0.1,0.4,0.3,0.8,0.7c0.4,0.4,0.4,0.9,0.5,0.9
							s0.2-0.6-0.3-1.1C142.8,291.2,142.2,291.2,142.2,291.3L142.2,291.3z M141,293.9c0.1,0,0.2-0.5,0-1c-0.3-0.5-0.7-0.8-0.8-0.7
							c-0.1,0.1,0.2,0.4,0.5,0.8C140.9,293.4,140.9,293.8,141,293.9z M144.5,288c0,0.1,0.5-0.1,1.1,0c0.6,0,1,0.2,1.1,0.1
							s-0.4-0.4-1-0.5C144.9,287.6,144.5,287.9,144.5,288L144.5,288z M144.1,285.7c0,0,0.4,0.1,1,0c0.3-0.1,0.7-0.2,1.1-0.3
							c0.2-0.1,0.4-0.2,0.6-0.3c0.1-0.1,0.2-0.1,0.3-0.2s0.2-0.4,0-0.5c-0.1-0.2-0.3-0.2-0.4-0.2c-0.2,0-0.3,0-0.4,0.1
							c-0.6,0.2-1.1,0.4-1.6,0.9C144.3,285.4,144.1,285.8,144.1,285.7c0.1,0.1,0.3-0.2,0.8-0.6c0.2-0.2,0.5-0.4,0.9-0.5
							c0.2-0.1,0.4-0.1,0.6-0.2c0.2-0.1,0.4-0.1,0.5,0c0,0,0,0,0,0.1s-0.1,0.1-0.2,0.2c-0.5,0.3-1,0.5-1.5,0.7
							C144.5,285.6,144.1,285.7,144.1,285.7L144.1,285.7z"/>
						<g id="g2409" class="st8">
							<path id="path2407" d="M154.3,286v2.3h-9.8V287L154.3,286z"/>
						</g>
						<g id="g2415">
							<path id="path2411" class="st10" d="M184.6,120.9c-0.2,0-0.8,2.3-1.1,3.5c-0.1,0.4,0,0.9,0.2,1.3l2.4,4.2
								c0.4,0.8,1.1,1.4,1.8,1.8l1.2,0.7l0.1,2.5l6.6,0.1l-2-7.7l-0.8-4.7c-0.2-0.5-0.7-0.8-1.2-0.9l-0.6-0.1L191,121
								c-0.1-0.4-0.5-0.6-0.8-0.7l-0.4-0.1l-0.5-1.3c-0.2-0.4-0.6-0.7-1.1-0.7H185c0,0-0.7,0.9,0.9,1.4c0.8,0.2,1.4,0.2,1.4,0.2
								l0.7,2.9c0,0,0.5,3-0.9,3c-1.2,0-1.4-1.6-1.4-1.6S186.3,120.7,184.6,120.9L184.6,120.9z"/>
							<path id="path2413" class="st11" d="M191.2,123.8c0,0,0-0.2-0.1-0.6s-0.4-0.8-0.9-1.1c-0.3-0.6-0.4-1.1-0.5-1.4
								c-0.1-0.4-0.1-0.6-0.1-0.6s0,0.2,0.1,0.6s0.3,0.9,0.5,1.4c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.3,0.3
								c0.1,0.2,0.2,0.4,0.3,0.6S191.2,123.6,191.2,123.8C191.2,123.7,191.2,123.8,191.2,123.8L191.2,123.8z M192.2,123.2
								c0,0,0-0.1-0.1-0.3c-0.1-0.2-0.3-0.4-0.6-0.4l0,0l0,0v-0.1c-0.2-0.7-0.4-1.2-0.4-1.2s0.2,0.5,0.5,1.2v0.1l0,0
								c0.3,0,0.6,0.3,0.7,0.5C192.3,123.1,192.2,123.2,192.2,123.2L192.2,123.2z M187.2,119.7c0,0-0.1-0.1-0.1-0.4
								c0-0.2,0.1-0.4,0.2-0.3c0,0,0,0.2-0.1,0.4S187.2,119.7,187.2,119.7L187.2,119.7z"/>
						</g>
						<g id="g2429">
							<path id="path2417" class="st10" d="M153.3,149.7l12-0.2l-1.3-10.2c0,0,0.8-0.2,0.9-4.6c0.1-4.4,0.3-12,0.3-12
								c-5.1-2.2-11.1-1.5-15.5,1.9l-0.7,0.5L153.3,149.7L153.3,149.7z"/>
							<path id="path2419" class="st10" d="M150.3,130.7c-0.1,0-3.1-0.9-3,2.1s3.1,2.3,3.1,2.2C150.5,134.9,150.3,130.7,150.3,130.7
								L150.3,130.7z M164.6,130c0.1,0,2.8-0.8,2.7,2.3c-0.1,3-3.1,2.3-3.1,2.2C164.1,134.4,164.6,130,164.6,130L164.6,130z"/>
							<path id="path2421" d="M168,121.3c0.1-0.6-0.2-1.1-0.6-1.5s-1.1-0.3-1.5,0.1c0.2-0.7-0.4-1.6-1.2-1.6c-0.7,0-1.3,0.5-2,0.7
								s-1.4,0-2.1-0.2s-1.3-0.6-1.9-0.9c-1.4-0.5-3-0.4-4.3,0.2c-1.4,0.6-2.4,1.8-2.9,3.2c-1-0.3-2.1,0.2-2.7,1
								c-0.6,0.8-0.8,1.9-0.6,2.9c0.1,0.7,0.3,1.3,0.5,2c0,0.1,0,0.1,0,0.2c0,2.4,1.3,10.2,2.4,10.2c1.8,0,0.4-8.2,0.4-8.2
								c2.2-0.9,1.4-4.2,2-4.7s1.7-1.7,6.4,0.7c1.8,0.9,4.2,0.9,5.5,0.6h0.1l0,0l0,0c0.4-0.1,0.8-0.3,1.2-0.5c0.4-0.3,0.8-0.6,1-1.1
								s0.1-1.1-0.3-1.4C167.7,122.3,167.9,121.8,168,121.3L168,121.3z"/>
							<path id="path2423" d="M149.3,130.3c0.5,2.5,0.4,3.9,0.8,5.6c0.7,2.7,2,4.8,4.4,6.1s5.7,1.3,7.8-0.5c1.4-1.3,2.5-2.7,2.7-4.6
								c0.2-1.9,0.4-5.3,0.4-7.2c0-3.5,0.3-6.8,0.3-6.8l-9.9-1.8l-5.6,3.4L149.3,130.3L149.3,130.3z M159.5,143.6
								c0,0.1-0.8,0.3-1.7,0.2c-1-0.1-1.7-0.5-1.7-0.5c0-0.1,0.8,0.2,1.7,0.2C158.8,143.5,159.5,143.5,159.5,143.6z"/>
							<path id="path2425" class="st4" d="M162.1,120.6c0,0-0.2,0.1-0.5,0.1s-0.8-0.1-1.3-0.3c-0.5-0.2-1-0.6-1.6-1.1
								c-0.6-0.4-1.3-0.9-2.1-1s-1.6,0.1-2.3,0.4c-0.6,0.3-1.1,0.8-1.4,1.3c-0.6,0.9-0.6,1.7-0.6,1.7s0-0.2,0.1-0.5s0.2-0.7,0.5-1.2
								s0.7-1,1.4-1.3c0.6-0.4,1.5-0.5,2.3-0.4c0.9,0.1,1.6,0.6,2.1,1c0.6,0.4,1.1,0.9,1.6,1.1s0.9,0.3,1.2,0.3
								C161.9,120.7,162.1,120.6,162.1,120.6L162.1,120.6z M152.6,121.9c0,0-0.3-0.2-0.7-0.3c-0.4-0.1-1.1-0.1-1.8,0.3
								c-0.6,0.4-0.9,1-1,1.4c-0.1,0.5-0.1,0.8-0.1,0.8s0-0.1,0-0.2s0-0.3,0-0.6c0.1-0.5,0.4-1.1,1-1.5s1.4-0.4,1.8-0.3
								c0.2,0.1,0.4,0.2,0.5,0.2C152.5,121.8,152.6,121.9,152.6,121.9L152.6,121.9z M152.3,132.7c-0.1,0-0.4-0.7-0.8-1.6
								c-0.4-0.9-0.6-1.6-0.6-1.7c0.1,0,0.4,0.7,0.8,1.6C152.1,131.9,152.4,132.7,152.3,132.7z M154.9,134.5c0,0.1-0.8-0.5-1.4-1.4
								c-0.6-0.9-0.8-1.8-0.7-1.8s0.4,0.8,0.9,1.7C154.3,133.8,154.9,134.4,154.9,134.5L154.9,134.5z M157.4,135
								c0,0.1-0.6-0.2-1.2-0.7c-0.6-0.5-0.9-1-0.9-1.1c0.1-0.1,0.5,0.4,1.1,0.9C157,134.6,157.5,135,157.4,135L157.4,135z M162,130.9
								c0.1,0,0.1,0.7-0.3,1.5c-0.3,0.8-0.8,1.3-0.9,1.3c-0.1-0.1,0.3-0.6,0.6-1.4C161.8,131.6,161.9,130.9,162,130.9z M163.5,128.5
								c0.1,0,0.1,0.9-0.2,1.9s-0.9,1.7-0.9,1.6c-0.1,0,0.4-0.8,0.7-1.7C163.4,129.3,163.4,128.5,163.5,128.5L163.5,128.5z"/>
							<path id="path2427" class="st4" d="M164.7,122.4c0,0-0.1,0.1-0.4,0.3c-0.3,0.2-0.7,0.3-1.3,0.4s-1.3,0-2-0.3s-1.5-0.7-2.2-1.1
								c-0.8-0.4-1.5-0.8-2.3-1c-0.7-0.2-1.4-0.2-2,0c-0.5,0.2-0.9,0.4-1.2,0.7c-0.2,0.2-0.3,0.4-0.3,0.4s0.1-0.2,0.3-0.4
								c0.2-0.2,0.6-0.5,1.2-0.7c0.6-0.2,1.3-0.2,2,0c0.8,0.2,1.5,0.6,2.3,1s1.5,0.8,2.2,1.1c0.7,0.3,1.4,0.4,2,0.3
								C164.1,122.9,164.7,122.3,164.7,122.4L164.7,122.4z M165.6,123.8c0,0-0.1,0-0.2,0.1c-0.1,0.1-0.3,0.1-0.5,0.2
								c-0.4,0.2-1,0.4-1.7,0.4c-0.7,0.1-1.3,0-1.7-0.1c-0.2-0.1-0.4-0.1-0.5-0.1s-0.2-0.1-0.2-0.1s0.3,0.1,0.7,0.1
								c0.4,0.1,1,0.1,1.7,0.1c0.7-0.1,1.3-0.2,1.7-0.4S165.6,123.8,165.6,123.8L165.6,123.8z"/>
						</g>
						<g id="g2435">
							<path id="path2431" class="st4" d="M160,215.7l2.9,28.1l2.1,42.4h11.2l0.3-90.2l-32,2.3l-6.6,47.3l5.7,41.4h12.3l-2.9-39.4
								L160,215.7"/>
							<path id="path2433" class="st12" d="M160,215.7c0.1,0.1-0.9,1-2.2,2.1s-2.3,2-2.4,1.9s0.9-1,2.2-2.1
								C158.9,216.5,160,215.6,160,215.7z M160,215.1c-0.1,0,0-1.6,0.1-3.6s0.1-3.6,0.2-3.6s0.1,1.6,0.1,3.6
								C160.3,213.5,160.1,215.1,160,215.1L160,215.1z M163.7,204.6c0,0,0.1,0,0.2,0c0.2,0,0.3,0,0.6,0c0.5,0,1.2-0.1,2.1-0.1
								c1.9-0.1,4.5-0.2,7.6-0.3h0.1v0.1c0,2.4,0,5,0,7.9v0.2h-0.1c-1.7,0.7-3.4,1.4-4.9,2l0,0l0,0c-1.6-0.5-3-1.1-4.4-1.5h-0.1v-0.1
								c-0.3-2.5-0.6-4.5-0.8-6c-0.1-0.7-0.2-1.2-0.2-1.6C163.7,204.8,163.7,204.6,163.7,204.6s0,0.1,0,0.2l0.1,0.4
								c0.1,0.4,0.2,1,0.3,1.7c0.2,1.4,0.5,3.5,0.9,5.9l-0.1-0.1c1.3,0.5,2.8,0.9,4.4,1.5h-0.1c1.6-0.6,3.2-1.3,4.9-2l-0.1,0.1v-0.1
								c0-2.8,0-5.5,0-7.9l0.1,0.1c-3.1,0.1-5.6,0.1-7.5,0.2c-0.9,0-1.6,0-2.2,0L163.7,204.6C163.8,204.6,163.7,204.6,163.7,204.6
								L163.7,204.6z M147.6,204.7c0,0,0.1,0,0.2,0c0.1,0,0.3,0,0.5,0l1.9-0.1c1.7,0,4.1-0.1,6.9-0.2h0.1v0.1c-0.1,2.3-0.2,5-0.3,7.9
								v0.1h-0.1c-0.3,0.2-0.5,0.3-0.8,0.5c-1.4,0.9-2.8,1.8-4.1,2.6h-0.1h-0.1c-1.6-1-3.2-1.9-4.6-2.8H147v-0.1
								c0.1-2.4,0.2-4.5,0.2-6c0-0.7,0.1-1.2,0.1-1.6C147.5,204.9,147.6,204.7,147.6,204.7s0,0.1,0,0.2v0.4c0,0.4,0,1,0,1.7
								c0,1.4-0.1,3.5-0.1,5.9l-0.1-0.1c1.4,0.8,2.9,1.8,4.6,2.8h-0.1c1.3-0.8,2.6-1.7,4.1-2.6l0.8-0.5l-0.1,0.1
								c0.1-2.9,0.2-5.6,0.3-7.9l0.1,0.1c-2.8,0-5.2,0-6.8,0h-1.9c-0.2,0-0.4,0-0.5,0C147.6,204.8,147.6,204.8,147.6,204.7
								L147.6,204.7z M173.1,217.5c0,0-0.6,0-1.5-0.1s-2.2-0.2-3.6-0.4s-2.7-0.4-3.6-0.6s-1.5-0.3-1.5-0.3s0.6,0,1.5,0.1
								c1,0.1,2.2,0.3,3.6,0.5c1.4,0.2,2.6,0.3,3.6,0.5C172.5,217.4,173.1,217.4,173.1,217.5L173.1,217.5z M172.8,248.8
								c0,0,0,0.2-0.3,0.4c-0.2,0.2-0.6,0.4-1.1,0.4s-1.1-0.2-1.7-0.6c-0.5-0.4-1-1-1.5-1.5c-0.9-1-1.6-2.1-2.3-3.2
								c-0.5-0.9-0.7-1.4-0.7-1.5c0,0,0.3,0.5,0.9,1.4c0.7,1.1,1.5,2.1,2.3,3.1c0.5,0.6,1,1.1,1.5,1.5s1,0.6,1.5,0.6s0.9-0.1,1.1-0.3
								S172.8,248.8,172.8,248.8L172.8,248.8z M150.7,249.8c0,0,0,0.1-0.1,0.4s-0.4,0.5-0.8,0.7c-0.4,0.2-0.9,0.4-1.5,0.4
								s-1.3-0.2-1.8-0.6c-1.2-0.8-1.8-1.9-2.2-2.6c-0.4-0.8-0.6-1.3-0.6-1.3s0.3,0.5,0.7,1.2s1.1,1.8,2.2,2.5
								c0.6,0.3,1.2,0.5,1.7,0.6c0.6,0,1.1-0.1,1.4-0.3C150.5,250.4,150.7,249.8,150.7,249.8L150.7,249.8z M151.5,245.6
								c0,0-0.3,0.3-0.9,0.5c-0.6,0.3-1.5,0.5-2.5,0.6c-1,0-1.9-0.1-2.5-0.4c-0.6-0.2-1-0.4-1-0.5s1.5,0.6,3.5,0.5
								C150,246.3,151.4,245.5,151.5,245.6L151.5,245.6z"/>
						</g>
						<g id="g2449">
							<path id="path2437" class="cp" d="M187.8,146.3h-16.3c0,0-2.2-1.3-3.3-1.1s-2,1.4-2,1.4l2.2,18l5.3-3.9l19.6-2
								c3.5,0,6.2-2.9,6.2-6.3c0-0.2,0-0.5,0-0.7l-3.6-19.3l-7.5,0.2l-0.3,4.9L187.8,146.3L187.8,146.3z M143,150.8
								c0,0-2.6,1.5-3.9,4.6c-0.9,2.3-7.4,13.7-10.9,19.6c-1.2,2.2-0.9,4.9,0.8,6.7c2.1,2.2,5.6,2.8,8.6,1.8c1.4-0.4,6.8-4.9,6.8-4.9
								L143,150.8L143,150.8z"/>
							<path id="path2439" class="cp" d="M145.8,191.6l-3.7-32.2l0.9-8.6l9.5-4.1l12.3-0.8l11.7,1.3l-2.7,33l3,18.6l-32.9,1.5
								L145.8,191.6z"/>
							<path id="path2441" class="st13" d="M145.8,191.4c-0.1,0-1-7.1-2-16s-1.8-16-1.7-16s1,7.2,2,16S145.9,191.4,145.8,191.4z
								M176.3,154.5c0,0,0,0.1,0,0.3l-0.1,0.8c-0.1,0.8-0.2,1.8-0.3,3c-0.3,2.6-0.6,6.1-1,10s-0.7,7.4-0.9,10c-0.1,1.3-0.2,2.3-0.2,3
								c0,0.3-0.1,0.6-0.1,0.8s0,0.3,0,0.3s0-0.1,0-0.3s0-0.5,0-0.8c0-0.7,0.1-1.8,0.1-3c0.1-2.6,0.4-6.1,0.8-10
								c0.4-3.9,0.8-7.4,1.1-10c0.2-1.3,0.3-2.3,0.4-3c0.1-0.3,0.1-0.6,0.1-0.8C176.3,154.6,176.3,154.5,176.3,154.5L176.3,154.5z
								M170.6,186.5c0,0-0.2,0.2-0.5,0.6c-0.2,0.2-0.4,0.4-0.7,0.7s-0.6,0.6-0.9,0.9c-0.4,0.3-0.8,0.6-1.2,0.9
								c-0.4,0.3-1,0.6-1.5,0.9c-1.1,0.6-2.4,1.2-3.8,1.6c-1.4,0.4-2.8,0.6-4,0.7c-0.6,0-1.2,0.1-1.8,0c-0.6,0-1.1-0.1-1.6-0.1
								s-0.9-0.1-1.3-0.2c-0.3-0.1-0.6-0.1-0.9-0.2c-0.5-0.1-0.8-0.2-0.8-0.2c0-0.1,1.1,0.3,3,0.5c0.5,0,1,0.1,1.5,0.1
								c0.6,0,1.1,0,1.8-0.1c1.2-0.1,2.6-0.3,4-0.7s2.6-1,3.7-1.5c0.5-0.3,1.1-0.6,1.5-0.9c0.5-0.3,0.9-0.6,1.2-0.9
								C169.8,187.4,170.6,186.5,170.6,186.5L170.6,186.5z"/>
							<path id="path2445" class="st13" d="M143.4,171.2c-0.3,0.4-3.1,4.2-3.1,4.2c-0.1,0.4-2.8,3.9-2.8,3.9s2.4-1.3,4.2-2.3
								c0.9-0.5,2.2-1.6,2.2-1.6s-0.4-2.6-0.6-3.6c-0.4-2.2-0.7-7.1-0.7-7.1"/>
							<path id="path2447" class="st13" d="M172.7,147c0-0.1,1.2,0.4,2.1,1.7c0.9,1.3,1,2.6,0.9,2.6s-0.3-1.2-1.2-2.4
								C173.8,147.6,172.7,147,172.7,147L172.7,147z"/>
						</g>
					</g>
				</g>
				<path id="path2269" class="st0" d="M102.5,231.7c2.2,0.9,3.9,3,4.8,5.3c0.8,2.3,1,4.8,0.7,7.2c-0.5,4.7-3.1,9.4-5.9,13.2
					c-4-3.4-4.9-9.3-5.3-12.4C96.3,240.1,98.4,231.1,102.5,231.7 M106.7,265c-0.7-2.6,0.4-5.5,2.4-7.4c2-1.8,4.8-2.6,7.5-2.4
					c1.2,0.1,2.6,0.4,3.3,1.4c0.7,1,0.6,2.3,0.1,3.3s-1.5,1.8-2.4,2.5C114.3,264.6,110.6,266.1,106.7,265 M100.2,267.7
					c-1.1-5.6-5.1-10.5-10.4-12.8c-1.3-0.6-3.1-0.9-4.1,0.1c-1,1-0.7,2.8-0.1,4.1c2.4,5.6,8.5,9.3,14.6,9"/>
				<path id="path2271" class="st5" d="M101.4,279.6c0,0,0-0.3,0.1-1c0.1-0.6,0.3-1.5,0.5-2.6c0.5-2.2,1.3-5.3,2.9-8.4
					s3.9-5.4,5.9-6.5c1-0.6,1.8-0.9,2.4-1.1c0.3-0.1,0.5-0.2,0.7-0.2s0.2-0.1,0.3,0c0,0.1-1.4,0.3-3.3,1.5s-4.1,3.4-5.7,6.5
					c-1.6,3-2.4,6.1-2.9,8.3c-0.3,1.1-0.5,1.9-0.6,2.6C101.5,279.3,101.4,279.6,101.4,279.6L101.4,279.6z"/>
				<path id="path2273" class="st5" d="M101.5,238.7c0,0,0,0.1,0.1,0.4c0,0.3,0.1,0.7,0.1,1.2c0.1,1,0.1,2.6,0.2,4.4
					c0.4,9.7,0.5,19.5,0.2,29.2c-0.1,1.9-0.1,3.4-0.2,4.4c0,0.5-0.1,0.9-0.1,1.2c0,0.3,0,0.4,0,0.4s0-0.1,0-0.4v-1.2
					c0-1.1,0-2.6,0.1-4.4c0.1-3.7,0.1-8.9,0.1-14.6c0-5.7-0.2-10.9-0.3-14.6c-0.1-1.8-0.1-3.3-0.1-4.4c0-0.5,0-0.9,0-1.2
					C101.5,238.9,101.5,238.8,101.5,238.7L101.5,238.7z"/>
				<path id="path2275" class="st5" d="M90.7,259.2c0,0,0.3,0.1,0.9,0.4c0.3,0.1,0.6,0.3,1,0.5s0.8,0.5,1.2,0.8
					c0.2,0.2,0.5,0.3,0.7,0.5s0.5,0.4,0.7,0.6c0.5,0.4,1,0.9,1.5,1.4c1,1.1,2,2.3,2.8,3.7c0.8,1.4,1.4,2.8,1.9,4.3
					c0.2,0.7,0.4,1.3,0.5,2c0.1,0.3,0.1,0.6,0.2,0.9c0,0.3,0.1,0.6,0.1,0.8c0.1,0.5,0.1,1,0.1,1.5c0,0.4,0,0.8,0,1.1
					c0,0.6-0.1,0.9-0.1,0.9c-0.1,0,0.1-1.3-0.2-3.5c0-0.3-0.1-0.5-0.1-0.8s-0.1-0.6-0.2-0.9c-0.1-0.6-0.3-1.3-0.5-1.9
					c-0.5-1.3-1.1-2.8-1.9-4.2c-0.8-1.3-1.7-2.5-2.8-3.7c-0.5-0.5-1-1-1.5-1.4c-0.2-0.2-0.5-0.4-0.7-0.6s-0.5-0.3-0.7-0.5
					C91.9,259.8,90.6,259.3,90.7,259.2L90.7,259.2z"/>
				<path id="path2277" class="st5" d="M109.4,296.2H95.2l-2.9-20.8h19.9L109.4,296.2z"/>
				<path id="path2279" class="st1" d="M110.8,286.2c0,0.1-3.8,0.2-8.6,0.2c-4.7,0-8.6-0.1-8.6-0.2s3.8-0.2,8.6-0.2
					S110.8,286.1,110.8,286.2z"/>
				<path id="path2267" class="st4" d="M325.6,296.2c0,0.1-55.6,0.1-124.3,0.1S77,296.2,77,296.2c0-0.1,55.6-0.1,124.3-0.1
					S325.6,296.1,325.6,296.2z"/>
			</g>
		</g>
	</svg>
</template>

<style scoped>
	.cp{fill:#C091FC;}
	.cs{fill:#3CDFAD;}
	.st0{fill:#F5F5F5;}
	.st1{fill:#FFFFFF;}
	.st2{fill:#E0E0E0;}
	.st4{fill:#2D2D2D;}
	.st5{fill:#E1E1E1;}
	.st7{fill:#263238;}
	.st8{opacity:0.3;}
	.st9{opacity:0.3;enable-background:new;}
	.st10{fill:#FFBE9D;}
	.st11{fill:#EB996E;}
	.st12{fill:#1E1E1E;}
	.st13{opacity:0.2;enable-background:new;}
</style>
