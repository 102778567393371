<template>
	<svg version="1.1" id="Funcionalidades_-_Cliente" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
			x="0px" y="0px" viewBox="0 0 448 384" style="enable-background:new 0 0 448 384;" xml:space="preserve">
		<path class="st0" d="M438.9,115.3c-0.6,31.5-19.1,35.6-23.7,68.5c-4.8,34.9,14.6,39.6,15,79.2c0.1,7.7-0.3,64.8-43,96.5
			c-3.7,2.8-32.4,23.5-67.7,19.5c-10.9-1.2-20-4.5-31.5-8.6c-23-8.2-36.9-17.4-45.4-22c-23.6-13-45.7-13.4-86.9-14.4
			c-66.7-1.5-81.1,16-108,1.1c-20.5-11.3-34-33.5-37.7-53.6c-5.5-29.8,13.6-36.8,19-80.5c4.7-38.2-8.5-44-2.7-85.7
			c2.7-19.5,5.9-42.6,19.4-58.9c25-30.3,81.9-33,141.5-11.9c17.1,5.3,44.8,11.3,78.6,7.4c18.8-2.2,34.6-6.8,46.8-11.5
			c8.9-3.6,24.4-8.5,43.9-7.7c10.9,0.4,36.6,1.5,57.3,20.5C439.2,76.5,439,110.1,438.9,115.3L438.9,115.3z"/>
		<g>
			<path class="st1" d="M281.3,46.6c0.6-0.9,1.4-1.6,2.2-2.3l-0.5-2.9l4.5-1.9l1.7,2.4c1-0.2,2.1-0.2,3.2,0l1.7-2.4l4.5,1.8l-0.5,2.9
				c0.9,0.6,1.6,1.4,2.3,2.2l2.9-0.5l1.9,4.5l-2.4,1.7c0.2,1,0.2,2.1,0,3.2l2.4,1.7l-1.8,4.5l-2.9-0.5c-0.6,0.9-1.4,1.6-2.2,2.3
				l0.5,2.9l-4.5,1.9l-1.7-2.4c-1,0.2-2.1,0.2-3.2,0l-1.7,2.4l-4.5-1.8l0.5-2.9c-0.9-0.6-1.6-1.4-2.3-2.2l-2.9,0.5l-1.9-4.5l2.4-1.7
				c-0.2-1-0.2-2.1,0-3.2l-2.4-1.7l1.8-4.5L281.3,46.6L281.3,46.6L281.3,46.6z M286.2,51.6c-1.1,2.7,0.2,5.7,2.9,6.8
				c2.7,1.1,5.7-0.2,6.8-2.9s-0.2-5.7-2.9-6.8C290.3,47.6,287.3,48.9,286.2,51.6z"/>
			<path class="cp" d="M286.2,51.6c0,0,0.1-0.5,0.6-1.2s1.4-1.6,2.9-2c0.7-0.2,1.6-0.3,2.5-0.1s1.8,0.6,2.5,1.3
				c0.4,0.3,0.7,0.7,1,1.2c0.3,0.4,0.5,0.9,0.6,1.5c0.3,1.1,0.2,2.3-0.2,3.3c-0.4,1.1-1.2,2-2.2,2.6c-0.5,0.3-1,0.5-1.5,0.6
				s-1,0.2-1.5,0.2c-1,0-1.9-0.4-2.7-0.8c-0.8-0.5-1.3-1.1-1.7-1.8c-0.8-1.3-0.8-2.6-0.7-3.4C285.9,52,286.2,51.6,286.2,51.6
				s-0.2,0.4-0.3,1.3c-0.1,0.8,0,2.1,0.7,3.3c0.4,0.6,0.9,1.2,1.6,1.7c0.7,0.4,1.6,0.8,2.6,0.8c0.5,0,1,0,1.5-0.2
				c0.5-0.1,1-0.3,1.4-0.6c0.9-0.5,1.6-1.4,2-2.4s0.5-2.1,0.2-3.2c-0.1-0.5-0.3-1-0.6-1.4c-0.3-0.4-0.6-0.8-0.9-1.1
				c-0.7-0.6-1.6-1-2.4-1.2s-1.7-0.1-2.4,0.1c-1.4,0.4-2.3,1.2-2.8,1.9C286.3,51.2,286.2,51.6,286.2,51.6L286.2,51.6z"/>
			<path class="cp" d="M281.3,46.6c0,0-0.1,0-0.2,0s-0.3,0-0.6-0.1c-0.5-0.1-1.2-0.2-2.2-0.3h0.1c-0.5,1.2-1.1,2.7-1.8,4.5v-0.1
				c0.7,0.5,1.6,1.1,2.4,1.7l0,0v0.1c-0.1,1-0.1,2,0,3.2v0.1l0,0c-0.7,0.5-1.5,1.1-2.4,1.7v-0.1c0.6,1.4,1.2,2.9,1.9,4.5l-0.1-0.1
				c0.9-0.2,1.9-0.3,2.9-0.5h0.1v0.1c0.6,0.8,1.4,1.5,2.3,2.2h0.1v0.1c-0.1,0.9-0.3,1.9-0.5,2.9l-0.1-0.1c1.4,0.6,3,1.2,4.5,1.8
				l-0.2,0.1c0.5-0.8,1.1-1.6,1.7-2.4v-0.1h0.1c1,0.1,2.1,0.1,3.1,0h0.1l0.1,0.1c0.5,0.7,1.1,1.6,1.7,2.4h-0.2
				c1.4-0.6,2.9-1.3,4.5-1.9l-0.1,0.2c-0.2-0.9-0.3-1.9-0.5-2.9v-0.1l0.1-0.1c0.8-0.6,1.5-1.4,2.2-2.2l0,0l0.1-0.1h0.1
				c1,0.2,1.9,0.3,2.9,0.5l-0.2,0.1c0.6-1.5,1.2-3,1.8-4.5l0.1,0.2c-0.8-0.6-1.6-1.1-2.4-1.7l-0.1-0.1v-0.1c0.1-1.1,0.1-2.1,0-3.1
				v-0.1l0.1-0.1c0.8-0.6,1.6-1.2,2.4-1.7v0.2c-0.6-1.5-1.3-3-1.9-4.5l0.2,0.1c-1,0.2-1.9,0.4-2.9,0.5h-0.1l-0.1-0.1
				c-0.7-0.8-1.4-1.6-2.2-2.2H298v-0.1c0.2-1,0.3-2,0.5-2.9l0.1,0.1c-1.6-0.6-3.1-1.2-4.5-1.8h0.1c-0.6,0.9-1.1,1.6-1.7,2.4v0.1h-0.1
				c-1.1-0.1-2.2-0.1-3.2,0h-0.1l0,0c-0.6-0.9-1.2-1.7-1.7-2.4h0.1c-1.7,0.7-3.2,1.4-4.5,1.9v-0.1c0.2,1.2,0.4,2.1,0.5,2.9l0,0l0,0
				c-0.8,0.7-1.4,1.2-1.7,1.6c-0.2,0.2-0.3,0.3-0.4,0.5C281.4,46.5,281.3,46.6,281.3,46.6s0,0,0.1-0.2c0.1-0.1,0.2-0.3,0.4-0.5
				c0.3-0.4,0.9-1,1.7-1.7l0,0c-0.2-0.8-0.3-1.7-0.5-2.9v-0.1h0.1c1.3-0.5,2.7-1.2,4.5-1.9h0.1v0.1c0.5,0.7,1.1,1.5,1.7,2.4h-0.1
				c1-0.1,2.1-0.2,3.2,0h-0.1c0.5-0.8,1.1-1.6,1.7-2.4l0.1-0.1h0.1c1.4,0.6,2.9,1.2,4.5,1.8h0.1v0.1c-0.2,0.9-0.3,1.9-0.5,2.9
				l-0.1-0.1c0.8,0.6,1.6,1.4,2.3,2.2l-0.1-0.1c1-0.2,1.9-0.4,2.9-0.5h0.1v0.1c0.6,1.5,1.3,3,1.9,4.5v0.1l-0.1,0.1
				c-0.8,0.6-1.6,1.1-2.4,1.7L303,52c0.2,1.1,0.2,2.1,0,3.2l-0.1-0.1c0.8,0.6,1.6,1.1,2.4,1.7l0.1,0.1V57c-0.6,1.5-1.2,3-1.8,4.5v0.1
				h-0.1c-1-0.2-1.9-0.3-2.9-0.5l0.1-0.1l0,0c-0.7,0.9-1.4,1.7-2.3,2.3l0.1-0.1c0.2,1,0.4,2,0.5,2.9v0.1h-0.1
				c-1.5,0.7-3.1,1.3-4.5,1.9h-0.1l-0.1-0.1c-0.6-0.8-1.2-1.6-1.7-2.4l0.1,0.1c-1.1,0.2-2.2,0.2-3.2,0l0.1-0.1
				c-0.6,0.8-1.2,1.7-1.7,2.4l-0.1,0.1h-0.1c-1.6-0.6-3.1-1.2-4.5-1.8H283v-0.1c0.2-1,0.3-2,0.5-2.9l0.1,0.1c-0.9-0.7-1.7-1.5-2.3-2.3
				h0.1c-1,0.2-2,0.4-2.9,0.5h-0.1v-0.1c-0.7-1.6-1.3-3.1-1.9-4.5v-0.1l0.1-0.1c0.8-0.6,1.6-1.2,2.4-1.7v0.1c-0.2-1.1-0.2-2.2,0-3.2
				v0.1c-0.9-0.6-1.7-1.2-2.4-1.7h-0.1v-0.1c0.7-1.8,1.4-3.4,1.8-4.5l0,0l0,0c0.9,0.2,1.7,0.3,2.2,0.4c0.2,0,0.4,0.1,0.6,0.1
				C281.2,46.6,281.3,46.6,281.3,46.6L281.3,46.6z"/>
		</g>
		<path class="st3 cs origin-[85.6%_14.9%] animate-spin" style="animation-duration: 10s;" d="M356.1,38.5c1.8-2.4,3.8-4.5,6.1-6.2l-1.5-8l12.4-5.3l4.8,6.6c2.9-0.4,5.8-0.5,8.8-0.1l4.6-6.7l12.5,5l-1.3,8
			c2.4,1.8,4.5,3.8,6.2,6.1l8-1.5l5.3,12.4l-6.6,4.8c0.4,2.9,0.5,5.8,0.1,8.8l6.7,4.6l-5,12.5l-8-1.3c-1.8,2.4-3.8,4.5-6.1,6.2l1.5,8
			L392,97.6l-4.8-6.6c-2.9,0.4-5.8,0.5-8.8,0.1l-4.6,6.7l-12.5-5l1.3-8c-2.4-1.8-4.5-3.8-6.2-6.1l-8,1.5l-5.3-12.4l6.6-4.8
			c-0.4-2.9-0.5-5.8-0.1-8.8l-6.7-4.6l5-12.5L356.1,38.5L356.1,38.5L356.1,38.5z M369.4,52.4c-3,7.4,0.6,15.8,8,18.7
			c7.4,3,15.8-0.6,18.7-8c3-7.4-0.6-15.8-8-18.7C380.8,41.4,372.4,45,369.4,52.4z"/>
		<g>
			<path class="st1" d="M10.2,283c1-1.3,2.1-2.5,3.4-3.5l-0.8-4.4l6.8-2.9l2.6,3.6c1.6-0.2,3.2-0.2,4.8-0.1l2.6-3.7l6.9,2.8l-0.7,4.4
				c1.3,1,2.5,2.1,3.5,3.4l4.4-0.8l2.9,6.8l-3.6,2.6c0.2,1.6,0.2,3.2,0.1,4.8l3.7,2.5l-2.8,6.9l-4.4-0.7c-1,1.3-2.1,2.5-3.4,3.5
				l0.8,4.4l-6.8,2.9l-2.6-3.6c-1.6,0.2-3.2,0.2-4.8,0.1l-2.6,3.7l-6.9-2.8l0.7-4.4c-1.3-1-2.5-2.1-3.5-3.4L6,306l-2.9-6.8l3.6-2.6
				c-0.2-1.6-0.2-3.2-0.1-4.8L3,289.2l2.8-6.9L10.2,283L10.2,283L10.2,283z M17.6,290.7c-1.6,4.1,0.3,8.7,4.4,10.4
				c4.1,1.6,8.7-0.3,10.4-4.4c1.6-4.1-0.3-8.7-4.4-10.4C23.9,284.6,19.2,286.6,17.6,290.7z"/>
			<path class="cp" d="M17.6,290.7c0,0,0.2-0.7,0.9-1.8c0.7-1,2.1-2.4,4.3-3.1c1.1-0.3,2.4-0.4,3.8-0.2c1.3,0.3,2.7,0.9,3.9,1.9
				c0.6,0.5,1.1,1.1,1.5,1.8c0.4,0.7,0.8,1.4,1,2.3c0.4,1.6,0.3,3.4-0.3,5.1c-0.7,1.6-1.8,3-3.3,3.9c-0.7,0.4-1.5,0.8-2.3,1
				s-1.6,0.3-2.3,0.2c-1.5,0-3-0.6-4.1-1.3c-1.2-0.7-2-1.7-2.6-2.7c-1.2-2-1.2-4-1-5.2C17.2,291.3,17.6,290.7,17.6,290.7
				s-0.3,0.7-0.4,1.9c-0.2,1.2-0.1,3.1,1.1,5.1c0.6,0.9,1.4,1.9,2.5,2.6s2.4,1.2,3.9,1.2c0.7,0,1.5-0.1,2.2-0.2
				c0.7-0.2,1.5-0.5,2.1-0.9c1.4-0.8,2.5-2.1,3.1-3.7c0.6-1.6,0.7-3.3,0.3-4.8c-0.2-0.8-0.5-1.5-0.9-2.1s-0.9-1.2-1.4-1.7
				c-1.1-1-2.4-1.6-3.7-1.9c-1.3-0.3-2.5-0.2-3.6,0.1c-2.2,0.6-3.6,1.9-4.3,2.9C17.8,290,17.6,290.7,17.6,290.7L17.6,290.7z"/>
			<path class="cp" d="M10.2,283c0,0-0.1,0-0.3,0s-0.5-0.1-0.8-0.1c-0.8-0.1-1.9-0.3-3.3-0.5h0.1c-0.7,1.8-1.6,4.1-2.7,6.9v-0.1
				c1.1,0.8,2.4,1.6,3.7,2.5l0.1,0.1v0.1c-0.2,1.5-0.2,3.1,0.1,4.8v0.1H7c-1.1,0.8-2.4,1.7-3.6,2.6l0.1-0.2c0.9,2.1,1.9,4.4,2.9,6.8
				L6,305.8c1.4-0.2,2.9-0.5,4.4-0.8h0.1l0.1,0.1c0.9,1.2,2.1,2.4,3.4,3.4l0.1,0.1v0.1c-0.2,1.4-0.5,2.9-0.7,4.4l-0.1-0.2
				c2.2,0.9,4.5,1.8,6.9,2.8l-0.2,0.1c0.8-1.1,1.7-2.4,2.6-3.7l0.1-0.1h0.1c1.5,0.2,3.1,0.2,4.8-0.1h0.1l0.1,0.1
				c0.8,1.1,1.7,2.4,2.6,3.6l-0.3-0.1c2.2-0.9,4.5-1.9,6.8-2.9l-0.1,0.2c-0.2-1.4-0.5-2.9-0.8-4.4v-0.1l0.1-0.1
				c1.2-0.9,2.4-2.1,3.3-3.4l0,0l0.1-0.1h0.1c1.5,0.2,3,0.5,4.4,0.7l-0.2,0.1c0.9-2.3,1.9-4.6,2.8-6.9l0.1,0.3
				c-1.2-0.9-2.5-1.7-3.7-2.5l-0.1-0.1v-0.1c0.2-1.6,0.2-3.2-0.1-4.8v-0.1l0.1-0.1c1.2-0.9,2.4-1.8,3.6-2.6l-0.1,0.3
				c-1-2.3-2-4.6-2.9-6.8l0.2,0.1c-1.5,0.3-3,0.5-4.4,0.8h-0.1l-0.1-0.1c-1-1.3-2.2-2.4-3.4-3.4l-0.1-0.1v-0.1c0.2-1.5,0.5-3,0.7-4.4
				l0.1,0.2c-2.4-1-4.7-1.9-6.9-2.8l0.2-0.1c-0.9,1.3-1.8,2.5-2.6,3.7L27,276h0c-1.7-0.2-3.3-0.2-4.8,0h-0.1l-0.1-0.1
				c-0.9-1.3-1.8-2.5-2.6-3.6l0.2,0.1c-2.7,1.1-4.9,2.1-6.8,2.9l0.1-0.1c0.3,1.8,0.6,3.2,0.8,4.4l0,0l0,0c-1.3,1-2.1,1.9-2.7,2.5
				c-0.2,0.3-0.4,0.5-0.6,0.7C10.2,282.9,10.2,283,10.2,283s0-0.1,0.1-0.2c0.1-0.2,0.3-0.4,0.6-0.7c0.5-0.6,1.3-1.5,2.6-2.6v0.1
				c-0.2-1.2-0.5-2.6-0.8-4.4v-0.1h0.1c1.9-0.8,4.2-1.8,6.8-2.9h0.1l0.1,0.1c0.8,1.1,1.7,2.3,2.6,3.6l-0.1-0.1
				c1.5-0.2,3.2-0.3,4.9-0.1l-0.2,0.1c0.8-1.2,1.7-2.4,2.5-3.7l0.1-0.1l0.1,0.1c2.2,0.9,4.5,1.8,6.9,2.8l0.1,0.1v0.1
				c-0.2,1.4-0.5,2.9-0.7,4.4l-0.1-0.2c1.3,1,2.5,2.1,3.5,3.4l-0.2-0.1c1.4-0.3,2.9-0.5,4.4-0.8h0.2l0.1,0.1c0.9,2.2,1.9,4.5,2.9,6.8
				l0.1,0.2l-0.1,0.1c-1.2,0.9-2.4,1.7-3.6,2.6l0.1-0.2c0.2,1.6,0.3,3.2,0.1,4.9l-0.1-0.2c1.2,0.8,2.4,1.7,3.7,2.5l0.2,0.1l-0.1,0.2
				c-0.9,2.3-1.8,4.6-2.8,6.9l-0.1,0.2H44c-1.5-0.2-2.9-0.5-4.4-0.7l0.2-0.1l0,0c-1,1.4-2.2,2.6-3.4,3.5l0.1-0.2
				c0.3,1.5,0.6,3,0.8,4.4v0.2L37,313c-2.3,1-4.7,2-6.8,2.9L30,316l-0.1-0.1c-0.9-1.3-1.8-2.5-2.6-3.6l0.2,0.1c-1.7,0.2-3.4,0.2-4.9,0
				l0.2-0.1c-0.9,1.3-1.8,2.5-2.6,3.7l-0.1,0.1L20,316c-2.4-1-4.7-1.9-6.9-2.8l-0.2-0.1v-0.2c0.2-1.5,0.5-3.1,0.7-4.4l0.1,0.2
				c-1.4-1-2.6-2.2-3.5-3.4l0.2,0.1c-1.5,0.3-3.1,0.5-4.4,0.8H5.9l-0.1-0.1c-1-2.4-2-4.7-2.9-6.8l-0.1-0.1l0.1-0.1
				c1.3-0.9,2.5-1.8,3.6-2.6l-0.1,0.1c-0.2-1.7-0.2-3.4,0-4.9l0.1,0.1c-1.3-0.9-2.6-1.8-3.7-2.6H2.7v-0.1c1.1-2.8,2.1-5.1,2.8-6.9
				v-0.1h0.1c1.4,0.3,2.5,0.4,3.3,0.6c0.4,0.1,0.6,0.1,0.8,0.1C10.1,283,10.2,283,10.2,283L10.2,283z"/>
		</g>
		<path class="st4" d="M69.5,65.9c0,1.2-0.9,2.1-2.1,2.1s-2.1-1-2.1-2.1c0-1.2,0.9-2.1,2.1-2.1C68.6,63.8,69.5,64.7,69.5,65.9z"/>
		<g>
			<path class="st1" d="M309.8,19.9c0.8-1.1,1.7-2,2.8-2.8l-0.7-3.6l5.6-2.4l2.1,3c1.3-0.2,2.6-0.2,3.9,0l2.1-3l5.6,2.3l-0.6,3.6
				c1.1,0.8,2,1.7,2.8,2.8l3.6-0.7l2.4,5.6l-3,2.1c0.2,1.3,0.2,2.6,0,3.9l3,2.1l-2.3,5.6l-3.6-0.6c-0.8,1.1-1.7,2-2.8,2.8l0.7,3.6
				l-5.6,2.4l-2.1-3c-1.3,0.2-2.6,0.2-3.9,0l-2.1,3l-5.6-2.3l0.6-3.6c-1.1-0.8-2-1.7-2.8-2.8l-3.6,0.7l-2.4-5.6l3-2.1
				c-0.2-1.3-0.2-2.6,0-3.9l-3-2.1l2.3-5.6L309.8,19.9L309.8,19.9L309.8,19.9z M315.9,26.2c-1.3,3.3,0.3,7.1,3.6,8.5
				c3.3,1.3,7.1-0.3,8.5-3.6s-0.3-7.1-3.6-8.5S317.2,22.8,315.9,26.2z"/>
			<path class="st5" d="M315.9,26.2c0,0,0.1-0.6,0.7-1.4s1.7-2,3.5-2.5c0.9-0.3,2-0.4,3.1-0.1c1.1,0.2,2.2,0.7,3.1,1.6
				c0.5,0.4,0.9,0.9,1.2,1.5c0.4,0.6,0.6,1.2,0.8,1.8c0.4,1.3,0.3,2.8-0.3,4.2c-0.6,1.3-1.5,2.5-2.7,3.2c-0.6,0.4-1.2,0.6-1.8,0.8
				c-0.6,0.2-1.3,0.2-1.9,0.2c-1.3,0-2.4-0.5-3.4-1.1c-0.9-0.6-1.6-1.4-2.1-2.2c-1-1.6-1-3.3-0.8-4.3
				C315.6,26.7,315.8,26.2,315.9,26.2c0,0-0.2,0.6-0.4,1.6c-0.1,1,0,2.6,0.9,4.1c0.5,0.8,1.2,1.5,2,2.1c0.9,0.5,2,1,3.2,1
				c0.6,0,1.2,0,1.8-0.2c0.6-0.2,1.2-0.4,1.7-0.8c1.1-0.7,2-1.7,2.5-3s0.6-2.7,0.3-3.9c-0.2-0.6-0.4-1.2-0.7-1.7
				c-0.3-0.5-0.7-1-1.2-1.4c-0.9-0.8-1.9-1.3-3-1.5c-1-0.2-2.1-0.1-2.9,0.1c-1.8,0.5-2.9,1.5-3.5,2.4
				C316.1,25.7,315.9,26.2,315.9,26.2L315.9,26.2z"/>
			<path class="st5" d="M309.8,19.9c0,0-0.1,0-0.2,0c-0.2,0-0.4-0.1-0.7-0.1c-0.6-0.1-1.5-0.2-2.7-0.4h0.1c-0.6,1.4-1.3,3.3-2.2,5.6
				v-0.1c0.9,0.6,1.9,1.3,3,2.1h0.1l0,0c-0.2,1.2-0.2,2.5,0,3.9V31l0,0c-0.9,0.7-1.9,1.4-3,2.2V33c0.8,1.8,1.5,3.6,2.4,5.6l-0.2-0.1
				c1.1-0.2,2.3-0.4,3.6-0.7h0.1l0.1,0.1c0.8,1,1.7,1.9,2.8,2.7l0.1,0.1v0.1c-0.2,1.1-0.4,2.4-0.6,3.6l-0.1-0.2
				c1.8,0.7,3.7,1.5,5.6,2.3l-0.2,0.1c0.6-0.9,1.4-2,2.1-3l0.1-0.1h0.1c1.2,0.1,2.6,0.2,3.9,0h0.1l0.1,0.1c0.7,0.9,1.4,1.9,2.1,3
				l-0.2-0.1c1.8-0.8,3.7-1.6,5.6-2.4l-0.1,0.2c-0.2-1.1-0.4-2.4-0.7-3.6v-0.1l0.1-0.1c1-0.8,1.9-1.7,2.7-2.8l0,0l0.1-0.1h0.1
				c1.2,0.2,2.4,0.4,3.6,0.6l-0.2,0.1c0.8-1.9,1.5-3.8,2.3-5.6l0.1,0.2c-1-0.7-2-1.4-3-2.1l-0.1-0.1v-0.1c0.2-1.3,0.1-2.6,0-3.9v-0.1
				l0.1-0.1c1-0.7,2-1.4,3-2.1l-0.1,0.2c-0.8-1.9-1.6-3.8-2.4-5.6l0.2,0.1c-1.2,0.2-2.4,0.4-3.6,0.7h-0.1l-0.1-0.1
				c-0.8-1.1-1.8-2-2.8-2.7l-0.1-0.1v-0.1c0.2-1.2,0.4-2.4,0.6-3.6l0.1,0.2c-2-0.8-3.8-1.5-5.6-2.3h0.2c-0.7,1.1-1.4,2-2.1,3v0.1h-0.1
				c-1.4-0.2-2.7-0.1-3.9,0h-0.1l0,0c-0.8-1.1-1.5-2.1-2.1-3h0.1c-2.2,0.9-4,1.7-5.6,2.3v-0.1c0.3,1.4,0.5,2.6,0.6,3.6l0,0l0,0
				c-1.1,0.8-1.7,1.5-2.2,2c-0.2,0.2-0.4,0.4-0.5,0.6C309.9,19.9,309.8,19.9,309.8,19.9s0-0.1,0.1-0.2s0.2-0.3,0.5-0.6
				c0.4-0.5,1.1-1.2,2.1-2.1v0.1c-0.2-1-0.4-2.2-0.7-3.6v-0.1h0.1c1.6-0.7,3.4-1.5,5.6-2.4h0.1v0.1c0.7,0.9,1.4,1.9,2.2,3h-0.1
				c1.2-0.2,2.6-0.2,4-0.1l-0.1,0.1c0.7-1,1.3-2,2.1-3l0.1-0.1h0.1c1.8,0.7,3.6,1.5,5.6,2.3h0.1v0.1c-0.2,1.2-0.4,2.4-0.6,3.6
				l-0.1-0.2c1,0.8,2,1.7,2.9,2.8l-0.2-0.1c1.2-0.2,2.4-0.4,3.6-0.7h0.1l0.1,0.1c0.8,1.8,1.6,3.7,2.4,5.6l0.1,0.1l-0.1,0.1
				c-1,0.7-2,1.4-3,2.1l0.1-0.2c0.2,1.3,0.2,2.7,0,4l-0.1-0.2c1,0.7,2,1.4,3,2.1l0.1,0.1l-0.1,0.1c-0.7,1.8-1.5,3.7-2.3,5.6l-0.1,0.1
				h-0.1c-1.2-0.2-2.4-0.4-3.6-0.6l0.2-0.1l0,0c-0.8,1.1-1.8,2.1-2.8,2.9l0.1-0.2c0.2,1.2,0.4,2.5,0.7,3.6v0.1l-0.1,0.1
				c-1.9,0.8-3.8,1.6-5.6,2.4l-0.1,0.1l-0.1-0.1c-0.7-1-1.5-2-2.1-3l0.2,0.1c-1.4,0.2-2.8,0.2-4,0l0.2-0.1c-0.7,1-1.4,2.1-2.1,3
				l-0.1,0.1H318c-1.9-0.8-3.8-1.5-5.6-2.3h-0.1v-0.1c0.2-1.3,0.4-2.5,0.6-3.6l0.1,0.2c-1.1-0.8-2.1-1.8-2.8-2.8l0.1,0.1
				c-1.3,0.2-2.5,0.4-3.6,0.7h-0.1v-0.1c-0.8-2-1.6-3.8-2.4-5.6v-0.1L304,33c1-0.8,2-1.5,3-2.1V31c-0.2-1.4-0.2-2.7,0-4v0.1
				c-1.1-0.8-2.1-1.5-3-2.1h-0.1v-0.1c0.9-2.3,1.7-4.2,2.3-5.6l0,0l0,0c1.2,0.2,2.1,0.4,2.7,0.5c0.3,0.1,0.5,0.1,0.7,0.1
				C309.7,19.9,309.8,19.9,309.8,19.9L309.8,19.9z"/>
		</g>
		<path class="st3 cs origin-[10%_67%] animate-spin" style="animation-duration: 15s; animation-direction: reverse;" d="M35.8,249.3c0.6-0.8,1.3-1.5,2.1-2.2l-0.5-2.8l4.3-1.8l1.6,2.3c1-0.1,2-0.2,3,0l1.6-2.3l4.3,1.7l-0.4,2.8
			c0.8,0.6,1.5,1.3,2.2,2.1l2.8-0.5l1.8,4.3l-2.3,1.6c0.1,1,0.2,2,0,3l2.3,1.6l-1.7,4.3L54,263c-0.6,0.8-1.3,1.5-2.1,2.2l0.5,2.8
			l-4.3,1.8l-1.6-2.3c-1,0.1-2,0.2-3,0l-1.6,2.3l-4.3-1.7l0.4-2.8c-0.8-0.6-1.5-1.3-2.2-2.1l-2.8,0.5l-1.8-4.3l2.3-1.6
			c-0.1-1-0.2-2,0-3l-2.3-1.6l1.7-4.3L35.8,249.3L35.8,249.3L35.8,249.3z M40.4,254.1c-1,2.6,0.2,5.4,2.8,6.5c2.6,1,5.4-0.2,6.5-2.8
			c1-2.6-0.2-5.4-2.8-6.5C44.3,250.3,41.4,251.5,40.4,254.1z"/>
		<g>
			<path class="st6" d="M53.7,56.9H382c1.8,0,3.3,1.5,3.3,3.4v223.2c0,1.8-1.5,3.4-3.3,3.4H53.7c-1.8,0-3.3-1.5-3.3-3.4V60.3
				C50.4,58.4,51.9,56.9,53.7,56.9z"/>
			<path class="st7" d="M54.2,57.8h327.2c1.8,0,3.3,1.5,3.3,3.3v221.6c0,1.8-1.5,3.3-3.3,3.3H54.2c-1.8,0-3.3-1.5-3.3-3.3V61.1
				C50.9,59.3,52.4,57.8,54.2,57.8z"/>
			<path class="st4" d="M54.2,77h327.2c1.8,0,3.3,1.4,3.3,3v203c0,1.7-1.5,3-3.3,3H54.2c-1.8,0-3.3-1.4-3.3-3V80.1
				C50.9,78.4,52.4,77,54.2,77z"/>
			<path class="st4" d="M74.7,65.8c0,1.2-0.9,2.1-2.1,2.1s-2.1-1-2.1-2.1c0-1.2,0.9-2.1,2.1-2.1C73.7,63.7,74.7,64.6,74.7,65.8z"/>
			<path class="st4" d="M68.7,65.8c0,1.2-0.9,2.1-2.1,2.1s-2.1-1-2.1-2.1c0-1.2,0.9-2.1,2.1-2.1C67.8,63.7,68.7,64.6,68.7,65.8z"/>
			<g>
				<rect x="71.1" y="209.2" class="st8" width="295.2" height="62.9"/>
				<g>
					<path class="st5" d="M338.7,220.4c0,0.2-57.3,0.3-128,0.3s-128-0.1-128-0.3s57.3-0.3,128-0.3S338.7,220.3,338.7,220.4
						L338.7,220.4z"/>
					<path class="st5" d="M204.1,256.9c0,0,0.7,0,2.1-0.3c1.3-0.3,3.3-1,5.2-2.8c1.8-1.8,3.4-4.9,3-8.6c-0.1-0.9-0.4-1.8-0.7-2.7
						c-0.4-0.9-0.9-1.8-1.6-2.6c-1.3-1.6-3.2-2.9-5.4-3.4c-1.1-0.3-2.3-0.3-3.5-0.3s-2.5,0-3.7,0c-2.5,0-5.2,0-7.9,0
						c-5.5,0-11.3,0-17.5,0c-12.3,0-25.9,0-40.1,0c-1.7,0-3.5,0.5-5,1.3c-1.5,0.9-2.8,2-3.7,3.5c-1.8,2.9-2,6.6-0.7,9.6
						s4.2,5.2,7.2,5.8c1.5,0.3,3.1,0.2,4.7,0.2c1.6,0,3.1,0,4.7,0c12.3,0,23.4,0,32.8,0.1c9.3,0,16.9,0.1,22.1,0.1c2.6,0,4.6,0,6,0.1
						c0.7,0,1.2,0,1.6,0C204,256.9,204.1,256.9,204.1,256.9s-0.2,0-0.5,0c-0.4,0-0.9,0-1.6,0c-1.4,0-3.4,0-6,0.1
						c-5.2,0-12.8,0.1-22.1,0.1s-20.4,0-32.8,0.1c-1.5,0-3.1,0-4.7,0c-1.6,0-3.2,0.1-4.8-0.2c-3.2-0.7-6.2-2.9-7.6-6.1
						c-1.4-3.1-1.2-7,0.7-10.1c0.9-1.5,2.3-2.8,3.8-3.7c1.6-0.9,3.4-1.3,5.2-1.4c14.2,0,27.8,0,40.1,0c6.2,0,12,0,17.5,0
						c2.7,0,5.4,0,7.9,0c1.3,0,2.5,0,3.7,0s2.5,0,3.6,0.3c2.3,0.6,4.3,1.9,5.6,3.6c0.7,0.8,1.2,1.7,1.6,2.7c0.4,0.9,0.6,1.9,0.7,2.8
						c0.4,3.8-1.2,7-3.1,8.8c-1.9,1.8-4,2.5-5.3,2.8c-0.7,0.1-1.2,0.2-1.6,0.2C204.3,256.9,204.1,256.9,204.1,256.9L204.1,256.9z"/>
					<path class="st5" d="M314.6,256.9c0,0,0.7,0,2.1-0.3c1.3-0.3,3.3-1,5.2-2.8c1.8-1.8,3.4-4.9,3-8.6c-0.1-0.9-0.4-1.8-0.7-2.7
						c-0.4-0.9-0.9-1.8-1.6-2.6c-1.3-1.6-3.2-2.9-5.4-3.4c-1.1-0.3-2.3-0.3-3.5-0.3s-2.5,0-3.7,0c-2.5,0-5.2,0-7.9,0
						c-5.5,0-11.3,0-17.5,0c-12.3,0-25.9,0-40.1,0c-1.7,0-3.5,0.5-5,1.3c-1.5,0.9-2.8,2-3.7,3.5c-1.8,2.9-2,6.6-0.7,9.6
						s4.2,5.2,7.2,5.8c1.5,0.3,3.1,0.2,4.7,0.2c1.6,0,3.1,0,4.7,0c12.3,0,23.4,0,32.8,0.1c9.3,0,16.9,0.1,22.1,0.1c2.6,0,4.6,0,6,0.1
						c0.7,0,1.2,0,1.6,0C314.4,256.9,314.6,256.9,314.6,256.9s-0.2,0-0.5,0c-0.4,0-0.9,0-1.6,0c-1.4,0-3.4,0-6,0.1
						c-5.2,0-12.8,0.1-22.1,0.1c-9.3,0-20.4,0-32.8,0.1c-1.5,0-3.1,0-4.7,0c-1.6,0-3.2,0.1-4.8-0.2c-3.2-0.7-6.2-2.9-7.6-6.1
						c-1.4-3.1-1.2-7,0.7-10.1c0.9-1.5,2.3-2.8,3.8-3.7c1.6-0.9,3.4-1.3,5.2-1.4c14.2,0,27.8,0,40.1,0c6.2,0,12,0,17.5,0
						c2.7,0,5.4,0,7.9,0c1.3,0,2.5,0,3.7,0s2.5,0,3.6,0.3c2.3,0.6,4.3,1.9,5.6,3.6c0.7,0.8,1.2,1.7,1.6,2.7c0.4,0.9,0.6,1.9,0.7,2.8
						c0.4,3.8-1.2,7-3.1,8.8s-4,2.5-5.3,2.8c-0.7,0.1-1.2,0.2-1.6,0.2C314.8,256.9,314.6,256.9,314.6,256.9L314.6,256.9z"/>
				</g>
			</g>
			<rect x="71.8" y="92.8" class="cs" width="106.5" height="101.5"/>
			<g>
				<path class="st4" d="M174.8,189.8c0,0,0-0.6,0-1.7c0-1.1,0-2.8,0-5c0-4.3,0-10.7-0.1-18.8c0-16.2-0.1-39.2-0.1-67l0.2,0.2
					c-28.8,0-63.1,0-99.8,0l0,0l0.3-0.3c0,33.7,0,65.4,0,92.4l-0.2-0.2c29.8,0.1,54.8,0.1,72.3,0.1c8.8,0,15.6,0.1,20.3,0.1
					c2.4,0,4.2,0,5.4,0c0.6,0,1.1,0,1.4,0C174.7,189.8,174.9,189.8,174.8,189.8c0,0-0.1,0-0.4,0c-0.3,0-0.8,0-1.4,0c-1.2,0-3,0-5.3,0
					c-4.7,0-11.6,0-20.3,0.1c-17.5,0-42.5,0.1-72.4,0.1h-0.2v-0.2c0-27.1,0-58.7,0-92.4v-0.3H75l0,0c36.7,0,71,0,99.8,0h0.2v0.2
					c-0.1,27.8-0.1,50.9-0.1,67.1c0,8.1-0.1,14.4-0.1,18.7c0,2.1,0,3.8,0,4.9C174.9,189.2,174.9,189.8,174.8,189.8L174.8,189.8z"/>
				<path class="st4" d="M174.8,140.9c0,0-0.1-0.1-0.4-0.3c-0.3-0.2-0.7-0.5-1.1-0.9c-1-0.8-2.5-2-4.3-3.5c-3.7-3.1-9-7.4-15.4-12.7
					h0.3c-8.2,6.7-18.3,15-29.2,23.9c-1.7,1.4-3.3,2.7-5,4.1l-0.2,0.1l-0.2-0.2c-5.4-4.9-10.6-9.6-15.3-13.9h0.3
					c-8.5,7.4-15.7,13.6-20.8,18c-2.5,2.2-4.5,3.9-5.9,5.1c-0.7,0.6-1.2,1-1.6,1.3c-0.2,0.1-0.3,0.3-0.4,0.3c-0.1,0.1-0.2,0.1-0.2,0.1
					s0-0.1,0.1-0.1c0.1-0.1,0.2-0.2,0.4-0.4c0.4-0.3,0.9-0.8,1.5-1.4c1.4-1.2,3.4-3,5.9-5.2c5.1-4.4,12.2-10.7,20.7-18.1l0.2-0.1
					l0.2,0.2c4.7,4.3,9.9,9,15.3,13.9h-0.3c1.6-1.3,3.3-2.7,5-4.1c10.9-8.9,21-17.1,29.3-23.8l0.1-0.1l0.1,0.1
					c6.4,5.3,11.6,9.8,15.3,12.8c1.8,1.5,3.2,2.7,4.2,3.6c0.5,0.4,0.8,0.7,1.1,1C174.8,140.8,174.9,140.9,174.8,140.9L174.8,140.9z"/>
				<path class="st4" d="M131,117.5c0,0,0-0.7-0.3-1.8c-0.3-1.1-1.1-2.8-2.8-4c-0.8-0.6-1.9-1.1-3.1-1.4c-1.2-0.2-2.6-0.2-3.8,0.3
					c-1.3,0.5-2.5,1.3-3.4,2.6c-0.9,1.2-1.4,2.7-1.4,4.3c0,1.6,0.5,3.1,1.4,4.3c0.9,1.2,2.1,2.1,3.4,2.6s2.6,0.5,3.8,0.3
					c1.2-0.2,2.3-0.7,3.1-1.4c1.7-1.3,2.5-2.9,2.8-4C131,118.1,130.9,117.4,131,117.5c0,0,0,0.2,0,0.5s0,0.8-0.2,1.4
					c-0.3,1.2-1,2.9-2.7,4.3c-0.9,0.7-2,1.2-3.3,1.5c-1.3,0.2-2.7,0.2-4.1-0.3s-2.7-1.4-3.6-2.7c-1-1.3-1.5-2.9-1.5-4.6
					s0.6-3.3,1.5-4.6c0.9-1.3,2.3-2.2,3.6-2.7c1.4-0.5,2.8-0.5,4.1-0.3c1.3,0.2,2.4,0.8,3.3,1.5c1.8,1.4,2.5,3.1,2.7,4.3
					c0.1,0.6,0.2,1.1,0.2,1.4C131,117.3,131,117.4,131,117.5L131,117.5z"/>
			</g>
			<path class="st10" d="M373.9,168.9l-9.1-11.4c0,0-14.7,11.5-16.4,10.6c-9.2-4.9-16.7-13.8-19.3-17c-0.1-0.6-0.2-1.3-0.2-2.1
				c-0.1-0.7,0-1.4,0.2-2.1c0.2-0.8,0.6-1.9,0.9-2.5c0.5-0.7-0.1-1.5-0.8-1.3c-0.7,0.2-2,2.1-2.4,4.6c-0.4,2.6-2.5-1-3.2-2.3
				c-0.8-1.3-3.3-5.2-3.9-5.2c-0.9-0.1-1.1,0.8-0.4,2c0.8,1.3,2.9,5.2,2.2,5.6c-0.7,0.4-4.5-6.2-4.5-6.2s-0.6-1.5-1.4-1.1
				c-1.5,0.7,3,7.4,3.5,8.1c0.3,0.4-0.4,0.9-0.7,0.4c-0.3-0.5-3.6-6.7-5.1-6c-1.2,0.6,3.7,5.6,3.6,7.2s-3.3-4.2-4.4-3.6
				c-0.4,0.2-0.7,0.4,1.7,3.5c1.1,1.5,3.5,4,5.7,6.2l0,0c0,0,21.8,33.5,36.9,24.6C372.8,171.3,373.9,168.9,373.9,168.9L373.9,168.9z"
				/>
			<g>
				<g>
					<g>
						<path d="M409.3,310.9l-4.7-3.7l6.8-8.9l7,5.3l-0.4,0.6c-1.7,2.5-9,12.9-10.8,14.1C405.2,319.8,409.3,311,409.3,310.9
							L409.3,310.9z"/>
						<g class="st11">
							<path class="st4" d="M407.4,315.4c0,0-1.3,3.2-0.6,3.2s8.4-10.1,11.5-14.9l-0.2-0.1l-8.9,11.9
								C409.2,315.4,407.8,314.9,407.4,315.4L407.4,315.4z"/>
						</g>
						<path class="st12" d="M418.2,303.4c0,0,0,0.1-0.1,0.2s-0.2,0.3-0.3,0.5c-0.3,0.5-0.7,1-1.2,1.7c-1,1.4-2.4,3.3-4,5.4
							s-3.1,3.9-4.2,5.3c-0.5,0.6-1,1.1-1.3,1.6c-0.1,0.2-0.2,0.3-0.4,0.4c-0.1,0.1-0.1,0.1-0.1,0.1s0-0.1,0.1-0.2s0.2-0.3,0.3-0.4
							c0.3-0.4,0.8-1,1.3-1.6c1.1-1.3,2.5-3.2,4.1-5.3c1.6-2.1,3-4,4-5.4c0.5-0.6,0.9-1.2,1.2-1.6c0.1-0.2,0.2-0.3,0.3-0.4
							C418.2,303.4,418.2,303.4,418.2,303.4L418.2,303.4z"/>
						<path class="st12" d="M409.3,315.6c0,0-0.4-0.2-1-0.3s-1,0.1-1,0.1s0.4-0.3,1-0.2C408.9,315.2,409.3,315.5,409.3,315.6
							L409.3,315.6z"/>
						<path class="st12" d="M410,311.9c0,0-0.2,0-0.5,0s-0.5-0.1-0.5-0.1s0.2,0,0.5,0S410,311.9,410,311.9z"/>
						<path class="st12" d="M410.2,310.9c0,0-0.2,0.1-0.5,0.1c-0.2,0-0.5,0.1-0.5,0s0.2-0.1,0.5-0.1
							C409.9,310.9,410.2,310.9,410.2,310.9z"/>
						<path class="st12" d="M409.7,309.8c0,0-0.1,0.2-0.3,0.5s-0.4,0.5-0.4,0.4c0,0,0.1-0.2,0.3-0.5
							C409.5,310,409.6,309.8,409.7,309.8L409.7,309.8z"/>
						<path class="st12" d="M409.2,309.2c0,0,0,0.3-0.2,0.6c-0.2,0.3-0.5,0.5-0.5,0.5s0.2-0.2,0.4-0.5
							C409,309.5,409.2,309.2,409.2,309.2L409.2,309.2z"/>
						<path class="st12" d="M409,311.6c0,0-0.1,0.2-0.4,0.6c-0.2,0.2-0.4,0.3-0.6,0.5c-0.1,0.1-0.2,0.2-0.4,0.2c-0.1,0-0.2,0-0.3,0
							c-0.1,0-0.2-0.2-0.2-0.3c0.1-0.8,0.9-1.1,1.3-1.1c0.2,0,0.4,0,0.5,0.1c0.1,0,0.2,0.1,0.2,0.1s-0.2-0.1-0.7-0.1
							c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.2-0.5,0.4-0.5,0.7c0,0.1,0.1,0.2,0.3,0.1c0.1,0,0.3-0.1,0.4-0.2c0.2-0.2,0.4-0.3,0.6-0.5
							C408.8,311.8,409,311.6,409,311.6L409,311.6z"/>
						<path class="st12" d="M408.9,311.8c0,0-0.2,0-0.5-0.2c-0.1-0.1-0.2-0.3-0.3-0.5c0-0.2-0.1-0.5,0.1-0.7c0.1-0.1,0.3-0.1,0.4,0
							s0.1,0.2,0.2,0.3c0.1,0.2,0.1,0.4,0.1,0.5C409,311.5,408.9,311.7,408.9,311.8c0-0.1,0-0.2-0.1-0.5c0-0.1-0.1-0.3-0.2-0.5
							s-0.2-0.4-0.4-0.3c-0.1,0.1-0.1,0.4,0,0.6s0.1,0.3,0.2,0.4C408.7,311.7,408.9,311.8,408.9,311.8L408.9,311.8z"/>
						<path class="st12" d="M415.6,301.7c0,0-0.2,0.2-0.5,0.7c-0.3,0.4-0.5,1.1-0.5,1.9s0.3,1.4,0.6,1.9c0.3,0.4,0.5,0.6,0.5,0.6
							s-0.1,0-0.2-0.1s-0.2-0.2-0.4-0.5c-0.3-0.4-0.6-1.1-0.6-1.9s0.3-1.5,0.6-1.9c0.1-0.2,0.3-0.4,0.4-0.5
							C415.6,301.8,415.6,301.7,415.6,301.7L415.6,301.7z"/>
						<path class="st12" d="M411.2,299.8c0,0,0.9,0.6,1.9,1.3c1,0.8,1.8,1.4,1.8,1.4s-0.9-0.6-1.9-1.3S411.2,299.8,411.2,299.8z"/>
						<path class="st12" d="M414.1,307.3c0,0-0.3,0.7-0.9,1.4c-0.5,0.7-1.1,1.2-1.1,1.2s0.5-0.6,1-1.3
							C413.6,307.9,414,307.3,414.1,307.3L414.1,307.3z"/>
						<path class="st12" d="M415.4,305.6c0,0,0.1,0.1,0.2,0.3c0.1,0.2,0.2,0.3,0.2,0.3s-0.2-0.1-0.3-0.2S415.4,305.6,415.4,305.6
							L415.4,305.6z"/>
						<path class="st12" d="M415.1,304.3c0,0,0.1,0.1,0.1,0.3c0,0.2,0,0.3,0,0.3s-0.1-0.1-0.1-0.3C415,304.4,415,304.3,415.1,304.3z"
							/>
						<path class="st12" d="M415.4,303.1c0,0-0.1,0.1-0.1,0.3c-0.1,0.2-0.1,0.3-0.1,0.3s-0.1-0.2,0-0.4S415.4,303,415.4,303.1
							L415.4,303.1z"/>
						<path class="st12" d="M415.8,302.3c0,0,0,0.1,0,0.2s-0.1,0.2-0.1,0.1s0-0.1,0-0.2C415.7,302.3,415.7,302.2,415.8,302.3z"/>
					</g>
					<g class="st13">
						<polygon points="415.4,301.6 408.5,310.3 407.4,309.5 414.9,301.1 				"/>
					</g>
				</g>
				<g>
					<g>
						<path d="M372.7,328.4l-0.8-5.9L383,321l1.2,8.6l-0.7,0.1c-3,0.6-15.5,2.7-17.6,2.4C363.5,331.8,372.7,328.4,372.7,328.4
							L372.7,328.4z"/>
						<g class="st11">
							<path class="st4" d="M368.2,330.2c0,0-3.2,1.4-2.7,1.9s13.1-1.2,18.6-2.4v-0.2l-14.7,2.1C369.4,331.5,368.9,330.2,368.2,330.2
								L368.2,330.2z"/>
						</g>
						<path class="st12" d="M384.3,329.4c0,0-0.1,0-0.2,0c-0.2,0-0.3,0.1-0.5,0.1c-0.5,0.1-1.2,0.2-2,0.4c-1.7,0.3-4,0.7-6.6,1
							c-2.6,0.4-5,0.6-6.7,0.8c-0.8,0.1-1.5,0.1-2,0.2c-0.2,0-0.4,0-0.5,0s-0.2,0-0.2,0s0.1,0,0.2,0c0.2,0,0.4,0,0.5-0.1
							c0.5-0.1,1.2-0.1,2-0.2c1.7-0.2,4.1-0.5,6.7-0.8s4.9-0.7,6.6-1c0.8-0.1,1.5-0.2,2-0.3c0.2,0,0.4-0.1,0.5-0.1
							C384.3,329.4,384.3,329.4,384.3,329.4L384.3,329.4z"/>
						<path class="st12" d="M369.4,331.7c0,0-0.1-0.5-0.5-0.9c-0.4-0.5-0.8-0.6-0.8-0.7c0,0,0.5,0.1,0.9,0.6S369.4,331.7,369.4,331.7z
							"/>
						<path class="st12" d="M372.5,329.6c0,0-0.2-0.1-0.4-0.4s-0.3-0.4-0.3-0.5c0,0,0.2,0.1,0.4,0.4S372.5,329.6,372.5,329.6z"/>
						<path class="st12" d="M373.3,329c0,0-0.2-0.1-0.4-0.2c-0.2-0.1-0.4-0.3-0.4-0.3s0.2,0.1,0.4,0.2
							C373.2,328.8,373.3,329,373.3,329z"/>
						<path class="st12" d="M373.7,327.9c0,0-0.2,0.1-0.6,0.1s-0.6,0-0.6,0s0.2-0.1,0.6-0.1S373.7,327.8,373.7,327.9z"/>
						<path class="st12" d="M373.8,327.1c0,0-0.2,0.2-0.6,0.3c-0.4,0.1-0.7,0-0.7,0s0.3-0.1,0.6-0.1S373.8,327.1,373.8,327.1
							L373.8,327.1z"/>
						<path class="st12" d="M372,328.7c0,0-0.3,0.1-0.7,0.1c-0.2,0-0.5,0-0.8-0.1c-0.1,0-0.3,0-0.5-0.1c-0.1,0-0.2-0.1-0.2-0.2
							s0-0.2,0.1-0.3c0.6-0.4,1.4-0.1,1.7,0.2c0.2,0.2,0.2,0.3,0.3,0.4c0,0.1,0,0.2,0,0.2s-0.1-0.3-0.4-0.6c-0.2-0.1-0.4-0.3-0.7-0.3
							c-0.3,0-0.6,0-0.8,0.2c-0.1,0.1,0,0.2,0.1,0.3c0.1,0.1,0.3,0.1,0.4,0.1c0.3,0.1,0.5,0.1,0.8,0.1
							C371.7,328.7,372,328.6,372,328.7L372,328.7z"/>
						<path class="st12" d="M371.8,328.7c0,0-0.1-0.2-0.1-0.5c0-0.2,0-0.4,0.1-0.5c0.1-0.2,0.2-0.4,0.5-0.4c0.2,0,0.3,0.2,0.3,0.3
							c0,0.1,0,0.2-0.1,0.3c-0.1,0.2-0.2,0.4-0.3,0.5C372,328.6,371.8,328.7,371.8,328.7s0.2-0.2,0.3-0.4c0.1-0.1,0.2-0.3,0.2-0.5
							c0.1-0.2,0.1-0.4-0.1-0.5c-0.2,0-0.3,0.2-0.4,0.4c-0.1,0.2-0.1,0.3-0.1,0.5C371.7,328.6,371.8,328.7,371.8,328.7L371.8,328.7z"
							/>
						<path class="st12" d="M383.7,326.4c0,0-0.3,0-0.8,0.1s-1.1,0.4-1.7,1s-0.8,1.2-0.9,1.7s-0.1,0.8-0.1,0.8s0-0.1,0-0.2
							s0-0.4,0-0.6c0.1-0.5,0.3-1.2,0.9-1.8s1.3-0.9,1.8-1c0.3,0,0.5-0.1,0.6-0.1C383.6,326.4,383.7,326.4,383.7,326.4L383.7,326.4z"
							/>
						<path class="st12" d="M381.9,321.9c0,0,0.2,1,0.4,2.3c0.2,1.3,0.3,2.3,0.3,2.3s-0.2-1-0.4-2.3S381.9,321.9,381.9,321.9z"/>
						<path class="st12" d="M378.6,329.2c0,0-0.7,0.2-1.6,0.4s-1.6,0.1-1.6,0.1s0.7-0.1,1.6-0.2C377.9,329.3,378.6,329.2,378.6,329.2
							L378.6,329.2z"/>
						<path class="st12" d="M380.8,328.9c0,0,0,0.2-0.1,0.3s-0.1,0.3-0.1,0.3s-0.1-0.2,0-0.4C380.6,329,380.8,328.9,380.8,328.9
							L380.8,328.9z"/>
						<path class="st12" d="M381.5,327.8c0,0,0,0.1-0.1,0.3s-0.2,0.2-0.2,0.2s0-0.1,0.1-0.3S381.4,327.8,381.5,327.8z"/>
						<path class="st12" d="M382.6,327.2c0,0-0.1,0.1-0.3,0.1c-0.2,0.1-0.3,0.2-0.3,0.2s0-0.2,0.2-0.3
							C382.4,327.1,382.6,327.1,382.6,327.2L382.6,327.2z"/>
						<path class="st12" d="M383.4,326.8c0,0,0,0.1-0.1,0.1s-0.2,0.1-0.2,0s0-0.1,0.1-0.1S383.4,326.8,383.4,326.8z"/>
					</g>
					<g class="st13">
						<polygon points="383.6,326.1 372.5,327.4 372.4,326 383.5,324.6 				"/>
					</g>
				</g>
				<g>
					<g>
						<path class="cp" d="M388.2,149.6c3.3-0.2,6.7,0.8,9.5,3.4l2.3,11.3l-3.9,15.8c-1.1,4.6,2.2,11.1,3.1,18.6l-30.9,5.4l-0.1-18.5
							l-3.2-9.9l-5.1,3.3L349,166.5l10.1-8c8.1-7.9,18-8,18-8"/>
						<path class="st14" d="M368.2,165.3c0,0.1-0.7,0.3-1.6,1s-2,1.9-2.5,3.6c-0.5,1.7-0.2,3.3,0.2,4.4c0.4,1.1,0.9,1.7,0.8,1.7
							c0,0-0.6-0.5-1.1-1.6s-0.9-2.8-0.3-4.6c0.5-1.8,1.7-3.1,2.7-3.8C367.4,165.4,368.2,165.2,368.2,165.3L368.2,165.3z"/>
					</g>
				</g>
				<g>
					<path class="st12" d="M391.8,123.8c1.9,0.7,3.2,2.9,2.8,4.9c2,0.2,3.9,1.7,4.4,3.7s-0.4,4.3-2.1,5.4c1.5,2,1.2,5.2-0.6,6.9
						s-4.9,1.9-6.9,0.4c-0.9,1.9-3.1,2.9-5.2,2.6c-2.1-0.2-3.9-1.5-5.2-3.1c-1.7,2.1-5,2.7-7.4,1.4s-3.5-4.5-2.6-7
						c-1.7-0.1-3-1.7-3.1-3.3c-0.1-1.7,0.8-3.3,2.1-4.2c0,0-4-6.7,3.6-6.3"/>
					<g>
						<g>
							<g>
								<path class="st10" d="M388.8,151l1-23.6l-9.5-7.2l-8.6,4.2c0,0-0.3,10.8,0,15.5s5,5.2,5,5.2s-0.1,2.6-0.3,5.3
									c-0.2,3.4,2.4,6.2,5.7,6.5l0,0C385.6,157.2,388.6,154.5,388.8,151L388.8,151z"/>
								<g>
									<path d="M374,132.6c0,0.3,0.3,0.6,0.6,0.6s0.6-0.3,0.6-0.6s-0.3-0.6-0.6-0.6S374,132.3,374,132.6z"/>
								</g>
								<g>
									<path d="M379.9,132.6c0,0.3,0.3,0.6,0.6,0.6s0.6-0.3,0.6-0.6s-0.3-0.6-0.6-0.6S379.9,132.3,379.9,132.6z"/>
								</g>
								<path class="st15" d="M376.7,145.1c0,0,3.2,0,6.2-2.1c0,0-1.6,3.4-6.3,3.3L376.7,145.1z"/>
								<path d="M377.2,137.6c0,0-0.4-0.1-1.1-0.1c-0.2,0-0.3,0-0.4-0.1c0-0.1,0-0.3,0.1-0.5c0.1-0.4,0.3-0.9,0.4-1.3
									c0.3-0.9,0.6-1.8,0.8-2.4c0.2-0.6,0.3-1,0.2-1c0,0-0.2,0.4-0.4,1c-0.2,0.6-0.5,1.5-0.8,2.4c-0.1,0.5-0.3,0.9-0.4,1.3
									c-0.1,0.2-0.1,0.4-0.1,0.7c0,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0,0.3,0C376.8,137.6,377.2,137.6,377.2,137.6L377.2,137.6z"/>
								<g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<g>
																			<g>
																				<g>
																					<g>
																						<g>
																							<g>
																								<path d="M379.1,129.6c0.1,0.2,0.7,0.1,1.5,0.2c0.8,0.1,1.4,0.3,1.5,0.2s-0.1-0.2-0.3-0.4
																									c-0.2-0.2-0.6-0.4-1.1-0.4s-0.9,0-1.2,0.1C379.2,129.4,379,129.6,379.1,129.6z"/>
																							</g>
																						</g>
																					</g>
																				</g>
																			</g>
																		</g>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
								</g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<g>
																			<g>
																				<g>
																					<g>
																						<g>
																							<g>
																								<path d="M373.1,129.8c0.1,0.2,0.6,0,1.1,0c0.6,0,1,0.1,1.1,0s0-0.2-0.2-0.4c-0.2-0.1-0.5-0.3-0.9-0.3
																									s-0.7,0.1-0.9,0.3C373.1,129.6,373.1,129.7,373.1,129.8z"/>
																							</g>
																						</g>
																					</g>
																				</g>
																			</g>
																		</g>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
								</g>
							</g>
							<path class="st5" d="M373.7,119.9c0,0.1-0.4-0.2-1-0.2s-1.4,0.3-1.8,1.1c-0.4,0.8-0.1,1.6,0.3,2.1c0.4,0.4,0.8,0.6,0.7,0.6
								c0,0.1-0.5,0-1-0.4c-0.2-0.2-0.5-0.6-0.6-1s-0.1-1,0.1-1.4s0.6-0.8,1-1s0.8-0.2,1.2-0.2C373.5,119.5,373.8,119.8,373.7,119.9
								L373.7,119.9z"/>
						</g>
						<g>
							<path class="st12" d="M366.4,139.6c0,0,0.1-0.1,0-0.4c0-0.2-0.1-0.6-0.5-0.6c-0.4-0.1-0.9,0.2-0.9,0.7
								c-0.1,0.5,0.3,1.1,0.9,1.2c0.6,0.2,1.2,0,1.6-0.3s0.6-0.8,0.7-1.2s0-0.8-0.1-0.9c-0.1-0.2-0.3-0.2-0.3-0.2s0.2-0.1,0.4,0.1
								c0.2,0.2,0.4,0.6,0.3,1.1c0,0.5-0.3,1.1-0.8,1.5s-1.3,0.7-2,0.4c-0.7-0.2-1.3-1-1.2-1.7c0-0.3,0.2-0.6,0.5-0.8s0.6-0.2,0.8-0.1
								s0.5,0.2,0.5,0.4c0.1,0.2,0.1,0.4,0.1,0.5C366.5,139.6,366.4,139.7,366.4,139.6L366.4,139.6z"/>
							<path class="st12" d="M396.5,135.5c0,0,0.1,0.3,0.5,0.6c0.2,0.2,0.5,0.3,0.9,0.4c0.4,0.1,0.8,0,1.2-0.3
								c0.8-0.5,1.3-1.6,1.2-2.9c0-0.6-0.2-1.3-0.6-1.8s-0.9-1-1.6-1.1c-0.6-0.1-1.3,0.1-1.8,0.5l-1,1.1l0.7-1.3
								c0.6-1.1,0.9-2.2,0.8-3.2s-0.7-1.8-1.4-2.1c-0.7-0.3-1.3-0.2-1.7,0c-0.4,0.2-0.5,0.4-0.5,0.4s0.1-0.2,0.5-0.5
								c0.4-0.2,1.1-0.4,1.9-0.2s1.5,1.2,1.7,2.2c0.2,1.1-0.2,2.3-0.8,3.5l-0.3-0.2c0.5-0.6,1.4-0.8,2.1-0.6c0.8,0.1,1.5,0.7,1.9,1.3
								s0.6,1.4,0.6,2c0,0.7-0.1,1.3-0.3,1.9c-0.2,0.6-0.7,1-1.1,1.2c-0.5,0.3-1,0.3-1.4,0.2s-0.7-0.3-0.9-0.5
								C396.4,135.9,396.5,135.5,396.5,135.5L396.5,135.5z"/>
						</g>
					</g>
					<path class="st12" d="M392.3,131.1c0.7-1.7,0.8-3.6,0.6-5.4c-0.2-1.5-0.6-3-1.6-4.2s-2.5-1.9-4-1.5c0-2-1.4-3.9-3.3-4.5
						s-4.1,0-5.4,1.5c-1.2-1.1-2.7-1.8-4.3-1.9s-3.2,0.6-4.2,1.8c-0.9,1.3-1,3.2-0.1,4.4c-1.2,0.4-2.1,1.8-2,3.1
						c0.1,1.3,1.3,2.5,2.6,2.6c1.3,0.2,2.7-0.7,3.1-1.9c0.2,1.4,1.7,2.4,3.1,2.2c1.4-0.2,2.6-1.2,3.1-2.5c0,2,1.7,3.9,3.7,4.1
						c0.5,0.1,1,0,1.5-0.1c-0.6,0.6-0.9,1.5-1,2.3c0,1.4,0.8,2.7,2.1,3.3c-0.8,0.2-1.3,1-1.5,1.8s0,1.6,0.2,2.4
						c0.7,2.3,2.3,5.8,4.5,6.7s5-1.2,6.1-3.3L392.3,131.1L392.3,131.1z"/>
					<path d="M379.5,138.9c0.1,0.1-0.3,0.7-1.1,1.1c-0.8,0.4-1.6,0.2-1.6,0.1c0-0.1,0.7-0.2,1.4-0.5
						C379,139.3,379.4,138.8,379.5,138.9L379.5,138.9z"/>
				</g>
				<g>
					<g>
						<g>
							<path class="st12" d="M369.8,203.9c0,0-11.6,59.3-10.4,62.1c1.3,2.8,44.4,48.6,44.4,48.6l13-15.7c0,0-35.9-40-35.6-41.1
								s12.7-58.2,12.7-58.2L369.8,203.9L369.8,203.9z"/>
							<path d="M400.6,312.5c0,0,0.1-0.3,0.5-0.7c0.4-0.5,0.8-1.1,1.4-1.8c1.2-1.5,2.8-3.7,4.5-6c1.8-2.4,3.3-4.5,4.4-6.1
								c0.5-0.7,1-1.4,1.3-1.9c0.3-0.4,0.5-0.7,0.5-0.6c0,0-0.1,0.3-0.4,0.8s-0.7,1.1-1.2,1.9c-1.1,1.6-2.6,3.8-4.3,6.2
								s-3.4,4.5-4.6,6c-0.6,0.8-1.1,1.3-1.5,1.8C400.9,312.3,400.6,312.5,400.6,312.5L400.6,312.5z"/>
						</g>
						<g>
							<g>
								<path class="st12" d="M373.2,202.8l-1.7,0.4l0.7,9.6c-2.9,15.8-8.8,49.3-8,59.5c1,13.5,3.4,54.7,3.4,54.7l19.3-3l-1.4-49.8
									l4.1-20.4l11-37c1.9-6.3,1.2-13-1.7-18.9l-1-2"/>
								<path d="M386.4,201.3c0.1,0,0.1,0.6,0.5,1.6c0.3,1,1.1,2.3,2.3,3.2c1,0.8,2.2,1.2,3.2,1.4s1.7,0.1,2,0.1l0,0
									c0.1,0,0.2,0.1,0.2,0.1s-0.1,0-0.2,0l0,0c-0.3,0.1-1,0.2-2.1,0.1c-1-0.1-2.3-0.5-3.4-1.4c-1.3-1-2-2.4-2.3-3.5
									C386.3,202,386.3,201.3,386.4,201.3L386.4,201.3z"/>
							</g>
							<path d="M367.3,322.6c0,0,0.3-0.1,0.7-0.2c0.5-0.1,1.2-0.3,2-0.5c1.7-0.4,4.1-0.9,6.8-1.3c2.7-0.4,5.1-0.8,6.9-0.9
								c0.9-0.1,1.6-0.1,2.1-0.2c0.5,0,0.8,0,0.8,0c0,0.1-1.1,0.2-2.8,0.5s-4.2,0.6-6.8,1c-2.7,0.4-5.1,0.9-6.8,1.2
								S367.3,322.7,367.3,322.6L367.3,322.6z"/>
						</g>
					</g>
					<path d="M372.3,212.9c0,0,0,0.2-0.1,0.6c-0.1,0.4-0.2,1-0.3,1.7c-0.3,1.5-0.6,3.6-1.1,6.2c-1,5.2-2.2,12.5-3.4,20.5
						s-2,15.3-2.4,20.6c-0.2,2.6-0.4,4.8-0.5,6.3c0,0.7-0.1,1.3-0.1,1.7c0,0.4-0.1,0.6-0.1,0.6s0-0.2,0-0.6s0-1,0-1.7
						c0-1.5,0.2-3.6,0.3-6.3c0.4-5.3,1.1-12.6,2.3-20.7c1.2-8,2.5-15.3,3.6-20.5c0.5-2.6,0.9-4.7,1.2-6.2c0.1-0.7,0.3-1.2,0.4-1.7
						C372.2,213.1,372.3,212.9,372.3,212.9L372.3,212.9z"/>
					<path d="M389.8,253.1c0,0,0,0.1-0.1,0.4c-0.1,0.3-0.1,0.7-0.3,1.2c-0.2,1.1-0.5,2.6-0.9,4.5c-0.8,3.8-1.8,9-2.9,14.8
						c0,0.1,0,0.2-0.1,0.3l-0.2-0.2c0,0,0.1,0,0.2,0.1c0,0,0,0,0,0.1v0.1v0.1v0.1v0.3v0.5c0,0.4,0,0.7,0.1,1.1c0,0.7,0.1,1.4,0.1,2.1
						c0,1.4,0.1,2.7,0.1,3.9c0,2.5,0.1,4.8,0.1,6.7c0,1.9,0,3.4,0,4.5c0,0.5,0,0.9,0,1.2c0,0.3,0,0.4,0,0.4s0-0.1,0-0.4s0-0.7-0.1-1.2
						c0-1.1-0.1-2.6-0.2-4.5c-0.1-3.8-0.3-9-0.5-14.8l-0.2-0.1c0-0.1,0-0.2,0.1-0.3c1.1-5.8,2.1-11,3-14.8c0.4-1.9,0.8-3.4,1.1-4.4
						c0.1-0.5,0.3-0.9,0.3-1.2C389.8,253.3,389.8,253.1,389.8,253.1L389.8,253.1z"/>
				</g>
			</g>
			<path class="st16" d="M239.2,136.1c0.1,0.6,0,1.6-0.1,2.4c-0.1,0.7-0.3,1.3-0.6,1.9c-0.6,1.1-1.1,2-1.5,2.5
				c0,0-20.7,11.2-24.6,12.4c-3.8,1.2-21.3-6.4-21.3-6.4c-1.6,4.3-3.7,8.5-6.5,12.4c0,0,15.6,5.8,27.7,6.8
				c7.7,0.6,31.1-18.1,31.2-18.1c2.6-1,5.5-2.2,7-3.1c3.1-1.8,3-2,2.7-2.4c-0.7-0.9-5.6,2.9-5,1.5s6.6-3.9,5.8-4.8
				c-1-1.2-6.1,2.9-6.6,3.2c-0.4,0.3-0.9-0.3-0.5-0.6c0.6-0.4,7-4.4,6-5.6c-0.5-0.6-1.6,0.5-1.6,0.5s-5.6,4.2-6.1,3.7
				c-0.5-0.6,2.8-3.2,3.9-4c1.1-0.8,1.2-1.7,0.5-1.9c-0.5-0.1-4.1,2.2-5.2,3c-1.1,0.8-4.2,3.1-3.6,0.8c0.6-2.4,0.1-4.4-0.4-4.8
				C239.9,134.8,239,135.4,239.2,136.1L239.2,136.1z"/>
			<g>
				<g>
					<polygon class="cp" points="332.9,200.8 198.5,166.1 214.7,104.5 349.1,139.4 			"/>
					<g>
						<path class="st5" d="M329.5,162.7c0,0-0.1,0-0.3-0.1c-0.2-0.1-0.5-0.1-0.8-0.2c-0.8-0.2-1.8-0.5-3.2-0.8
							c-2.8-0.8-6.8-1.8-11.7-3.2c-9.9-2.7-23.5-6.4-38.6-10.5s-28.7-7.7-38.6-10.3c-4.9-1.3-8.9-2.3-11.7-3.1
							c-1.4-0.4-2.4-0.6-3.2-0.8c-0.4-0.1-0.6-0.2-0.8-0.2c-0.2-0.1-0.3-0.1-0.3-0.1s0.1,0,0.3,0.1s0.5,0.1,0.8,0.2
							c0.8,0.2,1.8,0.4,3.2,0.8c2.8,0.7,6.8,1.7,11.8,2.9c9.9,2.5,23.6,6.1,38.6,10.2s28.7,7.8,38.5,10.6c4.9,1.4,8.9,2.5,11.7,3.3
							c1.4,0.4,2.4,0.7,3.2,0.9c0.3,0.1,0.6,0.2,0.8,0.2C329.4,162.7,329.5,162.7,329.5,162.7L329.5,162.7z"/>
						<path class="st5" d="M325.4,174.1c0,0-0.1,0-0.3-0.1c-0.2-0.1-0.5-0.1-0.8-0.2c-0.8-0.2-1.8-0.5-3.2-0.8
							c-2.8-0.8-6.8-1.8-11.7-3.2c-9.9-2.7-23.5-6.4-38.6-10.5s-28.7-7.7-38.6-10.3c-4.9-1.3-8.9-2.3-11.7-3.1
							c-1.4-0.4-2.4-0.6-3.2-0.8c-0.4-0.1-0.6-0.2-0.8-0.2c-0.2-0.1-0.3-0.1-0.3-0.1s0.1,0,0.3,0.1s0.5,0.1,0.8,0.2
							c0.8,0.2,1.8,0.4,3.2,0.8c2.8,0.7,6.8,1.7,11.8,2.9c9.9,2.5,23.6,6.1,38.6,10.2c15.1,4.1,28.7,7.8,38.5,10.6
							c4.9,1.4,8.9,2.5,11.7,3.3c1.4,0.4,2.4,0.7,3.2,0.9c0.3,0.1,0.6,0.2,0.8,0.2C325.3,174,325.4,174.1,325.4,174.1L325.4,174.1z"/>
						<path class="st5" d="M255,167.2c0,0-0.1,0-0.4-0.1c-0.3-0.1-0.7-0.2-1.2-0.3c-1.1-0.3-2.6-0.6-4.4-1.1c-3.7-1-8.8-2.3-14.4-3.8
							c-5.6-1.5-10.7-2.9-14.4-3.9c-1.8-0.5-3.3-0.9-4.4-1.2c-0.5-0.1-0.9-0.3-1.2-0.4c-0.3-0.1-0.4-0.1-0.4-0.1s0.1,0,0.4,0.1
							c0.3,0.1,0.7,0.2,1.2,0.3c1.1,0.3,2.6,0.6,4.4,1.1c3.7,1,8.8,2.3,14.5,3.8c5.6,1.5,10.7,2.9,14.4,3.9c1.8,0.5,3.3,0.9,4.4,1.2
							c0.5,0.1,0.9,0.3,1.2,0.4C254.9,167.1,255,167.1,255,167.2z"/>
						<path class="st5" d="M279.4,138.3c0,0-0.2,0-0.6-0.1s-1-0.2-1.7-0.4c-1.5-0.4-3.6-0.9-6.2-1.6c-5.3-1.4-12.5-3.2-20.5-5.3
							s-15.2-4-20.5-5.4c-2.6-0.7-4.7-1.3-6.2-1.7c-0.7-0.2-1.3-0.4-1.7-0.5s-0.6-0.2-0.6-0.2s0.2,0,0.6,0.1c0.4,0.1,1,0.2,1.7,0.4
							c1.5,0.4,3.6,0.9,6.2,1.6c5.3,1.4,12.5,3.2,20.5,5.3s15.2,4,20.5,5.4c2.6,0.7,4.7,1.3,6.2,1.7c0.7,0.2,1.3,0.4,1.7,0.5
							S279.4,138.3,279.4,138.3L279.4,138.3z"/>
					</g>
				</g>
				<g>
					<g>
						<g>
							<g>
								<path class="cp" d="M125.4,317.3l4.9-4.5l-8.4-9.2l-7.2,6.5l0.5,0.6c2.2,2.7,11.3,13.5,13.4,14.7
									C130.8,326.8,125.4,317.3,125.4,317.3L125.4,317.3z"/>
								<g class="st11">
									<path class="st4" d="M127.9,322.1c0,0,1.7,3.5,0.9,3.5s-10.4-10.5-14.2-15.5l0.2-0.2l11.1,12.4
										C125.9,322.4,127.3,321.6,127.9,322.1L127.9,322.1z"/>
								</g>
								<path class="st5" d="M114.7,309.8c0,0,0,0.1,0.1,0.2s0.2,0.3,0.4,0.5c0.4,0.5,0.9,1.1,1.5,1.7c1.2,1.5,3,3.5,5,5.6
									s3.8,4.1,5.1,5.5c0.6,0.6,1.2,1.2,1.6,1.6c0.2,0.2,0.3,0.3,0.4,0.4c0.1,0.1,0.2,0.1,0.2,0.1s0-0.1-0.1-0.2s-0.3-0.3-0.4-0.5
									c-0.4-0.5-0.9-1-1.6-1.6c-1.3-1.4-3.1-3.3-5.1-5.5s-3.7-4.1-5-5.6c-0.6-0.7-1.1-1.2-1.5-1.7c-0.1-0.2-0.3-0.3-0.4-0.5
									C114.7,309.8,114.7,309.8,114.7,309.8L114.7,309.8z"/>
								<path class="st5" d="M125.8,322.5c0,0,0.4-0.3,1.1-0.4c0.6-0.1,1.1,0,1.2,0c0,0-0.5-0.3-1.2-0.1
									C126.2,322.1,125.8,322.5,125.8,322.5L125.8,322.5z"/>
								<path class="st5" d="M124.7,318.5c0,0,0.3,0,0.6,0s0.6-0.1,0.6-0.2c0,0-0.3,0-0.6,0S124.7,318.5,124.7,318.5z"/>
								<path class="st5" d="M124.4,317.4c0,0,0.2,0.1,0.5,0.1s0.5,0,0.5,0s-0.2-0.1-0.5-0.1C124.6,317.3,124.4,317.3,124.4,317.4z"/>
								<path class="st5" d="M124.8,316.1c0,0,0.1,0.3,0.4,0.5s0.5,0.5,0.5,0.4c0,0-0.1-0.3-0.4-0.5
									C125.1,316.3,124.9,316.1,124.8,316.1z"/>
								<path class="st5" d="M125.3,315.4c0,0,0.1,0.4,0.3,0.7c0.3,0.3,0.6,0.5,0.6,0.5s-0.2-0.3-0.5-0.6
									C125.5,315.7,125.3,315.4,125.3,315.4z"/>
								<path class="st5" d="M125.8,318.1c0,0,0.2,0.3,0.6,0.6c0.2,0.2,0.4,0.3,0.7,0.5c0.1,0.1,0.3,0.2,0.5,0.2c0.1,0,0.2,0,0.3,0
									c0.1,0,0.2-0.2,0.2-0.3c-0.2-0.8-1.1-1.1-1.6-1.1c-0.3,0-0.4,0.1-0.6,0.2c-0.1,0.1-0.2,0.1-0.2,0.1s0.3-0.2,0.8-0.2
									c0.2,0,0.5,0,0.8,0.2c0.2,0.1,0.5,0.4,0.6,0.8c0,0.2-0.1,0.2-0.3,0.2c-0.1,0-0.3-0.1-0.4-0.2c-0.3-0.1-0.5-0.3-0.7-0.5
									C126,318.3,125.8,318.1,125.8,318.1L125.8,318.1z"/>
								<path class="st5" d="M125.9,318.3c0,0,0.2,0,0.5-0.3c0.1-0.1,0.2-0.3,0.3-0.6c0-0.2,0.1-0.5-0.1-0.8c-0.1-0.1-0.4,0-0.4,0.1
									c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.2-0.1,0.4-0.1,0.6C125.8,318,125.8,318.2,125.9,318.3c0-0.1,0-0.3,0-0.6
									c0-0.2,0.1-0.4,0.1-0.6c0.1-0.2,0.2-0.5,0.4-0.3c0.1,0.1,0.1,0.4,0.1,0.6s-0.1,0.4-0.2,0.5
									C126.1,318.2,125.9,318.2,125.9,318.3L125.9,318.3z"/>
								<path class="st5" d="M117.4,307.7c0,0,0.3,0.2,0.6,0.7c0.3,0.5,0.7,1.2,0.7,2c0.1,0.9-0.2,1.6-0.5,2.1s-0.5,0.8-0.5,0.8
									s0.1,0,0.2-0.2c0.1-0.1,0.3-0.3,0.4-0.6c0.3-0.5,0.6-1.3,0.5-2.2s-0.4-1.6-0.8-2.1C117.8,308,117.6,307.8,117.4,307.7
									C117.5,307.7,117.4,307.7,117.4,307.7L117.4,307.7z"/>
								<path class="st5" d="M122.1,305.1c0,0-0.9,0.7-2,1.6s-1.9,1.7-1.9,1.8s0.9-0.7,2-1.6S122.1,305.2,122.1,305.1z"/>
								<path class="st5" d="M119.7,313.8c0,0,0.4,0.7,1.1,1.5s1.3,1.3,1.3,1.2s-0.6-0.6-1.2-1.4S119.7,313.7,119.7,313.8z"/>
								<path class="st5" d="M118,311.9c0,0-0.1,0.2-0.2,0.4c-0.1,0.2-0.2,0.3-0.1,0.4s0.2-0.1,0.3-0.3
									C118.1,312.1,118.1,311.9,118,311.9z"/>
								<path class="st5" d="M118.2,310.5c0,0-0.1,0.1-0.1,0.3c0,0.2,0,0.3,0.1,0.3c0,0,0.1-0.1,0.1-0.3
									C118.3,310.6,118.3,310.5,118.2,310.5z"/>
								<path class="st5" d="M117.8,309.2c0,0,0.1,0.2,0.2,0.3c0.1,0.2,0.1,0.3,0.1,0.4c0,0,0.1-0.2,0-0.4S117.8,309.1,117.8,309.2z"
									/>
								<path class="st5" d="M117.3,308.3c0,0,0,0.1,0,0.2s0.1,0.2,0.1,0.2s0-0.1,0-0.2S117.3,308.3,117.3,308.3z"/>
							</g>
							<g class="st13">
								<polygon points="117.6,307.5 126.2,316.6 127.3,315.5 118.8,306.3 						"/>
							</g>
						</g>
						<g>
							<g>
								<path class="cp" d="M220.7,324.7l-2.7-6.1l-11.4,5l3.9,9l0.7-0.3c3.2-1.2,16.4-6.4,18.2-7.9
									C231.4,322.7,220.7,324.7,220.7,324.7L220.7,324.7z"/>
								<g class="st11">
									<path class="st4" d="M226,323.9c0,0,3.8-0.5,3.7,0.2s-13.2,6.5-19.2,8.5l-0.1-0.3l15.3-6.5
										C225.6,325.8,225.4,324.2,226,323.9L226,323.9z"/>
								</g>
								<path class="st5" d="M210.1,332.4c0,0,0.1,0,0.2-0.1c0.2-0.1,0.4-0.1,0.6-0.2c0.6-0.2,1.3-0.5,2.1-0.8
									c1.8-0.7,4.2-1.7,6.9-2.9c2.7-1.2,5.1-2.3,6.8-3.1c0.8-0.4,1.5-0.7,2-1c0.2-0.1,0.4-0.2,0.6-0.3c0.1-0.1,0.2-0.1,0.2-0.1
									s-0.1,0-0.2,0.1c-0.2,0.1-0.4,0.2-0.6,0.3c-0.6,0.3-1.2,0.6-2.1,1c-1.7,0.8-4.2,1.9-6.8,3.1c-2.7,1.2-5.1,2.2-6.9,2.9
									c-0.8,0.3-1.5,0.6-2.1,0.9C210.6,332.3,210.4,332.4,210.1,332.4C210.2,332.4,210.2,332.4,210.1,332.4L210.1,332.4z"/>
								<path class="st5" d="M225.7,326c0,0-0.1-0.5-0.1-1.2c0.1-0.6,0.4-1.1,0.4-1.1s-0.4,0.4-0.5,1.1
									C225.4,325.5,225.7,326,225.7,326z"/>
								<path class="st5" d="M221.6,325.8c0,0,0.1-0.3,0.2-0.6s0.1-0.6,0-0.6c0,0-0.1,0.3-0.2,0.6S221.6,325.7,221.6,325.8z"/>
								<path class="st5" d="M220.4,325.7c0,0,0.2-0.2,0.3-0.5s0.2-0.5,0.1-0.5c0,0-0.2,0.2-0.3,0.5
									C220.5,325.4,220.4,325.6,220.4,325.7z"/>
								<path class="st5" d="M219.4,324.8c0,0,0.3,0,0.6-0.2c0.3-0.1,0.6-0.3,0.6-0.3s-0.3,0-0.6,0.2
									C219.6,324.7,219.4,324.8,219.4,324.8z"/>
								<path class="st5" d="M218.8,324.2c0,0,0.4,0,0.8-0.1s0.7-0.4,0.6-0.4c0,0-0.3,0.1-0.7,0.2
									C219.2,324.1,218.8,324.1,218.8,324.2L218.8,324.2z"/>
								<path class="st5" d="M221.5,324.6c0,0,0.3-0.1,0.7-0.3c0.2-0.1,0.5-0.3,0.7-0.5c0.1-0.1,0.2-0.2,0.4-0.4
									c0.1-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.2-0.2-0.2c-0.9-0.1-1.4,0.6-1.5,1.1c-0.1,0.2-0.1,0.5,0,0.6c0,0.1,0.1,0.2,0.1,0.2
									s-0.1-0.3,0.1-0.8c0.1-0.2,0.2-0.5,0.4-0.7s0.6-0.4,0.9-0.3c0.1,0,0.2,0.2,0.1,0.3c-0.1,0.1-0.2,0.2-0.3,0.4
									c-0.2,0.2-0.5,0.4-0.7,0.5C221.8,324.5,221.5,324.6,221.5,324.6L221.5,324.6z"/>
								<path class="st5" d="M221.7,324.5c0,0,0-0.2-0.1-0.5c-0.1-0.1-0.2-0.3-0.4-0.5c-0.2-0.1-0.5-0.3-0.8-0.1
									c-0.2,0.1-0.2,0.3-0.1,0.4c0.1,0.1,0.2,0.2,0.3,0.3c0.2,0.1,0.4,0.2,0.5,0.3C221.4,324.5,221.7,324.6,221.7,324.5
									c-0.1,0-0.3-0.1-0.6-0.2c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.4-0.2-0.5s0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.4
									C221.7,324.3,221.7,324.5,221.7,324.5L221.7,324.5z"/>
								<path class="st5" d="M209,329.2c0,0,0.3-0.2,0.9-0.3c0.5-0.2,1.3-0.3,2.2,0s1.5,0.7,1.9,1.1s0.6,0.7,0.6,0.7s0-0.1-0.1-0.2
									s-0.2-0.3-0.4-0.6c-0.4-0.4-1-1-1.9-1.2s-1.7-0.1-2.2,0.1c-0.3,0.1-0.5,0.2-0.6,0.3C209.1,329.1,209,329.2,209,329.2
									L209,329.2z"/>
								<path class="st5" d="M208.1,323.9c0,0,0.4,1.1,0.9,2.4c0.6,1.3,1,2.4,1.1,2.3c0,0-0.4-1.1-0.9-2.4
									C208.6,324.9,208.2,323.9,208.1,323.9z"/>
								<path class="st5" d="M215.5,329c0,0,0.8-0.2,1.7-0.6s1.6-0.8,1.6-0.9s-0.8,0.3-1.7,0.7C216.3,328.6,215.5,328.9,215.5,329
									L215.5,329z"/>
								<path class="st5" d="M213.2,329.9c0,0,0.1,0.1,0.3,0.3c0.1,0.1,0.3,0.3,0.3,0.2c0,0,0-0.2-0.2-0.4
									C213.4,329.9,213.3,329.9,213.2,329.9z"/>
								<path class="st5" d="M211.9,329.3c0,0,0.1,0.1,0.3,0.2s0.3,0.1,0.3,0c0,0-0.1-0.1-0.3-0.2C212.1,329.2,212,329.2,211.9,329.3z
									"/>
								<path class="st5" d="M210.5,329.3c0,0,0.2,0,0.4,0s0.4,0,0.4,0s-0.1-0.2-0.4-0.1C210.6,329.1,210.5,329.3,210.5,329.3
									L210.5,329.3z"/>
								<path class="st5" d="M209.6,329.5c0,0,0.1,0.1,0.2,0.1s0.2,0,0.2-0.1c0,0-0.1-0.1-0.2-0.1S209.6,329.4,209.6,329.5z"/>
							</g>
							<g class="st13">
								<polygon points="208.9,328.9 220.2,323.7 219.6,322.3 208.2,327.4 						"/>
							</g>
						</g>
						<path d="M152.8,119.1c0.3-2.2-1.1-5.5-0.5-7.6c0.2-0.6-0.5-1.2-0.6-1.9s0.1-1.4,0.6-1.8s1.5-0.2,1.7,0.4c0.2-1,0.4-2.1,0.9-3
							s1.4-1.7,2.4-1.8c1-0.1,2.2,0.6,2.2,1.7c0.6-1.1,1.5-2,2.6-2.6c0.9-0.5,2.1-0.8,3.1-0.4s1.5,1.8,0.8,2.6
							c0.6-0.6,1.3-1.2,2.1-1.5s1.8-0.3,2.5,0.3c0.7,0.6,0.8,1.8,0.1,2.3c0.9-0.4,1.8-0.8,2.7-0.9s2,0.1,2.7,0.7s1,1.8,0.5,2.6
							c-0.4,0.6-1.1,0.9-1.8,1.2s-1.3,0.8-1.4,1.5c-0.1,0.4,0.1,0.8,0,1.1c-0.1,0.5-0.6,0.8-1,1s-1,0.1-1.5,0.1
							c-2.5-0.2-5.1-0.6-7.6-0.6s-5.2,0.5-7.3,1.9"/>
						<g>
							<g>
								<path d="M150.4,213.7c0,0,5.4,28.4,4.4,43.9s-11.7,21.8-18.1,28.2c-6.1,6.1-20.2,18.2-20.2,18.2l12.6,13.6
									c0,0,39.8-28.7,43.9-47.2c3.1-14.2,4.3-39.4,4.3-39.4L150.4,213.7L150.4,213.7z"/>
								<path d="M151,192.4c-0.4,0-4.7,12.8-0.6,21.2c0.4,0.9,3.7,5.5,3.7,5.5c11.5,13.6,26.6,42.4,28.1,46.7
									c4.5,12.5,22.5,63.2,22.5,63.2l18.8-8l-19.2-62.7l-25-55.6L151,192.4L151,192.4z"/>
								<path class="st12" d="M174.6,202.8c0,0,0.3,0.7,0.2,1.8c0,1.1-0.4,2.7-1.6,4.1c-1.1,1.4-2.6,2.1-3.7,2.3
									c-1.1,0.3-1.8,0.2-1.8,0.1c0-0.1,0.7-0.1,1.7-0.4c1-0.3,2.4-1,3.5-2.3s1.5-2.8,1.6-3.9C174.7,203.5,174.5,202.8,174.6,202.8
									L174.6,202.8z"/>
								<polygon class="st12" points="204.7,329.2 223.6,321.2 222.6,318.1 203.8,326.6 						"/>
							</g>
							<g>
								<g>
									<path class="st16" d="M160,147.7L160,147.7c3.7,0.3,6.9-2.6,7.1-6.3c0.1-3.6,0.3-7.2,0.3-7.2s5.4-0.5,5.8-5.9
										c0.4-5.4-0.2-17.1-0.2-17.1l0.5-0.7c-6.1-3.1-12.8-2.1-18.4,1.8l-1.2,2.2l0.1,26.7C154,144.6,156.6,147.5,160,147.7
										L160,147.7z"/>
									<g>
										<path d="M170.7,119.8c-0.3-0.3-0.3-0.8,0-1.1s0.8-0.3,1.1,0s0.3,0.8,0,1.1S171,120.1,170.7,119.8z"/>
										<path d="M172.2,116.7c-0.1,0.1-0.6-0.3-1.3-0.4s-1.3,0.3-1.4,0.2c0,0,0.1-0.2,0.3-0.4s0.6-0.3,1.1-0.3s0.8,0.2,1.1,0.4
											C172.2,116.5,172.2,116.7,172.2,116.7z"/>
									</g>
									<g>
										<path d="M164.8,119.4c0.4,0.2,0.4,0.7,0.2,1.1s-0.7,0.4-1.1,0.2c-0.4-0.2-0.4-0.7-0.2-1.1S164.4,119.2,164.8,119.4z"/>
										<path d="M165.2,117c-0.1,0.1-0.6-0.3-1.3-0.4s-1.3,0.3-1.4,0.2c0,0,0.1-0.2,0.3-0.4s0.6-0.3,1.1-0.3s0.8,0.2,1.1,0.4
											C165.2,116.8,165.3,117,165.2,117z"/>
									</g>
									<path d="M167.5,125c0-0.1,0.5-0.1,1.2-0.2c0.2,0,0.4,0,0.4-0.2c0-0.1,0-0.3-0.1-0.6c-0.2-0.5-0.3-1-0.5-1.5
										c-0.7-2.2-1.1-4-1-4s0.8,1.7,1.4,3.9c0.2,0.5,0.3,1.1,0.5,1.6c0.1,0.2,0.2,0.5,0.1,0.8c-0.1,0.2-0.2,0.3-0.4,0.3
										s-0.2,0-0.3,0C168,125.1,167.5,125.1,167.5,125L167.5,125z"/>
									<path class="st4" d="M165,126.5c0.4,0.2,2.1,1.3,3.6,1.3c0,0-0.9,1.3-2.5,0.7C164.5,127.7,165,126.5,165,126.5z"/>
									<path class="st17" d="M167.4,134.1c0,0-3.4,0.1-6.9-2.4c0,0,1.4,4,6.8,3.8L167.4,134.1L167.4,134.1z"/>
								</g>
								<g>
									<path class="st16" d="M154,118.9c0-0.4-0.3-0.8-0.8-0.8c-1,0-2.6,0.4-2.8,3.1c-0.2,4,3.6,3,3.6,2.9S154,120.5,154,118.9
										L154,118.9z"/>
									<path class="st17" d="M153.1,122.6c0,0-0.1,0-0.2,0.1c-0.1,0.1-0.3,0.1-0.5,0c-0.4-0.2-0.7-0.9-0.7-1.6c0-0.4,0.1-0.7,0.2-1
										c0.1-0.3,0.3-0.5,0.6-0.5c0.2,0,0.4,0.1,0.4,0.2c0.1,0.1,0,0.2,0,0.2s0.1-0.1,0.1-0.2s-0.1-0.2-0.1-0.3s-0.2-0.1-0.4-0.1
										c-0.3,0-0.6,0.3-0.8,0.6c-0.2,0.3-0.3,0.7-0.3,1.1c0,0.8,0.4,1.6,0.9,1.8c0.3,0.1,0.5,0,0.6-0.1S153.1,122.5,153.1,122.6
										L153.1,122.6z"/>
								</g>
							</g>
							<g>
								<path class="st12" d="M143.3,165.1c0.7,5.3,2.4,9.9,2.5,18.5l-0.4,20l37.8,2.1l-2.1-24.5l-1.4-20.8l8.4,2.2l3.5-14.1
									c0,0-8.9-4.3-12-5.7c-10-4.6-10-2.7-11.7-2.9c-4.6,1.4-9.2,1.4-13.8,0l0,0c0,0-7.4-2.1-11.2,7
									C141,151.6,143.3,165.1,143.3,165.1L143.3,165.1z"/>
							</g>
						</g>
						<path d="M153.4,119.8c0.3,0.6,1.1,0.8,1.7,0.5s1.3-0.8,1.4-1.4c0.1-0.7,0.1-1.5,0.2-2.2c0.1-1.1,0.2-1.6,0.4-2.2
							c2.4,0.5,4-0.2,5.6-2c1.6,1.6,4.2,2.1,6.3,1.2c0.5-0.2,1-0.5,1.6-0.5c0.9,0,1.6,0.8,2.5,0.8s1.6-0.9,1.6-1.8s-0.5-1.7-1.3-2.2
							c-0.7-0.5-1.6-0.8-2.5-0.9c-3.1-0.6-6.3-0.5-9.4-0.1c-1.5,0.2-2.9,0.4-4.3,1c-1.4,0.6-2.6,1.5-3.4,2.8c-1.1,2-0.9,4.4-0.6,6.7"
							/>
						<path class="st12" d="M182.2,265.9c0,0-0.1-0.2-0.3-0.5c-0.2-0.4-0.5-0.9-0.8-1.6c-0.7-1.4-1.7-3.3-3-5.7
							c-2.6-4.8-6.4-11.3-10.9-18.4c-4.4-7-8.6-13.3-11.6-17.9c-1.5-2.3-2.7-4.1-3.5-5.4c-0.4-0.6-0.7-1.1-0.9-1.5
							c-0.2-0.3-0.3-0.5-0.3-0.5s0.1,0.2,0.4,0.5c0.2,0.4,0.6,0.8,1,1.4c0.9,1.3,2.1,3.1,3.6,5.4c3.1,4.5,7.3,10.8,11.7,17.8
							c4.5,7,8.2,13.6,10.8,18.4c1.3,2.4,2.2,4.4,2.9,5.8c0.3,0.7,0.6,1.2,0.7,1.6C182.1,265.7,182.2,265.9,182.2,265.9L182.2,265.9z"
							/>
						<polygon class="st12" points="116.5,304 129.8,318.1 132.9,316.1 118.7,302.1 				"/>
					</g>
					<path class="st5" d="M164.2,158c0,0-0.6,0.8-1.6,1.9s-2.6,2.6-4.3,4.2c-1.7,1.6-3.4,2.9-4.6,3.9s-2,1.5-2.1,1.4
						c0-0.1,0.7-0.7,1.9-1.7s2.8-2.4,4.5-4s3.3-3.1,4.4-4.1C163.4,158.6,164.1,157.9,164.2,158L164.2,158z"/>
					<path d="M151.6,139.8c0,0,0.3,0.1,0.8,0.4c0.5,0.3,1.1,0.9,1.7,1.7c1.2,1.6,2.7,3.9,4.4,6.5c1.6,2.6,3,4.9,4.1,6.7
						c0.5,0.8,0.9,1.5,1.2,2s0.4,0.8,0.4,0.8s-0.2-0.2-0.6-0.7s-0.8-1.1-1.4-2c-1.1-1.7-2.6-4-4.2-6.6c-1.6-2.6-3.1-4.9-4.2-6.6
						C152.7,140.5,151.6,139.9,151.6,139.8L151.6,139.8z"/>
					<path d="M143.7,152.7c0.1,0,0.2,1,0.6,2.6c0.4,1.6,1,3.8,2,6.2c1,2.3,2.1,4.3,3,5.8s1.5,2.2,1.4,2.3c0,0-0.2-0.2-0.5-0.5
						s-0.7-0.9-1.2-1.6c-0.9-1.4-2.1-3.4-3.1-5.8c-1-2.4-1.6-4.6-1.9-6.3c-0.1-0.8-0.2-1.5-0.3-2S143.7,152.7,143.7,152.7z"/>
				</g>
			</g>
			<path d="M425.5,332.7c0,0.1-85.3,0.2-190.5,0.2s-190.5-0.1-190.5-0.2s85.3-0.2,190.5-0.2S425.5,332.6,425.5,332.7z"/>
			<g>
				<g>
					<path class="st6" d="M334.2,202.7l-140.8-34.5c-5-1.2-8-6.2-6.7-11.1l19.7-80.4c1.2-5,6.2-8,11.1-6.7l140.8,34.5
						c5,1.2,8,6.2,6.7,11.1L345.3,196C344.1,200.9,339.1,203.9,334.2,202.7L334.2,202.7z"/>
					<g>
						<circle class="cp" cx="275.5" cy="137.6" r="12.5"/>
						<path class="st4" d="M271.7,140.4l1.7-7.1c0.1-0.4,0.5-0.5,0.8-0.2l5.5,5.2c0.2,0.3,0.1,0.7-0.2,0.8l-7.3,2
							C271.9,141.1,271.6,140.8,271.7,140.4z"/>
					</g>
					<g>
						<path class="st4" d="M196.2,156.9l-0.1,0.3l-1.2-0.3v-0.2l0.8-0.4c0.2-0.1,0.3-0.2,0.3-0.2c0-0.2,0-0.2-0.2-0.3
							c-0.2,0-0.3,0-0.4,0.1l-0.1-0.2c0.1-0.2,0.5-0.2,0.7-0.1c0.4,0.1,0.5,0.3,0.5,0.6c0,0.2-0.1,0.3-0.4,0.4l-0.5,0.3L196.2,156.9
							L196.2,156.9z"/>
						<path class="st4" d="M196.6,156.2c0-0.1,0.1-0.2,0.2-0.1s0.2,0.1,0.1,0.2c0,0.1-0.1,0.2-0.2,0.1
							C196.5,156.4,196.6,156.3,196.6,156.2z M196.4,157c0-0.1,0.1-0.2,0.2-0.1s0.2,0.1,0.1,0.2c0,0.1-0.1,0.2-0.2,0.1
							C196.3,157.2,196.3,157.1,196.4,157z"/>
						<path class="st4" d="M197.8,155.8l-0.4,1.7l-0.3-0.1l0.3-1.4H197l0.1-0.3C197.2,155.7,197.8,155.8,197.8,155.8z"/>
						<path class="st4" d="M199.2,157.5l-0.3-0.1l-0.1,0.4l-0.3-0.1l0.1-0.4l-0.9-0.2v-0.2l1-0.9l0.3,0.1l-1,0.8l0.6,0.1l0.1-0.3
							l0.3,0.1l-0.1,0.3l0.3,0.1L199.2,157.5L199.2,157.5z"/>
					</g>
					
						<rect x="263.2" y="109.6" transform="matrix(0.2381 -0.9712 0.9712 0.2381 32.8248 387.6829)" class="st4" width="0.6" height="126.6"/>
					
						<rect x="220.5" y="142.5" transform="matrix(0.2381 -0.9712 0.9712 0.2381 10.8999 338.572)" class="cp" width="1.5" height="39.6"/>
					<path class="cp" d="M241.6,167.5c-0.3,1.1-1.3,1.6-2.3,1.4c-1.1-0.3-1.6-1.3-1.4-2.3c0.3-1.1,1.3-1.6,2.3-1.4
						C241.3,165.5,241.8,166.4,241.6,167.5z"/>
					<g>
						<path class="st4" d="M328.1,188.7c-0.1,0.3-0.4,0.4-0.7,0.3c-0.5-0.1-0.6-0.5-0.5-0.9c0.1-0.6,0.5-0.8,1-0.7
							c0.2,0,0.3,0.1,0.4,0.2l-0.1,0.2c-0.1-0.1-0.2-0.1-0.3-0.2c-0.3-0.1-0.5,0.1-0.6,0.5l0,0c0.1-0.1,0.3-0.1,0.4-0.1
							C327.9,188.2,328.2,188.4,328.1,188.7L328.1,188.7z M327.8,188.7c0-0.2,0-0.3-0.2-0.4c-0.2,0-0.3,0-0.4,0.2s0,0.3,0.2,0.4
							C327.6,188.9,327.8,188.8,327.8,188.7z"/>
						<path class="st4" d="M328.4,188.2c0-0.1,0.1-0.2,0.2-0.1c0.1,0,0.2,0.1,0.1,0.2c0,0.1-0.1,0.2-0.2,0.1
							C328.4,188.4,328.3,188.3,328.4,188.2z M328.1,189c0-0.1,0.1-0.2,0.2-0.1c0.1,0,0.2,0.1,0.1,0.2c0,0.1-0.1,0.2-0.2,0.1
							C328.2,189.3,328.1,189.1,328.1,189z"/>
						<path class="st4" d="M328.9,188.6c0.1-0.6,0.5-0.8,0.9-0.7s0.6,0.5,0.5,1s-0.5,0.8-0.9,0.7C329,189.4,328.7,189.1,328.9,188.6z
							M329.9,188.9c0.1-0.4-0.1-0.6-0.2-0.7c-0.2,0-0.4,0.1-0.5,0.5s0.1,0.6,0.2,0.7C329.6,189.4,329.8,189.3,329.9,188.9z"/>
						<path class="st4" d="M330.4,189c0.1-0.6,0.5-0.8,0.9-0.7s0.6,0.5,0.5,1s-0.5,0.8-0.9,0.7C330.5,189.8,330.3,189.5,330.4,189z
							M331.5,189.2c0.1-0.4-0.1-0.6-0.2-0.7s-0.4,0.1-0.5,0.5s0.1,0.6,0.2,0.7C331.2,189.7,331.4,189.6,331.5,189.2z"/>
					</g>
					<polygon class="st4" points="334.4,189.9 333.8,189.7 334.2,188.1 335.9,188.5 335.8,189.1 334.7,188.8 			"/>
					<polygon class="st4" points="335,192.2 333.3,191.8 333.7,190.2 334.3,190.3 334,191.4 335.1,191.7 			"/>
					<polygon class="st4" points="337.5,192.8 336,192.4 336.1,191.9 337.2,192.2 337.4,191.1 337.9,191.2 			"/>
					<polygon class="st4" points="338,190.7 337.5,190.6 337.8,189.6 336.7,189.3 336.9,188.7 338.4,189.1 			"/>
					<path class="st4" d="M335.9,109c0,0,0.3,0.1,0.9,0.1s1.5,0.3,2.5,0.5h0.2v0.2c-0.3,1.3-0.7,2.7-1,4.3l-0.1,0.5l-0.2-0.4
						c-0.1-0.1-0.2-0.4-0.4-0.6c-0.3-0.5-0.6-1-0.9-1.5l0.3,0.1c-0.7,0.4-1.5,0.8-2.1,1.1l-0.4,0.2l0.1-0.4c0.3-1.4,0.7-2.4,0.9-3.1
						C335.7,109.4,336,108.9,335.9,109c0,0-0.1,0.5-0.2,1.3c-0.2,0.8-0.3,1.8-0.6,3l-0.3-0.2c0.7-0.3,1.4-0.8,2.1-1.1l0.1-0.1l0.1,0.1
						c0.3,0.5,0.6,1,0.9,1.5c0.1,0.2,0.2,0.4,0.4,0.6h-0.3c0.4-1.6,0.7-3,1-4.3l0.1,0.2c-1-0.2-1.8-0.5-2.4-0.7
						C336.3,109.3,335.9,109,335.9,109z"/>
					<path class="st4" d="M348.1,112.5c0,0-0.4,0.3-1.1,0.9c-0.7,0.5-1.8,1.3-3.1,2.2l-0.4,0.3l0.1-0.5c0.2-0.7,0.3-1.4,0.5-2.1v-0.2
						l0.1,0.1c-0.4-0.6-0.7-1.2-1.1-1.8l-0.2-0.4l0.5,0.1c1.5,0.4,2.7,0.8,3.6,1C347.7,112.4,348.1,112.5,348.1,112.5
						s-0.6-0.1-1.5-0.3c-0.9-0.2-2.1-0.4-3.4-0.7l0.2-0.3c0.4,0.6,0.7,1.2,1.1,1.8l0.1,0.1l-0.1,0.3c-0.2,0.8-0.4,1.5-0.5,2.1
						l-0.3-0.2c1.2-0.8,2.2-1.4,3-1.9C347.5,112.9,348,112.5,348.1,112.5L348.1,112.5z"/>
					<path class="st4" d="M348.1,112.5c0,0.1-0.8,0.3-1.8,0.5s-1.9,0.2-1.9,0.1s0.8-0.3,1.8-0.5S348.1,112.4,348.1,112.5z"/>
					<path class="st4" d="M354.2,113.5c0,0,0.2,0,0.6,0.1c0.4,0.2,0.9,0.5,1.1,1.2c0.3,0.7,0.4,1.6-0.2,2.5c-0.5,0.8-1.7,1.4-2.8,1.1
						c-1.2-0.3-1.9-1.3-2-2.2c-0.1-0.9,0.5-1.8,1-2.3c0.6-0.5,1.2-0.5,1.6-0.5C354,113.3,354.2,113.5,354.2,113.5s-0.2,0-0.6,0.1
						c-0.4,0-1,0.2-1.4,0.6c-0.5,0.4-0.9,1.2-0.8,2.1c0.1,0.8,0.7,1.7,1.7,2c1,0.2,1.9-0.3,2.4-1s0.5-1.5,0.2-2.2
						c-0.3-0.6-0.7-1-1-1.2C354.4,113.5,354.2,113.5,354.2,113.5z"/>
					<path class="st4" d="M354.2,117.1c-0.1,0.1-0.5-0.4-1-1.2l-0.1-0.1v-0.1l0,0c0.2-0.8,0.5-1.4,0.6-1.4s0,0.7-0.2,1.5l0,0l-0.1-0.1
						C354,116.3,354.3,117,354.2,117.1z"/>
					<path class="st4" d="M295.9,102.6c0,0.1-16.4-3.8-36.5-8.7c-20.1-4.9-36.4-9-36.4-9.1s16.4,3.8,36.5,8.7
						C279.6,98.4,295.9,102.5,295.9,102.6z"/>
					<circle class="st4" cx="216.8" cy="83.2" r="3"/>
				</g>
			</g>
			<path class="st16" d="M212.6,165.6c-0.1,0.6-0.6,1.5-1,2.2c-0.3,0.6-0.8,1.1-1.3,1.5c-1,0.8-1.8,1.4-2.4,1.8c0,0-23.4,2.7-27.4,2.3
				c-4-0.3-17.4-13.9-17.4-13.9c-3.1,3.4-6.6,6.4-10.6,9.1c0,0,12.3,11.2,23.2,16.6c6.9,3.5,35.7-5.2,35.7-5.2c2.8,0,6,0,7.7-0.2
				c3.6-0.5,3.5-0.8,3.4-1.2c-0.3-1.1-6.3,0.6-5.2-0.5s7.5-1.2,7.1-2.3c-0.5-1.5-6.7,0.4-7.3,0.5c-0.5,0.1-0.7-0.7-0.2-0.7
				c0.7-0.1,8.1-1.5,7.6-3c-0.3-0.8-1.7-0.2-1.7-0.2s-6.8,1.8-7,1.1s3.8-1.9,5.1-2.2c1.4-0.3,1.8-1.1,1.1-1.6c-0.4-0.3-4.6,0.5-6,0.9
				c-1.3,0.3-5,1.3-3.6-0.6c1.4-2,1.8-4,1.5-4.6S212.7,164.8,212.6,165.6L212.6,165.6z"/>
			<g>
				<path class="st10" d="M346.4,191.6c-0.9-1.2-6.9-2.2-6.4-3.3c0.7-1.3,6.3,1.4,6.8,1.6c0.5,0.2,0.8-0.5,0.3-0.7
					c-0.6-0.2-7.4-2.6-6.8-3.9c0.4-0.7,1.6,0.1,1.6,0.1s6.2,2.7,6.5,2.1c0.3-0.6-3.3-2.4-4.5-2.9s-1.5-1.3-0.9-1.6
					c0.5-0.2,4.3,1.2,5.5,1.7s4.5,2,3.5-0.1v-0.1c-1.1-2-1.3-3.9-1-4.5s1.2-0.4,1.4,0.2c0,0.1,0.1,0.2,0.1,0.3s0,0.1,0,0.2
					c0.2,0.6,0.6,1.5,0.9,2.2c0.3,0.6,0.8,1.2,1.3,1.7c0.6,0.6,1.1,1,1.6,1.4c4.1,0.7,15.6,2.4,25.7-0.2c1.8-0.5,8.3-21,8.3-21l12.4,6
					c-0.5,2.5-2.7,8.5-6,18.8c-5.3,16.8-43.7,5-43.7,5l0,0h-0.3c-2.9-0.4-6.4-1-8.1-1.5c-3.3-1-3.2-1.3-3-1.6
					C341.9,190.3,347.3,192.8,346.4,191.6L346.4,191.6z"/>
				<g>
					<path class="cp" d="M394.3,150.8c0,0,8.5,1.5,8.8,15.4s-5.3,24.4-5.3,24.4l-14.9-7.8c3.4-6.8,5.9-13.8,7.5-21.1L394.3,150.8
						L394.3,150.8L394.3,150.8z"/>
					<path class="st14" d="M383.5,182.6c-0.1,0,0.2-1,0.7-2.5c0.5-1.6,1.2-3.7,1.9-6c0.7-2.3,1.3-4.4,1.8-6c0.5-1.5,0.8-2.5,0.8-2.5
						s-0.1,1-0.5,2.6s-1,3.7-1.7,6.1c-0.7,2.3-1.5,4.4-2,5.9S383.5,182.6,383.5,182.6L383.5,182.6z"/>
				</g>
			</g>
		</g>
	</svg>
</template>

<style scoped>
	.cp{fill:#C091FC;}
	.cs{fill:#3FE0AE;}
	.st0{fill:#FAFAFA;}
	.st1{fill:none;}
	.st3{fill-rule:evenodd;clip-rule:evenodd;}
	.st4{fill:#FFFFFF;}
	.st5{fill:#263238;}
	.st6{fill:#EBEBEB;stroke:#000000;stroke-width:0.5;stroke-miterlimit:10;}
	.st7{fill:#E0E0E0;}
	.st8{fill:#EBEBEB;}
	.st10{fill:#FFBE9D;}
	.st11{opacity:0.4;}
	.st12{fill:#2D2D2D;}
	.st13{opacity:0.3;}
	.st14{opacity:0.3;enable-background:new;}
	.st15{fill:#EB996E;}
	.st16{fill:#C78976;}
	.st17{fill:#B66955;}
</style>
