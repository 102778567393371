import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { dialog, safeJson } from '@/common/util';
import certificadosService from '@/services/certificados.service';
export const useCertificadosStore = defineStore('certificados', () => {
    const { t } = useI18n();
    const list = ref([]);
    const status = ref(0); // -1 erro de exceção; 0 - inicial; 1 - carregando; 2 - sucesso; 3 - erro não fatal
    const loading = computed(() => status.value === 1);
    const getById = async (id) => {
        status.value = 1;
        try {
            const { success, data } = await certificadosService.get(id);
            if (success)
                data.jsonCertificado = JSON.parse(data.jsonCertificado);
            status.value = 2;
            return { success, data };
        }
        catch (error) {
            status.value = -1;
        }
    };
    const getAll = async () => {
        status.value = 1;
        try {
            const { data, success } = await certificadosService.get();
            if (success) {
                list.value = (data || []).map((row) => ({
                    ...row,
                    jsonCertificado: safeJson(row.jsonCertificado) || {}
                }));
                status.value = 2;
                return;
            }
            status.value = 3;
        }
        catch (error) {
            //
            status.value = -1;
        }
    };
    const create = async (objData) => {
        status.value = 1;
        try {
            // O endpoint vai gerar isso aqui
            delete objData.id;
            delete objData.createdAt;
            const { data, success } = await certificadosService.create({
                ...objData,
                jsonCertificado: JSON.stringify(objData.jsonCertificado)
            }, 'multipart');
            if (success) {
                getAll();
                status.value = 2;
                return data;
            }
        }
        catch (error) {
            status.value = -1;
        }
        return false;
    };
    const update = async (objData) => {
        status.value = 1;
        const { nome, tipo, active, jsonCertificado } = objData;
        try {
            const { data, success } = await certificadosService.update(objData.id, {
                nome,
                tipo,
                active,
                jsonCertificado: JSON.stringify(jsonCertificado)
            }, 'multipart');
            if (success) {
                const value = list.value.find(el => el.id === data.id);
                value && Object.assign(value, {
                    ...data,
                    jsonCertificado: JSON.parse(data.jsonCertificado)
                });
                status.value = 2;
                return true;
            }
        }
        catch (error) {
            status.value = -1;
        }
        return false;
    };
    const remove = async (certificado, force) => {
        if (!force)
            return dialog.open({
                title: t('feedback.attention'),
                message: t('dialogs.delete.message', { type: t('labels.certificate').toLowerCase(), name: certificado.nome }),
                complete: () => remove(certificado, true)
            });
        try {
            status.value = 1;
            const { success } = await certificadosService.remove(certificado.id);
            if (success)
                list.value.splice(list.value.findIndex(el => el.id === certificado.id), 1);
            status.value = 2;
            return success;
        }
        catch (error) {
            status.value = -1;
        }
    };
    const upload = async (objectData) => {
        try {
            const { success, data } = await certificadosService.upload(objectData);
            if (success)
                getAll();
            return { success, data };
        }
        catch (error) {
            //
            return { success: false };
        }
    };
    const printCertificate = async (id, idCursoOuFormacao) => {
        status.value = 1;
        try {
            const { data } = await certificadosService.imprimir(id, idCursoOuFormacao);
            return data;
        }
        catch {
            //
        }
        finally {
            status.value = 2;
        }
    };
    const printUserCertificate = async (id, idCursoOuFormacao, userId) => {
        status.value = 1;
        try {
            const { data } = await certificadosService.imprimirCertificadoUsuario(id, idCursoOuFormacao, userId);
            return data;
        }
        catch {
            //
        }
        finally {
            status.value = 2;
        }
    };
    return {
        list,
        loading,
        //
        getById,
        getAll,
        create,
        update,
        remove,
        upload,
        printCertificate,
        printUserCertificate
    };
});
