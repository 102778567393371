import { defineStore } from 'pinia';
import { computed, reactive, ref } from 'vue';
import { toast, openAchievement, i18N } from '@/common/util';
import webStorage from '@/common/webStorage';
import crypto from '@/common/crypto';
import { useAuthStore, useContratosStore } from '@/stores';
import usuariosService from '@/services/usuarios.service';
import aliareService from '@/services/aliare/aliare.service';
import autenticacaoService from '@/services/autenticacao.service';
export const useUsuariosStore = defineStore('usuarios', () => {
    /**
     * States
     */
    const list = ref([]);
    const listProgress = ref([]);
    const pagedList = ref({
        list: [],
        totalItemsPage: 50,
        totalItems: 50,
        page: 1,
        totalPages: 1
    });
    const partnerList = ref([]);
    const status = reactive({
        create: 'initial',
        update: 'initial',
        getAll: 'initial',
        getById: 'initial',
        delete: 'initial',
        export: 'initial',
        email: 'initial',
        newPassword: 'initial',
        getProgress: 'initial',
        acceptTermOfUse: 'initial',
        fileImport: 'initial',
        nps: 'initial',
        partner: 'initial'
    });
    const nStatus = reactive({
        getUserAccounts: 'initial',
    });
    // const grupoPermissoesStore = useGrupoDePermissoes()
    const authStore = useAuthStore();
    /**
     * Getters
     */
    const loading = computed(() => Object.values(status).some(el => el === 'loading'));
    const pagedInfo = computed(() => {
        return {
            totalItemsPage: pagedList.value.totalItemsPage,
            totalItems: pagedList.value.totalItems,
            page: pagedList.value.page,
            totalPages: pagedList.value.totalPages
        };
    });
    const hasPartners = computed(() => partnerList.value.length > 1);
    /**
     * Actions
     */
    const create = async (dataObj) => {
        status.create = 'loading';
        try {
            const { data, success } = await usuariosService.create(dataObj);
            if (success) {
                getAll();
                useContratosStore().getUsageInfo();
                status.create = 'success';
            }
            else {
                status.create = 'error';
            }
            return data;
        }
        catch {
            status.create = 'error';
        }
    };
    const update = async (dataObj, id) => {
        status.update = 'loading';
        try {
            const { data, success } = await usuariosService.update(id, dataObj);
            if (success) {
                getAll();
                useContratosStore().getUsageInfo();
                status.update = 'success';
            }
            else {
                status.update = 'error';
            }
            if (success && data?.conquistas) {
                data.conquistas?.map(c => {
                    openAchievement({
                        title: 'Parabéns!',
                        subtitle: c.regra,
                        points: c.pontuacao || 0
                    });
                });
            }
            return data;
        }
        catch {
            status.update = 'error';
        }
    };
    const getAll = async (pagination) => {
        status.getAll = 'loading';
        try {
            const { getActiveTentant, partnerIdPlantar, hasElevatedPrivileges } = authStore;
            const result = await usuariosService.getUsersByTenant(getActiveTentant, hasElevatedPrivileges ? partnerIdPlantar : null, pagination);
            pagedList.value = {
                totalItems: result.totalItems,
                totalItemsPage: result.pageSize,
                list: result.data,
                page: result.pageNumber,
                totalPages: result.totalPages
            };
            status.getAll = 'success';
        }
        catch {
            status.getAll = 'error';
        }
    };
    const remove = async (id) => {
        status.delete = 'loading';
        try {
            const { success } = await usuariosService.remove(id);
            if (success) {
                getAll();
                useContratosStore().getUsageInfo();
                status.delete = 'success';
            }
            else {
                status.delete = 'error';
            }
            return success;
        }
        catch {
            status.delete = 'error';
        }
    };
    const getById = async (id) => {
        status.getById = 'loading';
        try {
            const { data } = await usuariosService.get(id);
            status.getById = 'success';
            return data;
        }
        catch {
            status.getById = 'error';
        }
    };
    const exportUsuarios = async () => {
        status.export = 'loading';
        try {
            const csvFile = await usuariosService.exportCsv();
            status.export = 'success';
            return csvFile;
        }
        catch {
            status.export = 'error';
        }
    };
    const sendEmailChangePassword = async (email) => {
        status.email = 'loading';
        try {
            const { success } = await autenticacaoService.forgotPassword(email);
            status.email = 'success';
            return { success };
        }
        catch (error) {
            status.email = 'error';
            const { success, notifications } = error.response?.data || {};
            return { success, notifications };
        }
    };
    const sendNewPassword = async (data, newPassword) => {
        status.newPassword = 'loading';
        try {
            const result = await aliareService.sendNewPassword(data);
            if (result)
                toast.open({
                    type: 'success',
                    title: newPassword ? i18N('toasts.users.accessValidated') : i18N('toasts.users.resetPassword'),
                    message: newPassword ? i18N('toasts.users.welcomeAliare') : i18N('toasts.users.updatedPassword')
                });
            status.newPassword = 'success';
            return result;
        }
        catch (e) {
            status.newPassword = 'error';
            return { success: false, data: e?.data };
        }
    };
    const confirmEmail = async (data) => {
        status.email = 'loading';
        try {
            const response = await aliareService.confirmEmail(data);
            status.email = 'success';
            return response;
        }
        catch {
            status.email = 'error';
        }
    };
    /**
     * Envia multiplas requisições de recuperação de senha
     * @param emailList
     */
    const massRecoverPassword = async (emailList) => {
        const requestList = [];
        const successList = [];
        const failList = [];
        emailList.forEach((email) => {
            requestList.push(autenticacaoService
                .forgotPassword(email)
                .then(() => successList.push(email))
                .catch(() => failList.push(email)));
        });
        await Promise.all(requestList);
        return { successList, failList };
    };
    const getProgress = async () => {
        status.getProgress = 'loading';
        try {
            const { data } = await usuariosService.getProgressUser();
            listProgress.value = data;
            status.getProgress = 'success';
        }
        catch {
            status.getProgress = 'error';
        }
    };
    const getSubscriptions = async (userId) => {
        try {
            const { data } = await usuariosService.getSubscriptionsUser(userId);
            return data;
        }
        catch {
            //
        }
    };
    const acceptTermOfUse = async (userId) => {
        status.acceptTermOfUse = 'loading';
        try {
            await usuariosService.acceptTermOfUse(userId);
            status.acceptTermOfUse = 'success';
        }
        catch {
            status.acceptTermOfUse = 'error';
        }
    };
    const validateFileImport = async (file) => {
        status.fileImport = 'loading';
        try {
            const { data } = await usuariosService.validateFileImport(file);
            status.fileImport = 'success';
            return data;
        }
        catch (error) {
            status.fileImport = 'error';
        }
    };
    const importUsers = async (fileId) => {
        status.fileImport = 'loading';
        try {
            const { data } = await usuariosService.importUsers(fileId);
            status.fileImport = 'success';
            return data;
        }
        catch (error) {
            status.fileImport = 'error';
        }
    };
    const getUserInfoNPS = async () => {
        status.nps = 'loading';
        try {
            const key = crypto.base64URLEncode('nps-user-info');
            const userInfo = webStorage.getItem(key);
            if (userInfo?.tenantId) {
                status.nps = 'success';
                return userInfo;
            }
            const { data } = await usuariosService.getUserInfoNPS();
            webStorage.setItem(key, data, true);
            status.nps = 'success';
            return data;
        }
        catch (error) {
            status.nps = 'error';
        }
    };
    const updateUserEmail = async (id, email) => {
        status.email = 'loading';
        try {
            const { success } = await usuariosService.updateUserEmail(id, email);
            if (success) {
                toast.success({ message: i18N('toasts.users.updatedEmail') });
                getAll();
            }
            status.email = 'success';
            return success;
        }
        catch {
            status.email = 'error';
        }
    };
    const clearUserInfoNps = () => webStorage.removeItem(crypto.base64URLEncode('nps-user-info'));
    /**
     * Retorna a lista de contas do usuário
     */
    const getUserAccounts = async () => {
        try {
            nStatus.getUserAccounts = 'loading';
            const { data } = await usuariosService.getUserAccounts();
            partnerList.value = data;
            nStatus.getUserAccounts = 'success';
            return data;
        }
        catch {
            nStatus.getUserAccounts = 'error';
        }
    };
    const setDefaultPartner = async (tenantId) => {
        try {
            status.partner = 'loading';
            const { success } = await usuariosService.setDefaultPartner(tenantId);
            if (success) {
                toast.success({ message: i18N('toasts.users.defaultAccount') });
                partnerList.value = partnerList.value.map((el) => ({ ...el, isPrincipal: el.tenantId === tenantId }));
                status.partner = 'success';
            }
            return success;
        }
        catch {
            status.partner = 'error';
        }
    };
    return {
        list,
        pagedList,
        listProgress,
        status,
        nStatus,
        partnerList,
        // Getters
        loading,
        pagedInfo,
        hasPartners,
        // Actions
        create,
        update,
        getAll,
        getById,
        remove,
        exportUsuarios,
        sendEmailChangePassword,
        sendNewPassword,
        confirmEmail,
        massRecoverPassword,
        getProgress,
        getSubscriptions,
        acceptTermOfUse,
        validateFileImport,
        importUsers,
        getUserInfoNPS,
        updateUserEmail,
        clearUserInfoNps,
        getUserAccounts,
        setDefaultPartner
    };
});
export default useUsuariosStore;
