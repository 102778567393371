import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { plantarApi, aliareApi, notificationHubApi, plantarApiV2, vistraBiApi } from './appAPIs';
import { toast } from '@/common/util';
import autenticacaoService from '@/services/autenticacao.service';
import { useSystemStore, useAuthStore, useVistraStore } from '@/stores';
// Maneira de dar parse em json, sem falha de segmentação
function getJson(str) {
    try {
        return JSON.parse(str);
    }
    catch {
        return false;
    }
}
export const setupEndPoints = () => {
    const authStore = useAuthStore();
    const vistraStore = useVistraStore();
    const interceptorResponseOnError = (error) => {
        const { details, notifications, success } = error.response?.data || {};
        let errors = [];
        ((typeof details === 'string' ? getJson(details)?.notifications || [details] : details) || [])?.map((msg) => errors.push(msg));
        ((typeof notifications === 'string' ? getJson(notifications) || [notifications] : notifications) || [])?.map((msg) => errors.push(msg));
        if (!success && errors.length > 0) {
            errors?.map((msg) => {
                toast.error({
                    message: msg
                });
            });
        }
        return Promise.reject(error);
    };
    const interceptorRequest = async (config) => {
        if (authStore.loggedIn) {
            config.headers['Authorization'] = `${authStore.token.token_type} ${authStore.token.access_token}`;
            config.headers['X-Tenant'] = authStore.getActiveTentant;
            config.headers['Accept-Language'] = useSystemStore().clientSettings.lang;
        }
        return config;
    };
    // Interceptor de requisição
    plantarApi.interceptors.request.use(interceptorRequest, (error) => Promise.reject(error));
    plantarApiV2.interceptors.request.use(interceptorRequest, (error) => Promise.reject(error));
    aliareApi.users.interceptors.request.use(async (config) => {
        if (authStore.loggedIn) {
            config.headers['Authorization'] = `${authStore.token.token_type} ${authStore.token.access_token}`;
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });
    notificationHubApi.interceptors.request.use(async (config) => {
        if (authStore.loggedIn) {
            config.headers['Authorization'] = `${authStore.token.token_type} ${authStore.token.access_token}`;
            config.headers['X-Tenant'] = authStore.getActiveTentant;
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });
    vistraBiApi.interceptors.request.use(async (config) => {
        if (vistraStore.vistraToken) {
            config.headers['Authorization'] = `Bearer ${vistraStore.vistraToken}`;
        }
        return config;
    }, (error) => Promise.reject(error));
    aliareApi.portal.interceptors.request.use(async (config) => {
        if (authStore.loggedIn) {
            config.headers['Authorization'] = `${authStore.token.token_type} ${authStore.token.access_token}`;
            config.headers['X-Tenant'] = authStore.getActiveTentant;
        }
        return config;
    }, (error) => Promise.reject(error));
    // Interceptor de resposta
    plantarApi.interceptors?.response.use((response) => response?.data, interceptorResponseOnError);
    plantarApiV2.interceptors?.response.use((response) => response?.data, interceptorResponseOnError);
    // Interceptador de token expirado
    createAuthRefreshInterceptor(plantarApi, async (failedRequest) => {
        const tokenRefreshResult = await authStore.refresh();
        if (tokenRefreshResult) {
            autenticacaoService.registerLogAuthentication();
            return failedRequest;
        }
        return Promise.reject();
    }, { pauseInstanceWhileRefreshing: false, interceptNetworkError: true });
    // Interceptador de token expirado
    createAuthRefreshInterceptor(aliareApi.users, async (failedRequest) => {
        const tokenRefreshResult = await authStore.refresh();
        if (tokenRefreshResult)
            return failedRequest;
        return Promise.reject();
    }, { pauseInstanceWhileRefreshing: false, interceptNetworkError: true });
};
