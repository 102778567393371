import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { dialog } from '@/common/util';
import termosDeUsoService from '@/services/termosDeUso.service';
export const useTermoDeUsoStore = defineStore('termosDeUso', () => {
    const { t } = useI18n();
    const list = ref([]);
    const status = ref(0); // -1 erro de exceção; 0 - inicial; 1 - carregando; 2 - sucesso; 3 - erro não fatal
    const loading = computed(() => status.value === 1);
    const create = async (dataObj) => {
        status.value = 1;
        try {
            const { data, success } = await termosDeUsoService.create(dataObj);
            if (success)
                getAll();
            return data;
        }
        catch {
            //
        }
        finally {
            status.value = 2;
        }
    };
    const update = async (dataObj, id) => {
        status.value = 1;
        try {
            const { data, success } = await termosDeUsoService.update(id, dataObj);
            if (success) {
                const value = list.value.find(el => el.id === data.id);
                value && Object.assign(value, data);
            }
            return data;
        }
        catch {
            //
        }
        finally {
            status.value = 2;
        }
    };
    const getAll = async () => {
        status.value = 1;
        try {
            const { data } = await termosDeUsoService.get();
            list.value = data;
        }
        catch {
            //
        }
        finally {
            status.value = 2;
        }
    };
    const remove = async (termoDeUso, force) => {
        if (!force)
            return dialog.open({
                title: t('feedback.attention'),
                message: t('dialogs.delete.message', { type: t('labels.termOfUse').toLowerCase(), name: termoDeUso.nome }),
                complete: () => remove(termoDeUso, true)
            });
        try {
            status.value = 1;
            const { success } = await termosDeUsoService.remove(termoDeUso.id);
            if (success)
                getAll();
            status.value = 2;
            return success;
        }
        catch {
            status.value = -1;
        }
    };
    const getById = async (id) => {
        status.value = 1;
        try {
            const { data } = await termosDeUsoService.get(id);
            return data;
        }
        catch {
            //
        }
        finally {
            status.value = 2;
        }
    };
    const exportTermosDeUso = async () => {
        status.value = 1;
        try {
            const csvFile = await termosDeUsoService.exportCsv();
            return csvFile;
        }
        catch {
            //
        }
        finally {
            status.value = 2;
        }
    };
    return {
        list,
        status,
        loading,
        // actions
        create,
        update,
        getAll,
        remove,
        getById,
        exportTermosDeUso
    };
});
export default useTermoDeUsoStore;
