<template>
	<svg
		version="1.1" id="Camada_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
		viewBox="0 0 475.2 461.3" style="enable-background:new 0 0 475.2 461.3;" xml:space="preserve"
	>
		<g>
			<g>
				<g>
					<g>
						<path class="cp" d="M315.9,20.2c0.7,7.8-5,14.6-12.7,15.4c-7.8,0.7-14.6-5-15.4-12.7c-0.7-7.8,5-14.6,12.7-15.4
							C308.3,6.8,315.2,12.5,315.9,20.2z"/>
					</g>
				</g>
			</g>
		</g>
		<g class="st1">
			<g>
				<g>
					<g>
						<path d="M315.9,20.2c0.7,7.8-5,14.6-12.7,15.4c-7.8,0.7-14.6-5-15.4-12.7c-0.7-7.8,5-14.6,12.7-15.4
							C308.3,6.8,315.2,12.5,315.9,20.2z"/>
					</g>
				</g>
			</g>
		</g>
		<g>
			<g>
				<g>
					<path class="cp" d="M312.3,20.3c0.6,5.9-3.8,11.1-9.7,11.7c-5.9,0.6-11.1-3.8-11.7-9.7s3.8-11.1,9.7-11.7
						C306.5,10.1,311.8,14.4,312.3,20.3z"/>
				</g>
			</g>
		</g>
		<g>
			<g>
				<g>
					<g>
						<path class="st2" d="M300.7,16.2c0,0-0.1-0.2-0.1-0.4s0-0.4,0-0.4s0.1,0.2,0.1,0.4C300.7,16,300.7,16.2,300.7,16.2z"/>
					</g>
				</g>
			</g>
		</g>
		<g>
			<g>
				<g>
					<g>
						<path class="st2" d="M301.6,10.3c0,0-0.1,0-0.3,0c-0.1,0-0.2,0-0.4,0c-0.2,0-0.3,0-0.5,0s-0.4,0-0.6,0.1c-0.2,0-0.5,0.1-0.7,0.1
							c-0.5,0.1-1.1,0.3-1.6,0.5c-0.6,0.2-1.1,0.5-1.5,0.8c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.3-0.5,0.4
							c-0.2,0.1-0.3,0.2-0.4,0.3s-0.2,0.2-0.3,0.3c-0.2,0.1-0.3,0.2-0.3,0.2s0.1-0.1,0.2-0.3c0.1-0.1,0.2-0.2,0.3-0.3
							c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.1,0.3-0.3,0.5-0.4c0.2-0.1,0.4-0.3,0.6-0.4c0.5-0.3,1-0.5,1.5-0.8c0.6-0.2,1.1-0.4,1.6-0.5
							c0.3-0.1,0.5-0.1,0.7-0.1s0.4-0.1,0.6-0.1s0.4,0,0.5,0c0.2,0,0.3,0,0.4,0C301.5,10.3,301.6,10.3,301.6,10.3z"/>
					</g>
				</g>
			</g>
		</g>
		<g>
			<path class="st3" d="M284.5,46.2c-0.3,0.3-0.8,0.5-1.4,0.6v0.9h-0.8v-0.9c-0.4,0-0.8-0.1-1.2-0.2c-0.4-0.1-0.7-0.3-0.9-0.4l0.5-1
				c0.2,0.2,0.5,0.3,0.7,0.4c0.3,0.1,0.6,0.2,0.9,0.2v-1.4c-0.4-0.1-0.7-0.2-1-0.3s-0.5-0.3-0.7-0.5s-0.3-0.5-0.3-0.9
				c0-0.5,0.2-0.8,0.5-1.2c0.3-0.3,0.8-0.5,1.4-0.6V40h0.8v0.9c0.7,0.1,1.2,0.2,1.7,0.5l-0.4,1c-0.4-0.2-0.8-0.4-1.3-0.4v1.4
				c0.4,0.1,0.7,0.2,1,0.3s0.5,0.3,0.7,0.5s0.3,0.5,0.3,0.9C285,45.5,284.8,45.9,284.5,46.2z M281.8,43c0.1,0.1,0.3,0.2,0.5,0.2V42
				c-0.2,0-0.4,0.1-0.5,0.2c-0.1,0.1-0.1,0.2-0.1,0.4C281.6,42.8,281.7,42.9,281.8,43z M283.5,45.5c0.1-0.1,0.1-0.2,0.1-0.4
				c0-0.1-0.1-0.2-0.2-0.3s-0.2-0.2-0.4-0.2v1.1C283.3,45.7,283.4,45.6,283.5,45.5z"/>
			<path class="st3" d="M290.1,41v5.7h-1.3V42h-1.1v-1H290.1z"/>
			<path class="st3" d="M292.4,45.4c0.1,0.1,0.2,0.3,0.2,0.6c0,0.1,0,0.2,0,0.3s-0.1,0.3-0.2,0.5l-0.5,1.2h-0.8l0.4-1.3
				c-0.1-0.1-0.2-0.1-0.3-0.3c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.2,0.1-0.4,0.2-0.6c0.2-0.1,0.3-0.2,0.6-0.2
				C292.1,45.2,292.3,45.3,292.4,45.4z"/>
			<path class="st3" d="M294.3,46.5c-0.4-0.2-0.7-0.6-0.9-1c-0.2-0.4-0.3-1-0.3-1.6s0.1-1.2,0.3-1.6c0.2-0.4,0.5-0.8,0.9-1
				s0.8-0.4,1.3-0.4s0.9,0.1,1.3,0.4c0.4,0.2,0.7,0.6,0.9,1c0.2,0.4,0.3,1,0.3,1.6s-0.1,1.2-0.3,1.6c-0.2,0.4-0.5,0.8-0.9,1
				s-0.8,0.4-1.3,0.4C295.1,46.8,294.7,46.7,294.3,46.5z M296.4,45.3c0.2-0.3,0.3-0.8,0.3-1.4c0-0.6-0.1-1.1-0.3-1.4
				c-0.2-0.3-0.5-0.5-0.8-0.5s-0.6,0.2-0.8,0.5c-0.2,0.3-0.3,0.8-0.3,1.4c0,0.6,0.1,1.1,0.3,1.4c0.2,0.3,0.5,0.5,0.8,0.5
				C295.9,45.7,296.2,45.6,296.4,45.3z"/>
			<path class="st3" d="M302.9,45.7v1.1h-4.3v-0.9l2.2-2.1c0.2-0.2,0.4-0.4,0.5-0.6c0.1-0.2,0.1-0.3,0.1-0.5s-0.1-0.4-0.2-0.5
				c-0.2-0.1-0.4-0.2-0.7-0.2c-0.3,0-0.5,0-0.7,0.1s-0.4,0.2-0.5,0.4l-1-0.6c0.2-0.3,0.5-0.6,0.9-0.8s0.8-0.3,1.4-0.3
				c0.4,0,0.8,0.1,1.1,0.2c0.3,0.1,0.6,0.3,0.7,0.6c0.2,0.3,0.3,0.6,0.3,0.9c0,0.3-0.1,0.6-0.2,0.9c-0.1,0.3-0.4,0.6-0.8,0.9l-1.3,1.2
				h2.5V45.7z"/>
			<path class="st3" d="M307.3,43.9c0.3,0.3,0.4,0.7,0.4,1.1c0,0.3-0.1,0.6-0.3,0.9c-0.2,0.3-0.4,0.5-0.8,0.7s-0.8,0.3-1.3,0.3
				c-0.4,0-0.8-0.1-1.2-0.2c-0.4-0.1-0.7-0.3-1-0.5l0.5-1c0.2,0.2,0.5,0.3,0.8,0.4c0.3,0.1,0.6,0.1,0.9,0.1s0.6-0.1,0.8-0.2
				c0.2-0.1,0.3-0.3,0.3-0.5c0-0.5-0.4-0.7-1.1-0.7h-0.6v-0.9l1.2-1.3h-2.4V41h4v0.9l-1.3,1.5C306.7,43.4,307.1,43.6,307.3,43.9z"/>
			<path class="st3" d="M309.7,45.4c0.1,0.1,0.2,0.3,0.2,0.6c0,0.1,0,0.2,0,0.3s-0.1,0.3-0.2,0.5l-0.5,1.2h-0.8l0.4-1.3
				c-0.1-0.1-0.2-0.1-0.3-0.3c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.2,0.1-0.4,0.2-0.6c0.2-0.1,0.3-0.2,0.6-0.2
				C309.3,45.2,309.5,45.3,309.7,45.4z"/>
			<path class="st3" d="M314.4,41.6c0.5,0.5,0.7,1.2,0.7,2.1c0,0.7-0.1,1.2-0.4,1.7c-0.2,0.5-0.6,0.8-1,1.1c-0.4,0.2-1,0.4-1.5,0.4
				c-0.3,0-0.6,0-0.9-0.1s-0.5-0.2-0.7-0.3l0.5-1c0.3,0.2,0.6,0.3,1.1,0.3s0.9-0.1,1.2-0.4c0.3-0.3,0.4-0.7,0.5-1.2
				c-0.3,0.3-0.8,0.5-1.4,0.5c-0.4,0-0.7-0.1-1-0.2c-0.3-0.1-0.5-0.4-0.7-0.6c-0.2-0.3-0.3-0.6-0.3-0.9c0-0.4,0.1-0.7,0.3-1
				c0.2-0.3,0.5-0.5,0.8-0.7s0.7-0.2,1.1-0.2C313.3,40.9,313.9,41.1,314.4,41.6z M313.3,43.4c0.2-0.2,0.3-0.4,0.3-0.6
				c0-0.3-0.1-0.5-0.3-0.6c-0.2-0.2-0.4-0.2-0.7-0.2c-0.3,0-0.5,0.1-0.7,0.2s-0.3,0.4-0.3,0.6s0.1,0.5,0.3,0.6
				c0.2,0.2,0.4,0.2,0.7,0.2C312.9,43.6,313.1,43.5,313.3,43.4z"/>
			<path class="st3" d="M320.3,44.3c0.2,0.2,0.2,0.5,0.2,0.8c0,0.4-0.1,0.7-0.3,0.9c-0.2,0.3-0.5,0.5-0.9,0.6s-0.8,0.2-1.3,0.2
				s-0.9-0.1-1.3-0.2c-0.4-0.1-0.6-0.4-0.8-0.6c-0.2-0.3-0.3-0.6-0.3-0.9c0-0.3,0.1-0.6,0.2-0.8s0.4-0.4,0.6-0.6
				c-0.2-0.1-0.4-0.3-0.5-0.5s-0.2-0.4-0.2-0.7s0.1-0.6,0.3-0.9c0.2-0.2,0.4-0.4,0.8-0.6c0.3-0.1,0.7-0.2,1.1-0.2s0.8,0.1,1.2,0.2
				c0.3,0.1,0.6,0.3,0.8,0.6c0.2,0.2,0.3,0.5,0.3,0.9c0,0.3-0.1,0.5-0.2,0.7s-0.3,0.4-0.5,0.5C319.9,43.9,320.2,44.1,320.3,44.3z
				M318.9,45.6c0.2-0.2,0.3-0.4,0.3-0.6c0-0.3-0.1-0.5-0.3-0.6c-0.2-0.1-0.5-0.2-0.8-0.2s-0.6,0.1-0.8,0.2c-0.2,0.1-0.3,0.3-0.3,0.6
				s0.1,0.5,0.3,0.6c0.2,0.1,0.5,0.2,0.8,0.2C318.4,45.9,318.7,45.8,318.9,45.6z M317.5,42.1c-0.2,0.1-0.2,0.3-0.2,0.5
				s0.1,0.4,0.2,0.5c0.2,0.1,0.4,0.2,0.6,0.2c0.3,0,0.5-0.1,0.7-0.2c0.2-0.1,0.2-0.3,0.2-0.5s-0.1-0.4-0.2-0.5
				c-0.2-0.1-0.4-0.2-0.7-0.2C317.8,41.9,317.6,41.9,317.5,42.1z"/>
			<path class="st3" d="M325.6,41v0.9l-2.1,4.9h-1.4l2.1-4.7h-1.9V43H321v-2H325.6z"/>
		</g>
		<g id="Background_Complete">
			<g class="st4">
				<path class="st5" d="M305.2,170.2l0.3,0.9c0.3,1.2,1.2,2.1,2.3,2.5l1.1,0.4c0.3,0.1,0.3,0.6,0,0.7l-1.1,0.4
					c-1.1,0.4-1.9,1.3-2.3,2.5l-0.3,0.9c-0.1,0.4-0.6,0.4-0.7,0l-0.3-1c-0.4-1.1-1.2-2-2.3-2.4l-1.2-0.4c-0.3-0.1-0.3-0.6,0-0.7
					l1.2-0.4c1.1-0.4,1.9-1.2,2.3-2.4l0.3-1C304.7,169.8,305.1,169.9,305.2,170.2z"/>
			</g>
			<g class="st4">
				<path class="st5" d="M106.7,98.9l0.7,2.3c0.9,3.2,3.2,5.7,6.2,6.7l3,1c0.9,0.3,0.9,1.7,0,2l-2.9,1c-2.9,1-5.2,3.5-6.2,6.7
					l-0.7,2.4c-0.3,1-1.5,1-1.8,0l-0.8-2.6c-1-3.1-3.3-5.5-6.1-6.4l-3.2-1.1c-0.9-0.3-0.9-1.7,0-2l3.1-1.1c2.9-1,5.1-3.4,6.1-6.4
					l0.8-2.6C105.1,97.9,106.4,98,106.7,98.9z"/>
			</g>
			<g class="st4">
				<path class="st5" d="M46.8,55.3l0.4,1.4c0.5,1.9,1.9,3.3,3.6,3.9l1.8,0.6c0.5,0.2,0.5,1,0,1.2L50.9,63c-1.7,0.6-3,2-3.6,3.9
					l-0.4,1.4c-0.2,0.6-0.9,0.6-1.1,0l-0.5-1.5c-0.6-1.8-1.9-3.2-3.5-3.7L40,62.3c-0.5-0.2-0.5-1,0-1.2l1.8-0.6c1.7-0.6,3-1.9,3.5-3.7
					l0.5-1.5C46,54.8,46.7,54.8,46.8,55.3z"/>
			</g>
			<g class="st4">
				<path class="st5" d="M400.6,163.7l0.4,1.4c0.5,1.9,1.9,3.3,3.6,3.9l1.8,0.6c0.5,0.2,0.5,1,0,1.2l-1.7,0.6c-1.7,0.6-3,2-3.6,3.9
					l-0.4,1.4c-0.2,0.6-0.9,0.6-1.1,0l-0.5-1.5c-0.6-1.8-1.9-3.2-3.5-3.7l-1.9-0.6c-0.5-0.2-0.5-1,0-1.2l1.8-0.6
					c1.7-0.6,3-1.9,3.5-3.7l0.5-1.5C399.7,163.1,400.5,163.1,400.6,163.7z"/>
			</g>
			<g class="st4">
				<path class="st5" d="M171.8,40l0.3,1c0.4,1.3,1.3,2.4,2.6,2.8l1.3,0.4c0.4,0.1,0.4,0.7,0,0.8l-1.2,0.4c-1.2,0.4-2.2,1.5-2.5,2.8
					l-0.3,1c-0.1,0.4-0.6,0.4-0.8,0l-0.3-1.1c-0.4-1.3-1.3-2.3-2.5-2.7l-1.3-0.5c-0.4-0.1-0.4-0.7,0-0.8l1.3-0.4
					c1.2-0.4,2.1-1.4,2.5-2.7l0.3-1.1C171.2,39.6,171.7,39.6,171.8,40z"/>
			</g>
			<g class="st4">
				<path class="st5" d="M46.6,115.8l0.3,0.9c0.4,1.2,1.3,2.2,2.4,2.6l1.2,0.4c0.3,0.1,0.3,0.7,0,0.8l-1.1,0.4c-1.1,0.4-2,1.4-2.4,2.6
					l-0.3,0.9c-0.1,0.4-0.6,0.4-0.7,0l-0.3-1c-0.4-1.2-1.3-2.1-2.4-2.5l-1.3-0.4c-0.3-0.1-0.3-0.7,0-0.8l1.2-0.4
					c1.1-0.4,2-1.3,2.4-2.5l0.3-1C46.1,115.5,46.5,115.5,46.6,115.8z"/>
			</g>
			<g>
				<path class="st5" d="M378.8,295.3l0.2,0.6c0.2,0.8,0.8,1.4,1.5,1.6l0.7,0.2c0.2,0.1,0.2,0.4,0,0.5l-0.7,0.2
					c-0.7,0.2-1.3,0.8-1.5,1.6l-0.2,0.6c-0.1,0.2-0.4,0.2-0.4,0l-0.2-0.6c-0.2-0.7-0.8-1.3-1.5-1.6l-0.8-0.3c-0.2-0.1-0.2-0.4,0-0.5
					l0.8-0.3c0.7-0.2,1.2-0.8,1.5-1.6l0.2-0.6C378.5,295.1,378.8,295.1,378.8,295.3z"/>
			</g>
			<g class="st4">
				<path class="st5" d="M381.6,118.9l0.2,0.6c0.2,0.8,0.8,1.4,1.5,1.6l0.7,0.2c0.2,0.1,0.2,0.4,0,0.5l-0.7,0.2
					c-0.7,0.2-1.3,0.8-1.5,1.6l-0.2,0.6c-0.1,0.2-0.4,0.2-0.4,0l-0.2-0.6c-0.2-0.7-0.8-1.3-1.5-1.6l-0.8-0.3c-0.2-0.1-0.2-0.4,0-0.5
					l0.8-0.3c0.7-0.2,1.2-0.8,1.5-1.6l0.2-0.6C381.3,118.7,381.6,118.7,381.6,118.9z"/>
			</g>
			<g class="st4">
				<path class="st5" d="M436.9,219.2l0.5,1.6c0.6,2.2,2.2,3.9,4.2,4.5l2.1,0.7c0.6,0.2,0.6,1.1,0,1.4l-2,0.7c-2,0.7-3.5,2.4-4.2,4.5
					l-0.5,1.6c-0.2,0.7-1,0.7-1.2,0l-0.6-1.7c-0.7-2.1-2.2-3.7-4.1-4.4l-2.2-0.7c-0.6-0.2-0.6-1.1,0-1.4l2.1-0.7
					c1.9-0.7,3.5-2.3,4.1-4.4l0.6-1.8C435.8,218.6,436.7,218.6,436.9,219.2z"/>
			</g>
			<g class="st4">
				<path class="st5" d="M431.8,33.1l0.5,1.6c0.6,2.2,2.2,3.9,4.2,4.5l2.1,0.7c0.6,0.2,0.6,1.1,0,1.4l-2,0.7c-2,0.7-3.5,2.4-4.2,4.5
					l-0.5,1.6c-0.2,0.7-1,0.7-1.2,0l-0.6-1.7c-0.7-2.1-2.2-3.7-4.1-4.4l-2.2-0.7c-0.6-0.2-0.6-1.1,0-1.4l2.1-0.7
					c1.9-0.7,3.5-2.3,4.1-4.4l0.6-1.8C430.8,32.5,431.6,32.5,431.8,33.1z"/>
			</g>
			<g>
				<g>
					<g>
						<g>
							<path class="st5" d="M282,363.7c-4.1-2.6-8.8-4.2-13.6-4.7c-2.2-0.2-4.6-0.2-6.4,1.2c-0.4,0.3-0.7,0.7-0.7,1.2
								c0.1,0.3,0.4,0.6,0.6,0.8C267.4,366.4,275.8,366.9,282,363.7"/>
						</g>
					</g>
					<g>
						<g>
							<path class="st5" d="M281.6,363.3c-3.1-2.4-6.2-4.8-8.8-7.7c-2.6-2.9-4.7-6.4-5.5-10.2c-0.2-1.2-0.3-2.5,0.4-3.4
								C274.8,347.5,280.3,354.5,281.6,363.3"/>
						</g>
					</g>
					<g>
						<g>
							<path class="st5" d="M281.9,363.6c-0.5-3.8-0.7-7.8,0.2-11.5c0.9-3.8,2.9-7.4,6.1-9.5c1.2,2.8,0.7,6.1-0.2,9
								c-1.3,4.6-3.4,8.9-6.1,12.8"/>
						</g>
					</g>
					<g>
						<g>
							<path class="st5" d="M288.7,375.9c-4.2,0.5-7.8,2.7-10.9,5.5s-5.3,6.6-6.2,10.7c4.1-0.3,7.5-3.1,10.1-6.4
								C284.2,382.5,285.9,379,288.7,375.9"/>
						</g>
					</g>
					<g>
						<g>
							<path class="st5" d="M290.2,377.9c-3.4-8.3-2.1-18.2,3.1-25.4C295.7,360.9,294.8,370.4,290.2,377.9"/>
						</g>
					</g>
					<g>
						<g>
							<path class="st5" d="M299.3,388.7c-3.8-0.2-7.6,0.7-10.9,2.6s-6,4.8-7.7,8.1c-0.2,0.5-0.4,1-0.1,1.5c0.3,0.4,0.8,0.4,1.2,0.4
								c3.7-0.2,6.9-2.4,9.6-4.9C294.1,393.7,296.4,390.8,299.3,388.7"/>
						</g>
					</g>
					<g>
						<g>
							<path class="st5" d="M299,388.1c-1.9-6.2-2.1-13-0.3-19.3c0.7-2.5,1.8-5.1,4.1-6.3c0.2-0.1,0.4-0.2,0.6-0.1s0.3,0.3,0.4,0.5
								c0.8,1.7,1.1,3.7,1,5.5c-0.1,1.9-0.5,3.8-0.9,5.6c-0.7,3.4-1.1,7.1-3,10.1c-0.4,0.7-0.8,1.5-1.2,2.2c-0.4,0.8-0.4,1-0.7,1.6"/>
						</g>
					</g>
					<g>
						<g>
							<path class="st5" d="M323,391.7c-1.8-6.8,0.1-15,4.4-20.5c0.3-0.4,0.7-0.8,1.2-0.8c0.8,0.1,1,1.1,1,1.9
								C329.2,379.2,326.9,386,323,391.7"/>
						</g>
					</g>
					<g>
						<g>
							<path class="st5" d="M325.2,393.1c8.2,2.1,17.6,0.1,24.5-5c-4-2.1-8.8-2.6-13.2-1.6C331.9,387.5,327.9,389.5,325.2,393.1"/>
						</g>
					</g>
					<g>
						<g>
							<path class="st5" d="M327,388.6c4.6-7.5,13.4-12.4,22.2-12.8c-1,3.6-3.9,6.4-7.3,8c-3.3,1.7-7,2.4-10.7,3.1
								C329.8,387.2,327.9,387.5,327,388.6"/>
						</g>
					</g>
					<g>
						<g>
							<path class="st5" d="M323.4,366.8c1.2-4.3,2.5-8.6,2.8-13s-0.3-9.1-2.6-12.9c-4.3,8.3-4.7,18-0.4,26.3"/>
						</g>
					</g>
					<g>
						<g>
							<path class="st5" d="M337.9,359.5c5-9.1,15.3-16.4,25.6-17.4C359.6,352,348.6,359.7,337.9,359.5"/>
						</g>
					</g>
					<g>
						<g>
							<path class="st5" d="M334.7,361.3c7.5,1.1,13.9,5.9,20.9,8.8c1.5,0.6,3.1,1.1,4.7,0.9s3.2-1.3,3.6-2.9c0.3-1.3-0.3-2.7-1.2-3.7
								c-0.9-1-2.2-1.6-3.4-2.1c-7.6-3.2-15.8-3.3-23.8-1.3"/>
						</g>
					</g>
					<g>
						<g>
							<path class="st5" d="M330.4,366.5c1.7,0.5,2.3,1.4,3.6,2.5c4.6,3.9,10,6.7,15.8,8.1c0.6,0.2,1.4,0.2,1.7-0.3
								c0.3-0.5-0.1-1.1-0.4-1.5c-2.4-3-5.9-5.1-9.6-6.5s-6.8-3-11.2-2.6"/>
						</g>
					</g>
					<g>
						<g>
							<path class="st5" d="M325.5,334.8c6.1,5.7,14.7,8.6,23,7.8c0.4,0,0.8-0.1,1-0.4c0.6-0.6-0.1-1.6-0.8-2.1
								C342,334.8,333.8,332.7,325.5,334.8"/>
						</g>
					</g>
					<g>
						<g>
							<path class="st5" d="M331.3,332.7c8.1-6,19.1-7.9,28.7-4.7c-3.6,3.6-8.6,5.5-13.7,6C341.3,334.5,336.2,333.9,331.3,332.7"/>
						</g>
					</g>
					<g>
						<g>
							<path class="st5" d="M329,318.1c-0.7-6.7-0.2-12.1-0.7-18.8c0-0.5-0.2-1.2-0.7-1.4c-0.7-0.3-1.3,0.6-1.5,1.3
								c-2,6.2-0.9,13.4,2.9,18.8"/>
						</g>
					</g>
					<g>
						<g>
							<path class="st5" d="M328.5,318.8c4.6-2.8,8-6.5,11.2-10.9c2.2-3,4.1-6.6,3.6-10.3c-3.1-0.1-6,1.6-8.1,4
								c-2,2.4-3.2,5.3-4.1,8.3S329.6,315.9,328.5,318.8"/>
						</g>
					</g>
					<g>
						<g>
							<path class="st5" d="M339.5,315.8c10-3.7,19.4-9.2,27.5-16.2c-3.3-1.3-7-0.5-10.3,0.8c-7.5,3-13.6,8.1-17.3,15.3"/>
						</g>
					</g>
					<g>
						<g>
							<path class="st5" d="M331.6,322.5c5.1,0.6,10.2,0.9,15.3,0.1c4.9-0.8,9.6-2.8,14.2-4.7c0.4-0.2,0.8-0.4,1-0.7
								c0.2-0.4,0-0.9-0.3-1.2c-0.3-0.3-0.8-0.4-1.2-0.6c-5-1.4-10.4-0.7-15.4,0.9C340.2,317.9,336.1,319.9,331.6,322.5"/>
						</g>
					</g>
					<g>
						<g>
							<path class="st5" d="M302.7,335c-3.5-7-3.2-15.1-0.2-22.3c2.1,3.2,2.4,7.3,2,11.1c-0.4,3.8-1.5,7.7-1.6,11.6"/>
						</g>
					</g>
					<g>
						<g>
							<path class="st5" d="M288.8,328.1c-8.6-0.2-17.3,3.5-23.2,9.9c4.3,0.7,8.8-0.4,12.7-2.4c3.9-1.9,7.4-4.6,10.9-7.3"/>
						</g>
					</g>
					<g>
						<g>
							<path class="st5" d="M279.5,324.9c-4.2-0.3-8.3-0.9-12.4,0.4c-4.1,1.2-7.8,4.1-9.4,8c4,0.4,8-0.5,11.6-2.2
								C273,329.5,276.3,327.2,279.5,324.9"/>
						</g>
					</g>
					<g>
						<g>
							<path class="st5" d="M293.3,325.1c-3.8-2.5-7.7-5-12.1-6.1c-4.4-1-9.6-0.3-12.6,3.1c3.6,2.1,7.9,2.4,12.1,2.3
								c4.2,0,9.3-0.1,13.2,1.3"/>
						</g>
					</g>
					<g>
						<g>
							<path class="st5" d="M293.3,325.3c-6.5-6.4-9-17.9-6.4-26.6C292.1,306.5,294.4,316.1,293.3,325.3"/>
						</g>
					</g>
					<g>
						<g>
							<path class="st5" d="M311.2,307.2c1.2-2.9,2.1-5.8,3.2-8.7c1.1-2.9,2.5-5.8,4.5-8.1c2.1-2.3,5-4,8.1-4.2
								c-1.9,8.8-7.8,16.6-15.6,21"/>
						</g>
					</g>
					<g>
						<g>
							<path class="st5" d="M308,311c-2-2.8-4.4-6.6-7.1-8.7c-2.8-2.1-6.3-3.3-9.7-2.5C294.6,305.9,301,310.2,308,311"/>
						</g>
					</g>
					<g>
						<g>
							<path class="st5" d="M308.6,307.4c-1.3-3.4-2.7-6.8-3.2-10.4s-0.2-7.4,1.6-10.6c3.6,6.7,4.7,14,1.8,21"/>
						</g>
					</g>
					<g>
						<g>
							<path class="st5" d="M311.2,286.5c2-6.7,8.1-11.6,14.8-13.9c0.8-0.3,1.7-0.5,2.6-0.4s1.7,0.7,1.9,1.6c0.2,1.1-0.7,2.2-1.6,2.9
								C323.7,281.1,317.7,284.5,311.2,286.5"/>
						</g>
					</g>
					<g>
						<g>
							<path class="st5" d="M311.3,281c0.3-4.3,0.5-8.7,1.9-12.8c1.4-4.1,4.1-7.9,8-9.7c0.7,4.4-1.3,8.7-3.6,12.4
								c-2.3,3.7-5.1,7.3-6.5,11.5"/>
						</g>
					</g>
					<g>
						<g>
							<path class="st5" d="M311.2,284c-1.7-4.9-1.8-11.1-4.8-15.3c-2-3-5.3-5-7.1-8.1c-1.2,2.9-0.8,6.3,0.4,9.2
								c1.2,2.9,3.2,5.4,5.4,7.7S309.2,281.5,311.2,284"/>
						</g>
					</g>
					<g>
						<g>
							<g>
								<path class="st5" d="M311.2,427.5c-0.1,0-0.2-32.5-0.3-72.6c0-40.1,0.1-72.6,0.2-72.6s0.2,32.5,0.3,72.6
									S311.4,427.5,311.2,427.5z"/>
							</g>
						</g>
					</g>
					<g>
						<g>
							<g>
								<path class="st5" d="M339.6,330.1c0,0-0.4,0.2-1.2,0.5c-0.9,0.3-2,0.7-3.4,1.1c-2.8,1-6.7,2.4-11,4.1
									c-4.2,1.8-8.1,3.3-10.4,5.2c-1.1,1-1.8,2-2.1,2.8c-0.3,0.8-0.4,1.2-0.4,1.2s0-0.1,0-0.3c0-0.2,0.1-0.5,0.2-0.9
									c0.3-0.8,0.9-1.9,2-2.9s2.7-2,4.5-2.8s3.8-1.7,5.9-2.6c4.2-1.7,8.2-3.1,11-4c1.4-0.4,2.6-0.8,3.4-1
									C339.2,330.2,339.6,330.1,339.6,330.1z"/>
							</g>
						</g>
					</g>
					<g>
						<g>
							<g>
								<path class="st5" d="M348.9,307.2c0,0-0.1,0.1-0.3,0.4c-0.3,0.3-0.6,0.6-1,1c-1,0.9-2.3,2.1-3.9,3.5c-3.4,3-7.9,7.1-13,11.5
									c-5.1,4.5-9.4,8.8-12.5,12c-1.5,1.6-2.7,3-3.5,3.9c-0.4,0.4-0.7,0.8-0.9,1.1c-0.2,0.2-0.3,0.4-0.4,0.4c0,0,0.1-0.2,0.3-0.4
									c0.2-0.3,0.5-0.7,0.9-1.1c0.8-1,1.9-2.4,3.4-4c3-3.3,7.3-7.7,12.4-12.2s9.7-8.5,13.1-11.4c1.6-1.4,3-2.6,4-3.4
									c0.4-0.4,0.8-0.7,1.1-0.9C348.8,307.3,348.9,307.2,348.9,307.2z"/>
							</g>
						</g>
					</g>
					<g>
						<g>
							<g>
								<path class="st5" d="M325.2,328.3c-0.1-0.1,0.9-2.5,2-5.6s1.6-5.7,1.7-5.7s-0.2,2.7-1.3,5.8c-0.5,1.6-1.1,2.9-1.6,3.9
									C325.6,327.7,325.3,328.3,325.2,328.3z"/>
							</g>
						</g>
					</g>
					<g>
						<g>
							<g>
								<path class="st5" d="M311.3,347.9c0,0-0.3-0.3-0.8-0.9c-0.5-0.7-1.2-1.5-2-2.6c-1.7-2.2-4-5.2-6.6-8.4c-2.6-3.3-5-6.2-6.7-8.3
									c-0.8-1-1.5-1.9-2-2.5s-0.7-0.9-0.7-1c0,0,0.3,0.3,0.8,0.8c0.5,0.6,1.3,1.4,2.2,2.4c1.8,2.1,4.2,5,6.8,8.3
									c2.6,3.3,4.9,6.3,6.5,8.5c0.8,1.1,1.4,2,1.9,2.7C311.1,347.6,311.3,347.9,311.3,347.9z"/>
							</g>
						</g>
					</g>
					<g>
						<g>
							<g>
								<path class="st5" d="M305,339.9c-0.1,0.1-1.7-1.9-2.6-4.9c-0.6-2.1-0.7-4-0.6-4.9h0.2c-0.1,0.4-0.2,0.6-0.2,0.6s0-0.2,0-0.6
									h0.2c0.1,0.9,0.2,2.7,0.8,4.8C303.7,337.8,305.2,339.9,305,339.9z"/>
							</g>
						</g>
					</g>
					<g>
						<g>
							<g>
								<path class="st5" d="M297,329.8c-0.1,0.1-0.6-0.6-1.8-1.1c-1.2-0.5-3-0.6-5.1-0.4c-2,0.1-3.8,0.5-5.1,0.8
									c-1.3,0.3-2.1,0.6-2.1,0.5s0.7-0.4,2-0.8s3.1-0.8,5.1-1s4,0,5.2,0.6C296.6,329,297,329.8,297,329.8z"/>
							</g>
						</g>
					</g>
					<g>
						<g>
							<g>
								<path class="st5" d="M295.1,327.4c0,0.1-1.1-0.4-2.8-1c-1.7-0.6-4.2-1.2-7-1.4c-2.8-0.2-5.3,0-7.1,0.4
									c-1.8,0.3-2.9,0.7-2.9,0.6c0,0,0.3-0.1,0.7-0.3c0.5-0.2,1.2-0.4,2.1-0.6c1.8-0.4,4.4-0.7,7.2-0.5c2.8,0.2,5.3,0.9,7,1.6
									c0.9,0.3,1.5,0.7,2,0.9C294.8,327.2,295.1,327.3,295.1,327.4z"/>
							</g>
						</g>
					</g>
					<g>
						<g>
							<g>
								<path class="st5" d="M311.2,317c-0.1,0-1-3.1-2-7.1c-1-3.9-1.7-7.2-1.6-7.2s1,3.1,2,7.1C310.6,313.7,311.3,317,311.2,317z"/>
							</g>
						</g>
					</g>
					<g>
						<g>
							<g>
								<path class="st5" d="M311.3,317c-0.1,0-0.7-2.1-2.3-4.2c-1.5-2.2-3.4-3.3-3.3-3.4c0,0,0.5,0.1,1.3,0.7
									c0.7,0.5,1.6,1.4,2.4,2.5C311,314.7,311.4,317,311.3,317z"/>
							</g>
						</g>
					</g>
					<g>
						<g>
							<g>
								<path class="st5" d="M311.2,410.2c-0.1,0-0.1-0.8-0.5-2.2c-0.4-1.4-1-3.3-2.2-5.6c-2.2-4.6-6.3-10.4-11.5-16.2
									c-2.6-2.9-5.1-5.7-7.2-8.4c-2.1-2.7-3.9-5.2-5.1-7.4c-1.3-2.2-2.1-4.2-2.6-5.5c-0.2-0.7-0.4-1.2-0.5-1.6s-0.1-0.6-0.1-0.6
									s0.3,0.7,0.8,2.1c0.5,1.3,1.4,3.2,2.7,5.4c1.3,2.2,3.1,4.7,5.2,7.3c2.1,2.6,4.6,5.4,7.2,8.3c5.2,5.8,9.3,11.7,11.5,16.4
									c1.1,2.3,1.7,4.3,2.1,5.7c0.2,0.7,0.3,1.3,0.3,1.6C311.2,410,311.2,410.2,311.2,410.2z"/>
							</g>
						</g>
					</g>
					<g>
						<g>
							<g>
								<path class="st5" d="M336.9,381.2c0,0-0.5,0.3-1.3,0.8c-0.9,0.5-2.1,1.2-3.5,2.3c-1.4,1.1-3,2.5-4.6,4.2s-3.3,3.7-5,5.8
									c-3.4,4.2-6.5,8-8.4,11c-1,1.5-1.7,2.7-2.1,3.6c-0.5,0.9-0.7,1.4-0.7,1.4s0-0.1,0.1-0.4c0.1-0.2,0.2-0.6,0.5-1.1
									c0.4-0.9,1.1-2.2,2-3.7c1.8-3.1,5-6.9,8.4-11.1c1.7-2.1,3.4-4.1,5-5.8c1.6-1.7,3.3-3.1,4.7-4.1c1.4-1.1,2.7-1.8,3.6-2.2
									c0.4-0.2,0.8-0.4,1.1-0.5C336.8,381.2,336.9,381.2,336.9,381.2z"/>
							</g>
						</g>
					</g>
					<g>
						<g>
							<g>
								<path class="st5" d="M337.4,390.6c0,0.1-1.1,0.2-2.8,0.4c-1.8,0.2-4.2,0.5-6.7,1.3s-4.7,2.1-6,3.2c-1.4,1.1-2,2-2.1,1.9
									c0,0,0.1-0.3,0.4-0.7c0.3-0.4,0.8-0.9,1.5-1.5c1.4-1.2,3.5-2.5,6.1-3.4c2.6-0.8,5.1-1.1,6.9-1.2
									C336.3,390.5,337.4,390.5,337.4,390.6z"/>
							</g>
						</g>
					</g>
					<g>
						<g>
							<g>
								<path class="st5" d="M320.5,396.4c-0.1-0.1,1.1-2,2.1-4.7c0.9-2.7,1.2-5,1.3-5c0.1,0,0.1,0.6,0,1.5s-0.3,2.2-0.8,3.6
									s-1.1,2.5-1.6,3.3C320.9,396,320.5,396.4,320.5,396.4z"/>
							</g>
						</g>
					</g>
					<g>
						<g>
							<g>
								<path class="st5" d="M345.2,355.9c0,0-0.2,0.1-0.5,0.2c-0.4,0.2-0.8,0.4-1.3,0.6c-0.6,0.3-1.3,0.6-2.1,1
									c-0.8,0.4-1.7,0.8-2.7,1.4c-0.5,0.3-1,0.6-1.6,0.9c-0.5,0.3-1.1,0.7-1.6,1c-1.1,0.7-2.3,1.5-3.5,2.4c-2.4,1.8-4.9,4-7.3,6.4
									c-2.4,2.5-4.5,5.1-6.2,7.5c-0.8,1.3-1.6,2.4-2.3,3.6c-0.3,0.6-0.7,1.1-1,1.7c-0.3,0.5-0.6,1.1-0.8,1.6c-0.6,1-0.9,1.9-1.3,2.8
									c-0.4,0.8-0.7,1.5-0.9,2.1c-0.2,0.5-0.4,1-0.6,1.4c-0.1,0.3-0.2,0.5-0.2,0.5s0-0.2,0.1-0.5c0.1-0.4,0.3-0.8,0.5-1.4
									s0.5-1.3,0.8-2.2c0.4-0.8,0.7-1.8,1.3-2.8c0.3-0.5,0.5-1,0.8-1.6s0.6-1.1,1-1.7c0.6-1.2,1.5-2.4,2.3-3.6
									c1.7-2.5,3.8-5.1,6.2-7.6c2.4-2.5,5-4.7,7.4-6.5c1.2-0.9,2.4-1.7,3.6-2.4c0.6-0.3,1.1-0.7,1.7-1c0.5-0.3,1.1-0.6,1.6-0.9
									c1-0.6,1.9-1,2.8-1.4c0.8-0.4,1.5-0.7,2.1-0.9c0.6-0.2,1-0.4,1.4-0.5C345,356,345.2,355.9,345.2,355.9z"/>
							</g>
						</g>
					</g>
					<g>
						<g>
							<g>
								<path class="st5" d="M324.9,369.5c0,0,0.2-0.8,1.1-1.7c0.9-0.9,2.6-1.7,4.5-1.7c1.9-0.1,3.6,0.5,4.7,0.9
									c1.1,0.5,1.8,0.8,1.8,0.9s-0.7-0.2-1.9-0.6c-1.2-0.4-2.8-0.8-4.6-0.8c-1.8,0.1-3.4,0.8-4.3,1.5
									C325.3,368.9,325,369.6,324.9,369.5z"/>
							</g>
						</g>
					</g>
					<g>
						<g>
							<g>
								<path class="st5" d="M321.9,372.7c-0.1-0.1,1-1.8,1.3-4.2c0.3-2.4-0.4-4.3-0.3-4.3s0.3,0.4,0.5,1.2c0.2,0.8,0.4,1.9,0.2,3.2
									c-0.1,1.3-0.6,2.3-1,3C322.3,372.3,321.9,372.7,321.9,372.7z"/>
							</g>
						</g>
					</g>
				</g>
				<g>
					<g>
						<path class="st5" d="M288.8,414.8c0.7,12.4,4.4,25.1,10.2,37.8h25c5.5-12.6,9-25.3,9.6-37.8H288.8z"/>
					</g>
				</g>
				<g>
					<g>
						<g>
							<path class="st6" d="M329.6,434.9c0,0.1-8.2,0.2-18.4,0.2c-10.2,0-18.4-0.1-18.4-0.2s8.2-0.2,18.4-0.2
								C321.3,434.7,329.6,434.8,329.6,434.9z"/>
						</g>
					</g>
				</g>
				<g>
					<g>
						<g>
							<path class="st6" d="M329,439.4c0,0.1-7.7,0.2-17.3,0.2c-9.5,0-17.3-0.1-17.3-0.2s7.7-0.2,17.3-0.2
								C321.3,439.2,329,439.3,329,439.4z"/>
						</g>
					</g>
				</g>
				<g>
					<g>
						<g>
							<path class="st6" d="M299.4,423c0.1,0,0.2,2.6,0.2,5.8c0,3.2-0.1,5.8-0.2,5.8s-0.2-2.6-0.2-5.8
								C299.1,425.6,299.2,423,299.4,423z"/>
						</g>
					</g>
				</g>
				<g>
					<g>
						<g>
							<path class="st6" d="M312.8,423c0.1,0,0.2,2.7,0.2,6s-0.1,6-0.2,6s-0.2-2.7-0.2-6C312.6,425.7,312.7,423,312.8,423z"/>
						</g>
					</g>
				</g>
				<g>
					<g>
						<g>
							<path class="st6" d="M325.4,422.9c0.1,0,0.2,2.7,0.2,6.1s-0.1,6.1-0.2,6.1s-0.2-2.7-0.2-6.1
								C325.2,425.6,325.3,422.9,325.4,422.9z"/>
						</g>
					</g>
				</g>
				<g>
					<g>
						<g>
							<path class="st6" d="M332.8,423c0,0.1-9.7,0.2-21.6,0.2s-21.6-0.1-21.6-0.2s9.7-0.2,21.6-0.2
								C323.1,422.8,332.8,422.9,332.8,423z"/>
						</g>
					</g>
				</g>
			</g>
		</g>
		<g id="Background_Simple" class="st7">
			<g class="st8">
				<path class="st9" d="M455,183.4l-1.4-2.3C442,162,421,150.5,398.8,151.9c-2.8,0.2-5.7,0.6-8.5,1.2c-20.1,4.3-37.7,19.5-44.9,39.1
					c-4.3,11.5-5.5,24.7-13.6,33.8c-10.6,11.9-28.5,12.2-44.2,11.7c-15.8-0.5-33.7-0.1-44.2,11.8c-6.6,7.5-8.7,18-8.1,28
					c1.4,26.4,19.8,50,43.2,61.8c23.4,11.7,51,12.8,76.6,7.6c35.9-7.2,70-27.1,90.6-57.8C466.2,258.4,471.4,216.6,455,183.4z"/>
			</g>
			<g class="st8">
				<ellipse class="st9" cx="73.2" cy="79.5" rx="56.8" ry="56.8"/>
			</g>
		</g>
		<g id="Floor">
			<g>
				<g>
					<path class="st10" d="M469.7,453c0,0.1-103.6,0.3-231.3,0.3C110.6,453.3,7,453.2,7,453c0-0.1,103.6-0.3,231.4-0.3
						C366.2,452.8,469.7,452.9,469.7,453z"/>
				</g>
			</g>
		</g>
		<g id="Lines">
			<g>
				<g>
					<path class="st3" d="M349.9,72.2c0.1,0.1-4.7,5.1-10.7,11.1s-11,10.8-11.1,10.7c-0.1-0.1,4.7-5.1,10.7-11.1
						C344.9,76.9,349.8,72.1,349.9,72.2z"/>
				</g>
			</g>
			<g>
				<g>
					<path class="st3" d="M301.7,80.2c-0.1,0-0.3-6.3-0.3-14.1s0.1-14.1,0.3-14.1c0.1,0,0.3,6.3,0.3,14.1
						C301.9,73.9,301.8,80.2,301.7,80.2z"/>
				</g>
			</g>
			<g>
				<g>
					<path class="st3" d="M266.1,118.5c0,0.1-3.8,0.3-8.5,0.3c-4.7,0-8.5-0.1-8.5-0.3c0-0.1,3.8-0.3,8.5-0.3
						C262.3,118.2,266.1,118.3,266.1,118.5z"/>
				</g>
			</g>
			<g>
				<circle class="st3" cx="301.7" cy="52" r="1.4"/>
			</g>
			<g>
				<circle class="st3" cx="349.3" cy="73.3" r="1.4"/>
			</g>
			<g>
				<path class="st3" d="M283.5,158.8c0,0.8-0.6,1.4-1.4,1.4s-1.4-0.6-1.4-1.4s0.6-1.4,1.4-1.4S283.5,158,283.5,158.8z"/>
			</g>
			<g>
				<g>
					<path class="st3" d="M248.6,73.3c0.1-0.1,6.3,4.4,13.8,10.2c7.5,5.7,13.6,10.5,13.5,10.6s-6.3-4.4-13.8-10.2
						C254.5,78.1,248.5,73.4,248.6,73.3z"/>
				</g>
			</g>
			<g>
				<path class="st3" d="M247.4,73.6c0,0.8,0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4s-0.6-1.4-1.4-1.4S247.4,72.8,247.4,73.6z"/>
			</g>
			<g>
				<path class="st3" d="M250.6,119.5c-0.6,0.6-1.5,0.6-2,0c-0.6-0.6-0.6-1.5,0-2c0.6-0.6,1.5-0.6,2,0
					C251.2,118.1,251.2,119,250.6,119.5z"/>
			</g>
			<g>
				<g>
					<path class="st3" d="M288.5,139.9c0.1,0-0.2,1.1-0.7,2.8s-1.3,4-2.1,6.6c-0.9,2.6-1.7,4.9-2.4,6.5c-0.6,1.7-1.1,2.6-1.2,2.6
						s0.3-1.1,0.9-2.7c0.6-1.8,1.4-4.1,2.2-6.6c0.9-2.6,1.7-4.9,2.3-6.5C288,140.9,288.5,139.9,288.5,139.9z"/>
				</g>
			</g>
		</g>
		<g id="Game">
			<g>
				<g>
					<g>
						<ellipse class="cs" cx="300.2" cy="111" rx="25.2" ry="25.2"/>
					</g>
					<g>
						<g>
							<path class="st3" d="M325.4,110.9c0,0,0-0.1,0-0.4s0-0.7-0.1-1.2c0-0.3,0-0.6-0.1-0.9c0-0.3-0.1-0.7-0.2-1.1
								c-0.1-0.8-0.2-1.6-0.5-2.6c-0.4-1.9-1.4-4.2-2.8-6.7c-1.5-2.4-3.5-5-6.4-7.1c-2.8-2.1-6.4-3.9-10.5-4.7
								c-4.1-0.7-8.7-0.6-13.1,1.1c-1.1,0.4-2.2,0.9-3.3,1.4c-1,0.6-2.1,1.2-3.1,2c-1,0.7-1.9,1.6-2.8,2.4c-0.8,0.9-1.7,1.8-2.4,2.9
								c-1.5,2.1-2.8,4.4-3.6,6.9c-0.8,2.5-1.3,5.2-1.3,7.9c0,2.7,0.5,5.4,1.3,7.9s2.1,4.8,3.6,6.9c0.7,1.1,1.6,2,2.4,2.9
								c0.9,0.8,1.8,1.7,2.8,2.4c1,0.8,2,1.3,3.1,2c1.1,0.5,2.1,1.1,3.3,1.4c4.4,1.7,9,1.8,13.1,1.1s7.7-2.5,10.5-4.7
								c2.8-2.1,4.9-4.7,6.4-7.1c1.4-2.4,2.4-4.7,2.8-6.7c0.3-1,0.4-1.8,0.5-2.6c0.1-0.4,0.1-0.7,0.2-1.1c0-0.3,0-0.6,0.1-0.9
								c0-0.5,0.1-0.9,0.1-1.2C325.4,111.1,325.4,110.9,325.4,110.9s0,0.1,0,0.4s0,0.7,0,1.2c0,0.3,0,0.6,0,0.9s-0.1,0.7-0.1,1.1
								c-0.1,0.8-0.2,1.7-0.5,2.6c-0.4,2-1.3,4.3-2.7,6.8c-1.4,2.5-3.5,5-6.4,7.2c-2.9,2.2-6.5,4-10.7,4.8c-4.2,0.7-8.9,0.6-13.4-1.1
								c-1.1-0.4-2.2-0.9-3.3-1.5c-1-0.6-2.2-1.2-3.1-2c-1-0.7-1.9-1.6-2.9-2.5c-0.9-0.9-1.8-1.9-2.5-3c-1.5-2.1-2.8-4.4-3.6-7
								c-0.8-2.6-1.3-5.3-1.3-8c0-2.8,0.5-5.5,1.3-8c0.8-2.6,2.1-4.9,3.6-7c0.7-1.1,1.6-2,2.5-2.9s1.9-1.7,2.9-2.5s2.1-1.4,3.1-2
								c1.1-0.5,2.2-1.1,3.3-1.5c4.5-1.7,9.2-1.8,13.4-1.1c4.2,0.8,7.8,2.6,10.7,4.8c2.9,2.2,5,4.8,6.4,7.2c1.4,2.5,2.3,4.8,2.8,6.8
								c0.3,1,0.4,1.9,0.5,2.6c0.1,0.4,0.1,0.7,0.1,1.1c0,0.3,0,0.6,0,0.9c0,0.5,0,0.9,0,1.2C325.4,110.8,325.4,110.9,325.4,110.9z"/>
						</g>
					</g>
				</g>
				<g>
					<g>
						<g>
							<path class="cp" d="M329.7,122.2l-0.6-13.8c-0.3-7.3-6.1-13-13.2-13h-15.7h-15.7c-7,0-12.8,5.7-13.2,13l-0.6,13.8
								c0,0-0.6,9.3,7.8,9.3c0,0,3.9,1,6.8-3.4l5.3-7.1l9.6-0.2l9.6,0.2l5.3,7.1c3,4.3,6.8,3.4,6.8,3.4
								C330.3,131.5,329.7,122.2,329.7,122.2z"/>
						</g>
					</g>
					<g>
						<g>
							<path class="st2" d="M282.9,103.1v2.3c0,0.3,0.1,0.5,0.3,0.7l1.9,1.4l2-1.5c0.2-0.2,0.3-0.4,0.3-0.6v-2.3
								c0-0.4-0.4-0.8-0.8-0.8h-3C283.3,102.3,282.9,102.7,282.9,103.1z"/>
						</g>
						<g>
							<path class="st2" d="M287.5,115.3V113c0-0.3-0.1-0.5-0.3-0.7l-1.9-1.4l-2,1.5c-0.2,0.2-0.3,0.4-0.3,0.6v2.3
								c0,0.4,0.4,0.8,0.8,0.8h3C287.2,116.1,287.5,115.7,287.5,115.3z"/>
						</g>
						<g>
							<path class="st2" d="M278.8,111.6h2.3c0.3,0,0.5-0.1,0.7-0.3l1.4-1.9l-1.5-2c-0.2-0.2-0.4-0.3-0.6-0.3h-2.3
								c-0.4,0-0.8,0.4-0.8,0.8v3C278,111.3,278.3,111.6,278.8,111.6z"/>
						</g>
						<g>
							<path class="st2" d="M291.3,107H289c-0.3,0-0.5,0.1-0.7,0.3l-1.4,1.9l1.5,2c0.2,0.2,0.4,0.3,0.6,0.3h2.3c0.4,0,0.8-0.4,0.8-0.8
								v-3C292,107.4,291.7,107,291.3,107z"/>
						</g>
					</g>
					<g>
						<g>
							<path class="st2" d="M312.9,103.1v2.3c0,0.3,0.1,0.5,0.3,0.7l1.9,1.4l2-1.5c0.2-0.2,0.3-0.4,0.3-0.6v-2.3
								c0-0.4-0.4-0.8-0.8-0.8h-3C313.3,102.3,312.9,102.7,312.9,103.1z"/>
						</g>
						<g>
							<path class="st2" d="M317.5,115.3V113c0-0.3-0.1-0.5-0.3-0.7l-1.9-1.4l-2,1.5c-0.2,0.2-0.3,0.4-0.3,0.6v2.3
								c0,0.4,0.4,0.8,0.8,0.8h3C317.1,116.1,317.5,115.7,317.5,115.3z"/>
						</g>
						<g>
							<path class="st2" d="M308.8,111.6h2.3c0.3,0,0.5-0.1,0.7-0.3l1.4-1.9l-1.5-2c-0.2-0.2-0.4-0.3-0.6-0.3h-2.3
								c-0.4,0-0.8,0.4-0.8,0.8v3C308,111.3,308.3,111.6,308.8,111.6z"/>
						</g>
						<g>
							<path class="st2" d="M321.2,107h-2.3c-0.3,0-0.5,0.1-0.7,0.3l-1.4,1.9l1.5,2c0.2,0.2,0.4,0.3,0.6,0.3h2.3
								c0.4,0,0.8-0.4,0.8-0.8v-3C322,107.4,321.7,107,321.2,107z"/>
						</g>
					</g>
				</g>
			</g>
		</g>
		<g id="Comments">
			<g>
				<g>
					<path class="st10" d="M290.8,197.2c0,0.1-9.3,0.3-20.8,0.3s-20.8-0.1-20.8-0.3c0-0.1,9.3-0.3,20.8-0.3S290.8,197.1,290.8,197.2z"
						/>
				</g>
			</g>
			<g>
				<g>
					<path class="cp" d="M272.1,171.7h-16.8c-1.6,0-3,1.3-3,3v11.1c0,1.6,1.3,3,3,3h12.6l3.8,3.3l-0.3-3.3h0.3c1.6,0,3-1.3,3-3v-11.5
						C274.6,172.8,273.4,171.7,272.1,171.7z"/>
				</g>
				<g>
					<path class="st2" d="M259.9,180.1c0,1-0.8,1.7-1.7,1.7s-1.7-0.8-1.7-1.7s0.8-1.7,1.7-1.7S259.9,179.2,259.9,180.1z"/>
				</g>
				<g>
					<path class="st2" d="M265,180.1c0,1-0.8,1.7-1.7,1.7c-1,0-1.7-0.8-1.7-1.7s0.8-1.7,1.7-1.7C264.3,178.4,265,179.2,265,180.1z"/>
				</g>
				<g>
					<path class="st2" d="M270.5,180.1c0,1-0.8,1.7-1.7,1.7s-1.7-0.8-1.7-1.7s0.8-1.7,1.7-1.7S270.5,179.2,270.5,180.1z"/>
				</g>
			</g>
			<g>
				<path class="cp" d="M284.9,164.7h-16c-1.7,0-3.1,1.4-3.1,3.1v2.4c0,0.1,0.1,0.1,0.1,0.1h7.1c1.7,0,3.1,1.4,3.1,3.1v8
					c0,0.1,0.1,0.1,0.1,0.1h4.9h0.1l3.4,3.1c0.1,0.1,0.3,0,0.2-0.1l-0.3-2.8c0-0.1,0.1-0.2,0.1-0.2l0,0c1.7,0,3.1-1.4,3.1-3.1v-10.8
					C288,166.1,286.6,164.7,284.9,164.7z"/>
			</g>
			<g class="st1">
				<path d="M284.9,164.7h-16c-1.7,0-3.1,1.4-3.1,3.1v2.4c0,0.1,0.1,0.1,0.1,0.1h7.1c1.7,0,3.1,1.4,3.1,3.1v8c0,0.1,0.1,0.1,0.1,0.1
					h4.9h0.1l3.4,3.1c0.1,0.1,0.3,0,0.2-0.1l-0.3-2.8c0-0.1,0.1-0.2,0.1-0.2l0,0c1.7,0,3.1-1.4,3.1-3.1v-10.8
					C288,166.1,286.6,164.7,284.9,164.7z"/>
			</g>
		</g>
		<g id="Players">
			<g>
				<g>
					<g>
						<g>
							<g>
								<path class="cp" d="M225.2,120.1c1.8-0.8,3.1-2.6,3.1-4.8c0-2.9-2.2-5.2-4.9-5.2s-4.9,2.3-4.9,5.2c0,2.2,1.3,4.1,3.1,4.9
									c-7.5,1.1-7.2,9.3-7.2,9.3l17.8,0.1C232.3,129.5,232.7,121.2,225.2,120.1z"/>
							</g>
						</g>
					</g>
				</g>
				<g>
					<path class="cp" d="M238.2,121c1.6-0.7,2.8-2.4,2.8-4.3c0-2.6-2-4.7-4.4-4.7s-4.4,2.1-4.4,4.7c0,2,1.2,3.7,2.8,4.4
						c-1.5,0.2-2.2,0.7-3.3,1.2c2.9,2.4,2.5,6.9,2.5,7.2h10.4C244.5,129.5,244.9,122.1,238.2,121z"/>
					<path class="st1" d="M238.2,121c1.6-0.7,2.8-2.4,2.8-4.3c0-2.6-2-4.7-4.4-4.7s-4.4,2.1-4.4,4.7c0,2,1.2,3.7,2.8,4.4
						c-1.5,0.2-2.2,0.7-3.3,1.2c2.9,2.4,2.5,6.9,2.5,7.2h10.4C244.5,129.5,244.9,122.1,238.2,121z"/>
				</g>
				<g>
					<path class="st10" d="M225.9,142.3c0.3,0.1,0.5,0.4,0.7,0.6c0.2,0.3,0.3,0.6,0.3,0.9c0,0.4-0.1,0.7-0.3,1s-0.5,0.5-0.8,0.7
						s-0.7,0.2-1.1,0.2c-0.8,0-1.4-0.2-1.9-0.7s-0.7-1.2-0.7-2.1c0-0.7,0.1-1.2,0.4-1.7c0.2-0.5,0.6-0.8,1-1.1c0.4-0.2,1-0.4,1.5-0.4
						c0.3,0,0.6,0,0.9,0.1c0.3,0.1,0.5,0.2,0.7,0.3l-0.5,1c-0.3-0.2-0.6-0.3-1.1-0.3s-0.9,0.1-1.2,0.4c-0.3,0.3-0.4,0.7-0.5,1.2
						c0.3-0.3,0.8-0.5,1.4-0.5C225.2,142.1,225.6,142.2,225.9,142.3z M225.3,144.6c0.2-0.2,0.3-0.4,0.3-0.6c0-0.3-0.1-0.5-0.3-0.6
						c-0.2-0.2-0.4-0.2-0.7-0.2s-0.5,0.1-0.7,0.2c-0.2,0.2-0.3,0.4-0.3,0.6c0,0.3,0.1,0.5,0.3,0.6c0.2,0.2,0.4,0.2,0.7,0.2
						C224.9,144.8,225.1,144.7,225.3,144.6z"/>
					<path class="st10" d="M228.5,145.5c-0.4-0.2-0.7-0.6-0.9-1s-0.3-1-0.3-1.6s0.1-1.2,0.3-1.6c0.2-0.4,0.5-0.8,0.9-1
						s0.8-0.4,1.3-0.4s0.9,0.1,1.3,0.4c0.4,0.2,0.7,0.6,0.9,1s0.3,1,0.3,1.6s-0.1,1.2-0.3,1.6c-0.2,0.4-0.5,0.8-0.9,1
						s-0.8,0.4-1.3,0.4C229.3,145.8,228.9,145.7,228.5,145.5z M230.6,144.2c0.2-0.3,0.3-0.8,0.3-1.4c0-0.6-0.1-1.1-0.3-1.4
						c-0.2-0.3-0.5-0.5-0.8-0.5s-0.6,0.2-0.8,0.5s-0.3,0.8-0.3,1.4c0,0.6,0.1,1.1,0.3,1.4c0.2,0.3,0.5,0.5,0.8,0.5
						C230.2,144.7,230.4,144.5,230.6,144.2z"/>
					<path class="st10" d="M233.2,142.6c-0.3-0.3-0.4-0.7-0.4-1.2s0.1-0.9,0.4-1.2c0.3-0.3,0.6-0.4,1-0.4s0.8,0.1,1,0.4
						c0.3,0.3,0.4,0.7,0.4,1.2s-0.1,0.9-0.4,1.2c-0.3,0.3-0.6,0.4-1,0.4C233.8,143.1,233.5,142.9,233.2,142.6z M234.7,142.2
						c0.1-0.2,0.2-0.4,0.2-0.7s-0.1-0.5-0.2-0.7c-0.1-0.2-0.3-0.2-0.4-0.2c-0.2,0-0.3,0.1-0.4,0.2c-0.1,0.2-0.2,0.4-0.2,0.7
						s0.1,0.5,0.2,0.7c0.1,0.2,0.3,0.2,0.4,0.2S234.6,142.3,234.7,142.2z M237.7,140h1l-3.9,5.7h-1L237.7,140z M237.4,145.6
						c-0.2-0.1-0.4-0.3-0.5-0.6c-0.1-0.2-0.2-0.5-0.2-0.8s0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.6c0.2-0.1,0.5-0.2,0.7-0.2
						c0.4,0,0.8,0.1,1,0.4c0.3,0.3,0.4,0.7,0.4,1.2s-0.1,0.9-0.4,1.2c-0.3,0.3-0.6,0.4-1,0.4C237.9,145.8,237.6,145.7,237.4,145.6z
						M238.6,144.9c0.1-0.2,0.2-0.4,0.2-0.7s-0.1-0.5-0.2-0.7c-0.1-0.2-0.3-0.2-0.4-0.2c-0.2,0-0.3,0.1-0.4,0.2
						c-0.1,0.2-0.2,0.4-0.2,0.7s0.1,0.5,0.2,0.7c0.1,0.2,0.3,0.2,0.4,0.2C238.3,145.1,238.5,145,238.6,144.9z"/>
				</g>
				<g>
					<g>
						<path class="st10" d="M251,136c0,0.1-9.3,0.3-20.8,0.3s-20.8-0.1-20.8-0.3c0-0.1,9.3-0.3,20.8-0.3
							C241.7,135.8,251,135.9,251,136z"/>
					</g>
				</g>
			</g>
		</g>
		<g id="Wins">
			<g>
				<g>
					<path class="cp" d="M229.5,35l2.1,4.3c0.2,0.4,0.6,0.7,1.1,0.8l4.7,0.7c1.2,0.2,1.6,1.6,0.8,2.4l-3.4,3.3
						c-0.3,0.3-0.5,0.8-0.4,1.3l0.8,4.7c0.2,1.2-1,2-2.1,1.5l-4.2-2.2c-0.4-0.2-0.9-0.2-1.3,0l-4.2,2.2c-1,0.5-2.3-0.3-2.1-1.5
						l0.8-4.7c0.1-0.5-0.1-0.9-0.4-1.3l-3.4-3.3c-0.8-0.8-0.4-2.2,0.8-2.4l4.7-0.7c0.5-0.1,0.9-0.4,1.1-0.8L227,35
						C227.5,34,229,34,229.5,35z"/>
				</g>
				<g>
					<path class="st3" d="M224.9,68.7c0.2,0.2,0.2,0.5,0.2,0.8c0,0.4-0.1,0.7-0.3,0.9c-0.2,0.3-0.5,0.5-0.9,0.6s-0.8,0.2-1.3,0.2
						s-0.9-0.1-1.3-0.2c-0.4-0.1-0.6-0.4-0.8-0.6c-0.2-0.3-0.3-0.6-0.3-0.9s0.1-0.6,0.2-0.8c0.2-0.2,0.4-0.4,0.6-0.6
						c-0.2-0.1-0.4-0.3-0.5-0.5s-0.2-0.4-0.2-0.7c0-0.3,0.1-0.6,0.3-0.9c0.2-0.2,0.4-0.4,0.8-0.6c0.3-0.1,0.7-0.2,1.1-0.2
						c0.4,0,0.8,0.1,1.2,0.2c0.3,0.1,0.6,0.3,0.8,0.6c0.2,0.2,0.3,0.5,0.3,0.9c0,0.3-0.1,0.5-0.2,0.7c-0.1,0.2-0.3,0.4-0.5,0.5
						C224.5,68.3,224.8,68.5,224.9,68.7z M223.5,70.1c0.2-0.2,0.3-0.4,0.3-0.6c0-0.3-0.1-0.5-0.3-0.6s-0.5-0.2-0.8-0.2
						s-0.6,0.1-0.8,0.2s-0.3,0.3-0.3,0.6c0,0.3,0.1,0.5,0.3,0.6s0.5,0.2,0.8,0.2C223,70.3,223.3,70.2,223.5,70.1z M222.1,66.5
						c-0.2,0.1-0.2,0.3-0.2,0.5s0.1,0.4,0.2,0.5c0.2,0.1,0.4,0.2,0.6,0.2c0.3,0,0.5-0.1,0.7-0.2s0.2-0.3,0.2-0.5s-0.1-0.4-0.2-0.5
						c-0.2-0.1-0.4-0.2-0.7-0.2C222.4,66.3,222.2,66.4,222.1,66.5z"/>
					<path class="st3" d="M226.9,70.9c-0.4-0.2-0.7-0.6-0.9-1s-0.3-1-0.3-1.6s0.1-1.2,0.3-1.6s0.5-0.8,0.9-1s0.8-0.4,1.3-0.4
						s0.9,0.1,1.3,0.4c0.4,0.2,0.7,0.6,0.9,1s0.3,1,0.3,1.6s-0.1,1.2-0.3,1.6s-0.5,0.8-0.9,1s-0.8,0.4-1.3,0.4S227.3,71.1,226.9,70.9z
						M229,69.7c0.2-0.3,0.3-0.8,0.3-1.4s-0.1-1.1-0.3-1.4s-0.5-0.5-0.8-0.5s-0.6,0.2-0.8,0.5s-0.3,0.8-0.3,1.4s0.1,1.1,0.3,1.4
						s0.5,0.5,0.8,0.5C228.6,70.1,228.8,70,229,69.7z"/>
					<path class="st3" d="M231.6,68.1c-0.3-0.3-0.4-0.7-0.4-1.2s0.1-0.9,0.4-1.2c0.3-0.3,0.6-0.4,1-0.4s0.8,0.1,1,0.4
						c0.3,0.3,0.4,0.7,0.4,1.2s-0.1,0.9-0.4,1.2c-0.3,0.3-0.6,0.4-1,0.4C232.2,68.5,231.9,68.4,231.6,68.1z M233.1,67.6
						c0.1-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7c-0.1-0.2-0.3-0.2-0.4-0.2c-0.2,0-0.3,0.1-0.4,0.2c-0.1,0.2-0.2,0.4-0.2,0.7
						c0,0.3,0.1,0.5,0.2,0.7c0.1,0.2,0.3,0.2,0.4,0.2C232.8,67.8,233,67.8,233.1,67.6z M236.1,65.4h1l-3.9,5.7h-1L236.1,65.4z
						M235.8,71c-0.2-0.1-0.4-0.3-0.5-0.6c-0.1-0.2-0.2-0.5-0.2-0.8s0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.6c0.2-0.1,0.5-0.2,0.7-0.2
						c0.4,0,0.8,0.1,1,0.4c0.3,0.3,0.4,0.7,0.4,1.2s-0.1,0.9-0.4,1.2c-0.3,0.3-0.6,0.4-1,0.4C236.3,71.2,236,71.2,235.8,71z M237,70.3
						c0.1-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7c-0.1-0.2-0.3-0.2-0.4-0.2c-0.2,0-0.3,0.1-0.4,0.2c-0.1,0.2-0.2,0.4-0.2,0.7
						c0,0.3,0.1,0.5,0.2,0.7c0.1,0.2,0.3,0.2,0.4,0.2C236.7,70.6,236.9,70.5,237,70.3z"/>
				</g>
				<g>
					<g>
						<path class="st3" d="M249.2,61.5c0,0.1-9.3,0.3-20.8,0.3s-20.8-0.1-20.8-0.3c0-0.1,9.3-0.3,20.8-0.3S249.2,61.4,249.2,61.5z"/>
					</g>
				</g>
			</g>
		</g>
		<g id="Chart">
			<g>
				<g>
					<g>
						<rect x="358.3" y="55.9" class="cp" width="9.2" height="17.6"/>
					</g>
					<g>
						<rect x="372.2" y="50" class="cp" width="9.2" height="23.6"/>
					</g>
					<g>
						<rect x="385.9" y="45" class="cp" width="9.2" height="28.5"/>
					</g>
				</g>
				<g>
					<path class="st10" d="M373.2,86v0.9l-2.1,4.9h-1.4l2.1-4.7h-1.9V88h-1.2v-2H373.2z"/>
					<path class="st10" d="M374.8,91.5c-0.4-0.2-0.7-0.6-0.9-1c-0.2-0.4-0.3-1-0.3-1.6s0.1-1.2,0.3-1.6c0.2-0.4,0.5-0.8,0.9-1
						s0.8-0.4,1.3-0.4s0.9,0.1,1.3,0.4c0.4,0.2,0.7,0.6,0.9,1c0.2,0.4,0.3,1,0.3,1.6s-0.1,1.2-0.3,1.6c-0.2,0.4-0.5,0.8-0.9,1
						s-0.8,0.4-1.3,0.4C375.6,91.8,375.1,91.7,374.8,91.5z M376.9,90.2c0.2-0.3,0.3-0.8,0.3-1.4s-0.1-1.1-0.3-1.4
						c-0.2-0.3-0.5-0.5-0.8-0.5s-0.6,0.2-0.8,0.5c-0.2,0.3-0.3,0.8-0.3,1.4s0.1,1.1,0.3,1.4c0.2,0.3,0.5,0.5,0.8,0.5
						S376.7,90.6,376.9,90.2z"/>
					<path class="st10" d="M379.5,88.7c-0.3-0.3-0.4-0.7-0.4-1.2s0.1-0.9,0.4-1.2c0.3-0.3,0.6-0.4,1-0.4s0.8,0.1,1,0.4
						c0.3,0.3,0.4,0.7,0.4,1.2s-0.1,0.9-0.4,1.2c-0.3,0.3-0.6,0.4-1,0.4S379.7,88.9,379.5,88.7z M380.9,88.2c0.1-0.2,0.2-0.4,0.2-0.7
						c0-0.3-0.1-0.5-0.2-0.7s-0.3-0.2-0.4-0.2c-0.2,0-0.3,0.1-0.4,0.2c-0.1,0.2-0.2,0.4-0.2,0.7c0,0.3,0.1,0.5,0.2,0.7
						s0.3,0.2,0.4,0.2C380.7,88.4,380.8,88.3,380.9,88.2z M383.9,86h1l-3.9,5.7h-1L383.9,86z M383.7,91.6c-0.2-0.1-0.4-0.3-0.5-0.6
						c-0.1-0.2-0.2-0.5-0.2-0.8s0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.6c0.2-0.1,0.5-0.2,0.7-0.2c0.4,0,0.8,0.1,1,0.4
						c0.3,0.3,0.4,0.7,0.4,1.2s-0.1,0.9-0.4,1.2c-0.3,0.3-0.6,0.4-1,0.4C384.1,91.8,383.9,91.7,383.7,91.6z M384.8,90.9
						c0.1-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7s-0.3-0.2-0.4-0.2c-0.2,0-0.3,0.1-0.4,0.2c-0.1,0.2-0.2,0.4-0.2,0.7
						c0,0.3,0.1,0.5,0.2,0.7s0.3,0.2,0.4,0.2C384.6,91.1,384.7,91,384.8,90.9z"/>
				</g>
				<g>
					<g>
						<path class="st10" d="M393.4,79.4c0,0.1-7.4,0.3-16.5,0.3s-16.5-0.1-16.5-0.3c0-0.1,7.4-0.3,16.5-0.3
							C385.9,79.2,393.4,79.3,393.4,79.4z"/>
					</g>
				</g>
				<g>
					<g>
						<g>
							<path class="st10" d="M387.1,36c0,0-0.3,0.3-0.9,0.9c-0.7,0.6-1.6,1.4-2.6,2.3c-2.2,1.9-5.3,4.6-8.7,7.5l-0.5,0.4l-0.1,0.1
								l-0.1-0.1c-1.4-1.3-2.7-2.5-3.9-3.6h0.2c-2.3,1.9-4.3,3.5-5.7,4.7c-0.7,0.5-1.2,1-1.7,1.3c-0.4,0.3-0.6,0.5-0.6,0.4
								c0,0,0.2-0.2,0.5-0.5c0.4-0.4,0.9-0.8,1.6-1.4c1.4-1.2,3.4-2.9,5.6-4.8l0.1-0.1l0.1,0.1c1.2,1.1,2.5,2.3,3.9,3.6H374l0.5-0.4
								c3.4-2.9,6.5-5.5,8.8-7.4c1.1-0.9,2-1.6,2.7-2.2C386.7,36.3,387.1,36,387.1,36z"/>
						</g>
					</g>
				</g>
				<g>
					<g>
						<g>
							<path class="st10" d="M387.1,42.5c-0.1,0-0.1-0.7-0.1-1.9s-0.1-2.9-0.1-4.7l0.2,0.2c-0.1,0-0.2,0-0.3,0c-1.9,0-3.7,0-4.9-0.1
								c-1.3,0-2-0.1-2-0.1c0-0.1,0.8-0.1,2-0.1c1.3,0,3-0.1,4.9-0.1c0.1,0,0.2,0,0.3,0h0.2v0.2c0,1.9,0,3.5-0.1,4.7
								C387.2,41.8,387.1,42.5,387.1,42.5z"/>
						</g>
					</g>
				</g>
			</g>
		</g>
		<g id="Device">
		</g>
		<g id="Character_1_">
			<g>
				<g>
					<g>
						<g>
							<path class="st3" d="M130.6,256.1c-3.6-17.1-6.9-41.1-12.7-49.8c-4.7-7-16.2-14.4-19.1-27.4c-1.6-6.9-3.2-12.5-4.4-16.1
								c-0.9-2.9-3.7-4.9-6.7-4.9H54.2c-3.6,0-6.5,3.1-6.3,6.7c0.5,7.7,1,20-0.5,24.6c-2.2,6.9-5.8,19.7-4.4,25.8
								c0.5,2.4,9.4,28.8,14.5,44.3c2.4,7.4,4.1,15,5,22.7l5.5,45.6l79.9-1.8C147.8,325.9,133.4,268.9,130.6,256.1z M71.3,199.3
								c-0.5,0.4-1.2,0-1.6-0.4c-3.6-2.8-6.5-6.5-8.3-10.8c-1-2.6-1.2-5.4-1.4-8.1c-0.2-2.8-0.4-5.7,0.1-8.5s1.9-5.5,4.3-7
								c1-0.6,2.3-1.1,3.3-0.5c1.2,0.6,1.5,2.1,1.6,3.4l1.7,19.3c0.2,3.7,0.5,7.9,0.6,11.4C71.7,198.6,71.7,199.1,71.3,199.3z
								M89.8,187.4c-1,4.4-3.4,8.5-6.5,11.9c-0.4,0.4-1,0.9-1.6,0.6c-0.4-0.2-0.5-0.7-0.5-1.2c-0.4-3.4-0.7-7.7-1.1-11.3L78.8,168
								c0-1.3,0.1-2.8,1.1-3.6c0.9-0.7,2.3-0.5,3.4,0c2.6,1.2,4.4,3.6,5.3,6.3c1,2.7,1.2,5.5,1.4,8.3
								C90.2,181.9,90.4,184.7,89.8,187.4z"/>
						</g>
					</g>
				</g>
				<g>
					<g>
						<g>
							<path d="M134.8,256.1c-3.6-17.1-6.9-41.1-12.7-49.8c-4.7-7-16.2-14.4-19.1-27.4c-1.6-6.9-3.2-12.5-4.4-16.1
								c-0.9-2.9-3.7-4.9-6.7-4.9H58.3c-3.6,0-6.5,3.1-6.3,6.7c0.5,7.7,1,20-0.5,24.6c-2.2,6.9-5.8,19.7-4.4,25.8
								c0.5,2.4,9.4,28.8,14.5,44.3c2.4,7.4,4.1,15,5,22.7l5.5,45.6l79.9-1.8C152,325.9,137.5,268.9,134.8,256.1z M75.5,199.3
								c-0.5,0.4-1.2,0-1.6-0.4c-3.6-2.8-6.5-6.5-8.3-10.8c-1-2.6-1.2-5.4-1.4-8.1c-0.2-2.8-0.4-5.7,0.1-8.5s1.9-5.5,4.3-7
								c1-0.6,2.3-1.1,3.3-0.5c1.2,0.6,1.5,2.1,1.6,3.4l1.7,19.3c0.2,3.7,0.5,7.9,0.6,11.4C75.8,198.6,75.8,199.1,75.5,199.3z
								M93.9,187.4c-1,4.4-3.4,8.5-6.5,11.9c-0.4,0.4-1,0.9-1.6,0.6c-0.4-0.2-0.5-0.7-0.5-1.2c-0.4-3.4-0.7-7.7-1.1-11.3L82.9,168
								c0-1.3,0.1-2.8,1.1-3.6c0.9-0.7,2.3-0.5,3.4,0c2.6,1.2,4.4,3.6,5.3,6.3c1,2.7,1.2,5.5,1.4,8.3
								C94.3,181.9,94.6,184.7,93.9,187.4z"/>
						</g>
					</g>
				</g>
				<g>
					<g>
						<g>
							<g>
								<path class="st3" d="M126.8,234.8c0,0.1-16.1,0.3-36.1,0.3c-19.9,0-36.1-0.1-36.1-0.3c0-0.1,16.1-0.3,36.1-0.3
									C110.6,234.6,126.8,234.7,126.8,234.8z"/>
							</g>
						</g>
					</g>
				</g>
				<g>
					<g>
						<g>
							<g>
								<path class="st12" d="M154.4,336.7c0,0-16.8-67.6-19.5-80.4c-3.6-17.1-6.9-41.1-12.7-49.8c-4.7-7-16.2-14.4-19.1-27.4
									c-1.6-6.9-3.2-12.5-4.4-16.1c-0.9-2.9-3.7-4.9-6.7-4.9"/>
							</g>
						</g>
					</g>
					<g>
						<g>
							<g>
								<g>
									<path class="st3" d="M92,158.1c0,0,0.2,0,0.5,0s0.8,0.1,1.5,0.2c1.2,0.3,3.1,1.2,4.4,3.4c1.9,4.6,3.5,11.5,5.7,19.9
										c0.7,2.1,1.7,4.1,2.9,6.2c1.2,2,2.7,4,4.4,6c1.7,2,3.5,3.9,5.4,5.9s3.9,4,5.6,6.4s2.7,5.3,3.7,8.1c1,2.9,1.8,5.9,2.5,9
										c1.5,6.1,2.7,12.6,4,19.1c1.3,6.6,2.4,13,3.9,19.1s2.9,12.1,4.2,17.7c2.7,11.3,5.2,21.4,7.2,29.9c2.1,8.5,3.7,15.4,4.9,20.2
										c0.6,2.4,1,4.2,1.3,5.5c0.1,0.6,0.2,1.1,0.3,1.4s0.1,0.5,0.1,0.5s-0.1-0.2-0.1-0.5c-0.1-0.3-0.2-0.8-0.4-1.4
										c-0.3-1.3-0.8-3.1-1.4-5.5c-1.2-4.8-2.9-11.6-5-20.1s-4.6-18.6-7.4-29.9c-1.3-5.6-2.8-11.6-4.2-17.7
										c-1.5-6.1-2.7-12.6-3.9-19.1c-1.3-6.6-2.5-13-4-19.1c-0.7-3.1-1.5-6-2.5-8.9s-2-5.6-3.6-8s-3.6-4.4-5.5-6.4s-3.7-3.9-5.4-5.9
										c-1.7-2-3.2-4-4.4-6.1c-1.2-2.1-2.2-4.2-2.9-6.3c-0.7-2.1-1.1-4.2-1.5-6.1c-0.5-1.9-0.9-3.7-1.4-5.4s-0.9-3.2-1.3-4.7
										c-0.5-1.4-0.8-2.8-1.3-3.8c-1.2-2.2-3-3.1-4.2-3.4C92.7,158.1,92,158.2,92,158.1z"/>
								</g>
							</g>
						</g>
					</g>
				</g>
				<g>
					<g>
						<g>
							<g>
								<path class="st12" d="M150.1,336.7c0,0-17-67.6-19.7-80.4c-3.6-17.1-6.9-41.1-12.7-49.8c-4.7-7-16.2-14.4-19.1-27.4
									c-1.6-6.9-3.2-12.5-4.4-16.1c-0.9-2.9-3.7-4.9-6.7-4.9"/>
							</g>
						</g>
					</g>
				</g>
				<g>
					<g>
						<g>
							<g>
								<rect x="154.4" y="353.8" class="st5" width="7.7" height="84.7"/>
							</g>
						</g>
					</g>
					<g>
						<g>
							<g>
								<rect x="150.9" y="371.2" class="st13" width="14.4" height="61"/>
							</g>
						</g>
					</g>
					<g>
						<g>
							<g>
								<path class="st5" d="M76.7,340.1c0,0,46.7,14.9,80.4,14.9s72.6-14.9,72.6-14.9H74.6"/>
							</g>
						</g>
					</g>
					<g>
						<g>
							<g>
								<path d="M67.3,319.6V335c0,3.4,2.8,6.1,6.1,6.1h153.3c3.4,0,6.1-2.8,6.1-6.1v-7.4c0-3.4-2.7-6.1-6.1-6.1L67.3,319.6"/>
							</g>
						</g>
					</g>
					<g>
						<g>
							<g>
								<path class="st13" d="M84.6,433.9c0,0,30.7-29.2,74.3-28.5c43.5,0.6,73.1,28.5,73.1,28.5l-4.3,4.8c0,0-34.7-19.9-71.4-18.3
									s-68,18.3-68,18.3L84.6,433.9z"/>
							</g>
						</g>
					</g>
					<g>
						<g>
							<g>
								<path d="M93.6,437.8c7.4,9.7-3.6,20.7-13.3,13.3C72.9,441.4,83.9,430.4,93.6,437.8z"/>
							</g>
						</g>
					</g>
					<g>
						<g>
							<g>
								<path d="M167.2,444c0,5-4.1,9.1-9.1,9.1S149,449,149,444s4.1-9.1,9.1-9.1S167.2,438.9,167.2,444z"/>
							</g>
						</g>
					</g>
					<g>
						<g>
							<g>
								<path d="M243.3,444c0,5-4.1,9.1-9.1,9.1s-9.1-4.1-9.1-9.1s4.1-9.1,9.1-9.1C239.3,434.9,243.3,438.9,243.3,444z"/>
							</g>
						</g>
					</g>
					<g>
						<g>
							<g>
								<g>
									<path d="M130.1,273.9l1,4.3H223c1.8,0,3.4-1.3,3.7-3.1l0,0c0.3-1.7-0.6-3.3-2.1-4c-5.8-2.7-21.9-8.5-50.6-8.1
										c-40.7,0.5-43.9,6.9-43.9,6.9V273.9z"/>
								</g>
							</g>
						</g>
						<g>
							<g>
								<g>
									<path d="M195.7,274.7c-0.2,1.4-15.4,53-15.4,53h-15.7l15.2-53H195.7z"/>
								</g>
							</g>
						</g>
						<g>
							<g>
								<g>
									<g>
										<path class="st3" d="M224.6,278.2c0,0.1-20.7,0.3-46.2,0.3s-46.2-0.1-46.2-0.3c0-0.1,20.7-0.3,46.2-0.3
											C203.9,278,224.6,278.1,224.6,278.2z"/>
									</g>
								</g>
							</g>
						</g>
					</g>
					<g>
						<g>
							<g>
								<g>
									<path class="st10" d="M226.9,341.1c0,0-0.1,0.1-0.4,0.1c-0.3,0.1-0.6,0.2-1.1,0.4c-1,0.3-2.4,0.8-4.1,1.4
										c-3.6,1.3-8.8,2.9-15.3,4.8c-3.3,0.9-6.8,1.9-10.7,2.7c-1.9,0.4-3.9,1-6,1.3c-2.1,0.4-4.2,0.8-6.4,1.2
										c-8.8,1.4-18.7,2.3-29,2c-2.6-0.1-5.1-0.1-7.6-0.3c-1.3-0.1-2.5-0.2-3.7-0.3c-0.6,0-1.2-0.1-1.8-0.1
										c-0.6-0.1-1.2-0.1-1.8-0.2c-2.4-0.3-4.8-0.6-7.1-0.9c-2.3-0.4-4.6-0.8-6.8-1.1c-2.2-0.3-4.3-0.8-6.4-1.2
										c-2.1-0.4-4.1-0.8-6-1.2c-3.9-0.9-7.5-1.8-10.8-2.6c-3.3-0.9-6.2-1.7-8.8-2.4c-2.6-0.7-4.8-1.4-6.6-1.9
										c-1.8-0.5-3.2-1-4.2-1.2c-0.5-0.1-0.8-0.3-1.1-0.3c-0.2-0.1-0.4-0.1-0.4-0.1s0.1,0,0.4,0.1s0.6,0.2,1.1,0.3
										c1,0.3,2.4,0.7,4.2,1.1c1.8,0.5,4.1,1.1,6.6,1.8c2.6,0.7,5.6,1.4,8.8,2.3c3.3,0.8,6.9,1.6,10.8,2.5c1.9,0.4,4,0.8,6,1.2
										c2.1,0.4,4.2,0.9,6.4,1.2c2.2,0.4,4.5,0.7,6.8,1.1c2.3,0.3,4.7,0.6,7.1,0.9c0.6,0.1,1.2,0.1,1.8,0.2c0.6,0,1.2,0.1,1.8,0.1
										c1.2,0.1,2.5,0.2,3.7,0.3c2.5,0.2,5,0.3,7.6,0.3c10.3,0.2,20.1-0.6,28.9-2c2.2-0.4,4.3-0.8,6.4-1.2c2.1-0.4,4.1-0.9,6-1.3
										c3.9-0.8,7.5-1.8,10.7-2.7c6.5-1.8,11.7-3.4,15.3-4.6c1.8-0.6,3.2-1,4.1-1.3c0.5-0.1,0.8-0.3,1.1-0.3
										C226.7,341.2,226.9,341.1,226.9,341.1z"/>
								</g>
							</g>
						</g>
					</g>
					<g>
						<g>
							<g>
								<g>
									<path class="st3" d="M165.3,371.2c0,0.1-3.2,0.3-7.2,0.3s-7.2-0.1-7.2-0.3c0-0.1,3.2-0.3,7.2-0.3
										C162.1,370.9,165.3,371,165.3,371.2z"/>
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>
			<g>
				<g>
					<ellipse class="st3" cx="186.3" cy="135.2" rx="11.3" ry="11.3"/>
				</g>
			</g>
			<g>
				<g>
					<g>
						<g>
							<g>
								<g>
									<path d="M163.8,92.4c0.3-4.5,3.3-4.8,5.6-4c3-1.8,7.3-1.2,9.6,1.4c2.2-1,4.9-0.6,6.9,0.8s3.1,3.9,2.9,6.3
										c2.4-0.1,4.9,1.6,5.7,3.9s0,5.1-1.9,6.6c2.5,1.2,3.8,4.4,2.9,7c-0.9,2.6-4,4.2-6.7,3.6l-25.6-20.8
										C161.7,95.3,163.6,94.8,163.8,92.4z"/>
								</g>
							</g>
						</g>
					</g>
				</g>
				<g>
					<g>
						<g>
							<g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<path class="st14" d="M190.5,118.8c0.6-12.5-8.4-23.3-20.8-25l-1.2-0.2c-13.4-1-21.7,9.9-22.3,23.2l-3.9,44.4l-0.7,8.4
														l29.8,10.2l2-17.5c0,0,13-0.3,14.8-13C189.1,143.3,189.9,130.4,190.5,118.8z"/>
												</g>
											</g>
										</g>
									</g>
								</g>
							</g>
						</g>
						<g>
							<g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<path class="st15" d="M173.5,162.4c0,0-8.2-0.6-16.1-7.1c0,0,2.7,9.7,15.6,10.2L173.5,162.4z"/>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
								</g>
							</g>
						</g>
						<g>
							<g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<path d="M185,129.1c-0.1,1-1,1.7-2,1.6c-1-0.1-1.8-0.9-1.7-1.9s1-1.7,2-1.6C184.3,127.3,185.1,128.2,185,129.1z"/>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
								</g>
							</g>
						</g>
						<g>
							<g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<path d="M169,127.6c-0.1,1-1,1.7-2,1.6s-1.8-0.9-1.7-1.9c0.1-1,1-1.7,2-1.6C168.3,125.8,169.1,126.7,169,127.6z"/>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
								</g>
							</g>
						</g>
						<g>
							<g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<path d="M172.8,138c0-0.1,1.3-0.2,3.3-0.2c0.5,0,1,0,1.1-0.4c0.2-0.3,0-0.9-0.1-1.5c-0.3-1.3-0.6-2.6-1-4
																			c-1.3-5.7-2.2-10.4-2-10.4c0.2-0.1,1.5,4.5,2.8,10.2c0.3,1.4,0.6,2.7,0.9,4c0.1,0.6,0.4,1.3,0,2
																			c-0.2,0.4-0.6,0.6-0.9,0.6c-0.3,0.1-0.6,0-0.9,0C174.1,138.3,172.8,138.1,172.8,138z"/>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
								</g>
							</g>
						</g>
						<g>
							<g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<path d="M166.2,143c0.3-0.1,0.9,2,3.1,2.9c2.2,1,4.3,0,4.4,0.3c0,0.1-0.4,0.5-1.3,0.8c-0.9,0.3-2.3,0.4-3.7-0.1
																			c-1.4-0.6-2.2-1.7-2.5-2.5C165.9,143.7,166,143.1,166.2,143z"/>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
								</g>
							</g>
						</g>
						<g>
							<g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<path d="M171.1,118.9c-0.3,0.5-1.9-0.2-4-0.4c-2-0.3-3.8,0-4-0.6c-0.1-0.3,0.3-0.7,1.1-1s1.9-0.6,3.1-0.4
															c1.2,0.2,2.3,0.6,2.9,1.2C171,118.1,171.3,118.6,171.1,118.9z"/>
													</g>
												</g>
											</g>
										</g>
									</g>
								</g>
							</g>
						</g>
						<g>
							<g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<path d="M187.1,120.9c-0.4,0.4-1.7-0.1-3.3-0.2c-1.6-0.2-3,0.2-3.3-0.3c-0.1-0.2,0.1-0.7,0.7-1.1
															c0.6-0.4,1.6-0.7,2.8-0.6c1.1,0.1,2.1,0.5,2.6,1S187.3,120.6,187.1,120.9z"/>
													</g>
												</g>
											</g>
										</g>
									</g>
								</g>
							</g>
						</g>
						<g>
							<g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<path d="M190,112.8c0,0-2.3-8.4-5.9-10.8c-4.4-2.9-7,2-11.3,0.2s-8.4-4.6-13.4-1.7c-5.5,3.1-3.5,6.1-4.5,11.7
														c-4.3,22.8-9.3,28.7-9.1,17.8c0-1.2-3.1-2.6-3-7.6c0.2-6.6,1-14.5,3.1-18.9c1.7-3.7,4.3-6.1,8.6-8.5
														c6.9-3.8,15.5-2.8,21.3-1.5C181.5,94.7,191.9,102.9,190,112.8z"/>
												</g>
											</g>
										</g>
									</g>
								</g>
							</g>
						</g>
						<g>
							<g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<path class="st14" d="M146.6,129.6c0.2-2-1.3-3.8-3.3-3.8c-2.4-0.1-5.2,0.9-5.7,5.9c-0.9,8.8,8.1,7.8,8.1,7.6
														C145.8,139.1,146.3,133.3,146.6,129.6z"/>
												</g>
											</g>
										</g>
									</g>
								</g>
							</g>
						</g>
						<g>
							<g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<path class="st15" d="M144.1,135.8c0,0-0.2,0.1-0.4,0.2c-0.3,0.1-0.7,0.1-1.1-0.1c-0.9-0.4-1.5-2-1.4-3.6
														c0.1-0.8,0.3-1.5,0.6-2.1s0.8-1,1.3-1.1s0.8,0.2,0.9,0.5c0.1,0.3,0,0.4,0.1,0.4c0,0,0.2-0.1,0.2-0.5
														c0-0.2-0.1-0.4-0.3-0.6c-0.2-0.2-0.5-0.3-0.8-0.3c-0.7,0-1.3,0.5-1.7,1.2s-0.7,1.5-0.8,2.4c-0.1,1.8,0.6,3.5,1.9,4
														c0.6,0.2,1.1,0.1,1.4-0.1C144.1,136,144.2,135.8,144.1,135.8z"/>
												</g>
											</g>
										</g>
									</g>
								</g>
							</g>
						</g>
					</g>
					<g>
						<g>
							<g>
								<g>
									<g>
										<g>
											<path d="M149,112.8c1,0.9,2.1,1.9,3.5,2.2c1.3,0.3,3-0.3,3.4-1.6c0.2-0.6,0.1-1.3,0.6-1.7c0.5-0.5,1.4-0.2,2.1,0
												c2.5,0.6,5.3-0.9,6.3-3.2c0.5,1.1,2,1.2,3.1,0.7s1.8-1.5,2.7-2.2c0.9-0.8,2.1-1.4,3.2-1.1c0.6,0.2,1.2,0.6,1.8,0.7
												c1.2,0,1.9-1.2,2.2-2.3c0.3-1.1,0.6-2.4,1.7-2.9c1-0.5,2.1,0,3.1,0.3s2.4,0.2,2.8-0.8c0.2-0.5,0.1-1.1-0.2-1.6
												c-1.1-2.3-3.9-3.7-6.4-3c-0.5-3-2.9-5.6-5.8-6.3c-2.9-0.7-6.3,0.5-8,3c-1.6-1.9-4.2-3-6.7-2.7c-2.5,0.2-4.8,1.8-6.1,3.9
												c-2-0.8-4.6-0.3-6.1,1.2c-1.6,1.6-2.1,4.1-1.3,6.1c-1.7,0.7-3.2,2.1-3.5,3.9s1.3,3.8,3.1,3.6c-0.9,1.3-0.6,3.3,0.7,4.3
												s3.3,0.8,4.3-0.4"/>
										</g>
									</g>
								</g>
							</g>
						</g>
					</g>
				</g>
				<g>
					<g>
						<g>
							<g>
								<g>
									<g>
										<path class="st3" d="M144.1,108.6c0,0-0.3,0.7,0.3,1.7c0.3,0.5,0.8,1,1.6,1.3c0.8,0.2,1.8,0.2,2.6-0.4l-0.4-0.1
											c0.3,0.8,0.8,1.6,1.5,2.4c0.4,0.5,0.9,0.9,1.5,1.2c0.5,0.3,1.2,0.4,1.7,0.4c1.1,0,2.2-0.4,2.7-1.1c0.6-0.7,0.8-1.3,0.9-1.7
											c0.1-0.4,0.2-0.7,0.2-0.7s-0.1,0.2-0.3,0.6s-0.5,1-1,1.6c-1,1.1-3.8,1.4-5.3-0.6c-0.6-0.7-1.1-1.5-1.4-2.3l-0.1-0.3
											l-0.2,0.2c-0.7,0.5-1.5,0.6-2.2,0.4s-1.2-0.6-1.5-1C144,109.3,144.1,108.6,144.1,108.6z"/>
									</g>
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>
			<g>
				<g>
					<g>
						<g>
							<g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<g>
																			<g>
																				<g>
																					<g>
																						<g>
																							<path class="st3" d="M222.5,401l-8.9,26.7c0,0,24.2,18,23,23.1l-52.4-15.3l12.1-42.2L222.5,401z"/>
																						</g>
																					</g>
																				</g>
																			</g>
																		</g>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
								</g>
							</g>
							<g class="st16">
								<g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<g>
																			<g>
																				<g>
																					<g>
																						<g>
																							<path d="M222.5,401l-8.9,26.7c0,0,24.2,18,23,23.1l-52.4-15.3l12.1-42.2L222.5,401z"/>
																						</g>
																					</g>
																				</g>
																			</g>
																		</g>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
								</g>
							</g>
							<g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<g>
																			<g>
																				<g>
																					<g>
																						<g>
																							<g>
																								<path d="M198.1,420.7c-1.1,0-2.1,0.9-2.2,2c-0.1,1.1,0.9,2.2,2,2.2s2.4-1,2.3-2.1c-0.1-1.1-1.2-2.3-2.3-2.1"
																									/>
																							</g>
																						</g>
																					</g>
																				</g>
																			</g>
																		</g>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
								</g>
							</g>
							<g class="st17">
								<g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<g>
																			<g>
																				<g>
																					<g>
																						<g>
																							<path class="st2" d="M184.3,435.4l1.4-4.2l49.7,16.3c0,0,2,1.7,1.3,3.3L184.3,435.4z"/>
																						</g>
																					</g>
																				</g>
																			</g>
																		</g>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
								</g>
							</g>
							<g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<g>
																			<g>
																				<g>
																					<g>
																						<g>
																							<g>
																								<path d="M214.6,427.7c-0.1,0.3-1.4,0-2.9,0.4s-2.5,1.3-2.7,1.2c-0.2-0.1,0.6-1.5,2.4-2.1
																									C213.3,426.7,214.7,427.5,214.6,427.7z"/>
																							</g>
																						</g>
																					</g>
																				</g>
																			</g>
																		</g>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
								</g>
							</g>
							<g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<g>
																			<g>
																				<g>
																					<g>
																						<g>
																							<g>
																								<path d="M219.4,431.6c0,0.3-1.2,0.4-2.4,1.2c-1.2,0.8-1.8,1.9-2,1.8c-0.3,0,0-1.6,1.5-2.6
																									S219.5,431.3,219.4,431.6z"/>
																							</g>
																						</g>
																					</g>
																				</g>
																			</g>
																		</g>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
								</g>
							</g>
							<g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<g>
																			<g>
																				<g>
																					<g>
																						<g>
																							<g>
																								<path d="M220.6,439.1c-0.2,0-0.2-1.4,0.8-2.6c1.1-1.2,2.4-1.4,2.5-1.2c0.1,0.3-0.9,0.8-1.8,1.8
																									C221.3,438.1,220.9,439.1,220.6,439.1z"/>
																							</g>
																						</g>
																					</g>
																				</g>
																			</g>
																		</g>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
								</g>
							</g>
							<g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<g>
																			<g>
																				<g>
																					<g>
																						<g>
																							<g>
																								<path d="M215.9,421.4c-0.2,0.2-1.2-0.5-2.7-0.8c-1.5-0.4-2.7-0.2-2.8-0.5c-0.1-0.2,1.3-0.9,3-0.4
																									C215.2,420,216.1,421.2,215.9,421.4z"/>
																							</g>
																						</g>
																					</g>
																				</g>
																			</g>
																		</g>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
								</g>
							</g>
						</g>
					</g>
				</g>
				<g>
					<g>
						<g>
							<g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<g>
																			<g>
																				<g>
																					<g>
																						<g>
																							<path class="st3" d="M230.9,407.9l-1.1,28.1c0,0,28.2,10.6,28.5,15.8l-54.6-0.2l-0.1-43.9L230.9,407.9z"/>
																						</g>
																					</g>
																				</g>
																			</g>
																		</g>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
								</g>
							</g>
							<g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<g>
																			<g>
																				<g>
																					<g>
																						<g>
																							<g>
																								<path d="M212.9,433.6c-1.1,0.3-1.8,1.5-1.6,2.5c0.2,1.1,1.4,1.8,2.5,1.6c1.1-0.3,2-1.7,1.6-2.7
																									s-1.8-1.8-2.8-1.3"/>
																							</g>
																						</g>
																					</g>
																				</g>
																			</g>
																		</g>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
								</g>
							</g>
							<g class="st17">
								<g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<g>
																			<g>
																				<g>
																					<g>
																						<g>
																							<path class="st2" d="M203.7,451.6l0.2-4.4l52.3,1.8c0,0,2.4,1.1,2.1,2.8L203.7,451.6z"/>
																						</g>
																					</g>
																				</g>
																			</g>
																		</g>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
								</g>
							</g>
							<g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<g>
																			<g>
																				<g>
																					<g>
																						<g>
																							<g>
																								<path d="M230.7,435.7c0,0.3-1.3,0.4-2.7,1.2c-1.3,0.8-2,2-2.3,1.9c-0.2-0.1,0.2-1.6,1.8-2.7
																									C229.1,435.2,230.7,435.5,230.7,435.7z"/>
																							</g>
																						</g>
																					</g>
																				</g>
																			</g>
																		</g>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
								</g>
							</g>
							<g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<g>
																			<g>
																				<g>
																					<g>
																						<g>
																							<g>
																								<path d="M236.4,438.1c0.1,0.3-1.1,0.7-2,1.8c-0.9,1.1-1.2,2.3-1.4,2.3c-0.3,0-0.4-1.5,0.7-2.9
																									C234.8,438,236.4,437.9,236.4,438.1z"/>
																							</g>
																						</g>
																					</g>
																				</g>
																			</g>
																		</g>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
								</g>
							</g>
							<g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<g>
																			<g>
																				<g>
																					<g>
																						<g>
																							<g>
																								<path d="M239.6,445c-0.2,0.1-0.6-1.3,0.1-2.7c0.7-1.5,1.9-2,2.1-1.8c0.1,0.2-0.7,1-1.2,2.2
																									C240,443.9,239.9,445,239.6,445z"/>
																							</g>
																						</g>
																					</g>
																				</g>
																			</g>
																		</g>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
								</g>
							</g>
							<g>
								<g>
									<g>
										<g>
											<g>
												<g>
													<g>
														<g>
															<g>
																<g>
																	<g>
																		<g>
																			<g>
																				<g>
																					<g>
																						<g>
																							<g>
																								<path d="M230.2,429.3c-0.1,0.2-1.3-0.1-2.8,0s-2.7,0.6-2.8,0.3c-0.2-0.2,1-1.2,2.8-1.3
																									C229.1,428.2,230.3,429.1,230.2,429.3z"/>
																							</g>
																						</g>
																					</g>
																				</g>
																			</g>
																		</g>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</g>
											</g>
										</g>
									</g>
								</g>
							</g>
						</g>
					</g>
				</g>
				<g>
					<g>
						<g>
							<path class="st3" d="M164.6,268.1c0,0,64,14.8,72.2,26.2c6.9,9.6-12.5,115.4-12.5,115.4l-35.2-2.5l4.7-92.9L120.4,291
								l11.1-28.2L164.6,268.1z"/>
						</g>
					</g>
				</g>
				<g>
					<g>
						<g>
							<path class="st3" d="M170.2,278.1c0,0,66.9,16.3,73.7,27.8c3.2,5.4,1.1,23.1-0.1,31.4c-0.8,5.6-12.6,84.4-12.6,84.4h-28.9
								l0.6-89l0,0l-69.5-14c0,0-37.3,2.8-38.8-13.1l15.6-21.1l36.8-18.2L170.2,278.1z"/>
						</g>
					</g>
				</g>
				<g>
					<g>
						<g>
							<g>
								<path d="M165.1,276.1c0,0,0.3,0.1,1,0.3c0.7,0.2,1.6,0.4,2.8,0.8c2.5,0.7,6.2,1.7,10.9,3.1c4.7,1.3,10.5,2.9,17.2,4.9
									s14.3,4.4,22.5,7.5c4.1,1.5,8.4,3.3,12.8,5.3c1.1,0.5,2.2,1,3.3,1.5s2.2,1.1,3.3,1.8c1.1,0.7,2.1,1.4,3,2.3s1.8,1.9,2.4,3
									c2.3,10.3,0.9,21.5-0.8,32.9c-0.7,5.8-1.7,11.7-2.6,17.7c-0.9,6-1.8,12.2-2.8,18.4c-1.9,12.5-3.8,25.5-5.8,38.8
									c-0.4,2.5-0.8,5-1.1,7.5v0.2H231c-9.9,0-19.5,0-28.9,0h-0.2v-0.2c0.3-33.7,0.5-64.3,0.7-89l0.2,0.2
									c-20.8-4.2-37.7-7.7-49.4-10c-5.8-1.2-10.4-2.1-13.5-2.8c-1.5-0.3-2.7-0.6-3.5-0.7c-0.8-0.2-1.2-0.3-1.2-0.3s0.4,0.1,1.2,0.2
									c0.8,0.2,2,0.4,3.5,0.7c3.1,0.6,7.7,1.5,13.5,2.7c11.7,2.3,28.6,5.7,49.4,9.9h0.2v0.2c-0.2,24.7-0.4,55.2-0.6,89l-0.2-0.2
									c9.4,0,19.1,0,28.9,0l-0.2,0.2c0.4-2.5,0.7-5,1.1-7.5c2-13.3,3.9-26.2,5.8-38.7c0.9-6.3,1.9-12.4,2.8-18.4
									c0.9-6,1.8-11.9,2.6-17.7c0.4-2.9,0.8-5.7,1.2-8.5s0.6-5.6,0.7-8.3c0.1-2.7,0.1-5.5,0-8.1c-0.1-1.3-0.1-2.7-0.2-4
									c-0.1-1.3-0.2-2.6-0.8-3.7s-1.4-2.1-2.3-2.9c-0.9-0.8-1.9-1.6-3-2.3c-1-0.7-2.1-1.3-3.2-1.8c-1.1-0.5-2.2-1-3.3-1.5
									c-4.4-2-8.6-3.7-12.7-5.3c-8.2-3.1-15.8-5.5-22.5-7.6c-6.7-2-12.5-3.7-17.2-5c-4.7-1.4-8.4-2.4-10.9-3.1
									c-1.2-0.4-2.2-0.6-2.8-0.8C165.4,276.2,165.1,276.1,165.1,276.1z"/>
							</g>
						</g>
					</g>
				</g>
			</g>
			<g>
				<g>
					<g>
						<g>
							<path class="cp" d="M174.4,175.5c0,0,8.3,3.4,13.1,14.9c3.3,8.1,21.9,33.7,21.9,33.7l49.3,11l1.7,15.7l-78.9-0.6l-11-34.9
								L174.4,175.5z"/>
						</g>
					</g>
				</g>
			</g>
			<g>
				<g>
					<g>
						<g>
							<path class="cp" d="M172.4,171.1l7.6,8.3l-15.8,81l6.7,9.3l-76.5,36.1c0,0-5.8-33-2.9-55.3c2.9-22.1,10.5-47.1,18.8-66.6
								c3.5-8.1,13.8-15.9,21.9-19.3l11.1-3.1l28.3,15.8L172.4,171.1z"/>
						</g>
					</g>
				</g>
			</g>
			<g class="st1">
				<g>
					<g>
						<g>
							<path d="M177.4,181c0,0,0,0.2-0.1,0.7s-0.2,1.2-0.3,2c-0.3,1.8-0.7,4.3-1.3,7.5c-1.1,6.3-2.8,15-4.8,24.5
								c-2,9.6-3.9,18.2-5.3,24.4c-0.7,3.1-1.3,5.6-1.7,7.4c-0.2,0.8-0.4,1.5-0.5,2s-0.2,0.7-0.2,0.7s0-0.2,0.1-0.7s0.2-1.2,0.4-2
								c0.4-1.8,0.9-4.3,1.6-7.4c1.3-6.3,3.2-14.9,5.2-24.4c2-9.6,3.7-18.2,4.9-24.5c0.6-3.1,1.1-5.6,1.4-7.4c0.2-0.8,0.3-1.5,0.4-2
								C177.3,181.2,177.4,181,177.4,181z"/>
						</g>
					</g>
				</g>
			</g>
			<g class="st1">
				<g>
					<g>
						<g>
							<path d="M110.4,289.4c0,0,0.1-0.2,0.4-0.5s0.8-0.7,1.3-1.2c0.6-0.5,1.3-1.2,2.2-1.9c0.9-0.7,1.8-1.5,2.9-2.4
								c2.2-1.7,4.9-3.7,8-5.8c3.1-2,6.6-4.1,10.4-6.1s7.5-3.7,10.9-5.1s6.6-2.5,9.3-3.3c1.3-0.4,2.6-0.8,3.6-1.1
								c1.1-0.3,2-0.5,2.8-0.7c0.7-0.2,1.3-0.3,1.8-0.4c0.4-0.1,0.6-0.1,0.6-0.1s-0.2,0.1-0.6,0.2c-0.4,0.1-1,0.3-1.7,0.5
								c-0.8,0.2-1.7,0.5-2.7,0.8c-1.1,0.3-2.3,0.7-3.6,1.1c-2.7,0.9-5.8,2-9.2,3.4s-7.1,3.1-10.9,5.1c-3.8,2-7.2,4.1-10.3,6.1
								c-3.1,2-5.8,4-8,5.7c-1.1,0.8-2.1,1.6-3,2.3c-0.9,0.7-1.6,1.3-2.2,1.8s-1,0.9-1.4,1.1C110.6,289.2,110.4,289.4,110.4,289.4z"/>
						</g>
					</g>
				</g>
			</g>
			<g class="st1">
				<g>
					<g>
						<g>
							<path d="M148.4,261.4c0.1,0.1-7.1,4.2-16.1,9c-9,4.9-16.3,8.7-16.3,8.6c-0.1-0.1,7.1-4.2,16.1-9
								C141.1,265.1,148.4,261.3,148.4,261.4z"/>
						</g>
					</g>
				</g>
			</g>
			<g>
				<g>
					<g>
						<g>
							<path class="st14" d="M257.7,235.8c0.4-0.6,6-7,7.4-7c1.5,0,3.2,1.7,3.2,1.7s1.9-0.9,3.2-0.4c1.3,0.4,7.2,5.5,7.2,7.4
								s0,3.2,0,3.2s0.2,1.1,0,2.6s-3.8,4-3.8,4h-20.4c0,0-3.9-2.6-3.4-5.1C251.6,239.7,257.7,235.8,257.7,235.8z"/>
						</g>
					</g>
				</g>
				<g>
					<g>
						<g>
							<g>
								<path class="st15" d="M268.4,230.5c0,0,0.5,0.2,1.3,0.7c0.9,0.5,2,1.3,3.4,2.1c0.3,0.2,0.7,0.4,1.1,0.8
									c0.4,0.3,0.7,0.8,0.8,1.3c0.2,0.5,0.1,1.1,0.2,1.6c0,0.5,0.1,1.1,0.1,1.6c0.1,2.2,0.2,4.7,0.4,7.2c0,0.4,0,0.8,0.1,1.2v0.2
									h-0.2c-0.4,0.1-0.8,0.2-1.2,0.3l0,0l0,0c-0.6,0-1.2-0.2-1.6-0.6c-0.4-0.4-0.8-0.8-1-1.2c-0.5-0.9-0.8-1.8-1.1-2.7
									c-0.5-1.8-0.6-3.5-0.7-4.9l0.1,0.2c-1.4-0.6-2.6-1-3.6-1.4c-0.8-0.3-1.3-0.5-1.3-0.6c0,0,0.5,0.1,1.3,0.4
									c0.9,0.3,2.2,0.8,3.6,1.3h0.1v0.1c0.1,1.4,0.3,3,0.8,4.8c0.2,0.9,0.6,1.8,1,2.6c0.5,0.8,1.2,1.6,2.2,1.6h-0.1
									c0.4-0.1,0.8-0.2,1.2-0.3l-0.2,0.3c0-0.4,0-0.8-0.1-1.2c-0.1-2.6-0.2-5-0.3-7.2c0-0.6,0-1.1-0.1-1.6c0-0.5,0-1-0.1-1.5
									c-0.1-0.4-0.4-0.8-0.7-1.1c-0.3-0.3-0.7-0.5-1-0.8c-1.3-0.9-2.4-1.7-3.3-2.3C268.8,230.9,268.3,230.5,268.4,230.5z"/>
							</g>
						</g>
					</g>
				</g>
				<g>
					<g>
						<g>
							<g>
								<path class="st15" d="M254.6,246.6c0,0,0.5-0.1,1.4-0.2c1-0.1,2.2-0.2,3.7-0.3l0,0l0,0c1.2,0.3,2.6,0.6,4.2,0.8
									c0.8,0.1,1.6,0.2,2.5,0.2c0.8,0,1.8,0,2.2-0.6c0.3-0.3,0.4-0.8,0.4-1.2c0-0.5-0.1-0.9-0.1-1.4c0-0.4-0.3-0.7-0.6-1
									c-0.3-0.3-0.6-0.6-1-0.8c-1.5-0.8-3.1-0.9-4.4-0.8c-1.4,0-2.5-0.5-2.9-1.2c-0.4-0.6-0.4-1.1-0.4-1.1s0.1,0.4,0.5,1
									s1.5,1,2.7,0.9c1.3-0.1,3-0.2,4.6,0.7c0.4,0.2,0.8,0.5,1.1,0.8c0.3,0.3,0.7,0.8,0.7,1.4c0,0.4,0.1,0.9,0.1,1.4
									s-0.1,1.1-0.5,1.5c-0.3,0.4-0.8,0.6-1.3,0.7c-0.5,0.1-0.9,0.1-1.4,0.1c-0.9,0-1.7-0.1-2.5-0.2c-1.6-0.2-3-0.5-4.2-0.9h0.1
									c-1.5,0.1-2.7,0.1-3.7,0.1C255,246.6,254.6,246.6,254.6,246.6z"/>
							</g>
						</g>
					</g>
				</g>
				<g>
					<g>
						<g>
							<g>
								<path class="st15" d="M275.4,246.5c-0.1-0.1,0.7-1.7,1.6-3.7s1.7-3.7,1.9-3.6c0.1,0.1-0.5,1.8-1.4,3.8
									C276.5,245.1,275.5,246.6,275.4,246.5z"/>
							</g>
						</g>
					</g>
				</g>
			</g>
			<g>
				<g>
					<g>
						<g>
							<path class="cp" d="M121.4,201.5c0,0,36,49,41.6,49s52.6,0,52.6,0v-19.9l-39.7-9.4c0,0-19.6-40.5-23.9-45.7
								c-4.3-5.2-15.9-12.2-24.6-4.4C118.6,178.9,121.4,201.5,121.4,201.5z"/>
						</g>
					</g>
				</g>
			</g>
			<g>
				<g>
					<g>
						<g>
							<path class="st14" d="M215.5,232.4l8.6-0.7c0,0,0.2-3.2,2.1-4.3c1.9-1.1,1.7-3.6,2.8-3.4c1.1,0.2,1.9,0,1.9,0l7.1-6l6-3.9
								l4.7-0.9c0,0,2.8,0.8,0,3.4l-3,1.7l-2.6,2.6l6-3c0.6-0.3,1.2-0.3,1.8,0c1.4,0.7,3.9,1.9,4.6,2.6c0.9,0.9,0,1.9,0,1.9l2.6,2.3
								c0,0,0.6,3-2.4,2.3l-2.4-0.6l-3.2,3.2c0,0,10.9-2.3,11.7-1.9c0.8,0.4,1.7,2.1,0,3.6s-8.3,1.1-9.8,2.6s-11.4,10.6-13.9,11.1
								c-2.4,0.6-22.5,5.5-22.5,5.5L215.5,232.4z"/>
						</g>
					</g>
				</g>
				<g>
					<g>
						<g>
							<g>
								<path class="st15" d="M258.1,224.6c0,0-0.6-0.4-1.5-1c-0.5-0.3-1-0.7-1.6-1.1c-0.3-0.2-0.6-0.4-1-0.6c-0.3-0.2-0.7-0.4-1-0.4
									c-0.7,0-1.5,0.6-2.1,0.9c-0.6,0.4-1.2,0.7-1.7,1c-1,0.6-1.5,0.9-1.6,0.9c0-0.1,0.5-0.5,1.4-1.2c0.5-0.3,1-0.7,1.7-1.1
									c0.3-0.2,0.7-0.4,1-0.6c0.4-0.2,0.8-0.4,1.3-0.4c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0.1,0.3,0.1c0.2,0.1,0.4,0.2,0.6,0.3
									c0.3,0.2,0.7,0.4,1,0.7c0.6,0.4,1.1,0.9,1.5,1.2C257.7,224,258.1,224.6,258.1,224.6z"/>
							</g>
						</g>
					</g>
				</g>
				<g>
					<g>
						<g>
							<g>
								<path class="st15" d="M231.7,223.3c0.1,0.1-0.8,0.9-1.9,1.9c-1,1-1.9,1.8-2,1.7s0.6-1.1,1.7-2.1
									C230.6,223.8,231.6,223.2,231.7,223.3z"/>
							</g>
						</g>
					</g>
				</g>
			</g>
			<g class="st1">
				<g>
					<g>
						<g>
							<path d="M151.9,175.5c0,0,0.8,1,2.1,3c1.2,2.1,3,5.1,5.1,9.1c4.2,7.9,10.1,19.5,16.9,33.5l-0.1-0.1c11.7,2.8,25.2,5.9,39.8,9.3
								h0.2v0.2c0,6.4,0,13.1,0,19.9v0.2h-0.2c-0.8,0-1.6,0-2.4,0c-15.8,0-30.9,0-44.7,0c-1.7,0-3.4,0-5.1,0c-0.2,0-0.4,0-0.7,0
								c-0.2,0-0.4-0.1-0.6-0.2c-0.4-0.2-0.8-0.4-1.1-0.7c-0.7-0.5-1.3-1-1.9-1.6c-2.4-2.2-4.5-4.6-6.6-6.8c-4-4.6-7.6-9-10.8-13.1
								c-6.5-8.1-11.5-14.8-15-19.5c-1.7-2.3-3-4.1-4-5.4c-0.4-0.6-0.8-1.1-1-1.4c-0.2-0.3-0.3-0.5-0.3-0.5s0.1,0.2,0.4,0.5
								c0.2,0.3,0.6,0.8,1.1,1.4c0.9,1.2,2.3,3,4,5.3c3.5,4.6,8.6,11.3,15.1,19.4c3.3,4,6.8,8.4,10.9,13c2,2.3,4.2,4.6,6.5,6.8
								c0.6,0.5,1.2,1.1,1.9,1.5c0.3,0.2,0.7,0.5,1,0.6c0.4,0.2,0.7,0.2,1.1,0.2c1.7,0,3.4,0,5.1,0c13.7,0,28.8,0,44.7,0
								c0.8,0,1.6,0,2.4,0l-0.2,0.2c0-6.8,0-13.5,0-19.9l0.2,0.2c-14.5-3.4-28-6.6-39.7-9.4h-0.1v-0.1c-6.8-14-12.6-25.6-16.7-33.5
								c-2.1-4-3.8-7.1-5-9.1c-0.6-1-1.1-1.8-1.5-2.3C152,175.7,151.9,175.5,151.9,175.5z"/>
						</g>
					</g>
				</g>
			</g>
			<g class="st1">
				<g>
					<g>
						<g>
							<path d="M146.6,172.3c0,0.1-1.2-0.4-3.2-0.9c-1-0.2-2.2-0.4-3.6-0.5c-1.4-0.1-2.9-0.1-4.4,0c-1.6,0.2-3,0.4-4.4,0.8
								c-1.3,0.4-2.5,0.8-3.4,1.2c-1.9,0.8-3,1.5-3,1.4c0,0,0.2-0.2,0.7-0.5s1.2-0.8,2.2-1.2c0.9-0.5,2.1-0.9,3.4-1.3
								c1.3-0.4,2.8-0.7,4.4-0.9c1.6-0.1,3.1-0.1,4.5,0s2.6,0.4,3.6,0.7s1.8,0.6,2.3,0.8C146.3,172.1,146.6,172.2,146.6,172.3z"/>
						</g>
					</g>
				</g>
			</g>
			<g class="st1">
				<g>
					<g>
						<g>
							<path d="M168.5,230.2c-0.1,0,0.8-2.3,2.7-4.6s3.8-3.7,3.9-3.6c0.1,0.1-1.7,1.7-3.5,4C169.8,228.2,168.7,230.3,168.5,230.2z"/>
						</g>
					</g>
				</g>
			</g>
			<g class="st1">
				<g>
					<g>
						<g>
							<path d="M167.3,224.1c-0.1-0.1,1.3-1.3,3.4-2c2.1-0.8,3.9-0.7,3.9-0.6c0,0.1-1.7,0.3-3.8,1.1
								C168.9,223.2,167.4,224.2,167.3,224.1z"/>
						</g>
					</g>
				</g>
			</g>
			<g>
				<path class="st3" d="M143.8,122v0.9h3.2c2.8-28.6,18.2-30.5,17.8-30.7c-0.1-1.3,0.2-2.3,1.3-3.4C144.6,91.4,143.7,121.6,143.8,122
					z"/>
			</g>
			<g>
				<g>
					<circle class="st3" cx="142.8" cy="131.4" r="11.3"/>
				</g>
			</g>
			<g>
				<g>
					<path d="M148.9,131.1c-0.1,0,0-0.5-0.3-1.4c-0.2-0.8-0.8-2.1-2.1-3c-1.2-0.9-3.3-1.5-5.2-0.7c-1.9,0.7-3.6,2.7-3.6,5.1
						c0,2.4,1.6,4.5,3.6,5.1c2,0.7,4,0.2,5.2-0.7c1.3-0.9,1.9-2.2,2.1-3C148.9,131.6,148.9,131.1,148.9,131.1s0,0.1,0,0.4
						c0,0.2,0,0.6-0.1,1.1c-0.2,0.9-0.7,2.2-2.1,3.3c-1.3,1-3.5,1.7-5.6,0.9c-1.1-0.4-2.1-1.1-2.8-2.1s-1.2-2.2-1.2-3.5
						s0.4-2.6,1.2-3.5c0.7-1,1.7-1.7,2.8-2.1c2.1-0.8,4.3-0.1,5.6,0.9s1.9,2.4,2.1,3.3c0.1,0.5,0.1,0.8,0.1,1.1
						C149,131,149,131.1,148.9,131.1z"/>
				</g>
			</g>
			<g>
				<g>
					<g>
						<g>
							<path d="M78.5,273.9l1,4.3h91.9c1.8,0,3.4-1.3,3.7-3.1l0,0c0.3-1.7-0.6-3.3-2.1-4c-5.8-2.7-21.9-8.5-50.6-8.1
								c-40.7,0.5-43.9,6.9-43.9,6.9L78.5,273.9L78.5,273.9z"/>
						</g>
					</g>
				</g>
				<g>
					<g>
						<g>
							<path d="M144.1,274.7c-0.2,1.4-15.4,53-15.4,53H113l15.2-53H144.1z"/>
						</g>
					</g>
				</g>
				<g>
					<g>
						<g>
							<g>
								<path class="st3" d="M173,278.2c0,0.1-20.7,0.3-46.2,0.3s-46.2-0.1-46.2-0.3c0-0.1,20.7-0.3,46.2-0.3
									C152.3,278,173,278.1,173,278.2z"/>
							</g>
						</g>
					</g>
				</g>
			</g>
		</g>
		<g id="Desk">
			<g>
				<g>
					<g>
						<path class="cs" d="M345.5,184.8c-0.1,0.5,17.4,64.9,17.4,64.9l-28,0.1l-0.1,2.9h33.6L349.3,178L345.5,184.8z"/>
					</g>
					<g>
						<g>
							<path class="st3" d="M345.5,184.8c0,0,0.1-0.2,0.2-0.4c0.2-0.3,0.4-0.7,0.7-1.3c0.6-1.2,1.6-2.8,2.8-5l0.1-0.2l0.1,0.2
								c3.2,12.5,10.4,40.4,19.2,74.7l0.1,0.3h-0.3c-7.5,0-15.4,0-23.5,0c-3.4,0-6.8,0-10.1,0h-0.3v-0.3c0-1,0.1-2,0.1-2.9v-0.2h0.2
								c9.8,0,19.2,0,28-0.1l-0.2,0.3c-5.1-19.1-9.5-35.3-12.6-46.8c-1.5-5.7-2.7-10.3-3.5-13.4c-0.4-1.5-0.7-2.7-0.9-3.6
								c-0.1-0.4-0.2-0.7-0.2-0.9C345.5,184.9,345.4,184.8,345.5,184.8c0.3,1,1.9,6.9,4.9,18.2c3.1,11.5,7.6,27.7,12.8,46.7l0.1,0.3
								H363c-8.8,0-18.2,0.1-28,0.1l0.3-0.2c0,1-0.1,2-0.1,2.9l-0.3-0.3c3.3,0,6.7,0,10.1,0c8.1,0,16,0,23.5,0l-0.2,0.3
								c-8.7-34.3-15.8-62.2-18.9-74.7h0.2c-1.3,2.2-2.2,3.8-2.9,5c-0.3,0.5-0.6,1-0.8,1.3C345.5,184.6,345.5,184.8,345.5,184.8z"/>
						</g>
					</g>
				</g>
				<g>
					<g>
						
							<rect x="287.9" y="164.8" transform="matrix(0.2979 -0.9546 0.9546 0.2979 84.1671 451.0588)" class="cs" width="121.6" height="7.1"/>
					</g>
					<g>
						<g>
							<path class="st3" d="M327.2,225.3c0,0,0-0.2,0.2-0.6c0.1-0.4,0.3-1,0.5-1.7c0.5-1.5,1.2-3.8,2-6.7c1.8-5.8,4.4-14.3,7.6-24.7
								c6.5-21,15.5-50,25.6-82.4l0.1-0.2l0.2,0.1c2.2,0.7,4.5,1.4,6.8,2.1l0,0l0.2,0.1l-0.1,0.2c-16.9,54.3-31.4,100.3-36.4,116v0.1
								h-0.1c-2.3-0.7-4-1.3-5.1-1.7c-0.5-0.2-1-0.3-1.2-0.4C327.3,225.4,327.2,225.3,327.2,225.3s0.2,0,0.4,0.1
								c0.3,0.1,0.7,0.2,1.3,0.4c1.1,0.3,2.8,0.9,5.1,1.5l-0.1,0.1c4.8-15.8,19.1-61.8,36-116.1l0.2,0.3l0,0c-2.3-0.7-4.6-1.5-6.8-2.1
								l0.3-0.2c-10.1,32.3-19.2,61.3-25.8,82.2c-3.3,10.4-6,18.9-7.8,24.7c-0.9,2.9-1.6,5.2-2.1,6.7c-0.2,0.8-0.4,1.3-0.6,1.7
								C327.2,225.1,327.2,225.3,327.2,225.3z"/>
						</g>
					</g>
				</g>
			</g>
			<g>
				<g>
					<g>
						<rect x="232.5" y="247.3" class="cs" width="72.1" height="5.3"/>
					</g>
					<g>
						<g>
							<path class="st3" d="M304.5,252.7c0,0,0-0.1,0-0.4c0-0.3,0-0.6,0-1c0-0.9,0-2.2-0.1-4l0.1,0.1c-11,0.1-39.1,0.2-72.1,0.2l0,0
								c0.1-0.1-0.3,0.3,0.3-0.3l0,0l0,0l0,0l0,0l0,0v0.1v0.2v0.3v0.7c0,0.5,0,0.9,0,1.4c0,0.9,0,1.8,0,2.6l-0.3-0.3
								c20.1,0,38.1,0.1,51.1,0.1c6.5,0,11.7,0.1,15.4,0.1c1.8,0,3.2,0,4.2,0c0.5,0,0.8,0,1.1,0C304.4,252.6,304.5,252.6,304.5,252.7
								c0,0-0.1,0-0.3,0c-0.3,0-0.6,0-1.1,0c-1,0-2.4,0-4.1,0c-3.6,0-8.8,0-15.3,0.1c-13,0-31.1,0.1-51.3,0.1h-0.3v-0.3
								c0-0.8,0-1.7,0-2.6c0-0.4,0-0.9,0-1.4V248v-0.3v-0.2v-0.1l0,0l0,0l0,0l0,0l0,0c0.5-0.5,0.1-0.1,0.3-0.3l0,0
								c32.9,0,61.1,0.1,72.1,0.2h0.1v0.1c0,1.7,0,3.1-0.1,4c0,0.4,0,0.8,0,1C304.5,252.5,304.5,252.7,304.5,252.7z"/>
						</g>
					</g>
				</g>
				<g>
					<g>
						<path class="st3" d="M279.7,252.9c-0.1,0-0.3-1.2-0.3-2.7s0.1-2.7,0.3-2.7s0.3,1.2,0.3,2.7C279.9,251.7,279.8,252.9,279.7,252.9
							z"/>
					</g>
				</g>
			</g>
			<g>
				<g>
					<g>
						<g>
							<g>
								<rect x="122.9" y="264.9" class="st3" width="11.2" height="188.1"/>
							</g>
						</g>
					</g>
					<g>
						<g>
							<g>
								<g>
									<path d="M134.1,453c0,0,0-0.3,0-1s0-1.6,0-2.8c0-2.5,0-6.2,0-10.9c0-9.5,0-23.1-0.1-40.1c0-33.9-0.1-80.9-0.1-133.3l0.3,0.3
										c-3.5,0-7.3,0-11.2,0l0,0l0.3-0.3c-0.1,87.9-0.2,162.5-0.2,188.1l-0.1-0.1c3.8,0,6.5,0,8.4,0c0.9,0,1.6,0,2.1,0
										C133.9,453,134.1,453,134.1,453s-0.2,0-0.7,0s-1.1,0-2.1,0c-1.9,0-4.7,0-8.4,0h-0.1v-0.1c0-25.6-0.1-100.2-0.2-188.1v-0.3
										h0.3l0,0c3.8,0,7.6,0,11.2,0h0.3v0.3c0,52.5-0.1,99.5-0.1,133.5c0,16.9-0.1,30.6-0.1,40c0,4.7,0,8.3,0,10.8
										c0,1.2,0,2.2,0,2.8C134.1,452.7,134.1,453,134.1,453z"/>
								</g>
							</g>
						</g>
					</g>
				</g>
				<g>
					<g>
						<g>
							<g>
								<rect x="337.3" y="261.2" class="st3" width="11.2" height="191.8"/>
							</g>
						</g>
					</g>
					<g>
						<g>
							<g>
								<g>
									<path d="M348.4,453c0,0,0-0.3,0-1s0-1.6,0-2.9c0-2.5,0-6.3,0-11.1c0-9.6,0-23.6-0.1-40.9c0-34.6-0.1-82.5-0.1-135.9l0.3,0.3
										c-3.5,0-7.3,0-11.2,0l0,0l0.3-0.3c-0.1,89.7-0.2,165.7-0.2,191.8l-0.1-0.1c3.8,0,6.5,0,8.4,0c0.9,0,1.6,0,2.1,0
										C348.2,453,348.4,453,348.4,453s-0.2,0-0.7,0s-1.1,0-2.1,0c-1.9,0-4.7,0-8.4,0h-0.1v-0.1c0-26.1-0.1-102.1-0.2-191.8v-0.3
										h0.3l0,0c3.8,0,7.6,0,11.2,0h0.3v0.3c0,53.5-0.1,101.5-0.1,136.1c0,17.3-0.1,31.2-0.1,40.8c0,4.8,0,8.5,0,11
										c0,1.2,0,2.2,0,2.9C348.4,452.6,348.4,453,348.4,453z"/>
								</g>
							</g>
						</g>
					</g>
				</g>
				<g>
					<g>
						<g>
							<g>
								<rect x="42.6" y="252.7" class="st3" width="351.7" height="11.7"/>
							</g>
							<g>
								<g>
									<path d="M394.3,264.4c0-0.4,0-3.8-0.1-11.7l0.1,0.1c-38.2,0-181.2,0.1-351.7,0.2l0,0l0.3-0.3c0,4,0,8,0,11.7l-0.3-0.3
										c97.7,0,185.6,0.1,249,0.1c31.8,0,57.4,0.1,75.1,0.1c8.9,0,15.7,0,20.4,0c2.3,0,4.1,0,5.3,0
										C393.7,264.4,394.3,264.4,394.3,264.4s-0.6,0-1.8,0c-1.2,0-3,0-5.3,0c-4.7,0-11.5,0-20.3,0c-17.7,0-43.3,0-75.1,0.1
										c-63.5,0-151.5,0.1-249.2,0.1h-0.3v-0.3c0-3.7,0-7.7,0-11.7v-0.3h0.3l0,0c170.4,0.1,313.5,0.2,351.7,0.2h0.1v0.1
										c0,4,0,7,0,8.9C394.3,263.5,394.3,264.4,394.3,264.4z"/>
								</g>
							</g>
						</g>
					</g>
				</g>
				<g>
					<g>
						<path class="st10" d="M215,264c-0.1,0-0.3-2.5-0.3-5.7c0-3.1,0.1-5.7,0.3-5.7s0.3,2.5,0.3,5.7C215.3,261.5,215.2,264,215,264z"
							/>
					</g>
				</g>
			</g>
		</g>
		<g>
			<g>
				<g>
					<g>
						<g>
							<path class="st2" d="M302.5,20.8l-0.3-2.7l0,0c0.5,0,1,0.1,1.5,0.2c0.4,0.2,0.7,0.4,0.7,0.4v-0.1l0.3-1l0.1-0.2l-0.5-0.5
								l-0.2,0.1c-0.6-0.2-1.3-0.4-2-0.4l-0.1-1.5l-0.5-0.3v0.4l-0.6,0.1l0.1,1.5c-2.1,0.4-2.9,1.7-2.8,3c0.2,1.9,1.8,2.2,3.3,2.4
								l0.3,2.7c-1,0-2.1-0.3-2.8-0.8l-0.5,1.4c0.8,0.5,2.1,0.8,3.4,0.8l0.1,1.1l-0.5,0.1l0.6,0.3l1.1-0.1l-0.1-1.5
								c2.1-0.4,3-1.7,2.8-3C305.6,21.3,304,21,302.5,20.8z M301.3,20.6c-0.8-0.2-1.4-0.4-1.5-1s0.3-1.1,1.2-1.3L301.3,20.6z
								M302.2,18.1L302.2,18.1L302.2,18.1L302.2,18.1z M302.8,24.8l-0.2-2.4c0.8,0.2,1.4,0.4,1.5,1.1C304.2,24,303.8,24.5,302.8,24.8
								z"/>
						</g>
					</g>
				</g>
			</g>
			<g>
				<g>
					<g>
						<g>
							<path class="st2" d="M302.5,20.8l-0.3-2.7l0,0c0.5,0,1,0.1,1.5,0.2c0.4,0.2,0.7,0.4,0.7,0.4v-0.1l0.3-1l0.1-0.2l-0.5-0.5
								l-0.2,0.1c-0.6-0.2-1.3-0.4-2-0.4l-0.1-1.5l-0.5-0.3v0.4l-0.6,0.1l0.1,1.5c-2.1,0.4-2.9,1.7-2.8,3c0.2,1.9,1.8,2.2,3.3,2.4
								l0.3,2.7c-1,0-2.1-0.3-2.8-0.8l-0.5,1.4c0.8,0.5,2.1,0.8,3.4,0.8l0.1,1.1l-0.5,0.1l0.6,0.3l1.1-0.1l-0.1-1.5
								c2.1-0.4,3-1.7,2.8-3C305.6,21.3,304,21,302.5,20.8z M301.3,20.6c-0.8-0.2-1.4-0.4-1.5-1s0.3-1.1,1.2-1.3L301.3,20.6z
								M302.2,18.1L302.2,18.1L302.2,18.1L302.2,18.1z M302.8,24.8l-0.2-2.4c0.8,0.2,1.4,0.4,1.5,1.1C304.2,24,303.8,24.5,302.8,24.8
								z"/>
						</g>
					</g>
				</g>
			</g>
		</g>
		<g class="st1">
			<rect x="372.2" y="50" width="9.2" height="23.6"/>
		</g>
	</svg>
</template>

<style scoped>
	.cp{fill:#C091FC;}
	.cs{fill:#3CDFAD;}
	.st1{opacity:0.3;enable-background:new;}
	.st2{fill:#FFFFFF;}
	.st3{fill:#2D2D2D;}
	.st4{opacity:0.38;}
	.st5{fill:#E0E0E0;}
	.st6{fill:#FAFAFA;}
	.st7{display:none;}
	.st8{display:inline;}
	.st9{fill:#F5F5F5;}
	.st10{fill:#263238;}
	.st12{fill:none;}
	.st13{fill:#E1E1E1;}
	.st14{fill:#FFBF9D;}
	.st15{fill:#FF9A6C;}
	.st16{opacity:0.3;}
	.st17{opacity:0.6;}
</style>
