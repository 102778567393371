<template>
	<svg
		version="1.1" id="Camada_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
		viewBox="0 0 560 490" style="enable-background:new 0 0 560 490;" xml:space="preserve"
	>
		<g id="Layer_7">
			<g>
				<g>
					<g>
						<rect x="55.3" y="402.8" class="st0" width="18.7" height="43.1"/>
						<rect x="118.8" y="402.8" class="st0" width="18.7" height="43.1"/>
						<rect x="87.1" y="363.6" class="st1" width="18.7" height="82.4"/>
						<rect x="150.6" y="363.6" class="st1" width="18.7" height="82.4"/>
						<rect x="182.3" y="365.9" class="st0" width="18.7" height="80"/>
						<rect x="245.8" y="365.9" class="st0" width="18.7" height="80"/>
						<rect x="309.3" y="365.9" class="st0" width="18.7" height="80"/>
						<rect x="214" y="323.9" class="st1" width="18.7" height="122"/>
						<rect x="277.5" y="335.9" class="st1" width="18.7" height="110.1"/>
						<rect x="403" y="365.9" class="st0" width="18.7" height="80"/>
						<rect x="371.3" y="381.3" class="st1" width="18.7" height="64.6"/>
						<rect x="469.4" y="323.2" class="st0" width="18.7" height="122.7"/>
						<rect x="437.7" y="381.3" class="st1" width="18.7" height="64.6"/>
						<rect x="501.2" y="381.3" class="st1" width="18.7" height="64.6"/>
						<rect x="341" y="352.7" class="st0" width="18.7" height="93.2"/>
						<path class="cs" d="M226.7,323.9c0,2.7-2.2,4.8-4.8,4.8s-4.8-2.2-4.8-4.8c0-2.6,2.2-4.8,4.8-4.8S226.7,321.3,226.7,323.9z"/>
						<path class="cs" d="M221.9,332.7c-4.8,0-8.7-3.9-8.7-8.7c0-4.8,3.9-8.7,8.7-8.7s8.7,3.9,8.7,8.7
							C230.6,328.8,226.7,332.7,221.9,332.7L221.9,332.7z M221.9,315.9c-4.4,0-8,3.6-8,8s3.6,8,8,8s8-3.6,8-8S226.3,315.9,221.9,315.9
							z"/>
						<polygon class="st0" points="225.7,311.7 225.4,302.2 227.8,302.2 223.4,296.8 219,302.3 221.4,302.3 221.7,311.7"/>
						<path class="cs" d="M70,383.1c0,2.7-2.2,4.8-4.8,4.8c-2.6,0-4.8-2.2-4.8-4.8s2.2-4.8,4.8-4.8C67.9,378.3,70,380.5,70,383.1z"/>
						<path class="cs" d="M65.2,392c-4.8,0-8.7-3.9-8.7-8.7c0-4.8,3.9-8.7,8.7-8.7s8.7,3.9,8.7,8.7C73.9,388.1,70,392,65.2,392z
							M65.2,375.3c-4.4,0-8,3.6-8,8s3.6,8,8,8s8-3.6,8-8S69.6,375.3,65.2,375.3z"/>
						<polygon class="st0" points="62.3,353.3 62.6,363.1 60.1,363.1 64.7,368.7 69.3,363 66.7,363 66.5,353.3"/>
						<polygon class="st0" points="316.9,332.7 317.2,342.5 314.7,342.5 319.3,348.1 323.9,342.4 321.4,342.4 321.1,332.7"/>
					</g>
					<path class="cs" d="M374.9,419.9L318.6,367c-35.7-17.2-90.8-42.6-97.3-42.3c-7.2,0.4-143.9,54.3-195.9,75l-1-2.6
						c7.7-3,187.6-74.7,196.8-75.2c0.1,0,0.1,0,0.2,0c10,0,89.5,38.3,98.6,42.7l0.3,0.2l55.1,51.7c10.4-5.5,70.3-37.1,71.4-37.6
						c0.4-0.2,1.3-0.5,83.7-0.5v2.8c-31.6,0-79.1,0.1-82.7,0.4c-3.1,1.6-48.1,25.3-71.9,37.9L374.9,419.9L374.9,419.9z"/>
				</g>
				<path class="cs" d="M324.1,364.7c0,2.7-2.2,4.8-4.8,4.8s-4.8-2.2-4.8-4.8s2.2-4.8,4.8-4.8S324.1,362.1,324.1,364.7z"/>
				<path class="cs" d="M319.3,373.7c-4.8,0-8.7-3.9-8.7-8.7s3.9-8.7,8.7-8.7c4.8,0,8.7,3.9,8.7,8.7S324.1,373.7,319.3,373.7z
					M319.3,356.9c-4.4,0-8,3.6-8,8s3.6,8,8,8s8-3.6,8-8S323.7,356.9,319.3,356.9z"/>
				<path class="cs" d="M452.1,379.1c0,2.7-2.2,4.8-4.8,4.8s-4.8-2.2-4.8-4.8c0-2.6,2.2-4.8,4.8-4.8S452.1,376.4,452.1,379.1z"/>
				<path class="cs" d="M447.2,388c-4.8,0-8.7-3.9-8.7-8.7c0-4.8,3.9-8.7,8.7-8.7c4.8,0,8.7,3.9,8.7,8.7
					C456,384.1,452.1,388,447.2,388z M447.2,371.2c-4.4,0-8,3.6-8,8s3.6,8,8,8s8-3.6,8-8S451.7,371.2,447.2,371.2z"/>
				<polygon class="st0" points="444.6,350.5 444.9,360.4 442.4,360.3 447,365.9 451.6,360.3 449.1,360.2 448.8,350.5 		"/>
			</g>
			<g>
				<path class="st1" d="M453.2,259.8c-0.4-23.3,18.2-42.6,41.4-43s42.4,18.3,42.8,41.6s-18.2,42.6-41.4,43
					C472.8,301.8,453.6,283.1,453.2,259.8L453.2,259.8z M515.4,258.8c-0.2-11.1-9.4-20.1-20.5-19.9s-20,9.4-19.8,20.5
					s9.4,20.1,20.5,19.9C506.7,279.1,515.6,269.9,515.4,258.8z"/>
				<path class="st3" d="M453.2,259.8c-0.4-23.3,18.2-42.6,41.4-43l0.4,22.1c-11.1,0.2-20,9.4-19.8,20.5s9.4,20.1,20.5,19.9l0.4,22.1
					C472.8,301.8,453.6,283.1,453.2,259.8L453.2,259.8z"/>
				<path class="st0" d="M490.8,213.4c27.2-0.4,48.8,20.6,49.3,47.9l-25.7,0.4c-0.2-13-10.1-21.3-23.1-21L490.8,213.4z"/>
			</g>
			<g>
				<rect x="126.3" y="12.9" class="st0" width="19" height="59.2"/>
				<rect x="154.6" y="26.4" class="st0" width="19" height="45.7"/>
				<rect x="180.9" y="45" class="st0" width="19" height="27.1"/>
			</g>
			<g>
				<path class="st0" d="M362.8,48.7V15.5c18.2,0,33.2,15,33.2,33.2S381,82,362.8,82S330,67.5,329.6,49.6L362.8,48.7z"/>
				<path class="st1" d="M356.6,36.2L323.4,37c0-8.6,3.4-17.7,9.4-23.9s14.6-9.8,23.3-10L356.6,36.2L356.6,36.2z"/>
			</g>
			<g>
				<path class="st1" d="M64.5,233.2l23.2-18.4c10.1,12.8,7.9,31.6-4.8,41.7c-12.8,10.1-31.6,7.9-41.7-4.8
					c-10.1-12.8-7.9-31.6,4.8-41.7c2-1.6,4.3-2.9,6.6-4L64.5,233.2L64.5,233.2z"/>
				<path class="st3" d="M58.2,203l8.2,21.5l17.3-14.7C83.7,209.8,74.6,200.3,58.2,203L58.2,203z"/>
			</g>
			<path class="st4" d="M509.2,473.1c0,0.2-102.2,0.3-228.1,0.3s-228.2-0.1-228.2-0.3c0-0.2,102.1-0.3,228.2-0.3
				S509.2,472.9,509.2,473.1z"/>
			<g>
				<g>
					<path class="cs" d="M83.3,85.3c-4.5-10.4-16.6-15.1-27-10.6s-15.1,16.6-10.6,27c3.3,7.5,10.5,12.1,18.2,12.3
						c1,2.2,2.1,4.8,2.1,4.8l16.5-7.2c0,0-1.1-2.7-2.1-5.1C85.1,100.7,86.5,92.6,83.3,85.3L83.3,85.3z"/>
					<path class="st5" d="M76.5,114.1c0,0,0-0.1-0.1-0.2c-0.1-0.2-0.1-0.4-0.2-0.7c-0.2-0.6-0.5-1.6-0.8-2.8s-0.7-2.7-1-4.4
						s-0.6-3.7-0.6-6c0-1.1,0.1-2.3,0.3-3.5c0.1-0.6,0.3-1.2,0.8-1.7c0.4-0.5,1.1-0.8,1.8-0.7c0.3,0,0.7,0.2,0.9,0.5
						c0.2,0.3,0.4,0.6,0.3,1c0,0.7-0.4,1.4-0.9,1.9c-1.1,1-2.5,1.5-4,1.4s-3-0.8-4-2c-0.5-0.6-0.9-1.3-1.1-2.1
						C67.8,94,68.2,93,69,92.7c0.4-0.1,0.9,0,1.2,0.2c0.3,0.3,0.6,0.7,0.7,1.1c0.2,0.9-0.1,1.7-0.5,2.4c-0.8,1.5-2.1,2.8-3.8,3.2
						c-1.7,0.4-3.6,0-4.8-1.1c-0.7-0.5-1.1-1.4-1.1-2.2c0-0.4,0.1-0.9,0.5-1.2c0.3-0.3,0.8-0.4,1.2-0.4s0.8,0.2,1.2,0.5
						c0.3,0.3,0.6,0.6,0.8,1c0.4,0.7,0.6,1.5,0.7,2.3c0.1,1.6-0.3,3.1-1.3,4.3c-0.9,1.2-2.3,2-3.7,2.2c-0.7,0.1-1.4,0.2-2.1-0.2
						c-0.6-0.3-1.1-1.1-0.9-1.8c0.1-0.3,0.4-0.6,0.7-0.8s0.6-0.2,1-0.2c0.7,0,1.2,0.4,1.7,0.8c0.9,0.8,1.7,1.6,2.5,2.4
						c3.2,3.1,5.6,5.8,7.3,7.6c0.8,0.9,1.5,1.7,1.9,2.1c0.2,0.2,0.4,0.4,0.5,0.6c0.1,0.1,0.2,0.2,0.2,0.2s-0.1-0.1-0.2-0.2
						s-0.3-0.3-0.5-0.5c-0.4-0.5-1.1-1.2-1.9-2.1c-1.7-1.8-4.1-4.5-7.3-7.6c-0.8-0.8-1.6-1.6-2.5-2.4c-0.5-0.4-1-0.7-1.6-0.7
						s-1.3,0.3-1.4,0.9c-0.2,0.6,0.2,1.2,0.8,1.5s1.3,0.3,1.9,0.1c1.3-0.3,2.6-1,3.5-2.2c0.9-1.1,1.3-2.6,1.2-4.1
						c-0.1-0.8-0.3-1.5-0.6-2.2c-0.4-0.7-0.9-1.3-1.7-1.3c-0.4,0-0.8,0.1-1,0.3c-0.3,0.3-0.4,0.6-0.4,1c0,0.8,0.4,1.5,1,2
						c1.2,1.1,3,1.5,4.6,1.1s2.9-1.6,3.7-3.1c0.3-0.7,0.6-1.5,0.5-2.3c-0.1-0.4-0.3-0.7-0.6-0.9c-0.3-0.2-0.7-0.3-1-0.2
						s-0.6,0.4-0.8,0.7s-0.2,0.7-0.2,1.1c0.1,0.7,0.5,1.4,1,2c1,1.1,2.4,1.9,3.9,1.9c1.4,0.1,2.9-0.4,3.9-1.4c0.5-0.5,0.8-1.1,0.8-1.7
						s-0.5-1.2-1.1-1.3s-1.2,0.2-1.6,0.7s-0.6,1-0.7,1.6c-0.2,1.2-0.3,2.3-0.3,3.4c0,2.2,0.2,4.2,0.5,5.9s0.7,3.2,1,4.4
						s0.6,2.1,0.7,2.8c0.1,0.3,0.1,0.5,0.2,0.7C76.5,114,76.5,114.1,76.5,114.1L76.5,114.1z"/>
					<path class="st5" d="M82.6,115.2l-14.2,6.2c-0.6,0.3-1.3,0-1.5-0.6l0,0c-0.3-0.6,0-1.3,0.6-1.5l14.2-6.2c0.6-0.3,1.3,0,1.5,0.6
						l0,0C83.4,114.2,83.2,114.9,82.6,115.2L82.6,115.2z"/>
					<path class="st5" d="M83,116.1l-14.2,6.2c-0.6,0.3-0.9,0.9-0.6,1.5s0.9,0.9,1.5,0.6l1.6-0.7l3.8,3c0.4,0.3,1,0.4,1.5,0.2l5.1-2.2
						c0.5-0.2,0.9-0.7,0.9-1.3l0.3-4.8l1.1-0.5c0.6-0.3,0.9-0.9,0.6-1.5C84.3,116.2,83.6,115.9,83,116.1L83,116.1z"/>
				</g>
				<g class="st6">
					<path d="M47.2,86.8c0.8-1.7,1.7-3.1,2.7-4.1c0.9-1,1.9-1.7,2.5-2.1c0.7-0.4,1.1-0.5,1.2-0.4c0.2,0.3-1,1.4-2.3,3.6
						c-1.4,2.1-2.8,5.3-2.9,9.1c-0.1,3.7,1.2,7,2.5,9.2s2.4,3.4,2.2,3.7c-0.1,0.1-0.5,0-1.2-0.4s-1.5-1.1-2.4-2.2s-1.8-2.5-2.5-4.3
						c-0.7-1.7-1.1-3.8-1.1-6"/>
				</g>
				<g class="st6">
					<path d="M58.5,79c-1.2,0.5-2.4,0.6-2.8,0.1c-0.2-0.3-0.1-0.7,0.2-1.2c0.3-0.4,0.9-0.9,1.6-1.2c0.7-0.3,1.4-0.4,2-0.3
						c0.5,0.1,0.9,0.3,1,0.7"/>
				</g>
				<path class="st7" d="M82.4,123.9c0,0-0.4,0-1.1,0.1s-1.6,0.3-2.5,0.7s-1.7,1-2.2,1.5s-0.8,0.8-0.8,0.8s0.2-0.4,0.7-0.9
					s1.2-1.1,2.2-1.6s1.9-0.7,2.6-0.7C82,123.8,82.4,123.9,82.4,123.9L82.4,123.9z"/>
				<path class="st7" d="M82.9,118.7c0,0.1-2.5,1.2-5.7,2.6s-5.7,2.4-5.8,2.4c0-0.1,2.5-1.2,5.7-2.6C80.3,119.7,82.9,118.7,82.9,118.7
					z"/>
				<path class="st7" d="M82.8,120.8c0,0-0.5,0.3-1.3,0.8c-0.8,0.4-2,1-3.3,1.6s-2.5,1.1-3.4,1.5c-0.9,0.3-1.4,0.5-1.4,0.5
					s0.5-0.3,1.4-0.7s2.1-0.9,3.4-1.5s2.4-1.1,3.3-1.5C82.2,121,82.7,120.8,82.8,120.8L82.8,120.8z"/>
			</g>
			<g>
				<g>
					<g>
						<path class="st8" d="M242.2,242.3h83.2c3.2,0,5.8,2.6,5.8,5.8V299c0,3.2-2.6,5.8-5.8,5.8h-83.2c-3.2,0-5.8-2.6-5.8-5.8v-50.9
							C236.5,244.9,239.1,242.3,242.2,242.3L242.2,242.3z"/>
						<path class="st5" d="M325.4,304.8c0,0,0.3,0,0.8-0.1s1.3-0.3,2.1-0.8c0.9-0.5,1.8-1.4,2.4-2.8s0.3-3.1,0.4-5
							c0-7.7,0-18.9-0.1-32.6c0-3.4,0-7.1,0-10.8c0-0.9,0-1.9,0-2.8c0-1,0.1-1.9-0.1-2.9c-0.4-1.8-1.8-3.5-3.6-4.1
							c-0.9-0.4-1.9-0.3-3-0.3c-1,0-2.1,0-3.1,0c-2.1,0-4.2,0-6.4,0c-4.3,0-8.8,0-13.3,0c-18.2,0-38.2,0-59.3,0
							c-1.3,0-2.5,0.4-3.5,1.2s-1.7,1.9-1.9,3.1c-0.1,0.6-0.1,1.2-0.1,1.9v1.9c0,1.3,0,2.6,0,3.8c0,5.1,0,10.2,0,15.1
							c0,9.9,0,19.5,0,28.6c0,1.1,0.1,2.2,0.6,3.2s1.2,1.8,2.2,2.3c0.9,0.5,2,0.8,3,0.8c1.1,0,2.2,0,3.3,0c4.3,0,8.5,0,12.6,0
							c8.1,0,15.6,0,22.5,0c13.8,0,24.9,0.1,32.6,0.1c3.8,0,6.8,0,8.9,0c1,0,1.8,0,2.3,0C325.2,304.7,325.5,304.8,325.4,304.8
							c0,0-0.3,0-0.8,0s-1.3,0-2.3,0c-2,0-5,0-8.9,0c-7.7,0-18.9,0-32.6,0.1c-6.9,0-14.4,0-22.5,0c-4.1,0-8.2,0-12.6,0
							c-1.1,0-2.2,0-3.3,0s-2.3-0.2-3.3-0.8c-1-0.6-1.8-1.5-2.4-2.5c-0.6-1-0.7-2.3-0.6-3.4c0-9.1,0-18.7,0-28.6c0-5,0-10,0-15.1
							c0-1.3,0-2.6,0-3.8v-1.9c0-0.6,0-1.3,0.1-2c0.3-1.3,1-2.5,2.1-3.4s2.4-1.3,3.8-1.3c21,0,41,0,59.3,0c4.6,0,9,0,13.3,0
							c2.2,0,4.3,0,6.4,0c1.1,0,2.1,0,3.1,0s2.1,0,3.1,0.3c2,0.7,3.5,2.5,3.9,4.4c0.2,1,0.1,2,0.1,2.9c0,1,0,1.9,0,2.8
							c0,3.8,0,7.4,0,10.8c0,13.8-0.1,24.9-0.1,32.6c0,1,0,1.9,0,2.7c0,0.9-0.1,1.7-0.4,2.4c-0.6,1.4-1.6,2.3-2.4,2.8
							c-0.9,0.5-1.7,0.7-2.2,0.7C325.7,304.7,325.5,304.7,325.4,304.8L325.4,304.8z"/>
					</g>
					<g>
						<rect x="278.9" y="278.6" class="cp" width="3.6" height="14.5"/>
						<rect x="248.6" y="276.5" class="cp" width="3.6" height="16.6"/>
						<rect x="256.2" y="273" class="cp" width="3.6" height="20"/>
						<rect x="263.8" y="274.1" class="cp" width="3.6" height="18.9"/>
						<rect x="271.3" y="267.7" class="cp" width="3.6" height="25.3"/>
						<rect x="286.5" y="267.8" class="cp" width="3.6" height="25.2"/>
						<rect x="294.1" y="276.1" class="cp" width="3.6" height="16.9"/>
						<rect x="301.7" y="269" class="cp" width="3.6" height="24.1"/>
						<rect x="309.3" y="263.5" class="cp" width="3.6" height="29.5"/>
						<rect x="316.9" y="268.8" class="cp" width="3.6" height="24.2"/>
					</g>
				</g>
				<path class="st5" d="M275.5,254.6c0,0.2-6.5,0.3-14.4,0.3c-8,0-14.4-0.1-14.4-0.3s6.5-0.3,14.4-0.3
					C269.1,254.3,275.5,254.4,275.5,254.6z"/>
			</g>
			<g>
				<g>
					<path class="st10" d="M386.1,204.7l3.9,4.9v-8.4h12.3c0,0,1.4,19.2,1.1,23.3c-0.1,1.4-0.7,3.8-1.5,6.3c-1.6,5.1-8.1,6.7-11.9,3
						c-1.3-1.3-2.6-2.7-3.8-4.3c-5.5-7-14.5-24.2-14.5-24.2l9.5-5.7L386.1,204.7L386.1,204.7z"/>
					<path class="st11" d="M385.3,203.8c-0.1,0.1,0.6,0.9,1.6,2.1s2.5,3.1,4.1,5s3.2,3.6,4.4,4.8c1.2,1.2,2,1.7,2.1,1.7
						c0.1-0.1-0.7-0.8-1.8-2c-1.1-1.2-2.6-2.9-4.3-4.8C388.2,206.7,385.4,203.7,385.3,203.8L385.3,203.8z"/>
				</g>
				<g>
					<g>
						<path class="st10" d="M452.3,94.9l0.6,44.7c0.1,7.6-1.3,18.8-13.4,18.4l0,0c-10.4-1.1-13.2-11-13.2-18.1c0-3.4-0.1-6.2-0.1-6.5
							c0,0-11.4-0.9-12.4-12.2c-0.5-5.4-0.5-16.7-0.4-26.9c0.1-11,8.6-20,19.5-20.8l1.1-0.1C445.7,73.3,452.5,83.2,452.3,94.9
							L452.3,94.9z"/>
						<path class="st11" d="M426.2,133.4c0,0,7.1-0.1,14.3-5.4c0,0-3,8.5-14.3,8.3V133.4z"/>
					</g>
					<g>
						<g>
							<path class="st4" d="M432.6,98.9c0.2,0.2,1.5-0.8,3.4-0.9s3.3,0.9,3.5,0.6c0.1-0.1-0.1-0.5-0.8-1c-0.6-0.4-1.6-0.8-2.8-0.8
								c-1.2,0-2.1,0.5-2.7,1C432.7,98.4,432.5,98.8,432.6,98.9L432.6,98.9z"/>
							<path class="st4" d="M431.6,103.5c0,0.8,0.7,1.4,1.6,1.4c0.8,0,1.5-0.7,1.5-1.5s-0.7-1.4-1.6-1.4S431.6,102.7,431.6,103.5z"/>
							<path class="st4" d="M427.9,112.1c0-0.1-1.1-0.2-2.8-0.3c-0.4,0-0.9-0.1-1-0.4s0-0.8,0.2-1.3c0.3-1.1,0.7-2.2,1.1-3.5
								c1.5-4.9,2.5-9,2.3-9c-0.2-0.1-1.6,3.9-3,8.8c-0.4,1.2-0.7,2.4-1,3.5c-0.1,0.5-0.4,1.1-0.1,1.7c0.1,0.3,0.5,0.5,0.8,0.6
								s0.5,0.1,0.8,0C426.8,112.2,427.9,112.1,427.9,112.1L427.9,112.1z"/>
						</g>
						<path class="st4" d="M433.4,90.9c0.2,0.4,1.7,0.1,3.5,0.2s3.3,0.5,3.5,0.1c0.1-0.2-0.2-0.6-0.8-0.9c-0.6-0.4-1.6-0.7-2.6-0.7
							c-1.1,0-2.1,0.2-2.7,0.5C433.7,90.4,433.3,90.7,433.4,90.9L433.4,90.9z"/>
						<path class="st4" d="M415.5,93.7c0.2,0.3,1.7,0,3.4-0.1c1.8-0.1,3.3,0.2,3.4-0.2c0.1-0.2-0.2-0.5-0.8-0.7
							c-0.6-0.3-1.6-0.5-2.6-0.4c-1.1,0-2,0.3-2.6,0.7C415.7,93.3,415.4,93.6,415.5,93.7L415.5,93.7z"/>
					</g>
					<path class="st10" d="M452,103.3c0.2-0.1,7.6-2.9,7.9,4.9s-7.5,6.4-7.5,6.2C452.3,114.2,452,103.3,452,103.3L452,103.3z"/>
					<path class="st11" d="M454.6,111.4c0,0,0.1,0.1,0.4,0.2c0.2,0.1,0.6,0.1,1,0c0.8-0.3,1.4-1.7,1.4-3c0-0.7-0.2-1.4-0.5-1.9
						c-0.3-0.6-0.6-0.9-1.1-1c-0.4-0.1-0.7,0.2-0.8,0.4c-0.1,0.2,0,0.4-0.1,0.4c0,0-0.2-0.1-0.1-0.4c0-0.2,0.1-0.3,0.3-0.5
						c0.2-0.2,0.4-0.3,0.7-0.2c0.6,0,1.2,0.5,1.5,1.1s0.5,1.3,0.6,2.1c0,1.6-0.7,3-1.8,3.4c-0.5,0.2-1,0-1.2-0.1
						C454.6,111.6,454.6,111.4,454.6,111.4L454.6,111.4z"/>
					<path class="st4" d="M429.7,117.4c-0.1-0.2,1.4-0.7,2.5-2.1c0.5-0.7,0.8-1.5,1.1-2c0.2-0.6,0.4-0.9,0.5-0.9s0.1,0.4-0.1,1
						c-0.1,0.6-0.4,1.5-1,2.3s-1.4,1.3-2,1.5C430.1,117.5,429.7,117.5,429.7,117.4L429.7,117.4z"/>
					<path d="M398.4,84.2c0.1-0.5,0.5-0.8,0.9-1.1c0.9-0.5,1.9-0.4,2.9-0.5c3,0,5.9-1,8.5-2.6c1.2-1.7,2.5-3.3,3.9-4.8
						c3.4-3.5,6.2-5.9,9.1-7.1c3.8-1.5,10-2.1,13.9-1.1s7.4,4.2,8,8.2c2.2-1.9,5.5-1.8,8.2-0.8h0.1c3,1.1,4.7,4.2,5.6,7.2
						s1.3,6.3,2.9,9c0.3,0.6,0.7,1.3,0.4,1.8c-0.2,0.5-0.8,0.7-1.3,0.6s-1-0.4-1.4-0.6c0.3,0.8,0.7,1.6,1,2.4
						c-1.1,0.2-2.2,0.1-3.2-0.2c-0.6,2.8-1.4,6.1-2.1,8c-1.2,0-2.4,0.1-3.2,0.8c-1,0.9-1.5,2.7-3.2,2c-0.9-0.3-1.7-2.2-2-3.1
						c-2-6-2.6-12.4-1.9-18.6c-1.2-1.3-2.7-2.4-4.4-3.1l0.1,0.1c-3.1-1.1-6.7-2.2-9.7-0.7c-2.3,1.1-3.7,3.6-5.4,5.5
						c-3.3,3.6-7.7,5.6-12.6,5.8c-0.2,3.2,0.1,7.7-0.1,10.8l-2.7-5.1l0,0c-0.2-0.4-0.4-0.7-0.6-1.1l-0.1-0.1l0,0
						c-0.3-0.6-0.5-1.2-0.9-1.8l0,0c-1.7,1.5-4.2-0.8-2.9-2.6c0.3-0.4,0.6-0.7,1-1c-0.8,0.7-2,1.1-3,0.8s-1.8-1.4-1.7-2.4
						c0.1-0.7,0.6-1.4,1.2-1.7c-1.4,0.1-2.8-0.2-4-0.9C399,85.9,398.2,85.2,398.4,84.2L398.4,84.2z"/>
					<path class="st5" d="M442.1,76.7c0,0,0.5,0.3,1.1,1c0.7,0.7,1.4,2,1.7,3.7c0.2,0.9,0.3,1.8,0.3,2.9c0,1-0.1,2.1-0.2,3.3
						c-0.1,1.2-0.2,2.4-0.1,3.7c0,0.6,0.1,1.3,0.3,2c0.1,0.6,0.3,1.3,0.8,1.9c0.4,0.4,1,0.7,1.6,0.5c0.6-0.1,1.1-0.4,1.4-0.8
						c0.7-0.8,0.9-1.9,1.1-2.9h-0.2c0.4,0.8,0.8,1.7,1.4,2.4s1.4,1.1,2.2,1.2c0.4,0,0.8,0,1.2-0.2c0.4-0.2,0.6-0.5,0.8-0.8
						c0.2-0.3,0.3-0.7,0.2-1c0-0.3-0.1-0.7-0.4-0.9l-0.1,0.1c0.9,1,1.8,1.5,2.4,1.8c0.3,0.1,0.6,0.2,0.8,0.3c0.2,0.1,0.3,0.1,0.3,0.1
						s-0.1,0-0.3-0.1c-0.2-0.1-0.4-0.2-0.7-0.3c-0.6-0.3-1.5-0.9-2.3-1.8l-0.1,0.1c0.4,0.4,0.4,1.1,0.1,1.7c-0.2,0.3-0.4,0.5-0.7,0.7
						c-0.3,0.1-0.7,0.2-1.1,0.2c-0.7-0.1-1.5-0.5-2-1.1c-0.6-0.6-0.9-1.5-1.3-2.3l-0.2-0.4l-0.1,0.4c-0.2,0.9-0.4,2-1,2.7
						c-0.3,0.4-0.8,0.6-1.3,0.7s-1-0.1-1.3-0.5c-0.4-0.5-0.6-1.1-0.7-1.8c-0.1-0.6-0.2-1.3-0.3-1.9c-0.1-1.3,0-2.5,0.1-3.7
						s0.1-2.3,0.1-3.3c0-1.1-0.1-2-0.3-2.9c-0.4-1.8-1.1-3-1.8-3.8C442.6,76.8,442.1,76.6,442.1,76.7L442.1,76.7z"/>
					<path class="st5" d="M407.2,87.6C407.2,87.6,407.3,87.7,407.2,87.6c0.2,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.3,0.2,0.5,0.3
						c0.2,0.1,0.4,0.3,0.7,0.4c1.1,0.5,2.7,1,4.8,0.9c2.1-0.1,4.6-0.9,6.8-2.5c2.2-1.6,4.2-4,5.8-6.7c0.8-1.4,1.5-2.8,2.2-4.1
						c0.7-1.3,1.5-2.5,2.5-3.4s2.1-1.6,3.2-2c0.6-0.2,1.2-0.2,1.7-0.2c0.6,0,1.1,0.1,1.6,0.3c1,0.3,1.7,0.9,2.3,1.4
						c0.6,0.6,1,1.1,1.3,1.6c0.5,1,0.5,1.7,0.6,1.7c0,0,0,0,0-0.1s0-0.2-0.1-0.3c-0.1-0.3-0.2-0.7-0.4-1.3c-0.3-0.5-0.7-1.1-1.3-1.7
						c-0.6-0.6-1.4-1.1-2.4-1.5c-0.5-0.2-1-0.3-1.6-0.3s-1.2,0-1.8,0.2c-1.2,0.3-2.4,1-3.4,2s-1.8,2.2-2.6,3.5c-0.7,1.3-1.4,2.7-2.2,4
						c-1.6,2.7-3.6,5.1-5.8,6.7c-2.2,1.6-4.6,2.4-6.7,2.5s-3.7-0.3-4.8-0.8C407.8,88,407.3,87.6,407.2,87.6L407.2,87.6z"/>
					<path class="st5" d="M406.2,82.7c0,0,0.1,0.1,0.4,0.3c0.3,0.2,0.7,0.4,1.2,0.6s1.2,0.4,1.9,0.6c0.7,0.2,1.6,0.3,2.4,0.3
						c0.9,0,1.7,0,2.4-0.2c0.7-0.1,1.4-0.3,1.9-0.5s1-0.4,1.2-0.5s0.4-0.2,0.4-0.3c0,0-0.6,0.3-1.7,0.6c-0.5,0.2-1.2,0.3-1.9,0.4
						c-0.7,0.1-1.5,0.2-2.4,0.1c-0.8,0-1.7-0.1-2.4-0.3c-0.7-0.2-1.4-0.4-1.9-0.6C406.9,83,406.3,82.6,406.2,82.7L406.2,82.7z"/>
					<path class="st4" d="M415.4,98.9c0.2,0.2,1.5-0.8,3.4-0.9s3.3,0.9,3.5,0.6c0.1-0.1-0.1-0.5-0.8-1c-0.6-0.4-1.6-0.8-2.8-0.8
						c-1.2,0-2.1,0.5-2.7,1C415.5,98.4,415.3,98.8,415.4,98.9L415.4,98.9z"/>
					<path class="st4" d="M415.8,103.5c0,0.8,0.7,1.4,1.6,1.4c0.8,0,1.5-0.7,1.5-1.5s-0.7-1.5-1.6-1.4
						C416.5,102,415.8,102.7,415.8,103.5z"/>
				</g>
				<g>
					<path class="cp" d="M474.6,435.1l2.3,28.1l8.3,21.7c0.6,1.6-0.3,3.3-1.9,3.8l0,0c-0.9,0.3-1.9,0.1-2.6-0.5
						c-4.7-3.7-21.7-17.1-21.9-18.8c-0.3-2.3-4.6-29.9-4.6-29.9L474.6,435.1L474.6,435.1z"/>
					<path class="st5" d="M483.3,488.7l-25-22.5l0.2,2.1c0.2,1.1,0.2,1.8,0.9,2.5c2,1.9,7.9,7.7,20.9,17.9
						C481.2,489.3,482.4,489,483.3,488.7L483.3,488.7L483.3,488.7z"/>
					<path class="st5" d="M463.2,457.4c0.8,0.4,1.1,1.5,0.7,2.2c-0.4,0.8-1.4,1.1-2.2,0.7s-1.2-1.6-0.7-2.4c0.5-0.7,1.7-1.1,2.4-0.5"
						/>
					<path class="st5" d="M475.6,477.1c0.2,0.1,0.6-1.5,2.2-2.4c1.6-1,3.1-0.8,3.2-1c0-0.1-0.4-0.3-1-0.3s-1.6,0.2-2.5,0.7
						s-1.4,1.3-1.7,1.9C475.5,476.7,475.5,477.1,475.6,477.1z"/>
					<path class="st5" d="M478,480.6c0.1,0.1,0.9-0.9,2.4-1.5c1.4-0.6,2.7-0.5,2.7-0.6c0.1-0.1-1.3-0.6-2.9,0.1
						C478.5,479.2,477.8,480.6,478,480.6L478,480.6z"/>
					<path class="st5" d="M471.4,472.7c0.1,0.1,1.4-1.4,3.5-2.5s4.1-1.2,4.1-1.4c0-0.2-2.1-0.3-4.3,0.9
						C472.3,470.8,471.2,472.6,471.4,472.7L471.4,472.7z"/>
					<path class="st5" d="M469.9,464.2c0.1,0.2,1.6-0.7,3.6-0.9c2-0.3,3.7,0,3.7-0.1c0.1-0.1-1.6-0.8-3.8-0.5
						C471.3,463,469.8,464.1,469.9,464.2L469.9,464.2z"/>
					<path class="st5" d="M469,455.9c0.1,0.1,1.6-0.9,3.7-1.1s3.7,0.7,3.8,0.5c0-0.1-0.3-0.4-1-0.7c-0.7-0.3-1.7-0.5-2.9-0.5
						c-1.2,0.1-2.1,0.5-2.8,0.9C469.3,455.5,469,455.9,469,455.9z"/>
					<path class="st5" d="M472.5,453.3c0,0,0.1-0.4-0.1-1.1c-0.1-0.7-0.4-1.7-1.1-2.7c-0.4-0.5-0.7-1.1-1.6-1.4c-0.4-0.1-1,0-1.3,0.4
						s-0.3,0.9-0.3,1.3c0.2,1.8,1.9,3.3,4,3.4c2,0,3.7-1.3,4.4-3c0.1-0.4,0.2-0.9-0.1-1.4c-0.3-0.5-0.9-0.6-1.2-0.5
						c-0.8,0.2-1.3,0.8-1.7,1.2c-0.9,0.9-1.4,1.8-1.7,2.4c-0.3,0.6-0.4,1-0.3,1c0.1,0.1,0.6-1.5,2.3-3.1c0.4-0.4,0.9-0.9,1.5-1
						c0.3-0.1,0.5,0,0.7,0.3c0.1,0.2,0.1,0.6,0,0.9c-0.5,1.3-2.1,2.5-3.8,2.5c-1.7,0-3.2-1.4-3.4-2.8c0-0.3,0-0.7,0.2-0.9
						c0.2-0.2,0.4-0.3,0.7-0.2c0.6,0.2,1,0.7,1.3,1.2C472.3,451.8,472.4,453.4,472.5,453.3L472.5,453.3z"/>
				</g>
				<g>
					<path class="cp" d="M408,436.5l1,23.8c0,0-23.8,9-24.1,13.5l46.2-0.4l-0.1-37.2L408,436.5L408,436.5z"/>
					<path class="st5" d="M423.3,458.2c0.9,0.2,1.5,1.2,1.3,2.1s-1.2,1.6-2.1,1.3c-0.9-0.2-1.7-1.4-1.4-2.3c0.3-0.9,1.5-1.6,2.4-1.1"
						/>
					<path class="st5" d="M431.2,473.4l-0.2-3.7l-44.3,1.7c0,0-2.1,0.9-1.8,2.4L431.2,473.4z"/>
					<path class="st5" d="M408.3,460.1c0,0.2,1.1,0.3,2.2,1s1.7,1.7,1.9,1.6c0.2,0-0.1-1.4-1.5-2.2
						C409.6,459.6,408.2,459.9,408.3,460.1L408.3,460.1z"/>
					<path class="st5" d="M403.5,462.1c0,0.2,0.9,0.6,1.7,1.5c0.8,0.9,1,2,1.2,1.9c0.2,0,0.4-1.3-0.6-2.5
						C404.8,462,403.5,461.9,403.5,462.1L403.5,462.1z"/>
					<path class="st5" d="M400.8,468c0.2,0,0.5-1.1-0.1-2.3c-0.6-1.2-1.6-1.7-1.7-1.5s0.6,0.8,1,1.9C400.4,467,400.5,467.9,400.8,468
						L400.8,468z"/>
					<path class="st5" d="M408.7,454.6c0.1,0.2,1.1-0.1,2.4,0c1.3,0,2.3,0.5,2.4,0.3s-0.8-1-2.4-1.1
						C409.6,453.7,408.5,454.5,408.7,454.6L408.7,454.6z"/>
				</g>
				<g>
					<g>
						<path class="st5" d="M400,265.6c0,1.4,5.4,191.6,5.4,191.6H434l5.8-162.7c0,0,6.8,85.1,6.8,86.9s6,76.7,6,76.7l26.5-2.2
							l-1.7-171.3l-3.2-25.1L400,265.6L400,265.6z"/>
						<path d="M442.7,288.5c0,0-0.4,0.2-1.2,0.5c-0.8,0.3-1.9,0.7-3.2,1.1l-0.3,0.1v-0.3c-0.2-2.3-0.3-5.3-0.5-8.7
							c-0.2-3.6-0.3-6.9-0.4-9.3c0-1.2-0.1-2.2,0-2.8c0-0.7,0-1,0.1-1c0,0,0.1,0.4,0.2,1c0.1,0.7,0.1,1.6,0.2,2.8
							c0.2,2.4,0.4,5.7,0.5,9.3c0.2,3.4,0.3,6.4,0.4,8.7l-0.3-0.2c1.4-0.4,2.5-0.7,3.3-0.9C442.2,288.6,442.6,288.5,442.7,288.5
							L442.7,288.5z"/>
						<path d="M440.5,268.7c0,0,0.1-0.1,0.4-0.3c0.3-0.1,0.8-0.1,1.3,0.1s1,0.8,1,1.6c-0.1,0.7-0.6,1.5-1.5,1.7s-1.7-0.3-2.1-1
							c-0.4-0.6-0.3-1.4,0-1.8c0.3-0.5,0.7-0.7,0.9-0.8c0.3-0.1,0.4-0.1,0.5-0.1c0,0.1-0.6,0.2-1.1,1c-0.2,0.4-0.3,0.9,0.1,1.4
							c0.3,0.4,0.9,0.8,1.6,0.7c0.6-0.1,1.1-0.7,1.1-1.2c0.1-0.5-0.3-1-0.6-1.2C441.1,268.4,440.5,268.8,440.5,268.7L440.5,268.7z"/>
						<path d="M453.6,270.7c-0.2,0-0.4-0.8-0.5-1.8s-0.1-1.9,0.1-1.9s0.4,0.8,0.5,1.8C453.8,269.8,453.8,270.7,453.6,270.7z"/>
						<path d="M455.6,270.5c-0.2,0-0.3-0.9-0.3-2s0.1-2,0.3-2s0.3,0.9,0.3,2S455.7,270.5,455.6,270.5z"/>
						<path d="M421.1,271.8c-0.2,0-0.4-1-0.5-2.2s-0.1-2.2,0-2.3c0.2,0,0.4,1,0.5,2.2C421.3,270.8,421.3,271.8,421.1,271.8z"/>
						<path d="M423.5,272c-0.1,0.1-0.6-1-0.7-2.3c-0.1-1.4,0.4-2.4,0.5-2.4c0.2,0,0,1.1,0.1,2.3C423.5,270.9,423.7,271.9,423.5,272
							L423.5,272z"/>
					</g>
					<path d="M414.5,269.4c0,0,0,0.3-0.1,0.8s-0.3,1.3-0.6,2.2c-0.6,1.9-1.7,4.4-3.5,6.8s-3.9,4.3-5.6,5.4c-0.8,0.6-1.5,1-2,1.2
						c-0.5,0.3-0.8,0.4-0.8,0.4c0-0.1,1-0.7,2.6-1.9s3.6-3,5.4-5.4s2.9-4.8,3.6-6.6C414.2,270.6,414.4,269.4,414.5,269.4L414.5,269.4z
						"/>
					<path d="M476.3,282.2c0,0-0.3,0.1-0.9,0.1s-1.4,0.1-2.4,0c-2-0.2-4.8-0.9-7.3-2.7c-2.5-1.8-4.1-4.1-4.9-5.8
						c-0.4-0.9-0.6-1.6-0.8-2.2c-0.1-0.5-0.1-0.8-0.1-0.8c0.1,0,0.3,1.1,1.2,2.8c0.8,1.7,2.4,3.9,4.9,5.6c2.4,1.7,5.1,2.5,7.1,2.8
						C475,282.3,476.3,282.2,476.3,282.2L476.3,282.2z"/>
					<path d="M429.7,378.6c0,0.1-4.8-1-10.6-2.4c-5.8-1.5-10.5-2.8-10.4-2.9c0-0.1,4.8,1,10.6,2.4
						C425.1,377.1,429.8,378.4,429.7,378.6L429.7,378.6z"/>
					<path d="M475.8,371.6c0.1,0.1-4.1,2.4-9.2,5.1c-5.2,2.7-9.4,4.8-9.4,4.7c-0.1-0.1,4.1-2.4,9.2-5.1S475.7,371.5,475.8,371.6z"/>
				</g>
				<g>
					<polygon class="st10" points="414.2,145.6 427.1,140.5 451,139.2 462.3,143.4 446.9,152 433.6,151.5"/>
					<path class="cp" d="M398.9,267.7c1.2,0.2,79.5,3.5,79.5,3.5l-2.8-70.4l-0.2-13.8l-4.5-41.9c0,0-11.7-4-17.7-5.9
						c0,0-6.3,6.6-18.2,6.4c-11.7-0.2-14.1-2.8-14.1-2.8l-16.3,6.3c0,0-11.6,4.2-13.6,9.3c-1.9,5-5.5,33.6-5.5,33.6h16.9l-0.3,50.5
						L398.9,267.7L398.9,267.7z"/>
					<g class="st12">
						<polyline points="448.1,173.7 450.5,191.5 462.3,196.7 471,189.3 471,172.8 448.1,173.7"/>
					</g>
					<path class="st6" d="M470.7,178.8c0,0.1-4.9,0.3-10.9,0.3s-10.9-0.1-10.9-0.3s4.9-0.3,10.9-0.3
						C465.8,178.5,470.7,178.6,470.7,178.8z"/>
					<g>
						<g>
							<path class="st6" d="M393.6,165.6c0,0,0.1,0.2,0.1,0.5c0.1,0.4,0.2,0.8,0.3,1.4c0.3,1.2,0.8,2.9,1.6,5c0.8,2.1,1.9,4.6,3.2,7.3
								c1.3,2.8,2.4,6.1,3.6,9.6l0,0l0,0c0,4.2-0.1,8.8-0.1,13.7c-0.1,10.8-0.2,20.5-0.3,27.6c-0.1,3.5-0.1,6.3-0.1,8.4
								c0,0.9,0,1.7-0.1,2.3c0,0.5,0,0.8-0.1,0.8s0-0.3,0-0.8c0-0.6,0-1.3,0-2.3c0-2,0-4.9,0-8.4c0-7.1,0.1-16.9,0.1-27.6
								c0-4.8,0.1-9.4,0.1-13.7v0.1c-1.2-3.5-2.3-6.8-3.5-9.6c-1.3-2.8-2.4-5.3-3.1-7.4c-0.8-2.1-1.2-3.9-1.4-5.1
								c-0.1-0.6-0.2-1.1-0.2-1.4C393.6,165.8,393.6,165.6,393.6,165.6L393.6,165.6z"/>
							<path class="st4" d="M476.6,157.4c0,0,0,0.5,0,1.5c0,1.1-0.1,2.4-0.1,4.1c-0.1,3.5-0.3,8.3-0.5,13.5s-0.4,9.9-0.6,13.5
								c-0.1,1.7-0.2,3-0.2,4.1c-0.1,1-0.1,1.5-0.2,1.5c0,0,0-0.5,0-1.5c0-1.1,0-2.4,0.1-4.1c0.1-3.5,0.2-8.2,0.5-13.5
								c0.2-5.3,0.5-10,0.7-13.5c0.1-1.7,0.2-3.1,0.3-4.1C476.5,157.9,476.6,157.4,476.6,157.4L476.6,157.4z"/>
							<path class="st6" d="M448.9,203.8c0,0,0,0.2-0.1,0.4c-0.1,0.4-0.2,0.8-0.3,1.3c-0.1,0.5-0.3,1.2-0.5,2s-0.5,1.7-0.8,2.6
								c-0.6,1.9-1.6,4.2-2.7,6.6c-1.2,2.4-2.6,5-4.3,7.6c-1.7,2.6-3.6,4.9-5.3,6.9c-1.8,2-3.5,3.7-5,5c-0.7,0.7-1.5,1.2-2.1,1.7
								s-1.2,0.9-1.7,1.2c-0.4,0.3-0.8,0.5-1.1,0.7c-0.3,0.2-0.4,0.2-0.4,0.2s0.1-0.1,0.3-0.3c0.3-0.2,0.6-0.5,1-0.8s1-0.8,1.6-1.3
								s1.3-1.1,2-1.8c1.5-1.3,3.2-3.1,4.9-5c1.7-2,3.5-4.3,5.2-6.9s3.1-5.1,4.3-7.5c1.2-2.4,2.1-4.6,2.8-6.5c0.4-0.9,0.6-1.8,0.9-2.6
								s0.5-1.4,0.6-2c0.1-0.5,0.3-0.9,0.4-1.2C448.8,204,448.9,203.8,448.9,203.8L448.9,203.8z"/>
						</g>
						<path class="st6" d="M446.6,181.8c0,0,0,0.3-0.1,0.7c-0.1,0.5-0.2,1.2-0.4,2c-0.1,0.9-0.4,2-0.7,3.2c-0.3,1.2-0.6,2.7-1.1,4.2
							c-0.2,0.8-0.5,1.6-0.7,2.4c-0.3,0.8-0.6,1.7-0.9,2.6c-0.6,1.8-1.4,3.7-2.2,5.7c-1.7,3.9-3.8,8.1-6.3,12.3
							c-2.6,4.2-5.4,8-8.1,11.3c-1.4,1.6-2.7,3.2-4,4.5c-0.7,0.7-1.3,1.4-1.9,2s-1.3,1.2-1.8,1.7c-1.2,1.1-2.3,2.1-3.3,2.9
							s-1.8,1.5-2.6,2.1c-0.7,0.5-1.2,0.9-1.7,1.3c-0.4,0.3-0.6,0.4-0.6,0.4s0.2-0.2,0.5-0.5c0.4-0.3,0.9-0.8,1.6-1.3
							c0.7-0.6,1.6-1.3,2.5-2.1c0.9-0.9,2.1-1.8,3.2-2.9c0.6-0.6,1.2-1.2,1.8-1.8c0.6-0.6,1.2-1.3,1.9-2c1.3-1.3,2.6-2.9,4-4.5
							c2.7-3.3,5.4-7.1,8-11.2c2.5-4.2,4.6-8.4,6.4-12.3c0.8-2,1.6-3.8,2.2-5.6c0.3-0.9,0.7-1.7,0.9-2.6c0.3-0.8,0.5-1.6,0.7-2.4
							c0.5-1.5,0.8-2.9,1.2-4.2c0.3-1.2,0.6-2.3,0.8-3.2c0.2-0.8,0.3-1.5,0.5-2C446.6,182.1,446.6,181.8,446.6,181.8L446.6,181.8z"/>
					</g>
				</g>
				<g>
					<g>
						<g>
							<path class="st3" d="M384.7,159.9c4.8,7.3,24.4,37.1,30.1,45.9c0.6,1-0.2,2.2-1.3,2.1l-34.7-4.4c-0.4,0-0.7-0.2-0.9-0.5
								l-33.2-45.3c-0.7-0.9,0-2.2,1.2-2.2l35.7,2.6C382.9,158.2,384,158.8,384.7,159.9L384.7,159.9z"/>
							<path class="st5" d="M384.7,159.9c0,0-0.1-0.2-0.3-0.4c-0.2-0.3-0.6-0.6-1.1-0.9c-0.6-0.3-1.4-0.5-2.3-0.6s-2-0.2-3.2-0.3
								c-4.9-0.4-11.9-0.9-20.6-1.6c-2.2-0.2-4.5-0.3-6.8-0.5c-1.2-0.1-2.4-0.2-3.6-0.3c-0.3,0-0.6-0.1-1-0.1s-0.7,0.2-1,0.4
								c-0.6,0.5-0.7,1.4-0.3,2c3,4.1,6.3,8.6,9.7,13.2c6.8,9.3,14.3,19.5,22.1,30.3c0.5,0.7,0.9,1.3,1.5,2c0.2,0.2,0.4,0.3,0.6,0.4
								c0.2,0.1,0.5,0.1,0.7,0.1l1.2,0.1c1.6,0.2,3.3,0.4,4.9,0.6c3.2,0.4,6.4,0.8,9.5,1.2c6.2,0.8,12.2,1.5,18,2.2
								c0.4,0,0.7,0.1,1.1,0.1s0.9-0.2,1.2-0.5c0.3-0.3,0.5-0.7,0.5-1.1c0-0.4-0.2-0.9-0.4-1.1c-0.8-1.2-1.5-2.3-2.3-3.5
								c-1.5-2.3-3-4.5-4.4-6.6c-2.8-4.3-5.5-8.3-7.9-11.9c-4.8-7.3-8.7-13.1-11.4-17.2c-1.3-2-2.4-3.6-3.1-4.7
								c-0.4-0.5-0.6-0.9-0.8-1.2C384.8,160,384.7,159.9,384.7,159.9s0.1,0.2,0.3,0.5c0.2,0.3,0.4,0.7,0.8,1.2c0.7,1.1,1.7,2.7,3,4.7
								c2.7,4.1,6.5,10,11.3,17.3c2.4,3.7,5,7.7,7.8,11.9c1.4,2.1,2.9,4.4,4.4,6.6c0.8,1.1,1.5,2.3,2.3,3.5c0.2,0.3,0.3,0.5,0.3,0.8
								s-0.1,0.6-0.3,0.8c-0.2,0.2-0.5,0.3-0.8,0.3s-0.7-0.1-1-0.1c-5.7-0.7-11.7-1.5-18-2.3c-3.1-0.4-6.3-0.8-9.5-1.2
								c-1.6-0.2-3.2-0.4-4.9-0.6l-1.2-0.2c-0.2,0-0.4,0-0.5-0.1s-0.3-0.1-0.4-0.3c-0.5-0.7-1-1.3-1.5-2c-7.9-10.7-15.4-21-22.2-30.3
								c-3.4-4.6-6.6-9.1-9.7-13.2c-0.3-0.4-0.2-1.1,0.2-1.4c0.2-0.2,0.4-0.3,0.7-0.3c0.3,0,0.6,0,0.9,0.1c1.2,0.1,2.4,0.2,3.6,0.3
								c2.4,0.2,4.6,0.3,6.8,0.5c8.7,0.6,15.7,1.1,20.6,1.4c1.2,0.1,2.3,0.1,3.2,0.2c0.9,0,1.7,0.2,2.3,0.5
								C384.4,159.3,384.7,159.9,384.7,159.9L384.7,159.9z"/>
						</g>
						<path class="st5" d="M344.8,157.6c0,0,0.3,0.1,0.7,0.1c0.5,0.1,1.2,0.1,2.1,0.2c1.9,0.2,4.6,0.4,7.8,0.7
							c6.7,0.5,16.1,1.3,26.7,2.2l-0.2-0.1c1.7,2.5,3.4,5.1,5.1,7.7c7,10.8,13.2,20.8,17.8,27.9c2.3,3.6,4.2,6.4,5.6,8.3
							c0.7,0.9,1.2,1.7,1.6,2.2s0.6,0.8,0.6,0.8s-0.1-0.3-0.5-0.8c-0.4-0.6-0.9-1.3-1.5-2.3c-1.3-2-3.2-4.9-5.4-8.4
							c-4.6-7.1-10.7-17.1-17.7-27.9c-1.7-2.7-3.5-5.2-5.1-7.7l-0.1-0.1h-0.1c-10.6-0.8-20-1.5-26.7-2c-3.3-0.2-6-0.4-7.9-0.5
							c-0.9,0-1.6-0.1-2.1-0.1C345,157.6,344.8,157.6,344.8,157.6L344.8,157.6z"/>
						<g>
							<path class="st8" d="M366.3,164.1L366.3,164.1L366.3,164.1L366.3,164.1z"/>
							<path class="st8" d="M365.4,164.6c0.1-0.4,0.5-0.6,0.9-0.5s0.6,0.2,0.9,0.5s0.4,0.8,0.2,1.2c-0.3,0.4-1.1,0.3-1.5-0.1
								C365.6,165.4,365.3,165,365.4,164.6L365.4,164.6z"/>
						</g>
					</g>
					<path class="st10" d="M381.6,190.3c0,0,1.4-3.3-2.1-3.7s-12.6,0-12.6,0s-5,0.5-3.9,4.1c0,0-2.9,2.6-1.4,5.5c1.6,2.8,2.2,2,2.2,2
						s-0.4,3.5,2.2,4c0,0,2.9,3.4,5.5,3.2s13.8-5.6,14.5-6.6s1.8-1.7,1.2-2.8c0,0,1.4-3.1-1.6-3.6
						C385.7,192.4,385.4,189.8,381.6,190.3L381.6,190.3z"/>
					<path class="st11" d="M365.8,191.5c0,0.1,0.9,0.1,2.4,0.2c1.5,0,3.4,0,5.7,0c2.2-0.1,4.2,0,5.7-0.3s2.1-1.2,2-1.2
						c-0.1-0.1-0.7,0.6-2.1,0.8s-3.4,0.1-5.6,0.2c-2.2,0-4.2,0.1-5.7,0.2S365.8,191.4,365.8,191.5z"/>
					<path class="st11" d="M368.3,196.8c0,0,0.3,0.1,0.8,0.3c0.5,0.1,1.3,0.2,2.2,0.1c0.9-0.1,2-0.3,3.2-0.6s2.4-0.8,3.7-1.2
						c1.3-0.4,2.6-0.7,3.7-0.8c1.1,0,2.4,0,3.2-0.6c0.4-0.3,0.7-0.7,0.8-1.1c0.1-0.4,0-0.7-0.1-1c-0.3-0.5-0.7-0.5-0.6-0.5
						c0,0,0.3,0.1,0.4,0.6c0.2,0.4,0.1,1.2-0.7,1.6c-0.7,0.5-1.8,0.4-3,0.4s-2.5,0.3-3.9,0.8c-1.3,0.4-2.5,0.8-3.7,1.2
						c-1.1,0.3-2.2,0.6-3.1,0.7C369.4,197,368.3,196.7,368.3,196.8L368.3,196.8z"/>
					<path class="st11" d="M372,201.2c0,0.1,0.9-0.1,2.3-0.5s3.4-0.9,5.5-1.6c2.1-0.7,4-1.4,5.4-2c1.4-0.5,2.2-0.9,2.2-1
						c-0.1-0.1-3.5,1-7.8,2.4C375.4,200,372,201,372,201.2L372,201.2z"/>
					<path class="st11" d="M371.1,205.3c0,0.1,0.8,0,1.9-0.2c1.2-0.3,2.8-0.8,4.5-1.6s3.1-1.5,4.2-2.1s1.7-0.9,1.7-1
						c-0.1-0.2-2.8,1-6.1,2.6S371.1,205.2,371.1,205.3z"/>
				</g>
				<g>
					<path class="st10" d="M482.2,173.9c0,0-7.1,28.4-16,38.9c-8.6,10.2-22.1,5.5-26.7-7.7c-4.7-13.2-13-43.2-13-43.2l10.2-7.8
						l17.6,34.2l7.9-23.3L482.2,173.9L482.2,173.9z"/>
					<g>
						<g>
							<path class="st10" d="M427,162.8l-7.5-10.8c0,0-2.5-0.6-3.2-1c-0.8-0.5-1.7-4.8-1.7-5.6s-1.5-2.8-0.9-3.4s2.6-0.6,2.5-1.4
								c0-0.2-1.5-2-0.3-3.3c0.4-0.5,2.1-0.9,2.1-0.9l-0.1-4.1c0,0,2.2-4.9,4.5-3.1c0,0,8.6,6.6,9.5,7.7c0.9,1.1,4.2,14.8,4.8,17.4"/>
							<path class="st11" d="M436.7,154.2c0,0,0-0.3-0.1-0.7c-0.1-0.5-0.3-1.2-0.4-2.1c-0.4-1.8-1-4.4-1.8-7.6
								c-0.4-1.6-0.9-3.3-1.4-5.2c-0.1-0.5-0.3-0.9-0.5-1.4s-0.6-0.9-1-1.2c-0.8-0.7-1.6-1.4-2.5-2.1c-1.7-1.4-3.5-2.8-5.4-4.3
								c-0.5-0.3-0.9-0.8-1.6-1s-1.4,0.1-1.9,0.5c-1,0.8-1.7,1.9-2.3,3.1v0.1v0.1c0,0.4,0,0.8,0,1.3c0,1,0.1,1.9,0.1,2.9l0.2-0.3
								c-0.5,0.1-1,0.3-1.5,0.5c-0.3,0.1-0.5,0.2-0.7,0.5c-0.2,0.2-0.3,0.5-0.4,0.8c-0.2,0.6-0.1,1.2,0.1,1.6c0.1,0.3,0.2,0.5,0.3,0.7
								l0.2,0.3v0.1l0,0c0,0,0,0,0,0.1s-0.2,0.2-0.4,0.3c-0.2,0.1-0.4,0.2-0.7,0.2c-0.2,0.1-0.5,0.1-0.7,0.2c-0.2,0.1-0.5,0.2-0.7,0.5
								c-0.2,0.4-0.1,0.6,0,0.9c0.1,0.3,0.2,0.5,0.3,0.7c0.2,0.4,0.4,0.9,0.5,1.3c0.1,0.2,0.1,0.4,0.1,0.6c0,0.3,0,0.5,0.1,0.7
								c0.1,0.5,0.1,0.9,0.2,1.3c0.2,0.9,0.4,1.7,0.7,2.5c0.2,0.4,0.3,0.8,0.7,1.2c0.4,0.3,0.8,0.4,1.2,0.5c0.8,0.2,1.5,0.4,2.2,0.6
								l-0.1-0.1c2.3,3.3,4.2,6,5.5,7.9c0.6,0.9,1.2,1.6,1.5,2.1c0.4,0.5,0.5,0.7,0.6,0.7s-0.1-0.3-0.5-0.8s-0.8-1.3-1.5-2.2
								c-1.3-1.9-3.2-4.6-5.4-8v-0.1h-0.1c-0.7-0.2-1.4-0.4-2.2-0.6c-0.3-0.1-0.8-0.3-1-0.4c-0.2-0.2-0.4-0.6-0.5-1
								c-0.3-0.8-0.5-1.6-0.7-2.4c-0.1-0.4-0.2-0.9-0.2-1.3c0-0.2-0.1-0.4-0.1-0.6c0-0.3-0.1-0.5-0.2-0.8c-0.2-0.4-0.4-0.9-0.5-1.3
								c-0.1-0.2-0.2-0.4-0.2-0.6c-0.1-0.2-0.1-0.4,0-0.5c0-0.1,0.3-0.2,0.5-0.3s0.4-0.1,0.7-0.2c0.2-0.1,0.5-0.1,0.7-0.2
								c0.2-0.1,0.5-0.2,0.7-0.6c0-0.1,0.1-0.2,0-0.3c0,0,0-0.1,0-0.2l-0.1-0.1l-0.2-0.3c-0.1-0.2-0.2-0.4-0.3-0.6
								c-0.2-0.4-0.3-0.9-0.1-1.3c0-0.2,0.2-0.4,0.3-0.6c0.1-0.1,0.3-0.2,0.6-0.3c0.4-0.2,0.9-0.4,1.4-0.5l0.2-0.1v-0.2
								c0-0.9-0.1-1.9-0.1-2.9c0-0.4,0-0.9,0-1.3v0.1c0.5-1.1,1.2-2.2,2.1-2.9c0.5-0.4,1-0.5,1.5-0.4s0.9,0.6,1.4,0.9
								c1.9,1.5,3.7,2.9,5.4,4.2c0.9,0.7,1.7,1.4,2.5,2c0.4,0.4,0.8,0.6,0.9,1.1c0.2,0.4,0.3,0.9,0.5,1.4c0.6,1.8,1.1,3.6,1.5,5.2
								c0.8,3.2,1.5,5.7,2,7.5c0.2,0.8,0.4,1.5,0.5,2C436.6,154,436.7,154.2,436.7,154.2L436.7,154.2z"/>
						</g>
						<path class="st11" d="M418.1,136.4c0,0.1,0.9,0,2.2,0.1c0.7,0.1,1.4,0.3,2.4,0.5s2,0.1,3,0c2.1-0.3,3.9,0.6,4.8,1.6
							c1,1,1.1,1.9,1.2,1.8c0,0,0.1-1-0.9-2.1c-0.5-0.5-1.2-1.1-2.1-1.5s-2-0.5-3-0.4c-1.1,0.1-2.1,0.2-2.9,0
							c-0.9-0.2-1.7-0.4-2.4-0.4C418.9,136.1,418.1,136.3,418.1,136.4L418.1,136.4z"/>
						<path class="st11" d="M421.1,136.1c0.1,0.1,0.5-0.4,0.4-1.2c0-0.7-0.4-1.2-0.6-1.1c-0.1,0.1,0,0.5,0,1.1
							C421.1,135.6,421,136.1,421.1,136.1L421.1,136.1z"/>
						<path class="st11" d="M416,140.7c0,0.1,2.9,0.3,6.5-0.5c0.9-0.2,1.7-0.4,2.4-0.3c0.7,0.1,1.2,0.6,1.5,1c0.7,0.9,0.8,1.7,0.9,1.6
							c0,0,0.1-0.8-0.5-1.9c-0.3-0.5-0.9-1.1-1.8-1.3s-1.7,0.1-2.6,0.3C418.9,140.4,416,140.6,416,140.7L416,140.7z"/>
						<path class="st11" d="M414.9,144.6c0,0.1,0.6,0.1,1.6,0s2.3-0.4,3.7-0.7c0.7-0.1,1.4-0.2,2-0.2s1.1,0.2,1.5,0.5
							c0.7,0.6,0.6,1.3,0.7,1.3c0,0,0.1-0.1,0.1-0.4c0-0.3-0.2-0.7-0.5-1.1c-0.4-0.4-1-0.7-1.7-0.7c-0.7-0.1-1.4,0-2.1,0.2
							c-1.5,0.3-2.8,0.7-3.7,0.9C415.5,144.5,414.9,144.5,414.9,144.6L414.9,144.6z"/>
						<path class="st11" d="M417.4,147.7c-0.1,0.1,0.6,0.8,2,0.8l-0.3-0.2c0,0.2,0.1,0.5,0.1,0.7c0.2,1.5,0.5,2.8,0.6,2.7
							c0.1,0,0.1-1.3-0.1-2.8c0-0.2-0.1-0.5-0.1-0.7V148h-0.2C418.3,148.1,417.5,147.6,417.4,147.7L417.4,147.7z"/>
					</g>
				</g>
				<path class="cp" d="M470.9,145.1c0,0,14.5,3.5,14.5,22.8c0,19.2-4.4,28.8-4.4,28.8l-26.8-14.6l6-15L470.9,145.1L470.9,145.1z"/>
				<path class="st6" d="M481,196.7c0,0-0.2-0.1-0.6-0.2c-0.4-0.2-0.9-0.5-1.6-0.8c-1.4-0.7-3.3-1.8-5.7-3
					c-4.9-2.6-11.6-6.2-19.1-10.3l-0.2-0.1l0.1-0.2c0.5-1.2,0.9-2.5,1.4-3.8c1.7-4.5,3.3-8.7,4.8-12.6c0.7-1.9,1.5-3.8,2.1-5.5
					s1.3-3.3,2.1-4.7c1.5-2.8,3.3-4.7,4.8-5.6c0.8-0.5,1.4-0.7,1.9-0.8c0.2,0,0.4-0.1,0.5-0.1s0.2,0,0.2,0c0,0.1-1,0.1-2.5,1
					s-3.2,2.9-4.6,5.6c-0.7,1.4-1.4,2.9-2,4.6c-0.7,1.7-1.3,3.6-2.1,5.5c-1.5,3.9-3.1,8.1-4.8,12.6c-0.5,1.3-1,2.6-1.4,3.8l-0.1-0.3
					c7.5,4.1,14.2,7.8,19,10.5c2.3,1.3,4.2,2.4,5.6,3.2c0.6,0.4,1.1,0.7,1.5,0.9C480.9,196.6,481.1,196.7,481,196.7L481,196.7z"/>
			</g>
			<g>
				<g>
					<g>
						<path class="cp" d="M125.5,416.4l-25.2,23l28.5,31.5c2.8-2.9-5.9-24.7-5.9-24.7l15.7-15.2L125.5,416.4L125.5,416.4z"/>
						<path class="st5" d="M115.5,435.3c-0.7-0.5-1.8-0.3-2.3,0.4c-0.5,0.7-0.3,1.8,0.4,2.2c0.7,0.5,2,0.3,2.4-0.5s0.1-2-0.7-2.3"/>
						<path class="st5" d="M100.4,439.4l2.6-2.4l26.2,31.3c0,0,0.7,2-0.5,2.7L100.4,439.4z"/>
						<path class="st5" d="M123.6,446.6c-0.2,0.1-0.9-0.6-2.1-0.9s-2.2-0.1-2.3-0.3c-0.1-0.2,1-0.8,2.4-0.4
							C123.1,445.4,123.8,446.5,123.6,446.6L123.6,446.6z"/>
						<path class="st5" d="M125.2,451.2c-0.1,0.2-1-0.3-2.1-0.2c-1.1,0-1.9,0.5-2.1,0.4s0.6-1,2-1.1
							C124.4,450.2,125.4,451,125.2,451.2L125.2,451.2z"/>
						<path class="st5" d="M123,456.6c-0.2-0.1,0.3-1,1.6-1.4c1.2-0.4,2.2,0,2.1,0.2c0,0.2-0.9,0.1-1.9,0.5
							C123.8,456.2,123.1,456.7,123,456.6L123,456.6z"/>
						<path class="st5" d="M127.1,443c-0.2,0.1-0.6-0.8-1.5-1.6c-0.8-0.8-1.7-1.2-1.7-1.4c0-0.2,1.2,0,2.2,0.9S127.2,443,127.1,443
							L127.1,443z"/>
						<path class="st5" d="M130.1,438.9c-0.1,0.1-0.7-0.5-1.4-1.6c-0.4-0.6-0.7-1.3-1-2.1c-0.1-0.4-0.3-0.9-0.3-1.3
							c0-0.2-0.1-0.5-0.1-0.8s0.3-0.8,0.8-0.8c0.4-0.1,0.8,0.1,1,0.4c0.2,0.2,0.3,0.5,0.5,0.7c0.2,0.4,0.4,0.9,0.5,1.3
							c0.3,0.8,0.4,1.7,0.4,2.3c0,1.3-0.4,2.1-0.4,2.1c-0.1,0,0-0.8-0.1-2.1c-0.1-0.6-0.2-1.4-0.5-2.1c-0.1-0.4-0.3-0.8-0.5-1.2
							c-0.2-0.4-0.5-0.7-0.7-0.7c-0.1,0-0.1,0-0.1,0.2c0,0.1,0,0.4,0,0.6c0.1,0.4,0.2,0.9,0.3,1.3c0.2,0.8,0.5,1.5,0.8,2.1
							C129.7,438.1,130.2,438.8,130.1,438.9L130.1,438.9z"/>
						<path class="st5" d="M129.7,438.9c-0.1,0,0.2-0.8,1.1-1.8c0.5-0.5,1.1-1,1.9-1.3c0.4-0.2,0.8-0.4,1.3-0.5s1.1-0.3,1.7,0.3
							c0.3,0.3,0.3,0.8,0.2,1.1c-0.1,0.3-0.3,0.5-0.4,0.7c-0.3,0.4-0.7,0.7-1.1,1c-0.8,0.5-1.6,0.7-2.2,0.8c-1.4,0.1-2.1-0.4-2.1-0.4
							c0-0.1,0.8,0.1,2-0.1c0.6-0.1,1.3-0.4,1.9-0.8c0.3-0.2,0.6-0.5,0.9-0.9c0.3-0.4,0.4-0.7,0.3-0.8c-0.1-0.2-0.6-0.2-1-0.1
							s-0.8,0.2-1.2,0.4c-0.7,0.3-1.4,0.7-1.9,1.1C130.2,438.2,129.8,438.9,129.7,438.9L129.7,438.9z"/>
					</g>
					<g>
						<path class="cp" d="M119.4,438.6l0.3,34.1l42.4-0.2c-0.3-4.1-22.3-12.1-22.3-12.1l0.7-21.9L119.4,438.6z"/>
						<path class="st5" d="M126.8,458.6c-0.8,0.2-1.4,1.2-1.2,2c0.2,0.8,1.1,1.4,2,1.2c0.8-0.2,1.5-1.3,1.2-2.1
							c-0.3-0.8-1.4-1.4-2.2-1"/>
						<path class="st5" d="M119.8,472.8v-3.7l40.8,1.4c0,0,1.9,0.8,1.7,2.2L119.8,472.8L119.8,472.8z"/>
						<path class="st5" d="M140.6,460.2c0,0.2-1,0.3-2.1,1c-1,0.6-1.6,1.5-1.7,1.5c-0.2,0,0.1-1.3,1.4-2.1
							C139.4,459.8,140.7,460,140.6,460.2L140.6,460.2z"/>
						<path class="st5" d="M145.1,462c0,0.2-0.8,0.6-1.6,1.4c-0.7,0.9-0.9,1.8-1.1,1.8c-0.2,0-0.3-1.2,0.5-2.3
							C143.9,461.9,145.1,461.8,145.1,462L145.1,462z"/>
						<path class="st5" d="M147.7,467.4c-0.2,0-0.5-1,0-2.1s1.5-1.6,1.6-1.4s-0.5,0.8-0.9,1.7C147.9,466.5,147.9,467.3,147.7,467.4
							L147.7,467.4z"/>
						<path class="st5" d="M140.3,455.2c-0.1,0.2-1-0.1-2.2,0s-2.1,0.5-2.2,0.3c-0.1-0.1,0.8-0.9,2.1-1
							C139.4,454.4,140.4,455,140.3,455.2L140.3,455.2z"/>
					</g>
					<g>
						<g>
							<path class="st5" d="M121.6,244c0,0-5.9,6.4-8.9,12.5c-2.9,6.1-7.4,19.1-7.4,19.1s-5.6,19.9-4.3,29.2
								c1.2,9.4,9.6,73.4,9.6,73.4s-1.6,50.8,5.5,73.3l1.3,6l23.9-4.2l0,0l5.3-125.7l14.6-83.1L121.6,244L121.6,244z"/>
							<path d="M152.2,249.3c-0.1,0-0.1-0.8-0.9-1.4c-0.4-0.3-1-0.4-1.6-0.2s-1.1,0.9-1.1,1.6s0.5,1.4,1.1,1.6s1.2,0.1,1.6-0.2
								C152.1,250.1,152.1,249.3,152.2,249.3c0,0,0.1,0.2,0,0.5c0,0.3-0.2,0.8-0.7,1.2s-1.3,0.7-2.1,0.4c-0.8-0.2-1.5-1.1-1.5-2.1
								s0.7-1.9,1.5-2.1c0.8-0.3,1.6-0.1,2.1,0.3s0.7,0.9,0.7,1.2C152.2,249.1,152.2,249.3,152.2,249.3L152.2,249.3z"/>
							<path class="st5" d="M179.9,261.2c0,0,8.8,79.7,10,93.3c1.3,13.7-48.4,86.6-48.4,86.6l-21-24.7l32-59.4l-10.8-59.2l14.2-53.4
								l22,0.3L179.9,261.2L179.9,261.2z"/>
						</g>
						<path d="M112.4,432.3c0,0,0,0.6,0.2,1.7c0.1,1.1,0.4,2.8,0.8,5c0.8,4.3,2.1,10.6,3.8,18.5v0.2h0.2c6.8-1.2,14.9-2.6,23.9-4.2
							h0.2v-0.2c0.7-16.4,1.5-36.1,2.3-57.1c0.9-22.7,1.7-43.7,2.4-60.9l0,0c1.8-13.8,3.3-25.3,4.3-33.3c0.5-4,0.9-7.1,1.2-9.3
							c0.1-1.1,0.2-1.9,0.3-2.4s0.1-0.8,0.1-0.8s-0.1,0.3-0.1,0.8c-0.1,0.6-0.2,1.4-0.4,2.4c-0.3,2.1-0.7,5.3-1.3,9.2
							c-1.1,8-2.6,19.4-4.5,33.3l0,0l0,0c-0.7,17.2-1.6,38.2-2.5,60.9c-0.8,21.1-1.6,40.7-2.2,57.1l0.2-0.2c-9,1.6-17.2,3.1-23.9,4.3
							l0.2,0.1c-1.8-7.8-3.1-14.1-4-18.4c-0.4-2.2-0.7-3.8-0.9-5c-0.1-0.6-0.1-1-0.2-1.3C112.5,432.5,112.4,432.3,112.4,432.3
							L112.4,432.3z"/>
					</g>
					<path d="M148.7,276.3c0,0,0.5,0,1.3,0.1c0.8,0,2.1,0.1,3.4-0.6c0.6-0.4,1.1-1,1.4-1.8c0.3-0.8,0.3-1.7,0.4-2.7
						c0.2-2,0.3-4.2,0.5-6.5c0.3-4.6,0.5-8.8,0.6-11.8c0-1.4,0.1-2.6,0.1-3.6c0-0.8,0.1-1.3,0.1-1.3s0.1,0.5,0.1,1.3s0.1,2.1,0,3.6
						c0,3-0.1,7.2-0.4,11.8c-0.1,2.3-0.3,4.5-0.5,6.5c-0.1,1-0.2,2-0.5,2.8c-0.3,0.9-0.9,1.6-1.6,2c-0.7,0.4-1.4,0.5-2,0.5
						s-1.1,0-1.6-0.1C149.1,276.5,148.7,276.4,148.7,276.3L148.7,276.3z"/>
					<path d="M179,261.3c0,0.1-0.9-0.3-2.3-1.2c-1.3-0.9-3.1-2.3-4.7-4.1c-1.6-1.9-2.8-3.8-3.5-5.2c-0.7-1.5-1-2.4-0.9-2.4
						c0.2-0.1,1.6,3.6,4.8,7.3C175.6,259.2,179.1,261.1,179,261.3L179,261.3z"/>
				</g>
				<g>
					<g>
						<path class="cp" d="M103.6,172.2l9.5,34.6l4.2,18.4c0,15.9-4.2,23.1-4.2,23.1l64.9-3.2c0,0,1.1-43.9,0-52.2l-7.9-28.8
							l-20.6-5.1l-25.7,0.5L103.6,172.2L103.6,172.2z"/>
						<path class="st4" d="M192.8,220.9c-0.1,0-0.2-0.6-0.2-1.7s0.2-2.6,0.7-4.1c0.6-1.5,1.4-2.8,2.1-3.6c0.7-0.8,1.3-1.2,1.3-1.1
							c0.1,0.1-1.9,1.9-2.9,4.9C192.7,218.2,193,220.9,192.8,220.9z"/>
						<path class="cp" d="M212.6,198.1l-16.9,10.1l-9.6-24.5c0,0-1.3-3-5.5-10.5c-5.6-9.8-12.7-9.3-12.7-9.3l-8.6,12.7l9.1,19.5
							c0,0,5.5,36.2,16.3,39C201.1,239.3,212.6,198.1,212.6,198.1L212.6,198.1L212.6,198.1z"/>
					</g>
					<g class="st13">
						<path d="M107.3,270c7.6-0.3,15.6-3.6,22.2-7.5c6.6-3.9,12.5-8.7,18.7-13.2c14.9-10.7,44.3-34.2,58.1-46.3l-4,0.9l-92.7,60
							L107.3,270L107.3,270z"/>
					</g>
					<path class="st6" d="M178.3,235.4c0,0,0-0.2-0.1-0.6c0-0.5,0-1.1,0-1.8c0-1.7,0-4,0-6.7c0-5.8,0.1-13.6,0.1-22.3
						c0-2.2,0.1-4.3-0.1-6.3c-0.1-2-0.4-4-0.8-5.8c-0.8-3.7-2.6-6.6-4-9c-1.5-2.4-2.6-4.5-3.3-5.9c-0.3-0.7-0.6-1.3-0.7-1.7
						c-0.1-0.4-0.2-0.6-0.2-0.6s0.1,0.2,0.3,0.6c0.2,0.4,0.5,0.9,0.8,1.6c0.7,1.4,1.9,3.4,3.4,5.8c1.5,2.4,3.3,5.4,4.2,9.1
						c0.4,1.9,0.7,3.8,0.8,5.9c0.1,2.1,0.1,4.2,0.1,6.4c-0.1,8.7-0.2,16.5-0.3,22.3c-0.1,2.8-0.1,5.1-0.1,6.7c0,0.7,0,1.3-0.1,1.8
						C178.3,235.2,178.3,235.4,178.3,235.4L178.3,235.4z"/>
				</g>
				<g>
					<path d="M172,134.3c-1.6,0.2-3.2-0.5-4.1-1.8c1.1,0.2,2.2,0.3,3.1-0.2c0.9-0.5,1.5-1.9,0.7-2.6c-0.6-0.6-1.5-0.5-2.4-0.5
						c-1.9-0.1-3.7-1.5-4.4-3.3c-0.7-2-0.1-4.2,0.2-6.3c0.7-6.1-2.2-12.6-7.3-16.1l-13.3-7.7c-4.8,6.2-6.3,14.5-5.3,22.2
						s4.3,15,8.2,21.8c1.2,2,2.4,3.9,4,5.5s3.7,2.8,6,3.1c0.6-1.9,0.2-4-1-5.6c3.6,0.2,7.2-1.1,9.9-3.5c-0.9-0.7-1.9-1.5-2.8-2.2
						C166.6,137.3,169.8,136.3,172,134.3L172,134.3z"/>
					<g>
						<g>
							<path class="st14" d="M120.9,112.6c0.2-5.5,3.7-10.2,9-11.7l16.6-4.8c7.2-1,12.3,7.8,13,15c0.8,8.1,1.3,16.6,0.5,22.6
								c-1.6,12.2-10.8,14-10.8,14s0.2,5.5,0.3,9.1l-0.2,27l-24.5-26.5l-4-44.6C120.9,112.6,120.9,112.9,120.9,112.6L120.9,112.6z"/>
							<path d="M156,119.9c0.1,0.8-0.6,1.5-1.5,1.6s-1.6-0.5-1.6-1.3c-0.1-0.8,0.6-1.5,1.5-1.6C155.2,118.5,155.9,119.1,156,119.9z"/>
							<path d="M141.6,120.5c0.1,0.8-0.6,1.5-1.5,1.6s-1.6-0.5-1.6-1.3c-0.1-0.8,0.6-1.5,1.5-1.6C140.8,119.1,141.6,119.7,141.6,120.5
								z"/>
							<path d="M147.6,129.4c0-0.1,1-0.3,2.7-0.6c0.4-0.1,0.8-0.2,0.9-0.4c0.1-0.3-0.1-0.8-0.3-1.3c-0.4-1-0.9-2.1-1.4-3.2
								c-1.9-4.6-3.3-8.3-3.1-8.4s1.9,3.6,3.8,8.1c0.5,1.1,0.9,2.2,1.3,3.2c0.2,0.5,0.5,1,0.3,1.7c-0.1,0.3-0.4,0.6-0.7,0.7
								c-0.3,0.1-0.5,0.1-0.7,0.1C148.7,129.5,147.6,129.5,147.6,129.4L147.6,129.4z"/>
							<path class="st15" d="M133.1,143.9c0,0,4.2,8.2,16.3,6.7l-0.1-2.8C149.3,147.8,141.4,148.6,133.1,143.9L133.1,143.9z"/>
							<path d="M142,112.3c-0.1,0.5-1.8,0.3-3.8,0.6s-3.5,0.9-3.8,0.5c-0.1-0.2,0.1-0.6,0.8-1.1c0.6-0.5,1.6-0.9,2.8-1.1s2.3,0,3,0.3
								S142.1,112.1,142,112.3L142,112.3z"/>
							<path d="M156.4,112.6c-0.3,0.4-1.5,0-2.8,0.1c-1.4,0-2.5,0.3-2.8-0.1c-0.1-0.2,0-0.6,0.5-0.9c0.5-0.4,1.3-0.7,2.3-0.7
								c0.9,0,1.8,0.3,2.3,0.7C156.3,112,156.5,112.4,156.4,112.6z"/>
						</g>
						<path d="M149.2,134.4c-0.2-0.2-1.4,1-3.3,0.8c-1.9-0.2-3.1-1.6-3.2-1.5c-0.1,0.1,0,0.5,0.5,1.1c0.5,0.5,1.5,1.1,2.6,1.2
							c1.1,0.1,2.1-0.3,2.6-0.8C149.1,134.9,149.3,134.4,149.2,134.4L149.2,134.4z"/>
					</g>
					<path d="M126.5,93.3c1.9-3,6.3-6.1,9.8-6.7c3.9-0.7,6.3,0,8.9,2.4c0,0,2,1.9,3.9,2.3s3.5,0.1,5.7,1.2c3.1,1.6,4.4,5.7,5.9,8.9
						c1.8,3.9,4.3,7.5,5.5,11.7s0.5,9.2-2.9,11.8c0.7-3.5,1.2-7.5-0.7-10.5c-1.5-2.3-4.1-3.6-6-5.6c-2.3-2.4-2.6-5.6-5-7.9
						C146,95.7,136.3,104.8,126.5,93.3"/>
					<path d="M161.3,109.9c-1.4-3.5-3-6.9-5.4-9.8c-3-3.5-7.4-5.9-12-6.5s-9.4,0.6-13.3,3.2l-1,0.1c3,3.8,7.7,6,12.5,6
						c2.8,0,5.9-0.7,8.4,0.7c2.4,1.4,3.2,4.4,5.2,6.3c1.3,1.3,3.1,2.1,4.4,3.5c1.4,1.4,2,3.4,2.7,5.2
						C163.6,115.8,162.5,112.7,161.3,109.9L161.3,109.9z"/>
					<path d="M135.9,99.2l-7.8-6.8c-6.1-0.3-9.9,5.4-12,9.8c-1.5,3.3-2,7-3.2,10.4c-1.2,3.5-3.1,6.8-4.2,10.3
						c-1.1,3.6-1.2,7.7,0.9,10.7c0.5-0.8,0.9-1.7,1.4-2.5c0.2,3.4,1.7,6.8,4.1,9.3c0.2-0.9,0.3-1.7,0.5-2.6c1.3,2.6,3.3,4.8,5.8,6.3
						l3.6,9.7C130.4,137.6,116.6,136.3,135.9,99.2L135.9,99.2z"/>
					<path d="M134.8,100.6c-0.5,2.2-0.9,4.4-1.8,6.5s-2.2,4-4,5.3c-2.7,1.9-6.1,2.3-9.3,3.1c-3.2,0.8-6.6,2.2-7.9,5.2
						c0.5-4.4,1.4-8.8,2.7-13c1.4-4.7,3.5-9.5,7.4-12.4c3.6-2.7,8.6-3.5,12.7-1.9"/>
					<g>
						<g>
							<path class="st14" d="M126.5,122c-0.2-0.4-7.7-2.9-7.6,5c0.1,7.7,7.8,6,7.9,5.7C126.8,132.6,126.5,122,126.5,122z"/>
							<path class="st15" d="M124.4,130c0,0-0.1,0.1-0.4,0.2c-0.2,0.1-0.6,0.2-1,0c-0.8-0.3-1.5-1.6-1.6-2.9c0-0.7,0.1-1.4,0.3-1.9
								c0.2-0.6,0.6-1,1-1.1s0.7,0.1,0.8,0.4c0.1,0.2,0.1,0.4,0.1,0.4s0.2-0.1,0.1-0.4c0-0.1-0.1-0.3-0.3-0.5
								c-0.2-0.1-0.5-0.2-0.7-0.2c-0.6,0.1-1.1,0.6-1.4,1.2s-0.5,1.4-0.4,2.2c0.1,1.6,0.9,3,2,3.3c0.5,0.1,1,0,1.2-0.2
								C124.4,130.2,124.4,130,124.4,130L124.4,130z"/>
						</g>
						<g>
							<path class="st8" d="M123.5,131.3c-0.8-0.1-1.6,0.4-1.7,1.2c-0.2,0.7,0.3,1.6,1.1,1.8c0.7,0.2,1.6-0.2,1.9-0.9
								s-0.1-1.6-0.8-1.9"/>
							<path d="M123.9,131.4c0,0,0.5,0.3,0.7,1c0.1,0.4,0.1,0.9-0.3,1.2c-0.3,0.4-0.9,0.6-1.4,0.5c-0.5-0.2-0.9-0.7-1-1.1
								c-0.1-0.5,0.1-0.9,0.4-1.2c0.6-0.5,1.2-0.4,1.2-0.5c0,0-0.1-0.1-0.4-0.1c-0.2,0-0.6,0.1-0.9,0.4c-0.3,0.3-0.7,0.8-0.6,1.4
								c0.1,0.6,0.5,1.2,1.2,1.4s1.4-0.1,1.8-0.6s0.4-1.1,0.3-1.5s-0.4-0.7-0.6-0.8S123.9,131.4,123.9,131.4L123.9,131.4z"/>
						</g>
					</g>
					<path class="st5" d="M135.3,101.2c-0.1,0.1-0.9-2.5-3-5s-4.6-3.5-4.5-3.7c0-0.1,0.7,0.1,1.6,0.7c0.9,0.5,2.2,1.4,3.3,2.7
						s1.8,2.6,2.2,3.6C135.2,100.5,135.3,101.1,135.3,101.2L135.3,101.2z"/>
				</g>
				<g>
					<path class="st5" d="M328.9,125.3c0,0,0.1,0.3,0.2,0.9s0.4,1.5,0.5,2.7c0.4,2.4,0.8,5.8,0.8,10.1s-0.4,9.4-1.6,15
						c-0.6,2.8-1.4,5.7-2.4,8.6c-1.1,2.9-2.3,5.9-3.9,8.7c-1.6,2.9-3.4,5.5-5.4,7.9s-4,4.6-6.1,6.5c-4.2,3.8-8.5,6.7-12.3,8.7
						s-7.1,3.2-9.4,3.9c-1.1,0.4-2.1,0.5-2.7,0.7c-0.6,0.1-0.9,0.2-0.9,0.2s0.3-0.1,0.9-0.3s1.5-0.4,2.6-0.8c2.3-0.7,5.5-2,9.3-4
						s8-5,12.1-8.8c2.1-1.9,4.1-4.1,6.1-6.5c1.9-2.4,3.7-5,5.3-7.9c1.6-2.8,2.9-5.8,3.9-8.7c1.1-2.9,1.9-5.7,2.5-8.5
						c1.2-5.5,1.6-10.6,1.7-14.9s-0.3-7.7-0.6-10.1c-0.1-1.2-0.3-2.1-0.4-2.7C329,125.6,328.9,125.2,328.9,125.3L328.9,125.3z"/>
					<g>
						<path class="st5" d="M220,164.5c0,0-0.1-0.2-0.3-0.5c-0.2-0.4-0.4-0.9-0.7-1.5c-0.3-0.7-0.7-1.5-1-2.4c-0.3-1-0.8-2.1-1.1-3.3
							c-0.2-0.6-0.4-1.3-0.5-2c-0.2-0.7-0.3-1.4-0.5-2.1c-0.4-1.5-0.5-3.1-0.8-4.8c-0.4-3.4-0.5-7.2-0.1-11.1
							c0.4-3.9,1.1-7.6,2.1-10.9c0.5-1.6,1-3.2,1.6-4.6c0.3-0.7,0.5-1.4,0.8-2s0.6-1.2,0.9-1.8c0.5-1.2,1.2-2.2,1.7-3.1s1-1.6,1.4-2.2
							c0.4-0.5,0.7-1,0.9-1.4c0.2-0.3,0.3-0.5,0.4-0.5c0,0-0.1,0.2-0.3,0.5c-0.2,0.4-0.5,0.8-0.9,1.4s-0.8,1.4-1.3,2.3
							s-1.1,1.9-1.6,3.1c-0.3,0.6-0.5,1.2-0.8,1.8s-0.5,1.3-0.8,2c-0.6,1.4-1,2.9-1.5,4.6c-0.9,3.3-1.6,6.9-2,10.8
							c-0.3,3.9-0.2,7.6,0.1,11c0.2,1.7,0.4,3.3,0.7,4.8c0.1,0.7,0.3,1.5,0.4,2.1c0.2,0.7,0.3,1.3,0.5,1.9c0.3,1.3,0.7,2.3,1,3.3
							s0.6,1.8,0.9,2.5c0.2,0.6,0.4,1.1,0.6,1.5C220,164.3,220.1,164.5,220,164.5L220,164.5z"/>
						<path class="st5" d="M229.5,176.3c-0.1,0.1-1.2-0.3-2.2-1.3c-1.1-1-1.6-2.1-1.5-2.1c0.1-0.1,0.9,0.8,1.9,1.7
							C228.7,175.5,229.6,176.2,229.5,176.3L229.5,176.3z"/>
						<g>
							<g>
								<path class="st5" d="M211.6,113.2c5.8-12.5,15.4-23.3,28.3-30.6c15.9-9,34.2-11.2,51.8-6.3c17.5,4.9,32.1,16.3,41.1,32.2
									c18.5,32.7,6.9,74.4-25.9,92.9l0,0c-15.9,9-34.2,11.2-51.8,6.3c-17.5-4.9-32.1-16.3-41.1-32.2
									C202.8,155.6,202.7,132.4,211.6,113.2L211.6,113.2z M331.1,168.8c8.3-18,8.2-39.6-2.2-58.1c-8.3-14.8-22-25.5-38.3-30
									c-16.4-4.6-33.5-2.5-48.3,5.9s-25.5,22-30,38.3s-2.5,33.5,5.9,48.3c8.3,14.8,22,25.5,38.3,30c16.4,4.6,33.5,2.5,48.3-5.9
									C316.7,190.6,325.7,180.4,331.1,168.8L331.1,168.8z"/>
								<path class="st5" d="M273.5,211.2c-6.2,0-12.5-0.8-18.7-2.6c-17.8-5-32.6-16.6-41.7-32.7c-10.9-19.4-11.8-43-2.4-63.3
									c6.1-13.2,16.1-23.9,28.7-31.1c16.1-9.1,34.8-11.4,52.6-6.4s32.6,16.6,41.7,32.7c18.8,33.3,7,75.6-26.3,94.4
									C296.9,208.2,285.3,211.2,273.5,211.2L273.5,211.2z M273.3,74.8c-11.4,0-22.7,2.9-32.9,8.7c-12.3,6.9-21.9,17.3-27.8,30.1
									c-9.1,19.6-8.2,42.5,2.4,61.3c8.8,15.6,23.2,26.8,40.4,31.7s35.4,2.6,51-6.2c32.2-18.2,43.6-59.2,25.5-91.4
									c-8.8-15.6-23.2-26.8-40.4-31.7C285.4,75.7,279.4,74.8,273.3,74.8L273.3,74.8z M273.5,206.7c-5.8,0-11.7-0.8-17.5-2.4
									c-16.7-4.6-30.5-15.5-39-30.5c-8.5-15.1-10.6-32.5-6-49.2s15.5-30.5,30.5-39c15.1-8.5,32.5-10.6,49.2-6s30.5,15.5,39,30.5
									c10.2,18.1,11.1,40.2,2.3,59.1l0,0c-5.7,12.3-15,22.4-26.8,29C295.4,203.8,284.5,206.7,273.5,206.7L273.5,206.7z M273.3,79.4
									c-10.6,0-21.1,2.7-30.6,8.1c-14.5,8.2-25,21.6-29.5,37.7s-2.4,33,5.8,47.5s21.6,25,37.7,29.5s33,2.4,47.5-5.8
									c11.4-6.5,20.4-16.2,25.9-28.1l0,0c8.5-18.3,7.7-39.6-2.2-57.1c-8.2-14.5-21.6-25-37.7-29.5C284.6,80.2,279,79.4,273.3,79.4
									L273.3,79.4z"/>
							</g>
							<path class="st5" d="M83.1,270.2l-1.2-1.9c-2.6-4.2,0.4-10.8,3.3-12.6l128.6-81.2c0.7-0.4,1.6,0,2.2,0.9l8.6,13.6
								c0.6,0.9,0.5,2-0.1,2.4L96.6,272.2C93.7,274.1,85.7,274.4,83.1,270.2L83.1,270.2z"/>
						</g>
					</g>
					<g>
						<path class="st14" d="M203,205.6l3.9-4.1c0,0-0.5-9.4,1.1-11.7l0.8-1.4c0,0-0.8-4.6,1.6-5.6c0.7-1.7,1.6-2.4,2.8-1.5
							c0,0-0.7-3.4,2.5-3.9s4.1,14.1,4.1,14.1s1.6,6.5-2.1,6.5c0,0-2.1,2.3-4.4,2.5c0,0-0.7,3.9-3.9,3.7L203,205.6L203,205.6z"/>
						<path class="st15" d="M213,198.9c0,0,0.2,0.1,0.4,0.5c0.2,0.3,0.3,0.9,0.2,1.6c-0.1,0.7-0.5,1.5-1.2,2.1s-1.6,1.1-2.6,1.4
							c-1.9,0.7-3.8,0.9-5.1,1s-2.2,0-2.2-0.1c0-0.2,3.3,0,7.1-1.4c0.9-0.3,1.8-0.7,2.4-1.3c0.6-0.5,1-1.2,1.2-1.8
							C213.5,199.6,212.9,198.9,213,198.9L213,198.9z"/>
						<path class="st15" d="M215.9,193.7c-0.2,0-0.2-2.9-0.9-6.4s-1.8-6.2-1.6-6.3c0.1,0,0.4,0.6,0.8,1.7c0.4,1.1,0.9,2.7,1.3,4.5
							s0.5,3.4,0.5,4.6C216,193,215.9,193.7,215.9,193.7L215.9,193.7z"/>
						<path class="st15" d="M213.1,195.3c-0.1,0-0.4-2.8-1.2-6.3c-0.7-3.5-1.6-6.2-1.5-6.2s1.2,2.6,2,6.1
							C213.2,192.4,213.2,195.3,213.1,195.3L213.1,195.3z"/>
						<path class="st15" d="M218.2,195.2c0.1-0.1,0.6,0.8,0.3,2c-0.3,1.1-1.2,1.7-1.2,1.6c-0.1-0.1,0.5-0.7,0.7-1.7
							C218.3,196.1,218.1,195.3,218.2,195.2L218.2,195.2z"/>
						<path class="st15" d="M209.3,195.9c-0.1,0-0.2-1.6-0.3-3.6s-0.3-3.6-0.1-3.6s0.5,1.6,0.7,3.6
							C209.6,194.3,209.4,195.9,209.3,195.9L209.3,195.9z"/>
					</g>
					<g>
						<path class="st14" d="M123.5,229.8c0,0,1.2-1.9,3.8-3.4s7.7-2.1,8.6-3.8c1-1.7,2.5,0,1.7,2.2c-0.5,1.4-4.2,4.7-4.2,4.7
							s3.4,1.5,6,0.6s11.3-1.8,11.9-0.5c0.8,1.5-1.5,1.5-2.5,1.9c-0.8,0.3-6.7,2-6.7,2s10.9,1.6,12.2,2.4c1.4,0.8-0.4,1.9-1.1,1.8
							c-0.4-0.1-2.6,1.3-4.1,2.2c-1.1,0.7-2.3,0.9-3.5,0.7l-2.5-0.4l7.5,2.2c0,0,1.1,1.5-0.9,1.7c-0.9,0.1-24.7-0.8-26.6-1
							c-3.2-0.4-8.2-0.5-8.2-0.5l3.9-14.2L123.5,229.8L123.5,229.8z"/>
						<path class="st15" d="M153.3,237.7c0,0.1-2.4,0.6-5.5,0.6c-3,0-5.5-0.5-5.4-0.7c0-0.1,2.4,0.1,5.4,0.2
							C150.8,237.8,153.3,237.5,153.3,237.7L153.3,237.7z"/>
					</g>
					<path class="cp" d="M104.6,222.5l10.9-22.5l-9.7-29.3c0,0-3.8,1.6-7.3,6.3c-3.4,4.7-7.2,14.6-7.2,14.6S79.5,218.9,77.6,223
						c-2,4.1-5.1,11.6,0,15.8s39.4,4.4,39.4,4.4l3.6-15.2L104.6,222.5L104.6,222.5z"/>
					<path class="st6" d="M108.4,185.6c0.1,0,2,7.8,4.2,17.4c2.2,9.6,3.8,17.5,3.7,17.6c-0.1,0-2-7.8-4.2-17.4
						C109.9,193.6,108.3,185.7,108.4,185.6z"/>
					<path class="st6" d="M120.6,228c0,0-0.5-0.1-1.3-0.3c-0.9-0.3-2.1-0.6-3.4-1c-2.9-0.8-6.8-2-11.2-3.2c-4.4-1.1-8.5-2-11.4-1.9
						c-3,0-4.6,1.1-4.7,0.9c0,0,0.4-0.3,1.2-0.6c0.8-0.3,2-0.6,3.5-0.7c3-0.2,7.1,0.6,11.6,1.8c4.4,1.2,8.3,2.4,11.2,3.3
						c1.4,0.5,2.6,0.8,3.4,1.1C120.2,227.8,120.6,228,120.6,228L120.6,228z"/>
				</g>
				<g>
					<rect x="285.7" y="119.5" class="cp" width="14.2" height="51.2"/>
					<g>
						<rect x="241.2" y="149.6" class="cp" width="14.2" height="23.2"/>
						<rect x="263.2" y="137" class="cp" width="14.2" height="35.9"/>
						<path class="cp" d="M294.6,102.6c0,0-0.1,0.2-0.3,0.5c-0.3,0.3-0.6,0.7-1.1,1.2c-1,1.1-2.3,2.7-4,4.6
							c-3.5,3.9-8.3,9.4-13.9,15.7l-0.2,0.2l-0.2-0.1c-2.7-1.8-5.7-3.7-8.7-5.7l0.4-0.1c-0.1,0.1-0.2,0.3-0.3,0.4
							c-7.2,8.4-13.7,16-18.4,21.6l-0.1,0.1l-0.1-0.1c-2.9-2.1-5.2-3.9-6.9-5.1c-0.8-0.6-1.4-1.1-1.9-1.4c-0.4-0.3-0.6-0.5-0.6-0.5
							s0.3,0.1,0.7,0.4c0.5,0.3,1.1,0.8,1.9,1.3c1.7,1.2,4.1,2.9,7,5h-0.3c4.7-5.6,11.2-13.3,18.3-21.7c0.1-0.1,0.2-0.3,0.3-0.4
							l0.2-0.2l0.2,0.1c3,2,6,3.9,8.7,5.7h-0.4c5.7-6.2,10.5-11.7,14.1-15.6c1.7-1.9,3.1-3.4,4.1-4.5c0.5-0.5,0.8-0.9,1.1-1.2
							C294.5,102.7,294.6,102.6,294.6,102.6L294.6,102.6z"/>
						<path class="cp" d="M295.1,113.4c-0.1,0-0.1-1.2-0.2-3.3c0-2-0.1-4.8-0.1-7.9l0.3,0.3c-0.1,0-0.2,0-0.3,0c-3.1,0.5-6,0.9-8,1.2
							s-3.3,0.4-3.3,0.4c0-0.1,1.2-0.4,3.3-0.7c2-0.4,4.9-0.9,8-1.4c0.1,0,0.2,0,0.3,0l0.3-0.1v0.3c0,3.1,0,5.9-0.1,7.9
							C295.3,112.1,295.2,113.4,295.1,113.4L295.1,113.4z"/>
					</g>
				</g>
			</g>
		</g>
	</svg>
</template>

<style scoped>
	.cp{fill:#C091FC;}
	.cs{fill:#3CDFAD;}
	.st0{fill:#EBEBEB;}
	.st1{fill:#F5F5F5;}
	.st3{fill:#E0E0E0;}
	.st4{fill:#263238;}
	.st5{fill:#2D2D2D;}
	.st6{opacity:0.3;enable-background:new;}
	.st7{fill:#455A64;}
	.st8{fill:#FFFFFF;}
	.st10{fill:#FFBE9D;}
	.st11{fill:#EB996E;}
	.st12{opacity:0.2;}
	.st13{opacity:0.3;}
	.st14{fill:#C17560;}
	.st15{fill:#7C4236;}
</style>
