import { computed, reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useLocalStorage } from '@vueuse/core';
import { defineStore } from 'pinia';
import { useAuthStore } from '.';
import { safeCopy, toast, mergeDeep, safeJson, isObject, ObjKeysLcFirst } from '@/common/util';
import { config as configModel } from './model/config';
import parametrosService from '@/services/parametros.services';
export const useSystemStore = defineStore('system', () => {
    const i18n = useI18n();
    const clientSettings = useLocalStorage('clientSettings', {
        loading: false,
        settings: safeCopy(configModel),
        timestamp: null,
        lang: 'pt'
    }, {
        mergeDefaults: true
    });
    const tenantParameters = ref('');
    const langs = [{
            label: 'Português',
            value: 'pt'
        }, {
            label: 'English',
            value: 'en'
        }, {
            label: 'Español',
            value: 'es'
        }];
    const options = useLocalStorage('userOptions', {
        mode: 0,
        dark: 1,
        fullWidth: 1,
        volume: 100,
        autoplay: false
    });
    const status = ref(0);
    const nStatus = reactive({
        loadParams: 'initial'
    });
    const isBusyLoadingConfig = ref(false);
    const playing = reactive({ type: null, src: null, trackProgress: false, progress: 0, objData: null });
    const hideMenu = ref(false);
    const tempConfig = ref(safeCopy(configModel));
    const adminConfigLoaded = ref(false);
    const previewMode = ref(false);
    const getConfig = computed(() => (!previewMode.value ? clientSettings.value.settings : tempConfig.value));
    const forClient = computed(() => options.value.mode === 0);
    const loading = computed(() => status.value === 1);
    const tenantIdParameters = computed(() => tenantParameters.value);
    const reset = () => Object.assign(tempConfig.value, safeCopy(clientSettings.value.settings));
    const save = async () => {
        const newValues = safeCopy(tempConfig.value);
        const previousValues = safeCopy(clientSettings.value.settings);
        // Cria / Atualiza as novas imagens de banners
        for (let i = 0; i < newValues.banners.items.length; i++) {
            const { url, name } = newValues.banners.items[i];
            if (url.substring(0, 10) === 'data:image') {
                const { data, success } = await parametrosService.dataUrlUpload(url, name);
                if (success)
                    newValues.banners.items[i].url = data;
            }
        }
        // Cria / Atualiza as novas imagens de banners
        for (let i = 0; i < newValues.login.backgrounds.length; i++) {
            const imgInfo = newValues.login.backgrounds[i];
            if (imgInfo[0].substring(0, 10) === 'data:image') {
                const { data, success } = await parametrosService.dataUrlUpload(imgInfo[0], imgInfo[1]);
                if (success)
                    newValues.login.backgrounds[i] = [data, data.substring(data.lastIndexOf('/') + 1, data.length)];
            }
        }
        const oldBanner = previousValues.banners.items.map((bannerUrl) => bannerUrl.url);
        const newBanner = newValues.banners.items.map((bannerUrl) => bannerUrl.url);
        // Percorre o array de imagens atuais, para ver quais não existem mais (devido a remoção/update)
        oldBanner.map(async (bannerUrl) => {
            if (!newBanner.includes(bannerUrl))
                await parametrosService.deleteImg(bannerUrl.substring(bannerUrl.lastIndexOf('/') + 1, bannerUrl.length));
        });
        const oldBGImgs = previousValues.login.backgrounds.map((bg) => bg[0]);
        const newBGImgs = newValues.login.backgrounds.map((bg) => bg[0]);
        // Percorre o array de imagens atuais, para ver quais não existem mais (devido a remoção/update)
        oldBGImgs.map(async (bg) => {
            if (!newBGImgs.includes(bg))
                await parametrosService.deleteImg(bg.substring(bg.lastIndexOf('/') + 1, bg.length));
        });
        Object.assign(tempConfig.value, safeCopy(newValues));
        Object.assign(clientSettings.value.settings, safeCopy(tempConfig.value));
        newValues.login.backgrounds = newBGImgs; // O endpoint aguarda array de strings apenas
        // Não precisa propriedades padrões do sistema
        delete newValues.defaultScripts;
        // Arrumando a bagunça
        status.value = 1;
        try {
            parametrosService.silent(true);
            const { success } = await parametrosService.update({
                ...newValues
            });
            if (success)
                toast.success({ title: i18n.t('feedback.success'), message: i18n.t('toasts.system.saveSuccess') });
            status.value = 2;
        }
        catch (error) {
            status.value = -1;
        }
    };
    const loadParams = async (asAdmin) => {
        isBusyLoadingConfig.value = true;
        if (clientSettings.value.loading)
            return;
        const host = window.location.host;
        const isPlantar = host.includes('plantareducacao.com.br');
        nStatus.loadParams = 'loading';
        try {
            const publicRoute = asAdmin || !useAuthStore().loggedIn;
            const { success, data } = await (!publicRoute
                ? parametrosService.get()
                : parametrosService.clientSettings({
                    subDomain: isPlantar ? host.split('.')[0] : ' ',
                    clientUrl: isPlantar ? ' ' : host
                }));
            if (success) {
                if (data && data?.tenantId) {
                    tenantParameters.value = data.tenantId;
                }
                // Normaliza o resultado
                let remoteSettings = safeJson(data?.jsonConfigParams) || data || {};
                if (publicRoute)
                    remoteSettings = ObjKeysLcFirst(remoteSettings);
                // Limpa recursivamente os atributos que são vazios (desse endpoint, ex: data.colors = null)
                const cleanDeep = (obj) => {
                    if (obj === null || !isObject(obj))
                        return;
                    Object.keys(obj).forEach((key) => {
                        if (obj[key] && typeof obj[key] === 'object')
                            cleanDeep(obj[key]);
                        else if (obj[key] === null)
                            delete obj[key];
                    });
                };
                cleanDeep(remoteSettings);
                const mergedSettings = mergeDeep(safeCopy(configModel), remoteSettings);
                if (mergedSettings.login?.backgrounds.length) {
                    mergedSettings.login.backgrounds = mergedSettings.login.backgrounds.map((bg) => {
                        return typeof bg === 'string' ? [bg, bg.split('/').pop()] : bg;
                    });
                }
                Object.assign(clientSettings.value.settings, mergedSettings);
                Object.assign(tempConfig.value, mergedSettings);
                adminConfigLoaded.value = true;
                isBusyLoadingConfig.value = false;
                nStatus.loadParams = 'success';
            }
        }
        catch (error) {
            nStatus.loadParams = 'error';
            throw new Error(error);
        }
        finally {
            clientSettings.value.loading = false;
        }
    };
    const resetConfig = () => {
        Object.assign(tempConfig.value, safeCopy(configModel));
        Object.assign(clientSettings.value.settings, safeCopy(configModel));
    };
    const setAdminMode = () => (options.value.mode = 1);
    const setClientMode = () => (options.value.mode = 0);
    const toggleTheme = () => (options.value.dark = 1 - options.value.dark);
    const toggleWidth = () => (options.value.fullWidth = 1 - options.value.fullWidth);
    const setVolume = (value) => (options.value.volume = value);
    const play = (props) => Object.assign(playing, { ...props });
    const stopPlaying = () => Object.assign(playing, { id: null, type: null, src: null, trackProgress: false, progress: 0, title: null, objData: null });
    const isDark = computed(() => options.value.dark === 1 ||
        (options.value.dark === 2 && window.matchMedia('(prefers-color-scheme: dark)').matches));
    const setLanguage = (lang) => {
        lang = lang || clientSettings.value.lang;
        clientSettings.value.lang = lang;
        i18n.locale.value = lang;
    };
    return {
        availableLangs: langs,
        nStatus,
        clientSettings,
        tempConfig,
        previewMode,
        options,
        playing,
        hideMenu,
        adminConfigLoaded,
        isBusyLoadingConfig,
        // Getters
        getConfig,
        forClient,
        isDark,
        loading,
        tenantIdParameters,
        // Actions
        reset,
        save,
        loadParams,
        resetConfig,
        setAdminMode,
        setClientMode,
        setLanguage,
        toggleTheme,
        toggleWidth,
        setVolume,
        play,
        stopPlaying
    };
});
export default useSystemStore;
