<template>
	<svg version="1.1"
		id="svg197" xmlns:svg="http://www.w3.org/2000/svg" xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape" xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
		xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 236.7 249"
		style="enable-background:new 0 0 236.7 249;" xml:space="preserve"
	>
		<g id="c" transform="translate(-77.732371,-59.471655)" inkscape:export-filename="contas.svg" inkscape:export-xdpi="142.44" inkscape:export-ydpi="142.44">
			<g id="g452">
				<path id="path10" class="st0" d="M154,239.2c-1.1-1.1-2.6-1.5-4.1-2.1c-6.4-2.3-11.5-7.8-13.4-14.3s-0.6-13.9,3.4-19.3l-0.2-1.1
					c-0.5-5.2-6.8-9-11.7-6.9c-4.8,2.1-6.5,9.3-3,13.2c-5.7,0.5-9.4,6.9-8.6,12.6c0.8,5.6,4.9,10.3,9.5,13.7
					c7.5,5.5,16.9,8.6,26.2,8.7c0.6,0,1.3,0,1.9-0.3C155.2,242.5,155,240.4,154,239.2L154,239.2z M272.3,215c1.3-2.1,1.5-5,0.2-7.1
					s-4.2-3.2-6.5-2.1c1.3-2.1,0.5-5.2-1.6-6.5s-5.2-0.6-6.5,1.5c-1.5,2.3-0.7,5.5,0.6,7.9c1.3,2.5,3.1,4.8,3.5,7.5
					c0.4,2.8-0.6,5.7-2.3,8s-3.9,4.1-6.1,5.9l-0.4,0.4c4,1.4,8.5,1,12.2-1.1c2.1-1.2,4-3.1,4.2-5.4c0.1-1.3-0.4-2.7-0.2-4
					C269.5,218,271.2,216.6,272.3,215L272.3,215z M184.3,299.6c-2.1-0.3-4.1-1.2-5.4-2.9c-0.8-1.1-1.2-2.4-1.8-3.6
					c-0.7-1.1-1.9-2.2-3.2-2.1c-2.1,0.2-3.4,3-5.5,2.5c-1.2-0.3-1.8-1.7-1.8-3c0-1.2,0.4-2.5,0.2-3.7c-0.4-3.5-4.9-5.2-8.2-4.1
					s-8.4,5.1-10.5,7.8c2.3,3.8,6.9,5.3,11.2,6.3c7.2,1.8,14.5,3.3,21.8,4.6c1.7,0.3,3.5,0.6,5.2,0.3c1.7-0.3,3.4-1.2,4.2-2.7
					C188.5,299.6,186.4,299.9,184.3,299.6L184.3,299.6z M253.1,266.5c-0.5-3-3.5-5.6-6.4-4.9c2.4-2,2.4-6,0.5-8.5s-5.3-3.6-8.4-3.5
					s-6.2,1.2-9.1,2.4c1.4-1.8-0.7-4.7-3-4.8c-2.3-0.2-4.4,1.3-6.3,2.7c-1.9,1.4-4,2.8-6.3,2.4l2.2,0.9c5.1,0.8,10.3,2,14.8,4.7
					c4.4,2.7,8.1,7.1,8.9,12.2c0.8,5.1-1.9,10.9-6.8,12.7c6.6,0.4,13.3-2.6,17.3-7.8C252.3,272.5,253.6,269.5,253.1,266.5L253.1,266.5
					z M113.1,193.1c-0.1,0-0.1-2.2-0.1-5s0.1-5,0.1-5s0.1,2.2,0.1,5S113.2,193.1,113.1,193.1z"/>
				<g id="g98">
					<path id="path86" class="cp" d="M180.6,261.2l-1.3,14l-4.2,10.8c-0.3,0.8,0.1,1.6,0.9,1.9c0.4,0.1,0.9,0.1,1.3-0.2
						c2.4-1.8,11-8.5,11-9.5c0-1.2,2.3-14.6,2.3-14.6L180.6,261.2L180.6,261.2z"/>
					<g id="g90" class="st2">
						<path id="path88" class="st3" d="M176.1,287.8l12.4-10.6l-0.1,0.6c-0.1,0.5-0.3,1-0.7,1.3c-1,0.9-3.8,3.5-10.4,8.5
							C177,287.9,176.5,287.9,176.1,287.8L176.1,287.8z"/>
					</g>
					<g id="g94" class="st2">
						<path id="path92" class="st3" d="M186.2,272.3c-0.4,0.2-0.6,0.7-0.4,1.1s0.7,0.6,1.1,0.4c0.4-0.2,0.6-0.8,0.4-1.2
							C187.1,272.3,186.4,272.1,186.2,272.3"/>
					</g>
					<path id="path96" class="st4" d="M180,282.1c-0.1,0-0.3-0.7-1.1-1.2s-1.6-0.4-1.6-0.5c0,0,0.2-0.1,0.5-0.1s0.8,0.1,1.2,0.4
						s0.7,0.7,0.8,1C180,281.8,180,282.1,180,282.1L180,282.1z M178.8,283.8c-0.1,0.1-0.5-0.4-1.2-0.7c-0.7-0.3-1.3-0.2-1.3-0.3
						c0-0.1,0.7-0.3,1.5,0C178.5,283.1,178.8,283.8,178.8,283.8L178.8,283.8z M182.1,279.9c-0.1,0.1-0.7-0.7-1.7-1.3
						c-1-0.6-2-0.6-2-0.7s1-0.1,2.2,0.4C181.6,278.9,182.1,279.9,182.1,279.9L182.1,279.9z M182.8,275.7c0,0.1-0.8-0.3-1.8-0.5
						s-1.8,0-1.8-0.1s0.8-0.4,1.9-0.2C182.2,275.1,182.9,275.6,182.8,275.7L182.8,275.7z M183.3,271.6c-0.1,0.1-0.8-0.5-1.8-0.5
						c-1.1-0.1-1.9,0.3-1.9,0.3s0.2-0.2,0.5-0.3s0.9-0.3,1.4-0.2c0.6,0,1.1,0.2,1.4,0.4C183.2,271.4,183.3,271.6,183.3,271.6
						L183.3,271.6z M181.6,270.3c0,0,0-0.2,0-0.5c0.1-0.3,0.2-0.8,0.6-1.3c0.2-0.2,0.4-0.6,0.8-0.7c0.2-0.1,0.5,0,0.6,0.2
						s0.2,0.4,0.1,0.7c-0.1,0.9-1,1.6-2,1.7c-1,0-1.9-0.7-2.2-1.5c-0.1-0.2-0.1-0.5,0-0.7s0.4-0.3,0.6-0.2c0.4,0.1,0.6,0.4,0.9,0.6
						c0.4,0.4,0.7,0.9,0.8,1.2c0.1,0.3,0.2,0.5,0.2,0.5c-0.1,0-0.3-0.7-1.1-1.5c-0.2-0.2-0.5-0.4-0.8-0.5c-0.1,0-0.3,0-0.3,0.1
						c-0.1,0.1-0.1,0.3,0,0.4c0.3,0.7,1,1.3,1.9,1.3s1.6-0.7,1.7-1.4c0-0.2,0-0.4-0.1-0.5s-0.2-0.2-0.3-0.1c-0.3,0.1-0.5,0.4-0.7,0.6
						C181.7,269.5,181.6,270.3,181.6,270.3L181.6,270.3z"/>
				</g>
				<g id="g110">
					<path id="path100" class="st4" d="M193.5,269.4l10.5-82.8l-3.3-19.2l-19.7,1l-2.5,99.5c0,0.7,0.5,1.4,1.2,1.5l11.5,1.8
						C192.3,271.3,193.3,270.5,193.5,269.4z"/>
					<path id="path102" class="st4" d="M203.6,189.3l19,18.8l-27.4,6.7l1.9,13.8l37-5c3.2-0.4,6-2.1,7.9-4.7c2.9-4,2.9-9.3,0.2-13.4
						l-28.9-42.4l-12.9,4.2L203.6,189.3L203.6,189.3z"/>
					<path id="path104" class="st5" d="M202.6,184.6c0.1,0,0.4,1,0.7,2.4c0.3,1.3,0.5,2.4,0.4,2.4s-0.4-1-0.7-2.4
						C202.7,185.7,202.5,184.6,202.6,184.6z"/>
					<path id="path106" d="M199.8,216.6c0,0,0.1-0.1,0.4-0.1c0.3-0.1,0.8-0.2,1.3-0.4c1.2-0.4,2.9-0.9,5.1-1.6
						c4.4-1.3,10.8-3.2,18.6-5.6l-0.1,0.2c-6.4-6.9-14.3-15.2-22.8-24.3l0.2-0.1c-0.4,3.8-0.9,7.8-1.4,11.9
						c-2.4,19.7-4.6,37.6-6.3,50.5c-0.9,6.4-1.6,11.7-2.1,15.3c-0.3,1.8-0.5,3.2-0.7,4.1c-0.1,0.5-0.2,0.8-0.2,1.1
						c-0.1,0.2-0.1,0.4-0.1,0.4s0-0.1,0.1-0.4c0-0.2,0.1-0.6,0.2-1.1c0.2-0.9,0.4-2.3,0.7-4.1c0.5-3.6,1.2-8.8,2.1-15.3
						c1.7-12.9,3.8-30.8,6.2-50.5c0.5-4.1,1-8.1,1.4-11.9v-0.3l0.2,0.2c8.6,9.1,16.4,17.5,22.8,24.4l0.1,0.1l-0.2,0.1
						c-7.8,2.3-14.2,4.2-18.6,5.5c-2.2,0.6-3.9,1.1-5.1,1.5c-0.6,0.2-1,0.3-1.3,0.4C200,216.5,199.8,216.6,199.8,216.6L199.8,216.6z"
						/>
					<path id="path108" d="M193.7,267.8c-0.1,0,2.1-17.6,4.9-39.2c2.8-21.7,5.1-39.2,5.1-39.2s-2.1,17.6-4.9,39.2
						C196,250.3,193.7,267.8,193.7,267.8L193.7,267.8z M200.5,175.8c0,0,0.2,0.5,0.4,1.3s0.5,1.9,0.8,3.2c0.3,1.2,0.5,2.4,0.6,3.2
						s0.2,1.3,0.1,1.4c0,0-0.2-0.5-0.3-1.3c-0.2-0.9-0.4-2-0.7-3.2s-0.5-2.3-0.7-3.2C200.6,176.3,200.5,175.8,200.5,175.8L200.5,175.8
						z M201.8,174.2c0,0,0.3-0.3,0.8-0.1c0.2,0.1,0.5,0.3,0.6,0.7c0.1,0.3-0.1,0.8-0.4,1c-0.4,0.2-0.8,0.1-1.1-0.1
						c-0.3-0.2-0.3-0.6-0.2-0.9c0.1-0.2,0.2-0.4,0.4-0.5c0.1-0.1,0.2-0.1,0.2-0.1s-0.3,0.1-0.4,0.6c0,0.2,0,0.5,0.2,0.6
						c0.2,0.2,0.5,0.2,0.8,0.1c0.2-0.1,0.4-0.5,0.3-0.7c0-0.3-0.2-0.4-0.4-0.5C202.1,174.1,201.8,174.2,201.8,174.2L201.8,174.2z
						M228.2,211.4c-0.1,0.1-1.1-0.9-2.7-1.8c-1.5-0.9-2.9-1.3-2.9-1.4c0-0.1,1.5,0.3,3,1.2C227.3,210.2,228.3,211.3,228.2,211.4z"/>
				</g>
				<path id="path158" class="cs" d="M159.4,154.1c0,0-0.3,0.1-0.9,0.2c-0.6,0.1-1.5,0.3-2.6,0.5c-2.3,0.5-5.7,1.2-10,2.4
					s-9.6,2.8-15.4,5.4c-1.4,0.7-2.9,1.4-4.4,2.2s-3,1.8-4.4,2.8c-1.4,1.1-2.9,2.3-4.1,3.7s-2.3,3.1-2.8,5.1c-0.5,1.9-0.4,4,0.4,6
					c0.8,1.9,2.1,3.6,3.7,5.2c3.2,3,7.2,5.3,11.4,7.3c8.6,3.9,18.4,6.5,28.8,8.5c10.4,1.9,21.5,3.1,33,3.6s23.5,0.4,35.7-0.6
					s24-2.8,35.2-5.7c5.6-1.5,11-3.2,16-5.5c2.5-1.1,5-2.4,7.2-3.9c2.3-1.5,4.4-3.1,6.1-5.1s3-4.4,3-7c0-0.3,0-0.6,0-0.9
					c0-0.3,0-0.6-0.1-0.9l-0.2-0.9c-0.1-0.3-0.2-0.6-0.3-0.9c-0.4-1.2-1.1-2.2-1.8-3.3c-1.5-2-3.4-3.6-5.4-5s-4.1-2.6-6.2-3.6
					c-4.2-2.1-8.5-3.7-12.7-5s-8.2-2.3-12.2-3.2c-15.7-3.4-28.7-4.3-37.6-4.8c-4.5-0.2-8-0.2-10.3-0.3c-1.2,0-2.1,0-2.7-0.1
					c-0.6,0-0.9,0-0.9,0h0.9c0.6,0,1.5,0,2.7,0c2.4,0,5.8,0,10.3,0.2c8.9,0.5,21.9,1.4,37.6,4.7c3.9,0.9,8,1.9,12.2,3.2
					s8.5,2.9,12.7,5c2.1,1.1,4.2,2.2,6.2,3.6c2,1.4,3.9,3,5.5,5c0.7,1,1.4,2.1,1.9,3.3c0.1,0.3,0.3,0.6,0.3,0.9l0.2,0.9
					c0.1,0.3,0.1,0.6,0.1,1c0,0.3,0.1,0.6,0,1c0,1.3-0.4,2.6-0.9,3.8s-1.3,2.3-2.2,3.3c-1.7,2-3.9,3.7-6.2,5.2
					c-2.3,1.5-4.7,2.7-7.3,3.9c-5.1,2.3-10.5,4-16.1,5.5c-11.2,2.9-23,4.7-35.2,5.7s-24.2,1.1-35.7,0.6s-22.6-1.7-33-3.7
					s-20.2-4.6-28.8-8.5c-4.3-2-8.3-4.3-11.5-7.3c-1.6-1.5-2.9-3.3-3.7-5.2c-0.8-2-1-4.1-0.4-6.1c0.5-2,1.6-3.7,2.8-5.1
					c1.2-1.5,2.7-2.7,4.1-3.8c1.5-1.1,3-2,4.4-2.8c1.5-0.8,3-1.6,4.4-2.2c5.8-2.6,11.1-4.2,15.4-5.4c4.3-1.2,7.7-1.9,10.1-2.4
					C158.2,154.3,159.4,154.1,159.4,154.1L159.4,154.1z"/>
				<path id="path12" class="st7" d="M118.2,188.1c0,0.1-2.3,0.1-5.1,0.1s-5.1-0.1-5.1-0.1s2.3-0.1,5.1-0.1S118.2,188,118.2,188.1z
					M268.1,252.1c-0.1,0-0.1-2.2-0.1-5s0.1-5,0.1-5c0.1,0,0.1,2.2,0.1,5S268.2,252.1,268.1,252.1z"/>
				<path id="path14" class="st7" d="M273.2,247.2c0,0.1-2.3,0.1-5.1,0.1c-2.8,0-5.1-0.1-5.1-0.1s2.3-0.1,5.1-0.1
					C270.9,247,273.2,247.1,273.2,247.2z M263.7,84.2c-0.1,0-0.1-2.2-0.1-5s0.1-5,0.1-5c0.1,0,0.1,2.2,0.1,5
					C263.8,81.9,263.7,84.2,263.7,84.2z"/>
				<path id="path16" class="st7" d="M268.8,79.2c0,0.1-2.3,0.1-5.1,0.1c-2.8,0-5.1-0.1-5.1-0.1c0-0.1,2.3-0.1,5.1-0.1
					S268.8,79.1,268.8,79.2z"/>
				<g>
					<path id="path18" class="st8" d="M87.5,72.3c0,0-3.4,1.7,1.4,8.8c4.7,7.1,7.8,10.5,7.8,10.5s0.9,1.7-0.6,2.1
						c-1.4,0.4-4.7,0.3-5.2,2.2c-0.5,1.9,1.1,3.5,4,4.8s9.3,4.1,9.8,4.4c0.6,0.3,1.2,1,0.2,1.5s-8.1,2.1-7.2,5.4
						c1,3.4,9.7,5.4,10.7,5.7c0.9,0.2,1.2,0.6,1.2,1.1s-2.5,0.8-4.5,1.4c-2,0.5-4.3,1.5-3.6,3.4c0.7,1.9,6.2,5.4,18.3,5.2l5.6-0.5
						l2.5-5.1c4.7-11.2,3.4-17.6,1.9-19s-3.2,0.4-4.5,2.1c-1.2,1.7-2.4,3.9-2.9,3.7c-0.5-0.2-0.7-0.5-0.6-1.5s1.4-9.8-1.4-12
						s-6.9,4-7.7,4.7s-1.2-0.2-1.3-0.8c0-0.6-0.4-7.6-0.5-10.8c-0.1-3.2-1-5.2-3-5.5c-1.9-0.3-3,2.8-3.9,4s-2.2-0.3-2.2-0.3
						s-2-4.1-6.9-11.1C90.4,69.8,87.5,72.4,87.5,72.3"/>
					<path id="path20" class="st7" d="M126.1,129.5c-3.5-5.1-9.7-14.4-15-22.3S101,92.2,97.4,87c-1.8-2.6-3.2-4.7-4.2-6.1
						c-0.5-0.7-0.9-1.3-1.1-1.7c-0.1-0.2-0.2-0.3-0.3-0.4c-0.1-0.1-0.1-0.2-0.1-0.2s0,0,0.1,0.1s0.2,0.2,0.3,0.4
						c0.3,0.4,0.7,0.9,1.2,1.6c1,1.4,2.4,3.5,4.2,6.1c3.5,5.1,8.4,12.3,13.7,20.2C116.6,115.1,122.7,124.3,126.1,129.5"/>
					<path id="path22" class="st7" d="M95.6,96.3c0,0,0.1,0,0.4,0.1s0.6,0.1,1.1,0.2c0.9,0.2,2.2,0.6,3.5,0.9c1.4,0.4,2.6,0.7,3.5,1
						c0.4,0.1,0.8,0.2,1.1,0.3c0.3,0.1,0.4,0.1,0.4,0.1s-0.1,0-0.4-0.1s-0.6-0.1-1.1-0.2c-0.9-0.2-2.2-0.6-3.5-1
						c-1.4-0.4-2.6-0.7-3.5-1c-0.4-0.1-0.8-0.2-1.1-0.3C95.7,96.3,95.6,96.3,95.6,96.3L95.6,96.3z M105.9,98.6c0,0,0.5-2.6,1.3-5.8
						c0.8-3.2,1.4-5.8,1.4-5.8s-0.5,2.6-1.3,5.8C106.6,96,106,98.6,105.9,98.6z M113.6,111.1c0,0,0.1-0.1,0.2-0.4
						c0.2-0.3,0.3-0.6,0.6-1c0.5-0.8,1.1-2,1.8-3.3s1.2-2.5,1.6-3.4c0.2-0.4,0.3-0.8,0.5-1c0.1-0.2,0.2-0.4,0.2-0.4s0,0.1-0.1,0.4
						c-0.1,0.2-0.2,0.6-0.4,1.1c-0.5,1.2-1,2.3-1.6,3.4c-0.7,1.3-1.3,2.5-1.8,3.3c-0.2,0.4-0.5,0.7-0.6,1
						C113.6,111,113.6,111.1,113.6,111.1L113.6,111.1z M103.7,111.1c0,0,0.1,0,0.4,0s0.6,0,1.1,0c0.9,0,2.1-0.1,3.5-0.1s2.6,0,3.5,0
						c0.4,0,0.8,0,1.1,0c0.2,0,0.4,0,0.4,0s-0.1,0-0.4,0s-0.6,0-1.1,0c-0.9,0-2.1,0.1-3.5,0.1s-2.6,0-3.5,0c-0.4,0-0.8,0-1.1,0
						C103.9,111.1,103.7,111.1,103.7,111.1z M109.1,123c0,0,0.2,0,0.5,0s0.8,0,1.3-0.1c1.1,0,2.7-0.1,4.4-0.1s3.3-0.1,4.4-0.1h1.3
						c0.3,0,0.5,0,0.5,0s-0.2,0-0.5,0s-0.8,0-1.3,0.1c-1.1,0-2.7,0.1-4.4,0.1c-1.7,0-3.3,0.1-4.4,0.1h-1.3
						C109.2,123,109.1,123,109.1,123L109.1,123z M121.3,122.6c0,0,0.1-0.2,0.2-0.5c0.2-0.3,0.4-0.8,0.7-1.3c0.6-1.1,1.3-2.6,2.1-4.2
						c0.8-1.7,1.5-3.2,2-4.3c0.2-0.5,0.4-1,0.6-1.3c0.1-0.3,0.2-0.5,0.2-0.5s-0.1,0.2-0.2,0.5s-0.3,0.8-0.5,1.3
						c-1.2,2.9-2.6,5.8-4.1,8.6c-0.3,0.5-0.5,1-0.7,1.3S121.3,122.6,121.3,122.6L121.3,122.6z"/>
				</g>
				<g id="g34">
					<path id="path24" class="st8" d="M278.9,250.9c-6-5.2-6.6-13.8-5.5-21.4c0.2-1.3,0.2-2.6,0.9-3.6s2.2-1.7,3.3-1
						c0.9,0.5,1.2,1.7,1.6,2.6c0.6,1.3,1.5,2.4,2.7,3.2c0.8,0.5,1.7,0.9,2.6,0.5c1.2-0.5,1.4-2,1.6-3.2c0.2-2.3,0.5-4.7,0.7-7
						c0.1-1.3,0.3-2.5,0.8-3.7s1.5-2.2,2.7-2.4s2.6,0.6,2.8,1.9c0,0.5-0.1,1-0.1,1.6c0,0.5,0.2,1.1,0.6,1.3c0.5,0.2,1-0.1,1.4-0.4
						c1.4-1.1,2.5-2.7,3.5-4.2s2-3.1,3.3-4.4c1.3-1.3,3.1-2.2,4.9-2.1c1.8,0.1,3.6,1.4,3.9,3.2s-1,3.5-2.2,4.8
						c-1.9,1.9-4.1,3.6-6.4,4.9c-0.4,0.2-0.9,0.5-1.1,0.9c-0.4,0.8,0.3,1.7,1.1,2.1c0.9,0.4,1.9,0.4,2.9,0.6s2,0.8,2.3,1.7
						c0.4,1.3-0.8,2.6-2,3.3c-2.3,1.5-4.9,2.7-7.6,3.3c-1,0.2-2,0.4-2.8,1s-1.4,1.6-1,2.6c0.4,0.9,1.5,1.3,2.5,1.3
						c1-0.1,1.9-0.5,2.9-0.8c1.8-0.5,4-0.1,5,1.5c0.6,1.1,0.5,2.4,0,3.6s-1.4,2.1-2.2,2.9c-3.1,2.9-6.9,5.2-11.1,6.1
						C286.5,252.9,282.8,252.8,278.9,250.9"/>
					<path id="path26" class="st7" d="M281.4,246.4c1.5-3.7,3.5-7.6,5.8-11.6c0.6-1,1.1-2,1.7-3c0.5-1,1.1-1.9,1.7-2.9
						c1.2-1.8,2.4-3.5,3.7-5.1s2.5-3,3.7-4.3s2.3-2.5,3.4-3.6c2.1-2.1,4.1-3.6,5.4-4.6c0.7-0.5,1.2-0.9,1.6-1.1
						c0.2-0.1,0.3-0.2,0.4-0.3s0.1-0.1,0.1-0.1s0,0-0.1,0.1s-0.2,0.2-0.4,0.3c-0.4,0.3-0.9,0.6-1.6,1.1c-1.9,1.4-3.7,3-5.4,4.7
						c-1.1,1.1-2.2,2.3-3.3,3.6c-1.2,1.3-2.4,2.8-3.7,4.3s-2.5,3.2-3.7,5c-0.6,0.9-1.1,1.9-1.7,2.8c-0.6,1-1.1,2-1.7,3
						c-2.2,4-4.2,7.9-5.8,11.6"/>
					<path id="path28" class="st7" d="M290.6,228.9c0,0,0-0.2-0.1-0.6c0-0.4-0.1-0.9-0.1-1.5c0-1.3,0-3,0-5c0-1.9,0-3.7-0.1-5
						c0-0.5-0.1-1-0.1-1.5c0-0.4,0-0.6,0-0.6s0.1,0.2,0.1,0.5c0.1,0.3,0.1,0.9,0.2,1.5c0.1,1.3,0.2,3,0.1,5c0,1.9,0,3.6,0,5v1.5
						C290.6,228.7,290.6,228.9,290.6,228.9L290.6,228.9z"/>
					<path id="path30" class="st7" d="M307.1,227.7c0,0-0.2,0-0.7,0s-1,0-1.8,0c-1.5,0-3.6,0.1-5.8,0.3c-2.5,0.2-5.1,0.5-7.6,0.9
						c-0.4,0.1-0.6,0.1-0.6,0.1s0.2-0.1,0.6-0.2s1-0.2,1.7-0.4c1.5-0.3,3.5-0.6,5.8-0.7c2.3-0.2,4.4-0.2,5.9-0.2
						c0.8,0,1.4,0.1,1.8,0.1C306.8,227.6,307.1,227.6,307.1,227.7L307.1,227.7z M281.6,246.1c0,0,0-0.1-0.1-0.2
						c-0.1-0.2-0.1-0.4-0.2-0.6c-0.2-0.5-0.4-1.3-0.7-2.3c-0.6-1.9-1.3-4.6-2-7.6c-0.8-3-1.4-5.7-2-7.6c-0.3-0.9-0.5-1.7-0.6-2.3
						c-0.1-0.2-0.1-0.5-0.2-0.6c0-0.1-0.1-0.2,0-0.2c0,0,0,0.1,0.1,0.2c0.1,0.2,0.1,0.4,0.2,0.6c0.2,0.5,0.4,1.3,0.7,2.3
						c0.6,1.9,1.3,4.6,2,7.6c0.8,3,1.4,5.7,2,7.6c0.3,0.9,0.5,1.7,0.6,2.3c0.1,0.2,0.1,0.5,0.2,0.6
						C281.6,246.1,281.6,246.1,281.6,246.1L281.6,246.1z"/>
					<path id="path32" class="st7" d="M303.8,241.5c0,0-0.1,0-0.2,0.1c-0.2,0-0.4,0.1-0.6,0.1c-0.6,0.1-1.4,0.3-2.4,0.5
						c-2,0.4-4.8,1-7.8,1.7c-3.1,0.7-5.8,1.3-7.9,1.6c-1,0.2-1.8,0.3-2.4,0.4c-0.3,0-0.5,0.1-0.7,0.1c-0.1,0-0.2,0-0.2,0
						s0.1,0,0.2-0.1c0.2,0,0.4-0.1,0.6-0.1c0.6-0.1,1.4-0.3,2.4-0.5c2-0.4,4.8-1,7.8-1.7c3.1-0.7,5.8-1.3,7.9-1.6
						c1-0.2,1.8-0.3,2.4-0.4c0.3,0,0.5-0.1,0.7-0.1S303.8,241.5,303.8,241.5L303.8,241.5z"/>
				</g>
				<path id="path36" class="st0" d="M233.7,87.7c1-5.5,3.6-13.7,6.8-18.3s8.1-8.4,13.6-9.1c5.6-0.8,11.7,1.8,14.2,6.9
					c1.3,2.7,1.6,5.8,2.6,8.7c1,2.9,3.1,5.7,6.1,5.9c2.7,0.2,5.2-1.7,7.9-1.2c3.4,0.7,4.7,4.9,4.8,8.4c0,3.5-0.5,7.3,1.7,10.1
					c1.8,2.2,4.7,3,7.2,4.5c5.2,3.3,7.5,10,6.9,16.1s-3.4,11.8-6.4,17.2c-2.5-8.8-5.9-17.4-10.1-25.5c-2.4-4.6-6.8-8.6-10.8-12.1
					c-6-5.3-19.2-7.9-27.2-8.9C243,89.4,241.6,89.1,233.7,87.7"/>
				<g id="g46">
					<path id="path38" class="cs" d="M181,93.7c-19.8,1.4-41.3,4.1-57.1,10.1l5,33.8c0,0,30.6-11,55.6-10.2L181,93.7L181,93.7z"/>
					<path id="path40" class="st9" d="M179,123.4c0,0-0.1,0-0.2,0c-0.1,0-0.3,0-0.6,0.1c-0.5,0.1-1.2,0.2-2.2,0.3
						c-0.9,0.1-2.1,0.2-3.4,0.4c-1.3,0.2-2.8,0.4-4.5,0.7c-6.7,1-15.8,2.7-25.8,5.3c-3.5,0.9-6.8,1.8-9.9,2.8l-0.1,0.1v-0.2
						c-1.1-7.7-2.1-14.4-2.9-19.2c-0.4-2.4-0.7-4.2-1-5.5c-0.1-0.6-0.2-1.1-0.3-1.5c0-0.2,0-0.3-0.1-0.4c0-0.1,0-0.1,0-0.1s0,0,0,0.1
						s0.1,0.2,0.1,0.4c0.1,0.3,0.2,0.8,0.3,1.5c0.3,1.3,0.6,3.2,1,5.5c0.8,4.7,1.9,11.4,3,19.2l-0.2-0.1c3.1-1,6.4-1.9,9.9-2.8
						c10-2.6,19.2-4.2,25.8-5.2c1.7-0.3,3.2-0.4,4.5-0.6s2.5-0.3,3.4-0.4s1.6-0.2,2.2-0.2c0.2,0,0.4,0,0.6-0.1
						C179,123.4,179,123.4,179,123.4L179,123.4z"/>
					<path id="path42" class="st9" d="M179.7,115.2c0,0-0.1-0.2-0.2-0.7c-0.2-0.4-0.5-1.1-1-1.7c-0.5-0.7-1.3-1.5-2.4-2.1
						c-0.3-0.2-0.6-0.3-0.9-0.5c-0.3-0.1-0.6-0.3-1-0.3c-0.7-0.2-1.4-0.3-2.2-0.2c-0.8,0.1-1.6,0.4-2.2,0.9c-0.7,0.5-1.3,1.2-1.8,1.9
						c-1,1.5-1.7,3.4-2.5,5.4c-0.4,1-1,2-1.9,2.7c-0.4,0.3-1,0.6-1.5,0.7c-0.6,0.1-1.2,0-1.7-0.2c-2.2-0.9-3.2-3.5-5.5-4.3
						c-1.1-0.4-2.4-0.2-3.4,0.3c-0.5,0.3-1,0.6-1.3,1c-0.4,0.4-0.6,0.9-0.9,1.4c-0.5,1-0.9,2.1-1.7,2.9c-0.4,0.4-1,0.7-1.5,0.7
						c-0.6,0.1-1.1-0.1-1.6-0.3c-1-0.5-1.7-1.3-2.2-2.2c-0.5-0.9-0.8-1.8-1.2-2.7c-0.3-0.9-0.7-1.7-1.2-2.4c-0.5-0.7-1.1-1.3-1.8-1.6
						c-1.4-0.7-2.9-0.7-4.1-0.1c-1.2,0.5-2,1.2-2.6,1.9c-0.4,0.5-0.8,1.1-1,1.8c-0.1,0.4-0.2,0.7-0.2,0.7s0-0.2,0.1-0.7
						c0.1-0.4,0.4-1.1,0.9-1.8c0.6-0.7,1.4-1.4,2.6-1.9c1.1-0.6,2.8-0.7,4.2,0.1c0.7,0.4,1.4,1,1.9,1.7s0.9,1.6,1.2,2.4
						c0.3,0.9,0.7,1.8,1.2,2.6c0.5,0.8,1.2,1.6,2.1,2.1c0.4,0.2,1,0.4,1.5,0.3c0.5,0,1-0.3,1.4-0.6c0.8-0.7,1.2-1.8,1.7-2.8
						c0.3-0.5,0.5-1,0.9-1.5s0.9-0.8,1.4-1.1c1.1-0.5,2.4-0.7,3.6-0.3c1.2,0.4,2.1,1.3,2.9,2.2c0.8,0.9,1.6,1.7,2.7,2.1
						c0.5,0.2,1.1,0.3,1.6,0.2c0.5-0.1,1-0.3,1.4-0.7c0.8-0.7,1.4-1.6,1.8-2.6c0.8-1.9,1.5-3.8,2.6-5.4c0.5-0.8,1.1-1.5,1.9-2
						c0.7-0.5,1.5-0.8,2.3-0.9s1.6,0,2.3,0.2c0.4,0.1,0.7,0.2,1,0.4c0.3,0.2,0.6,0.3,0.9,0.5c1.1,0.7,1.9,1.5,2.4,2.2s0.8,1.4,1,1.8
						c0.1,0.2,0.1,0.4,0.1,0.5S179.7,115.2,179.7,115.2L179.7,115.2z M127.4,110.8c0,0.1-0.2,0.2-0.6,0.2c-0.3,0.1-0.6,0.1-0.6,0
						s0.2-0.2,0.6-0.2C127.1,110.7,127.4,110.7,127.4,110.8z M128.3,117c0,0.1-0.2,0.2-0.4,0.4c-0.3,0.1-0.5,0.2-0.6,0.1
						c0-0.1,0.2-0.2,0.4-0.4S128.2,116.9,128.3,117z M129.3,123.7c0,0.1-0.2,0.2-0.4,0.3c-0.3,0.1-0.5,0.1-0.5,0s0.2-0.2,0.4-0.3
						C129.1,123.6,129.3,123.6,129.3,123.7z M130.7,130.3c0,0.1-0.2,0.2-0.5,0.3s-0.6,0.1-0.6,0s0.2-0.2,0.5-0.3
						S130.6,130.3,130.7,130.3z M167.9,98.5c0,0,0.2-0.1,0.6-0.1c0.4-0.1,1-0.2,1.8-0.3c1.6-0.2,3.8-0.5,6.5-0.8h0.1v0.1
						c0.2,2.3,0.4,4.9,0.7,7.7v0.1v0.1h-0.1c-3.2,0.4-6.3,0.8-8.8,1.1h-0.1v-0.1c-0.2-2.3-0.5-4.2-0.6-5.6c-0.1-0.6-0.1-1.2-0.2-1.6
						C167.9,98.7,167.9,98.5,167.9,98.5s0,0.2,0.1,0.6s0.1,0.9,0.2,1.6c0.2,1.4,0.4,3.3,0.7,5.6l-0.1-0.1c2.5-0.3,5.6-0.7,8.8-1.1
						l-0.1,0.2v-0.1c-0.2-2.8-0.5-5.4-0.7-7.7l0.1,0.1C171.9,98,168.9,98.4,167.9,98.5L167.9,98.5z"/>
					<path id="path44" class="st9" d="M176.1,99.8c0,0.1-1.5,0.1-3.4,0.3c-1.9,0.2-3.3,0.5-3.4,0.4c0-0.1,1.4-0.5,3.3-0.7
						C174.6,99.6,176.1,99.8,176.1,99.8L176.1,99.8z M176.2,101.8c0,0.1-1.4,0.1-3.1,0.3c-1.7,0.2-3.1,0.4-3.1,0.3s1.4-0.4,3.1-0.6
						C174.8,101.7,176.2,101.7,176.2,101.8L176.2,101.8z M174.1,103.5c0,0.1-0.8,0.2-1.8,0.4c-1,0.1-1.8,0.2-1.8,0.1s0.8-0.2,1.8-0.4
						C173.2,103.5,174.1,103.5,174.1,103.5z"/>
				</g>
				<g id="g60">
					<path id="path48" class="cs" d="M276.2,104.1c-19.3-5.9-35.6-10.9-57.6-10.8l-3.4,34.4c22.8,2,30.4,3.5,51.4,11.1L276.2,104.1z"
						/>
					<path id="path50" class="st9" d="M232.9,110.1l0.9-7.3c-2-0.2-4.2,0.4-6,1.7c-2.1,1.5-3.6,4-3.7,7.2c0,0.3,0,0.7,0,1.1l0,0l8.5-1
						L232.9,110.1L232.9,110.1z"/>
					<path id="path52" class="st9" d="M241.8,108.9c-0.8-2.7-2.9-5-6.1-5.8c-0.1,0-0.2,0-0.3-0.1c-0.1,0-0.1,0-0.2,0
						c-0.2,0-0.3-0.1-0.5-0.1l0,0l-0.9,7.3l-0.2,1.7l8.1,2.9l0,0c0.2-0.6,0.3-1.2,0.4-1.9C242.3,111.6,242.2,110.2,241.8,108.9
						L241.8,108.9z"/>
					<path id="path56" class="st9" d="M233.1,112.7l-8.5,1c0.4,4.1,3.4,7.6,7.4,8.1c4.1,0.5,7.8-2.1,9.2-6.1L233.1,112.7 M269.2,110.7
						c0,0-0.3-0.1-0.8-0.3c-0.5-0.2-1.2-0.5-2-0.9c-2.2-0.9-4.5-1.7-6.9-2.4c-2.7-0.8-5.3-1.3-7.1-1.6c-0.9-0.2-1.7-0.3-2.2-0.3
						c-0.5-0.1-0.8-0.1-0.8-0.1s0.3,0,0.8,0s1.3,0.1,2.2,0.2c1.9,0.2,4.4,0.7,7.2,1.5c2.7,0.8,5.2,1.7,6.9,2.5c0.9,0.4,1.5,0.7,2,1
						C269,110.5,269.3,110.7,269.2,110.7L269.2,110.7z M268.7,114.3c0,0-0.3-0.1-0.8-0.3c-0.5-0.2-1.2-0.5-2-0.9
						c-2.2-0.9-4.5-1.7-6.9-2.4c-2.7-0.8-5.3-1.3-7.1-1.6c-0.9-0.2-1.7-0.3-2.2-0.3c-0.5-0.1-0.8-0.1-0.8-0.1s0.3,0,0.8,0
						s1.3,0.1,2.2,0.2c1.9,0.2,4.4,0.7,7.1,1.5s5.2,1.7,6.9,2.5c0.9,0.4,1.5,0.7,2,1C268.4,114.1,268.7,114.3,268.7,114.3L268.7,114.3
						z M268.1,117.9c0,0-0.3-0.1-0.8-0.3c-0.5-0.2-1.2-0.5-2-0.9c-2.2-0.9-4.5-1.7-6.9-2.4c-2.7-0.8-5.3-1.3-7.1-1.6
						c-0.9-0.2-1.7-0.3-2.2-0.3c-0.5-0.1-0.8-0.1-0.8-0.1s0.3,0,0.8,0s1.3,0.1,2.2,0.2c1.9,0.2,4.4,0.7,7.2,1.5
						c2.7,0.8,5.2,1.7,6.9,2.5c0.9,0.4,1.5,0.7,2,1C267.8,117.7,268.1,117.9,268.1,117.9L268.1,117.9z M266.9,121.5
						c0,0-0.3-0.1-0.7-0.3c-0.5-0.2-1.1-0.5-2-0.9c-1.7-0.7-4-1.6-6.6-2.4c-2.7-0.8-5.1-1.3-6.9-1.6c-0.9-0.2-1.6-0.3-2.1-0.3
						c-0.5-0.1-0.8-0.1-0.8-0.1s0.3,0,0.8,0s1.2,0.1,2.1,0.2c1.8,0.2,4.3,0.7,6.9,1.5c2.7,0.8,5,1.7,6.7,2.5c0.8,0.4,1.5,0.7,1.9,1
						C266.7,121.3,266.9,121.5,266.9,121.5L266.9,121.5z M264.4,124.3c0,0-0.2-0.1-0.7-0.3c-0.5-0.2-1.1-0.5-1.8-0.8
						c-1.5-0.6-3.6-1.5-5.9-2.2c-2.4-0.7-4.6-1.1-6.2-1.3c-0.8-0.1-1.4-0.2-1.9-0.3c-0.4-0.1-0.7-0.1-0.7-0.1s0.3,0,0.7,0
						s1.1,0.1,1.9,0.2c1.6,0.2,3.9,0.5,6.3,1.2c2.4,0.7,4.5,1.6,6,2.3c0.7,0.3,1.3,0.6,1.7,0.9C264.1,124.2,264.4,124.3,264.4,124.3
						L264.4,124.3z M255.8,124.7c0,0-0.5-0.1-1.4-0.3c-0.8-0.2-2-0.4-3.3-0.7c-1.3-0.2-2.5-0.4-3.3-0.5c-0.9-0.1-1.4-0.2-1.4-0.2
						s0.5,0,1.4,0c0.9,0.1,2.1,0.2,3.4,0.4s2.5,0.5,3.3,0.8C255.3,124.5,255.8,124.7,255.8,124.7L255.8,124.7z"/>
					<path id="path58" class="st9" d="M222.5,97.4c0,0,0.1,0,0.4,0c0.3,0,0.8,0,1.3,0c1.2,0,2.9,0,5.1,0.1c4.3,0.3,10.6,1,17.7,3.2
						h0.1v0.1c-1.3,7.6-2.9,16.7-4.6,26.4c-0.1,0.4-0.1,0.8-0.2,1.2v0.1h-0.1c-4-1.1-8-1.9-11.8-2.4c-3.8-0.6-7.5-1-10.9-1.2h-0.1
						v-0.1c0.9-8.1,1.7-15,2.3-19.8c0.3-2.4,0.5-4.2,0.6-5.5c0.1-0.6,0.1-1.1,0.2-1.5c0-0.2,0-0.3,0.1-0.4
						C222.5,97.5,222.5,97.4,222.5,97.4S222.5,97.4,222.5,97.4c0,0.2,0,0.3,0,0.5l-0.1,1.5c-0.1,1.3-0.3,3.2-0.6,5.6
						c-0.5,4.8-1.3,11.6-2.1,19.7l-0.1-0.1c3.4,0.2,7.1,0.6,10.9,1.2c3.8,0.6,7.8,1.4,11.9,2.4l-0.2,0.1c0.1-0.4,0.1-0.8,0.2-1.2
						c1.7-9.7,3.3-18.8,4.6-26.4l0.1,0.1c-7.1-2.2-13.4-2.9-17.7-3.3c-2.2-0.1-3.9-0.2-5-0.2H223C222.7,97.4,222.5,97.4,222.5,97.4
						L222.5,97.4z"/>
				</g>
				<path id="path66" class="st10" d="M230.2,128.9l23.8-3.2l3.7-1.1c0,0,8.6-1.9,9.4-2.2c0.9-0.3,1.3,0,1.4,0.4
					c0.2,0.5-0.4,1.2-1.2,1.5c-0.3,0.1-4.6,1.6-4.6,1.6s0.4,0.1,1.5,0.4s2,1.7,2.5,3.3s0.5,2-0.1,2.3c-0.2,0.1-0.5,0-0.7-0.1
					c-0.3-0.2-0.6-0.5-0.7-0.9c-0.8-2.5-2.4-2.7-2.4-2.7l-0.5,0.1c0,0,1.6,1.1,1.6,2.5c0,0.2-0.1,0.4-0.1,0.6c0.2,0.7,0.1,1.4-0.3,2
					c-0.5,0.6-1.2,0.9-1.9,0.7c-0.3-0.1-0.7-0.3-0.8-0.6c0-0.2,0-0.4,0.1-0.5c0-0.1,0.1-0.2,0.1-0.3l-1.4,0.2c0,0-3.2,0.3-4.6,0.5
					l-22.3,6.7c-3.1,0.9-6.3-0.9-7.2-4C224.4,132.9,226.7,129.4,230.2,128.9z"/>
				<path id="path68" class="st10" d="M254.5,126.3c0,0.5,0.5,0.8,1,0.9s1,0,1.5,0.1c1.5,0.2,2.6,1.6,4.1,1.7c0.2,0,0.5,0,0.5-0.2
					c0.1-0.2-0.1-0.4-0.2-0.5c-1.2-1.1-2.5-2.2-3.7-3.3"/>
				<path id="path70" class="st11" d="M257.7,125.1c0,0,0.2,0.1,0.4,0.3c0.3,0.2,0.7,0.5,1.1,0.9c0.5,0.4,1,0.9,1.6,1.4
					c0.2,0.1,0.3,0.3,0.5,0.4c0.1,0.1,0.4,0.3,0.4,0.6c0,0.2-0.1,0.3-0.3,0.4c-0.1,0.1-0.3,0.1-0.4,0.1c-0.2,0-0.5-0.1-0.7-0.1
					c-0.9-0.3-1.7-0.9-2.4-1.2c-0.4-0.2-0.7-0.3-1.1-0.3c-0.3-0.1-0.7,0-1-0.1c-0.6,0-1.2-0.3-1.4-0.6c-0.2-0.3-0.2-0.5-0.2-0.5
					s0,0.2,0.2,0.4c0.2,0.2,0.7,0.5,1.3,0.5c0.3,0,0.6,0,1,0s0.8,0.2,1.1,0.3c0.8,0.3,1.5,0.9,2.4,1.2c0.2,0.1,0.4,0.1,0.6,0.1
					s0.4-0.1,0.4-0.2c0-0.1-0.2-0.3-0.3-0.4c-0.2-0.1-0.3-0.3-0.5-0.4c-0.6-0.5-1.1-1-1.6-1.5c-0.5-0.4-0.8-0.8-1.1-1
					S257.7,125.1,257.7,125.1L257.7,125.1z M262.3,128.3c0,0,0.4-0.2,0.9,0c0.3,0.1,0.6,0.3,0.8,0.6c0.2,0.3,0.4,0.7,0.6,1.1
					c0.2,0.4,0.3,0.8,0.5,1.2c0.2,0.3,0.5,0.6,0.8,0.8c0.3,0.1,0.6,0.1,0.7,0c0.2-0.1,0.2-0.2,0.2-0.2s-0.1,0-0.2,0.1
					s-0.4,0.1-0.6-0.1c-0.2-0.1-0.5-0.4-0.7-0.7s-0.4-0.7-0.5-1.2c-0.2-0.4-0.4-0.8-0.6-1.2c-0.3-0.3-0.6-0.5-0.9-0.6s-0.6,0-0.7,0
					C262.4,128.3,262.3,128.3,262.3,128.3L262.3,128.3z M267.6,124.2c0,0-0.3,0.1-0.9,0.3c-0.6,0.2-1.3,0.5-2.1,0.8
					c-0.8,0.3-1.5,0.5-2.1,0.7c-0.5,0.2-0.9,0.3-0.9,0.3s0.4-0.1,0.9-0.2c0.5-0.2,1.3-0.4,2.1-0.7c0.8-0.3,1.5-0.6,2.1-0.8
					C267.4,124.4,267.7,124.3,267.6,124.2L267.6,124.2z M261.7,129.7c0,0,0.1,0.1,0.2,0.2c0.1,0.1,0.3,0.4,0.3,0.7
					c0,0.1-0.1,0.3-0.3,0.4s-0.4,0.1-0.6,0.2c-0.2,0-0.5,0.1-0.6,0.3c-0.2,0.2-0.2,0.5-0.1,0.8s0.3,0.5,0.5,0.6s0.5,0.1,0.7,0.1
					c0.4-0.1,0.8-0.3,1.1-0.4c0.3-0.2,0.5-0.3,0.6-0.5c0.1-0.1,0.2-0.2,0.1-0.2c0,0-0.1,0.1-0.2,0.2c-0.1,0.1-0.4,0.3-0.6,0.4
					c-0.3,0.1-0.7,0.3-1.1,0.4c-0.4,0.1-0.9-0.1-1.1-0.6c-0.1-0.2,0-0.5,0.1-0.6c0.1-0.2,0.3-0.2,0.5-0.3s0.4-0.1,0.6-0.2
					c0.2-0.1,0.3-0.3,0.3-0.5c0-0.4-0.2-0.6-0.3-0.7C261.8,129.7,261.7,129.7,261.7,129.7L261.7,129.7z"/>
				<path id="path72" class="st11" d="M261.2,132.6c0,0-0.1,0-0.2,0.1s-0.3,0.3-0.3,0.7c0,0.3,0.2,0.7,0.6,0.8c0.4,0.2,0.9,0.2,1.3,0
					c0.4-0.1,0.8-0.4,1-0.8c0.2-0.3,0.3-0.7,0.3-1s0-0.5-0.1-0.7s-0.1-0.2-0.1-0.2s0.1,0.4,0.1,0.9c0,0.3-0.1,0.6-0.4,0.9
					c-0.2,0.3-0.6,0.6-1,0.7s-0.8,0.1-1.2,0c-0.3-0.1-0.5-0.4-0.6-0.7c0-0.3,0.1-0.5,0.2-0.6C261.1,132.7,261.2,132.6,261.2,132.6
					L261.2,132.6z M257.2,130.7c0,0,0-0.2-0.2-0.4c-0.1-0.2-0.4-0.5-0.9-0.7v0.2c0.2-0.1,0.4-0.2,0.5-0.3c1-0.7,1.5-1.5,1.5-1.5
					s-0.6,0.8-1.6,1.4c-0.2,0.1-0.4,0.2-0.5,0.3l-0.2,0.1l0.2,0.1c0.4,0.1,0.7,0.4,0.8,0.6C257.1,130.5,257.2,130.7,257.2,130.7
					L257.2,130.7z M264.6,128.1c0,0-0.3,0.2-0.3,0.7c0,0.4,0.2,0.8,0.2,0.7c0.1,0-0.1-0.3,0-0.7C264.5,128.4,264.7,128.2,264.6,128.1
					L264.6,128.1z"/>
				<g id="g84">
					<path id="path74" class="st10" d="M153.6,136.4c0,0-3.5-5.6-4.1-6.1s-6.1-2.5-6.6-2.3s-7.1,1.3-6.4,2.1c0.8,0.8,5.4-0.1,5.4-0.1
						l0.6,0.3c0,0-4,0.6-4.9,1.1c-0.8,0.4-0.5,1.4,0.2,1.3c0.7-0.1,4.2-0.8,4.4-0.6c0.2,0.1-3.8,0.9-4.2,1.2c-0.6,0.3-0.6,1.4,0.1,1.3
						c0.4,0,4.2-1,4.2-1s-3,1.2-3.3,1.4c-0.4,0.4-0.1,1.1,0.6,0.9c0.4-0.1,2.6-1.1,3.2-1.2c0,0,0.6,0.1,1.1,1.3
						c0.2,0.5,1.3,3.8,3.3,3.8c1.7,0,3.4,1.4,3.4,1.4L153.6,136.4L153.6,136.4z"/>
					<path id="path76" class="st11" d="M136.5,130.1c0,0,0.4,0.2,1.2,0.3c0.4,0.1,0.9,0.1,1.4,0.1c0.5-0.1,1.1-0.1,1.7-0.2
						c0.4-0.1,0.8-0.1,1.2-0.2h-0.1c1.6,0.6,2.9,1,2.9,0.9s-1.1-0.6-2.8-1.2l0,0l0,0c-0.4,0.1-0.8,0.1-1.2,0.2
						c-0.6,0.1-1.1,0.2-1.6,0.2s-0.9,0-1.3,0C137,130.1,136.5,130,136.5,130.1L136.5,130.1z"/>
					<g id="g82">
						<path id="path78" class="st10" d="M150.9,135.7c-0.4,0.4-1.1,0.3-1.6,0s-0.8-0.7-1.3-1c-1.4-1-3.3-0.7-4.6-1.8
							c-0.2-0.1-0.4-0.4-0.3-0.6c0.1-0.2,0.4-0.2,0.6-0.2c1.8,0,3.7,0.1,5.5,0.1"/>
						<path id="path80" class="st11" d="M149.3,132.3c0,0-0.2,0-0.6-0.1c-0.4,0-0.9-0.1-1.6-0.1c-0.7,0-1.5-0.1-2.4-0.1
							c-0.2,0-0.5,0-0.7,0s-0.5-0.1-0.8,0.2c-0.1,0.1-0.2,0.4-0.1,0.5c0.1,0.2,0.2,0.3,0.3,0.4c0.2,0.2,0.4,0.3,0.7,0.4
							c1,0.5,2,0.5,2.8,0.8c0.4,0.1,0.8,0.3,1.1,0.5s0.6,0.5,0.9,0.7c0.5,0.5,1.2,0.6,1.5,0.5c0.4-0.1,0.5-0.3,0.5-0.3
							s-0.2,0.1-0.5,0.2c-0.4,0-0.9-0.1-1.4-0.6c-0.3-0.2-0.5-0.5-0.9-0.7s-0.8-0.4-1.2-0.6c-0.9-0.3-1.9-0.4-2.8-0.8
							c-0.2-0.1-0.4-0.2-0.6-0.4c-0.2-0.1-0.3-0.3-0.2-0.4c0.1-0.1,0.3-0.1,0.6-0.1c0.2,0,0.5,0,0.7,0c0.9,0,1.7,0,2.4,0s1.2,0,1.6,0
							C149.1,132.3,149.3,132.3,149.3,132.3L149.3,132.3z"/>
					</g>
				</g>
				<g id="g136">
					<path id="path112" class="cp" d="M187.1,110.9l-10.9,5.3l4.7,32.9l-1.9,14.7c-0.2,1.9,0.5,3.8,1.9,5.1l17.6-0.4l11.8-2.1
						c2.3-0.1,4.6-0.2,6.8-0.3c-0.8-6-1.6-11.9-2.4-17.9l0.5-14.7l12.9,6.9c1,0.5,2.2,0.7,3.2,0.4l23.7-6.1l-1.2-9.1l-21.4,2.4
						l-16.3-14.4l-12.1-2.7l-2.2-3.6h-12.4l-0.9,0.6c-0.4,0.3-0.7,0.7-0.9,1.2C187.5,109.7,187.3,110.3,187.1,110.9z"/>
					<path id="path114" class="cp" d="M176.2,116.1c0,0-3.6,2.3-4.9,6.4c-1.2,3.9-4.1,19.5-4.1,19.5l-12.4-7.7l-7.1,7.5l16.5,14.6
						c2.5,2.2,6.2,2.7,9.2,1.3c2.5-1.2,4.3-3.6,4.7-6.4l2-14.9L176.2,116.1z"/>
					<path id="path120" class="st9" d="M197,117.2c0,0-0.2,0-0.5,0c-0.3,0.1-0.9,0.1-1.3,0.6c-0.4,0.5-0.7,1.2-0.6,2.1
						c0,0.9,0.2,1.8,0.6,2.8l0.2,0.5l-0.4-0.3c-1-0.7-1.9-1.5-2.8-2.4c-2.1-2.2-3.5-4.6-4.2-6.5c-0.4-0.9-0.6-1.7-0.7-2.2
						c-0.1-0.6-0.1-0.8-0.1-0.9c0.1,0,0.2,1.2,1,3s2.2,4.2,4.2,6.4c0.9,0.9,1.8,1.7,2.7,2.4l-0.2,0.2c-0.2-0.5-0.4-1-0.5-1.5
						s-0.1-1-0.1-1.4c0-0.9,0.3-1.7,0.8-2.2s1.1-0.5,1.4-0.6C196.8,117.2,197,117.2,197,117.2L197,117.2z"/>
					<path id="path122" class="cp" d="M197.7,117.2c0.9-0.4,2.2,0.2,2.9,0.9c0.4,0.5,0.7,1.2,1.2,1.5s2.3,0.6,2.9,0.2
						c0,0,0.3-1.5,0.5-2.1c0.7-2.5,0.9-4.8,1.2-7.3c-1.7-0.8-1.2-1.9-4.3-1.7c0,0,0.8,2.3,0.1,4.4"/>
					<path id="path124" class="st9" d="M202.1,113c0,0,0.1-0.4,0.2-1.2c0.1-0.8,0-1.9-0.4-3.2v-0.1h0.1c0.7,0,1.5-0.1,2.3,0.2
						c0.4,0.2,0.7,0.5,1.1,0.8c0.3,0.3,0.7,0.6,1.1,0.7h0.1v0.1c-0.1,1.3-0.3,2.7-0.5,4.2c-0.2,0.9-0.4,1.9-0.6,2.8
						c-0.2,0.9-0.4,1.7-0.6,2.5v0.1l0,0c-0.3,0.2-0.6,0.2-0.9,0.2c-0.3,0-0.6,0-0.9-0.1s-0.5-0.1-0.8-0.2s-0.5-0.2-0.7-0.4
						c-0.4-0.4-0.6-0.8-0.8-1.1c-0.2-0.3-0.5-0.6-0.8-0.8c-0.6-0.4-1.2-0.5-1.5-0.5c-0.4,0-0.6,0.1-0.6,0.1s0.2-0.1,0.6-0.2
						c0.4,0,1,0.1,1.6,0.4c0.3,0.2,0.6,0.4,0.9,0.8c0.3,0.3,0.5,0.8,0.8,1.1c0.3,0.3,0.9,0.4,1.4,0.5c0.3,0,0.5,0.1,0.8,0
						c0.3,0,0.6,0,0.8-0.2v0.1c0.2-0.8,0.4-1.7,0.6-2.5c0.2-0.9,0.4-1.8,0.6-2.7c0.2-1.4,0.4-2.9,0.6-4.1l0.1,0.1
						c-0.4-0.2-0.8-0.5-1.1-0.8s-0.6-0.6-1-0.7c-0.7-0.3-1.5-0.2-2.2-0.2l0.1-0.1c0.4,1.3,0.4,2.5,0.3,3.2c-0.1,0.4-0.1,0.7-0.2,0.9
						C202.1,112.9,202.1,113,202.1,113L202.1,113z M200.2,161.9c0,0-0.2-0.4-0.5-1.4c-1.5-5.2-1.2-10.2-1-12.8c0.2-2.3,0.2-2.1,0.9-6
						c0.3-1.9,0.5-3.8,0.5-5.6c0.1-3.6-0.2-6.9-0.7-9.5c-0.5-2.7-1.1-4.8-1.6-6.3c-0.2-0.7-0.4-1.3-0.6-1.7c-0.1-0.2-0.1-0.3-0.1-0.4
						s0-0.1,0-0.1s0,0,0.1,0.1c0,0.1,0.1,0.2,0.2,0.4c0.1,0.4,0.4,0.9,0.6,1.6c0.5,1.4,1.1,3.6,1.7,6.3c0.5,2.7,0.9,6,0.8,9.6
						c0,1.1-0.1,3.2-0.5,5.7c-0.1,0.7-0.3,1.6-0.5,3.2c-0.1,0.7-0.2,1.7-0.3,2.8c0,0-0.2,2.3-0.2,4.5
						C198.7,157.3,200.3,161.9,200.2,161.9z"/>
					<path id="path126" class="st9" d="M186.4,171.5c0,0-0.4-0.2-1-0.7c-0.5-0.5-1.2-1.4-1.6-2.4c-0.4-1.1-0.6-2.1-0.6-2.9
						c0-0.8,0.1-1.2,0.2-1.2c0,0,0,0.5,0,1.2s0.2,1.7,0.6,2.8c0.4,1,1,1.9,1.5,2.4C186.1,171.1,186.5,171.4,186.4,171.5L186.4,171.5z
						M186,167.3c0.1,0-0.1,0.7,0,1.5s0.3,1.5,0.3,1.5c-0.1,0-0.5-0.6-0.5-1.5C185.7,168,186,167.3,186,167.3L186,167.3z M180.8,148.3
						c0,0,0-0.1-0.1-0.2c0-0.2-0.1-0.4-0.1-0.7c-0.1-0.7-0.3-1.6-0.4-2.7c-0.5-2.9-1-5.9-1.5-8.8c-0.6-3.4-1.3-6.5-1.7-8.8
						c-0.2-1.1-0.4-2-0.5-2.7c-0.1-0.3-0.1-0.5-0.1-0.7s0-0.3,0-0.3s0,0.1,0.1,0.2c0.1,0.2,0.1,0.4,0.2,0.7c0.2,0.6,0.4,1.5,0.6,2.6
						c0.5,2.2,1.2,5.3,1.8,8.7c0.6,3.4,1.1,6.6,1.5,8.8c0.2,1.1,0.3,2.1,0.4,2.7c0,0.3,0.1,0.5,0.1,0.7
						C180.8,148.2,180.8,148.2,180.8,148.3L180.8,148.3z M214.9,148.3c0,0,0-0.1,0-0.3s0-0.5,0-0.8c0-0.7,0-1.6,0-2.8
						c0-2.4,0.1-5.6,0.4-9.2s0.6-6.8,0.9-9.1c0.1-1.1,0.3-2.1,0.4-2.8c0-0.3,0.1-0.5,0.1-0.8c0-0.2,0.1-0.3,0.1-0.3s0,0.1,0,0.3
						c0,0.2,0,0.5-0.1,0.8c-0.1,0.7-0.2,1.6-0.3,2.8c-0.2,2.3-0.6,5.6-0.8,9.1c-0.3,3.6-0.4,6.8-0.5,9.2c0,1.1-0.1,2.1-0.1,2.8
						c0,0.3,0,0.6,0,0.8C214.9,148.2,214.9,148.3,214.9,148.3L214.9,148.3z M172.3,149.6c-0.1,0-0.8-1.9-2.2-4s-3-3.4-2.9-3.5
						c0,0,0.4,0.3,1,0.8s1.4,1.4,2.1,2.5c0.7,1.1,1.2,2.1,1.5,2.9S172.3,149.6,172.3,149.6z"/>
					<path id="path128" class="st9" d="M173.2,147.3c0,0-0.2-0.4-0.6-1c-0.4-0.6-1-1.4-1.8-2.2c-0.9-0.7-1.8-1.2-2.4-1.5
						c-0.7-0.3-1.1-0.5-1.1-0.5s0.5,0.1,1.2,0.3c0.7,0.3,1.6,0.7,2.5,1.4s1.5,1.6,1.8,2.3C173.1,146.9,173.2,147.3,173.2,147.3
						L173.2,147.3z"/>
				</g>
				<g id="g154">
					<path id="path138" class="st10" d="M188.9,90.8v18.4c0,1.1,0.4,2.1,1.1,2.9c1,1.2,2.3,2.3,3.7,3.1l3.7,2.1l3.1-2.7
						c0.9-0.8,1.4-1.9,1.5-3.2c0.1-1.3,0.1-2.7,0.1-2.7s5.2-0.4,5.7-5.5c0.2-2.4,0.3-7.4,0.3-11.9c0-5.2-4-9.5-9.1-10h-0.1
						C193.3,81.1,188.9,85.5,188.9,90.8L188.9,90.8z"/>
					<path id="path140" class="st11" d="M202,108.6c0,0-3.2-0.1-6.5-2.5c0,0,1.3,3.9,6.5,3.8V108.6L202,108.6z"/>
					<path id="path142" d="M206.5,94.5c0,0.4-0.4,0.7-0.8,0.7c-0.4,0-0.7-0.3-0.7-0.7s0.4-0.7,0.8-0.7
						C206.2,93.8,206.5,94.1,206.5,94.5L206.5,94.5z M199,94.2c0,0.4-0.4,0.7-0.8,0.7c-0.4,0-0.7-0.3-0.7-0.7s0.4-0.7,0.8-0.7
						C198.7,93.4,199,93.8,199,94.2L199,94.2z M201.3,98.9c0,0,0.5-0.1,1.3-0.1c0.2,0,0.4,0,0.4-0.2c0.1-0.1,0-0.4-0.1-0.6
						c-0.1-0.5-0.3-1-0.5-1.6c-0.6-2.2-1.1-4.1-1-4.1s0.7,1.8,1.3,4c0.2,0.6,0.3,1.1,0.4,1.6c0.1,0.2,0.2,0.5,0,0.8
						c-0.1,0.1-0.2,0.2-0.4,0.3c-0.1,0-0.2,0-0.3,0C201.8,99,201.3,98.9,201.3,98.9z M199,100.3c0.1,0,0.4,0.8,1.2,1.2
						c0.9,0.4,1.7,0.1,1.8,0.2c0,0-0.1,0.2-0.5,0.3c-0.5,0.1-1,0.1-1.5-0.1s-0.8-0.7-1-1C198.9,100.6,198.9,100.3,199,100.3L199,100.3
						z M199.4,90.7c-0.1,0.2-0.9,0-1.8,0.1c-0.9,0-1.7,0.2-1.8,0c0-0.1,0.1-0.3,0.4-0.5c0.3-0.2,0.8-0.3,1.4-0.4s1.1,0.1,1.4,0.3
						C199.3,90.5,199.4,90.6,199.4,90.7z M206.6,92c-0.2,0.2-0.7-0.1-1.3-0.1c-0.6-0.1-1.2,0-1.3-0.2c-0.1-0.1,0.1-0.3,0.3-0.4
						c0.2-0.2,0.7-0.3,1.1-0.2c0.4,0.1,0.8,0.2,1,0.5C206.6,91.7,206.6,91.9,206.6,92L206.6,92z M195,85.7L195,85.7
						c-0.9,0.6-1.5,1.5-1.7,2.5v0.1c-0.3,1.4-0.4,2.9-0.7,4.4c-0.3,1.4-0.3,2.5-1.7,2.9c-0.9,0.2-1.7-0.3-2-0.8
						c-0.1-0.2-0.1-0.5-0.1-0.7c0-3.6-0.7-4.9-0.4-8.6c0.1-1,0.6-1.9,1.5-2.4c0.8-0.5,1.8-1,2.6-0.5s1.8,2.2,2.4,2.9"/>
					<g id="g148">
						<path id="path144" class="st10" d="M190.5,93.3c-0.1,0-3.4-1.3-3.6,2.2c-0.2,3.5,3.4,3,3.4,2.9S190.5,93.3,190.5,93.3
							L190.5,93.3z"/>
						<path id="path146" class="st11" d="M189.2,97c0,0-0.1,0-0.2,0.1s-0.3,0.1-0.5,0c-0.4-0.2-0.6-0.8-0.6-1.4c0-0.3,0.1-0.6,0.2-0.9
							c0.1-0.2,0.3-0.4,0.5-0.4s0.3,0.1,0.4,0.2s0,0.2,0,0.2s0.1-0.1,0.1-0.2s-0.1-0.2-0.1-0.2c-0.1-0.1-0.2-0.1-0.3-0.1
							c-0.3,0-0.5,0.2-0.7,0.5s-0.3,0.6-0.3,1c0,0.7,0.3,1.4,0.8,1.6c0.2,0.1,0.5,0,0.6-0.1C189.2,97,189.3,97,189.2,97L189.2,97z"/>
					</g>
					<path id="path150" d="M192.2,83c-0.7-1.5,1.8-3.3,3.6-3.7s4,0.6,5.7,1.3c1.1,0.5,2.1,1,3.2,1.1c0.8,0,1.5-0.2,2.3-0.4
						c0.7-0.2,1.5-0.4,2.3-0.3s1.5,0.6,1.8,1.3c0.2,0.7-0.3,1.7-1,1.7c1-0.5,2.4,0.4,2.4,1.5c0.1,1.1-1.2,2.1-2.2,1.8
						c0.5-0.3,1.2,0.3,1.1,1c-0.1,0.6-0.7,1-1.3,1.2c-1.5,0.5-3.2,0.2-4.7-0.5c-1.5-0.6-2.8-1.5-4.3-2.2s-3.9-1.9-5.4-1.5
						c-1.1,0.3-2,1.6-2.6,1.2c-0.6-0.3-0.9-1-1-1.7S192,83.6,192.2,83"/>
					<path id="path152" class="st4" d="M210.4,84.1c0,0-0.2,0.2-0.6,0.6c-0.4,0.4-1.1,0.8-2.1,1.1c-0.9,0.2-2.2,0.2-3.3-0.4
						s-2.1-1.8-3.2-2.9c-1.1-1.1-2.4-2-3.8-2.2c-1.4-0.2-2.7,0.2-3.4,0.9c-0.8,0.7-1,1.6-1,2.2c0,0.3,0.1,0.5,0.1,0.7
						c0.1,0.1,0.1,0.2,0.1,0.2s-0.1-0.3-0.1-0.9c0-0.5,0.3-1.4,1-2c0.7-0.7,1.9-1,3.2-0.8s2.5,1.1,3.6,2.2s2,2.3,3.3,2.9
						c1.2,0.7,2.6,0.7,3.6,0.4c1-0.3,1.7-0.8,2.1-1.2c0.2-0.2,0.3-0.4,0.4-0.5C210.3,84.1,210.4,84.1,210.4,84.1L210.4,84.1z
						M189.6,93.1c0,0,0.2-0.1,0.3-0.3s0.3-0.7,0.3-1.3c0-1.1-0.9-2.4-0.9-4c0-0.8,0.2-1.5,0.6-2.1s0.9-0.9,1.4-1
						c0.5-0.2,0.9-0.1,1.2-0.1c0.3,0.1,0.4,0.1,0.4,0.1s-0.1-0.1-0.4-0.2c-0.3-0.1-0.8-0.2-1.3,0c-0.5,0.1-1.1,0.5-1.6,1.1
						c-0.4,0.6-0.7,1.4-0.6,2.2c0,0.8,0.2,1.6,0.5,2.3c0.2,0.7,0.4,1.2,0.5,1.7C190,92.5,189.5,93,189.6,93.1L189.6,93.1z"/>
				</g>
				<g id="g168">
					<path id="path160" class="cs" d="M234.7,198.9c-20.6,5.5-40.6,5.1-62.2,0l-2.5-39.7c22.4,4.4,44.9,3.8,64.8-0.9L234.7,198.9
						L234.7,198.9z"/>
					<path id="path162" class="st9" d="M231.6,191.3c0,0,0,0-0.1,0.1c-0.1,0-0.2,0.1-0.4,0.2c-0.3,0.1-0.9,0.4-1.5,0.6
						c-1.3,0.5-3.3,1.2-5.7,1.8c-4.9,1.3-11.9,2.3-19.6,2.2c-7.7-0.1-14.7-1.2-19.6-2.1c-2.5-0.5-4.5-0.9-5.9-1.2
						c-0.7-0.2-1.2-0.3-1.6-0.4c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.1,0-0.1,0h0.1c0.1,0,0.2,0,0.4,0.1l1.6,0.3c1.4,0.3,3.4,0.7,5.9,1.1
						c5,0.9,11.9,1.9,19.6,2c7.7,0.1,14.7-0.9,19.5-2.1c2.4-0.6,4.4-1.2,5.7-1.7c0.7-0.2,1.2-0.4,1.5-0.6c0.2-0.1,0.3-0.1,0.4-0.1
						C231.5,191.3,231.6,191.3,231.6,191.3L231.6,191.3z"/>
					<path id="path164" class="st9" d="M201.2,192.9c-2.6,0.8-5.2,0.6-7.9-0.3v-14.1c2.6-0.5,5.3-0.5,7.9,0V192.9z M214.1,192.1
						c0,0,0,0,0-0.1s0-0.2,0-0.3c0-0.3,0-0.7,0-1.2c0-1-0.1-2.5-0.1-4.4c-0.1-3.7-0.1-9-0.2-15.1l0.1,0.1c-2.5-0.5-5.4-0.5-8.2,0
						l0.1-0.1v0.3c0.1,8.3,0.2,15.9,0.2,21.3l-0.1-0.1c1.3,0.3,2.4,0.4,3.5,0.4c1,0,1.9-0.1,2.6-0.2c0.7-0.1,1.2-0.3,1.6-0.4
						C213.8,192.2,213.9,192.2,214.1,192.1L214.1,192.1c0,0,0,0-0.1,0.1l-0.4,0.2c-0.4,0.1-0.9,0.3-1.6,0.5c-0.7,0.1-1.6,0.3-2.6,0.3
						s-2.2-0.1-3.5-0.4h-0.1v-0.1c-0.1-5.4-0.2-12.9-0.3-21.3V171h0.1c2.9-0.6,5.8-0.5,8.3,0h0.1v0.1c0,6.1,0.1,11.4,0.1,15.1
						c0,1.8,0,3.3,0,4.4c0,0.5,0,0.9,0,1.2C214.1,191.9,214.1,192,214.1,192.1C214.1,192.1,214.1,192.1,214.1,192.1L214.1,192.1z
						M226.3,190.4c-2.1,1.1-4.9,1.3-7.9,1.1v-23.3c2.7-0.6,5.4-0.7,7.9,0V190.4z M188.6,191.8c0,0,0-0.2,0-0.6c0-0.4,0-1,0-1.6
						l-0.1-5.7l0.1,0.1c-2.1-0.8-4.8-1.5-7.7-1.3l0,0l0.1-0.1c0,2.9,0,5.6,0,7.9l-0.1-0.1c1.1,0.5,2.1,0.7,3.1,0.9
						c0.9,0.2,1.8,0.3,2.5,0.3c0.7,0.1,1.2,0.1,1.6,0.1C188.4,191.8,188.6,191.8,188.6,191.8s-0.2,0-0.6,0c-0.4,0-0.9,0-1.6-0.1
						c-0.7,0-1.5-0.1-2.5-0.3c-0.9-0.2-2-0.4-3.1-0.9h-0.1v-0.1c0-2.3,0-5,0-7.9v-0.1h0.1l0,0c2.9-0.3,5.6,0.5,7.8,1.3h0.1v0.1
						c0,2.4,0,4.4-0.1,5.8c0,0.7,0,1.2,0,1.6C188.6,191.6,188.6,191.8,188.6,191.8L188.6,191.8z M192.3,171.2c0,0-0.6,0.1-1.6,0.1
						s-2.3,0-3.8-0.1s-2.8-0.2-3.7-0.4c-1-0.1-1.5-0.2-1.5-0.3c0,0,0.6,0,1.6,0.1s2.3,0.2,3.7,0.3c1.5,0.1,2.8,0.1,3.7,0.2
						C191.7,171.1,192.3,171.1,192.3,171.2L192.3,171.2z M191.1,172.9c0,0-0.5,0-1.4,0c-2.2-0.1-4.4-0.3-6.6-0.5
						c-0.8-0.1-1.4-0.2-1.4-0.2s0.5,0,1.4,0c2.2,0.1,4.4,0.3,6.6,0.5C190.6,172.8,191.2,172.9,191.1,172.9L191.1,172.9z"/>
					<path id="path166" class="st9" d="M194.1,175.2c0,0,0-0.2,0-0.5s0-0.8,0.1-1.4c0.1-1.2,0.1-2.9,0.2-5l0.1,0.1
						c-3.8,0.1-9-0.1-14.7-0.8c-0.1,0.1,0.2-0.2,0.2-0.1v0.1v0.1v0.2v0.4c0,0.3,0,0.6-0.1,0.9c0,0.6-0.1,1.2-0.1,1.7
						c-0.1,1.1-0.1,2.2-0.2,3.3l-0.1-0.1c4.3,0.5,7.9,0.7,10.5,0.8c1.3,0,2.3,0.1,3.1,0.1c0.3,0,0.6,0,0.8,0
						C193.9,175.2,194,175.2,194.1,175.2c0,0-0.1,0-0.3,0s-0.5,0-0.8,0c-0.7,0-1.8,0-3.1,0c-2.6-0.1-6.3-0.2-10.6-0.8h-0.1v-0.1
						c0.1-1.1,0.1-2.2,0.2-3.3c0-0.6,0.1-1.2,0.1-1.7c0-0.3,0-0.6,0.1-0.9V168v-0.2v-0.1v-0.1v-0.1c0,0,0.3-0.2,0.2-0.1
						c5.7,0.7,10.9,0.9,14.7,0.9h0.1v0.1c-0.1,2.1-0.3,3.8-0.4,5c0,0.6-0.1,1-0.1,1.4C194.1,175,194.1,175.2,194.1,175.2L194.1,175.2z
						M195.9,178.2c-0.1,0-0.5-0.6-1-1.4c-0.5-0.8-0.9-1.5-0.8-1.6c0.1,0,0.5,0.6,1,1.4C195.6,177.4,195.9,178.1,195.9,178.2z"/>
				</g>
				<g id="g182">
					<path id="path170" class="cs" d="M109.6,127l6.8,40.8c0,0,10.3,19.3,37.7,23.6l-4.4-36.5C149.6,155,124.3,147.2,109.6,127z"/>
					<path id="path172" class="st9" d="M150.5,188.1c0,0-0.1,0-0.2,0c-0.1,0-0.3-0.1-0.5-0.1c-0.5-0.1-1.1-0.3-1.9-0.6
						c-0.8-0.3-1.9-0.5-3-1c-0.6-0.2-1.2-0.5-1.9-0.7s-1.4-0.5-2.1-0.9c-1.4-0.7-3-1.3-4.6-2.2c-1.7-0.8-3.3-1.9-5.1-3
						c-3.5-2.3-7-5.2-10.2-8.8c-1.4-1.6-2.7-3.3-3.7-5.1l0,0l0,0c-1.2-8-2.3-14.9-3.1-20c-0.4-2.5-0.7-4.5-0.9-5.9
						c-0.1-0.7-0.2-1.2-0.2-1.6l-0.1-0.4c0-0.1,0-0.1,0-0.1s0,0,0,0.1l0.1,0.4l0.3,1.6c0.2,1.4,0.6,3.4,1,5.9c0.8,5,1.9,12,3.2,20l0,0
						c1,1.8,2.2,3.4,3.6,5.1c3.1,3.6,6.7,6.5,10.1,8.8c1.7,1.1,3.4,2.2,5,3c1.6,0.9,3.2,1.6,4.6,2.2c0.7,0.3,1.4,0.6,2.1,0.9
						c0.7,0.3,1.3,0.5,1.8,0.7c1.1,0.5,2.2,0.8,3,1.1s1.5,0.5,1.9,0.6c0.2,0.1,0.4,0.1,0.5,0.2C150.4,188.1,150.5,188.1,150.5,188.1
						L150.5,188.1z"/>
					<path id="path174" class="st9" d="M148.1,176.2C148.1,176.2,148.1,176.3,148.1,176.2l-0.4,0.3l-1.1,0.9c-1,0.8-2.5,1.9-4.3,3.3
						l-0.1,0.1l-0.1-0.1c-1.4-2-3.2-4.5-5.3-7.3h0.2c-1.3,0.9-2.6,1.9-4,2.9l-0.1,0.1l-0.1-0.2c-1.6-4.3-3.3-9.1-5.2-14.1
						c-0.2-0.7-0.5-1.4-0.7-2h0.3c-1.1,2.2-2.2,4.4-3.3,6.5l-0.1,0.3l-0.1-0.3c-2.9-8.2-5.3-15.2-6.9-19.8h0.1c-0.4,1.8-0.8,3.3-1,4.3
						c-0.1,0.5-0.2,0.8-0.3,1.1l-0.1,0.3c0,0.1,0,0.1,0,0.1s0,0,0-0.1s0-0.2,0.1-0.3c0.1-0.3,0.1-0.6,0.2-1.1c0.2-1,0.5-2.4,0.9-4.3
						l0.1-0.3l0.1,0.2c1.6,4.5,4.1,11.5,7,19.7h-0.2c1.1-2.1,2.2-4.3,3.3-6.5l0.1-0.3l0.1,0.3c0.2,0.7,0.5,1.4,0.7,2
						c1.8,5,3.6,9.8,5.2,14.1l-0.2-0.1c1.4-1,2.8-2,4-2.9l0.1-0.1l0.1,0.1c2,2.8,3.8,5.3,5.2,7.3h-0.1c1.9-1.4,3.3-2.5,4.3-3.2
						c0.5-0.4,0.9-0.6,1.2-0.9c0.1-0.1,0.2-0.2,0.3-0.2C148,176.3,148.1,176.2,148.1,176.2L148.1,176.2z M147.1,156.6
						c0,0-0.2,0-0.6-0.1c-0.4-0.1-0.9-0.3-1.5-0.5c-1.3-0.4-3-1.1-4.9-1.9c-1.9-0.8-3.5-1.7-4.7-2.4c-0.6-0.3-1.1-0.6-1.4-0.8
						c-0.3-0.2-0.5-0.3-0.5-0.3s0.2,0.1,0.5,0.3c0.4,0.2,0.9,0.5,1.4,0.8c1.2,0.6,2.8,1.5,4.7,2.3s3.6,1.5,4.9,2
						c0.6,0.2,1.1,0.4,1.5,0.6C147,156.5,147.1,156.6,147.1,156.6L147.1,156.6z M148.2,158.3c0,0-0.5,0-1.3-0.2s-1.9-0.5-3.1-0.9
						c-1-0.4-2-0.8-3-1.2c-0.7-0.4-1.2-0.6-1.2-0.7c0,0,0.5,0.2,1.2,0.5c0.8,0.3,1.8,0.7,3,1.2c1.2,0.4,2.3,0.8,3.1,1
						C147.7,158.2,148.2,158.3,148.2,158.3L148.2,158.3z"/>
					<path id="path176" class="st3" d="M137.4,173.3c0,0.3-0.2,0.6-0.5,0.6s-0.5-0.3-0.5-0.6s0.2-0.6,0.5-0.6S137.4,172.9,137.4,173.3
						z"/>
					<ellipse id="ellipse178" class="st9" cx="127.2" cy="160.1" rx="0.5" ry="0.6"/>
					<path id="path180" class="st3" d="M117.3,147.1c0,0.3-0.2,0.6-0.5,0.6s-0.5-0.3-0.5-0.6s0.2-0.6,0.5-0.6S117.3,146.7,117.3,147.1
						z"/>
				</g>
				<g id="g194">
					<path id="path184" class="cs" d="M287.7,131.6c-0.9-2.9-3-7-8.1-7.8l-6,55.8c0,0,11.4-2.3,11.7-8.5c0-0.5,2.3-28.3,2.9-35.1
						C288.1,134.5,288.1,133.1,287.7,131.6L287.7,131.6z"/>
					<path id="path184_00000070117274775415600790000005137757051958060175_" class="st9" d="M287.7,131.6c-0.9-2.9-3-7-8.1-7.8
						l-6,55.8c0,0,11.4-2.3,11.7-8.5c0-0.5,2.3-28.3,2.9-35.1C288.1,134.5,288.1,133.1,287.7,131.6L287.7,131.6z"/>
					<path id="path186" class="cs" d="M288,135.4l-3.4,41.5c0,0-9.7,17.7-36.9,18.4l3.4-41.8C251.1,153.4,274.8,152.5,288,135.4
						L288,135.4z"/>
					<path id="path188" class="st9" d="M287.4,144.4c-2.7,3.9-4.2,8.6-4.3,13.4c0,1.4,0.1,2.8-0.5,4.1c-0.5,1.3-1.9,2.3-3.2,2
						c-1.4-0.4-2-2-3.2-2.8c-1.1-0.7-2.5-0.6-3.6,0.1c-1.1,0.6-1.9,1.7-2.4,2.9c-1,2.3-1.1,4.8-1.8,7.2c-0.7,2.4-2.5,4.8-5,4.9
						c-0.9,0-1.8-0.2-2.7-0.2c-1.9,0.1-3.6,1.5-4.5,3.2s-1.2,3.6-1.4,5.5c0,0,14.5,1.4,30.8-17.8L287.4,144.4L287.4,144.4z"/>
					<path id="path190" class="st9" d="M264.3,169.5c0,0.5-0.3,0.9-0.6,0.9s-0.6-0.4-0.6-0.9s0.3-0.9,0.6-0.9S264.3,169,264.3,169.5z
						M271.2,152.3c0,0.5-0.3,0.9-0.6,0.9s-0.6-0.4-0.6-0.9s0.3-0.9,0.6-0.9S271.2,151.8,271.2,152.3z"/>
					<path id="path192" class="st9" d="M287.1,146.4c0,0,0,0.1-0.1,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.3,0.6-0.7,1.5-1.2,2.5
						c-1.1,2.2-2.5,5.3-4.3,9l-0.1,0.1l-0.1-0.1c-3-1.9-6.5-4.1-10.3-6.5l0.2-0.1c-0.1,0.3-0.3,0.7-0.4,1.1
						c-2.5,6.2-4.9,11.9-6.6,16.2v0.1l-0.1-0.1c-2-1.1-4.1-2.3-6.1-3.4c-0.7-0.4-1.3-0.7-1.7-0.9l-0.4-0.2c-0.1,0-0.2-0.1-0.2-0.1
						c0-0.1,0.9,0.3,2.3,1.1c1.5,0.8,3.6,1.9,6.1,3.3l-0.1,0.1c1.8-4.3,4-10,6.6-16.2c0.1-0.4,0.3-0.7,0.4-1.1l0.1-0.2l0.1,0.1
						c3.7,2.4,7.2,4.6,10.2,6.5l-0.2,0.1c1.8-3.7,3.3-6.8,4.4-9c0.5-1.1,1-1.9,1.3-2.5c0.1-0.3,0.3-0.5,0.3-0.7
						C287,146.4,287,146.4,287.1,146.4L287.1,146.4z"/>
				</g>
			</g>
		</g>
	</svg>
</template>

<style scoped>
	.cp{fill:#BE8FFA;}
	.cs{fill:#3CDFAD;}
	.st0{fill:#F9F9F9;}
	.st2{opacity:0.6;}
	.st3{fill:#FFFFFF;}
	.st4{fill:#2D2D2D;}
	.st5{fill:#455A64;}
	.st7{fill:#E0E0E0;}
	.st8{fill:#F5F5F5;}
	.st9{opacity:0.3;enable-background:new;}
	.st10{fill:#FFBE9D;}
	.st11{fill:#EB996E;}
</style>
