import BaseService from './base.service';
import { useAuthStore } from '@/stores';
class AutenticacaoService extends BaseService {
    constructor() {
        super({ resource: 'autenticacao' });
    }
    async forgotPassword(email) {
        let headers = {};
        if (!useAuthStore().loggedIn) {
            const tenantId = await useAuthStore().getActiveTentant;
            headers = tenantId && tenantId !== "00000000-0000-0000-0000-000000000000" ? { 'x-tenant': tenantId } : {};
        }
        return await this.plantarApi.get(`${this.resource}/forgotpassword/${email}`, { headers });
    }
    async registerLogAuthentication() {
        try {
            return await this.plantarApi.post('AuthenticationLog');
        }
        catch (error) {
            //
        }
        return { success: false };
    }
}
const autenticacaoService = new AutenticacaoService();
export default autenticacaoService;
