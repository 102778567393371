import { toast } from '@/common/util';
import { vistraBiApi } from '@/config/appAPIs';
export default {
    async login({ email, password }) {
        try {
            return await vistraBiApi.post('/Integracao2/Login', { usuario: email, senha: password });
        }
        catch (error) {
            const { notifications } = error.response?.data || {};
            toast.error({ message: notifications[0] || 'Erro ao autenticar usuário no Vistra BI' });
        }
    },
    async setParamsRequest({ panelId, params }) {
        try {
            return await vistraBiApi.post('/Integracao2/SetParametrosRequisicaoExterna', {
                painel: panelId,
                parametros: params.map(el => ({ id: el.id, descricao: el.description, valor: el.value }))
            });
        }
        catch (error) {
            throw { ...error };
        }
    }
};
