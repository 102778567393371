import { defineStore } from 'pinia';
import { computed, reactive, ref } from 'vue';
import { useLocalStorage } from '@vueuse/core';
import { dialog, toast, i18N } from '@/common/util';
import contratosService from '@/services/contratos.service';
export const useContratosStore = defineStore('contratos', () => {
    /**
     * States
     */
    const list = ref([]);
    const status = ref(0); // -1 erro de exceção; 0 - inicial; 1 - carregando; 2 - sucesso; 3 - erro não fatal
    const usageInfo = reactive({ qtUsers: 0, qtInContract: 0 });
    /**
     * Getters
    */
    const loading = computed(() => status.value === 1);
    const canCreateUsers = computed(() => (usageInfo.qtUsers === 0 && usageInfo.qtInContract === 0) || usageInfo.qtUsers < usageInfo.qtInContract);
    const isDistribuidor = useLocalStorage('isDistribuidor', false);
    /**
     * Actions
     */
    const create = async (objData) => {
        status.value = 1;
        try {
            const { success } = await contratosService.create(getFormSubmit(objData));
            if (success) {
                getAll();
                toast.success({ message: i18N('toasts.createSuccess') });
            }
            return success;
        }
        catch {
            //
        }
        finally {
            status.value = 2;
        }
    };
    const update = async (objData, id) => {
        status.value = 1;
        try {
            objData.contratosComunicacoes = objData.contratosComunicacoes.map((el) => {
                if (!el.comunicacaoId)
                    return { comunicacaoId: el };
                return el;
            });
            const { success } = await contratosService.put(id, objData);
            if (success) {
                getAll();
                toast.success({ message: i18N('toasts.updateSuccess') });
            }
            return success;
        }
        catch {
            //
        }
        finally {
            status.value = 2;
        }
    };
    const getAll = async () => {
        status.value = 1;
        try {
            const { data } = await contratosService.get();
            list.value = data;
        }
        catch {
            //
        }
        finally {
            status.value = 2;
        }
    };
    const remove = async (contrato, force) => {
        if (!force)
            return dialog.open({
                title: i18N('feedback.attention'),
                message: i18N('dialogs.delete.message', { type: i18N('labels.contract').toLowerCase(), name: contrato.nomeEmpresa }),
                complete: () => remove(contrato, true)
            });
        try {
            status.value = 1;
            const { success } = await contratosService.delete(contrato.id);
            if (success)
                list.value.splice(list.value.findIndex(el => el.id === contrato.id), 1);
            status.value = 2;
            return success;
        }
        catch {
            status.value = -1;
        }
    };
    const getById = async (id) => {
        try {
            const { data } = await contratosService.get(id);
            return data;
        }
        catch {
            //
        }
    };
    const getIsDistribuidor = async () => {
        try {
            const { data, success } = await contratosService.isDistribuidor();
            if (success)
                isDistribuidor.value = data;
        }
        catch {
            //
        }
    };
    const getUsageInfo = async () => {
        try {
            const { data, success } = await contratosService.getUsageInfo();
            if (success) {
                Object.assign(usageInfo, {
                    qtUsers: data.usuarioUsageInfo?.usuariosAtivos,
                    qtInContract: data.usuarioUsageInfo?.usuariosContratados
                });
            }
        }
        catch {
            //
        }
    };
    const getFormSubmit = (data) => {
        return {
            ...data,
            contratosSecoes: data.secoesIds.map(el => ({ secaoId: el })),
            contratosComunicacoes: data.contratosComunicacoes.map(el => ({ comunicacaoId: el }))
        };
    };
    return {
        list,
        status,
        isDistribuidor,
        usageInfo,
        // Getters
        loading,
        canCreateUsers,
        // Actions
        create,
        update,
        getAll,
        getById,
        remove,
        getIsDistribuidor,
        getUsageInfo
    };
});
export default useContratosStore;
