/* eslint-disable no-unused-vars */
import { defineStore } from 'pinia';
import { computed, reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { dialog, toast, formatBytes, convertHMS } from '@/common/util';
import midiasService from '@/services/midias.service';
import { midiaService } from '@/services/midias.service';
export const useMidiaStore = defineStore('midias', () => {
    const { t } = useI18n();
    const transferList = reactive([]);
    const list = ref([]);
    const status = reactive({
        create: 'initial',
        update: 'initial',
        getAll: 'initial',
        getById: 'initial',
        delete: 'initial',
        export: 'initial',
        bonds: 'initial',
        favorites: 'initial',
        reaction: 'initial'
    });
    /**
     * Getters
     */
    const downloadList = computed(() => transferList.filter((item) => item.type === 0));
    const uploadList = computed(() => transferList.filter((item) => item.type === 1));
    const loading = computed(() => Object.values(status).some(el => el === 'loading'));
    const uploadProgress = computed(() => uploadList.value.some((el) => el.status === 1));
    /**
     * Actions
     */
    // Helpers
    const addQueue = (info, type) => {
        const listIdx = transferList.findIndex((el) => info.id === el.id && info.fileType !== el.fileType && info.isCoverImage === el.isCoverImage);
        if (listIdx !== -1 && ![-1, 2, 3].includes(transferList[listIdx].status))
            return;
        transferList.splice(listIdx, 1);
        transferList.push({
            id: info.id,
            type,
            progress: 0,
            name: info.name || '',
            controller: new AbortController(),
            status: 0,
            fileType: info.fileType ?? '',
            startTime: new Date().getTime(),
            isCoverImage: info.isCoverImage
        });
        return transferList.find((dl) => dl.id === info.id);
    };
    const fileDownload = async (id, name) => {
        const download = addQueue({ id, name }, 0);
        try {
            download.status = 1;
            await midiasService.fileDownload(id, {
                progressCB: (progressEvent) => {
                    download.progress = ((progressEvent.loaded / progressEvent.total) * 100) | 0;
                    if (download.progress === 100)
                        download.status = 2;
                },
                controller: download.controller
            });
            download.status = 2;
        }
        catch (error) {
            download.status = -1;
        }
    };
    const fileUpload = async (info, file) => {
        const upload = addQueue(info, 1);
        if (!upload)
            return;
        try {
            upload.status = 1;
            toast.open({
                type: 'info',
                title: null,
                icon: 'upload',
                message: `upload ${info.isCoverImage ? t('toasts.repository.uploadCover') : ''} ${t('labels.started').toLowerCase()}`,
                duration: 2
            });
            const optionsUpload = {
                progressCB: (progressEvent) => {
                    upload.progress = ((progressEvent.loaded / progressEvent.total) * 100) | 0;
                    upload.loaded = formatBytes(progressEvent.loaded);
                    upload.total = formatBytes(progressEvent.total);
                    if (upload.progress === 100) {
                        upload.status = 2;
                    }
                    const timeElapsed = new Date().getTime() - upload.startTime;
                    const uploadSpeed = (progressEvent.loaded / (timeElapsed / 1000));
                    upload.timeRemaining = convertHMS((progressEvent.total - progressEvent.loaded) / uploadSpeed);
                },
                controller: upload.controller
            };
            const result = info.isCoverImage
                ? await midiasService.fileUploadCoverImage(info.id, file, optionsUpload)
                : await midiasService.fileUpload(info.id, file, optionsUpload);
            upload.status = 3;
            upload.endTime = new Date().getTime();
            toast.success({
                title: null,
                message: `${info.name} enviado com sucesso`
            });
            return result;
        }
        catch (error) {
            console.log(error);
            upload.status = -1;
        }
        finally {
            await getAll();
        }
    };
    const cancelTransfer = (id) => {
        const listIdx = transferList.findIndex((el) => id === el.id);
        if (listIdx === -1)
            return;
        if (transferList[listIdx].status === 1) {
            transferList[listIdx].controller.abort();
            transferList[listIdx].status = -2;
            toast.open({
                type: 'warning',
                title: t('labels.transfer'),
                message: t('toasts.repository.cancelTransfer')
            });
        }
    };
    const removeTransfer = (id) => {
        const listIdx = transferList.findIndex((el) => id === el.id);
        if (listIdx !== -1 && (transferList[listIdx].status < 0 || transferList[listIdx].status === 3))
            transferList.splice(listIdx, 1);
    };
    const clearTransfer = () => {
        const temp = transferList;
        temp.forEach((el, index) => el.status < 0 || el.status === 3 ? transferList.splice(index, 1) : false);
    };
    const getAll = async () => {
        status.getAll = 'loading';
        try {
            const result = await midiasService.get();
            status.getAll = 'success';
            list.value = result.data;
        }
        catch {
            status.getAll = 'error';
        }
    };
    const remove = async (midia, force) => {
        if (!force) {
            return await dialog.open({
                title: t('feedback.attention'),
                message: t('dialogs.delete.message', { type: t('labels.media').toLowerCase(), name: midia.nome }),
                complete: () => remove(midia, true)
            });
        }
        try {
            status.delete = 'loading';
            const data = await midiasService.remove(midia.id);
            if (data.success)
                await getAll();
            status.delete = 'success';
            return data;
        }
        catch {
            status.delete = 'error';
        }
    };
    const create = async (dataObj, file, coverFile) => {
        status.create = 'loading';
        try {
            const { success, data } = await midiasService.create(dataObj);
            const result = data;
            if (file && success) {
                fileUpload({ id: result.id, name: file.name, fileType: file.type }, file);
            }
            if (coverFile && success) {
                fileUpload({ id: result.id, name: coverFile.name, fileType: coverFile.type, isCoverImage: true }, coverFile);
            }
            if (success && !file && !coverFile)
                getAll();
            status.create = 'success';
            return data;
        }
        catch (error) {
            status.create = 'error';
        }
    };
    const update = async (id, dataObj, file, coverFile) => {
        status.update = 'loading';
        try {
            const { data, success } = await midiasService.update(id, dataObj);
            if (file && success) {
                fileUpload({ id: data.id, name: file.name, fileType: file.type }, file);
            }
            if (coverFile && success) {
                fileUpload({ id: data.id, name: coverFile.name, fileType: coverFile.type, isCoverImage: true }, coverFile);
            }
            if (success)
                getAll();
            status.update = 'success';
            return data;
        }
        catch (error) {
            status.update = 'error';
        }
    };
    const exportMidias = async () => {
        status.export = 'loading';
        try {
            const csvFile = await midiasService.exportCsv();
            status.export = 'success';
            return csvFile;
        }
        catch {
            status.export = 'error';
        }
    };
    const bonds = async (midiaId) => {
        status.bonds = 'loading';
        try {
            const { data } = await midiasService.getBonds(midiaId);
            status.bonds = 'success';
            return data;
        }
        catch {
            status.bonds = 'error';
        }
    };
    const getById = async (midiaId) => {
        status.getById = 'loading';
        try {
            const { data } = await midiasService.get(midiaId);
            status.getById = 'success';
            return data;
        }
        catch {
            status.getById = 'error';
        }
    };
    const setFavorite = async (id, favorite) => {
        status.favorites = 'loading';
        try {
            const { success } = (favorite ? await midiaService.bookmark(id) : await midiaService.unbookmark(id));
            status.favorites = 'success';
            return success;
        }
        catch {
            status.favorites = 'error';
        }
    };
    const setReactionMedia = async (objData) => {
        status.reaction = 'loading';
        try {
            const data = await midiaService.setReactionMedia(objData);
            status.reaction = 'success';
            return data;
        }
        catch {
            status.reaction = 'error';
        }
    };
    return {
        transferList,
        list,
        status,
        // getters
        downloadList,
        uploadList,
        loading,
        uploadProgress,
        // actions
        fileDownload,
        fileUpload,
        cancelTransfer,
        removeTransfer,
        getAll,
        remove,
        create,
        update,
        exportMidias,
        bonds,
        clearTransfer,
        getById,
        setFavorite,
        setReactionMedia
    };
});
